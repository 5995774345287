import { SearchAction, searchActions } from 'modules/app/redux/creators';
import { getType } from 'typesafe-actions';
import { ACTIVITIES_FILTER_KEY } from '../../constants';
import { ActivitiesFilters } from '../../types';
import { ActivitiesActions, filterActions, FilterActions } from '../creators';

const filterReducer = (state: ActivitiesFilters = {}, action: ActivitiesActions | SearchAction | FilterActions) => {
  switch (action.type) {
    case getType(searchActions.setSearch):
      return action.payload.filterKey === ACTIVITIES_FILTER_KEY
        ? { ...state, search: action.payload.searchText }
        : state;

    case getType(filterActions.setOrganizationFilter):
      return { ...state, organizationIds: action.payload };

    case getType(filterActions.setTypeFilter):
      return { ...state, activityTypes: action.payload };

    case getType(filterActions.setDateFromFilter):
      return { ...state, dateFrom: action.payload };

    case getType(filterActions.setDateUntilFilter):
      return { ...state, dateUntil: action.payload };

    default:
      return state;
  }
};

export default filterReducer;
