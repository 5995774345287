import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const Repository: React.FunctionComponent = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" x="0px" y="0px">
      <path d="M28,15H22a1,1,0,0,0-.97.757l-1.667,6.667L16.993,1.885A1,1,0,0,0,15.02,1.8L11.851,17.65,9.965,10.737a1,1,0,0,0-1.894-.109L6.323,15H2a1,1,0,0,0,0,2H7a1,1,0,0,0,.929-.629l.891-2.229,2.215,8.121A1,1,0,0,0,12,23h.034a1,1,0,0,0,.946-.8L15.734,8.425l2.273,19.689a1,1,0,0,0,.928.884L19,29a1,1,0,0,0,.969-.758L22.781,17H28a1,1,0,0,0,0-2Z" />
    </svg>
  </BaseSvgWrapper>
);

export default Repository;
