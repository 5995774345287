import {
  Button,
  CombinedActionWrapper,
  FlexRow,
  Header,
  HeaderActions,
  IconButton,
  Modal,
  SubmitButton,
} from 'components';
import {
  AddPerson,
  ApprovePerson,
  BackArrow,
  Checkmark,
  Close,
  DeclinePerson,
  Delete,
  Difference,
  Edit,
  Mail,
  Print,
  Settings,
} from 'components/icons';
import { generateUniqueFileName } from 'helpers';
import { useActionIsPending } from 'hooks';
import { makeActionRequestIsPendingSelector } from 'modules/app/redux/selectors/loading';
import { organizationDetailActions } from 'modules/organization/redux/actions/creators';
import { makeGetUsers } from 'modules/organization/redux/selectors/organizationDetail';
import { ORGANIZATION_BASE_ROUTE } from 'modules/organization/routes';
import { OrganizationStatus } from 'modules/organization/types/organization';
import {
  DifferenceResponse,
  OrganizationDetailView,
} from 'modules/organization/types/organizationDetail/organizationDetail';
import { User, UserStatus } from 'modules/organization/types/organizationDetail/users';
import { useAccessRightsToFunctionality, useAccountInfo } from 'modules/security';
import { UserType } from 'modules/security/types/user';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getType } from 'typesafe-actions';
import { MultiSelectActions } from '../../../../../hooks/useMultiSelect';
import { DEFAULT_MAX_USERS, OrganizationAccessRights } from '../../../constants';
import AddUserModal from '../../forms/AddUserModal';
import EditOrganizationModal from '../../forms/EditOrganizationModal';
import RejectOrganizationModal from '../../forms/RejectOrganizationModal';
import RejectUserModal from '../../forms/RejectUserModal';
import ApproveConfirmation from './ApproveConfirmation';
import SuspendConfirmation from './SuspendConfirmation';
import DifferenceModal from './difference/DifferenceModal';
import { ModalSizes } from 'components/Modal/Modal';

type Props = {
  activeView: OrganizationDetailView;
  edited: boolean;
  editMode: boolean;
  formRef: React.RefObject<any>;
  organizationId?: string;
  organizationName?: string;
  selectedUsers: User[];
  selectedUsersActions: MultiSelectActions;
  setEdited: (edited: boolean) => void;
  setEditMode: (editMode: boolean) => void;
  status?: OrganizationStatus;
  maxUsers?: number;
  organizationDifference?: DifferenceResponse;
};

const OrganizationDetailHeader: React.FunctionComponent<Props> = ({
  activeView,
  edited,
  editMode,
  formRef,
  organizationId,
  organizationName,
  selectedUsers,
  selectedUsersActions,
  organizationDifference,
  setEdited,
  setEditMode,
  status,
  maxUsers,
}: Props) => {
  const { t } = useTranslation('organization');
  const history = useHistory();
  const dispatch = useDispatch();
  const accountInfo = useAccountInfo();
  const hasAccessToBaseEdit = useAccessRightsToFunctionality(OrganizationAccessRights.EditAndCreateOfBasicInfo);
  const hasAccessToStatusEdit = useAccessRightsToFunctionality(OrganizationAccessRights.EditOfStatus);
  const hasAccessToApproveUser = useAccessRightsToFunctionality(OrganizationAccessRights.ActivateOfUsers);
  const hasAccessToRejectUser = useAccessRightsToFunctionality(OrganizationAccessRights.RejectOfUsers);
  const hasAccessToFullEdit = useAccessRightsToFunctionality(OrganizationAccessRights.EditOfFullDetail);
  const hasAccessToOverview = useAccessRightsToFunctionality(OrganizationAccessRights.AccessToOverview);
  const hasAccessToUserCreate =
    useAccessRightsToFunctionality(OrganizationAccessRights.CreateOfUsers) &&
    accountInfo &&
    accountInfo.userType !== UserType.SuperAdmin;
  const hasAccessToSuspendUser = useAccessRightsToFunctionality(OrganizationAccessRights.SuspendOfUsers);
  const hasAccessToResendInvitation = useAccessRightsToFunctionality(OrganizationAccessRights.InviteUsers);
  const users = useSelector(makeGetUsers());
  const [isDeletingOrg, startDeletingOrg] = useActionIsPending(getType(organizationDetailActions.deleteOrganization));
  const [isApprovingOrg, startApprovingOrg] = useActionIsPending(getType(organizationDetailActions.approve));
  const [isApprovingUser, startApprovingUser] = useActionIsPending(getType(organizationDetailActions.approveUser));
  const [isSuspendingUser, startSuspendingUser] = useActionIsPending(getType(organizationDetailActions.deleteUser));
  const [isPrinting, startPrinting] = useActionIsPending(getType(organizationDetailActions.getPrintDetail));
  const isResendingInv = useSelector(
    makeActionRequestIsPendingSelector(getType(organizationDetailActions.resendInvitation))
  );

  const isUpdatingGeneralData = useSelector(
    makeActionRequestIsPendingSelector(getType(organizationDetailActions.updateGeneralInfo))
  );
  const isUpdatingContacts = useSelector(
    makeActionRequestIsPendingSelector(getType(organizationDetailActions.updateContacts))
  );
  const isUpdatingCTIData = useSelector(
    makeActionRequestIsPendingSelector(getType(organizationDetailActions.updateCTIData))
  );
  const submitButtonPending =
    (isUpdatingGeneralData && activeView === OrganizationDetailView.GENERAL_INFO) ||
    (isUpdatingContacts && activeView === OrganizationDetailView.CONTACTS) ||
    (isUpdatingCTIData && activeView === OrganizationDetailView.CTI);

  const handleBackClick = React.useCallback(() => history.push(ORGANIZATION_BASE_ROUTE), [history]);
  const handleEditClick = React.useCallback(() => setEditMode(true), [setEditMode]);
  const handleSave = React.useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.handleSubmit();
      setEdited(false);
    }
  }, [formRef, setEdited]);

  const handleCancelClick = React.useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.resetForm();
      setEdited(false);
    }
  }, [formRef, setEdited]);

  const handleCloseClick = React.useCallback(() => setEditMode(false), [setEditMode]);

  const handleApproveOrganization = React.useCallback(() => {
    if (hasAccessToStatusEdit && organizationId) {
      startApprovingOrg();
      dispatch(organizationDetailActions.approve(organizationId));
    }
  }, [dispatch, organizationId, hasAccessToStatusEdit, startApprovingOrg]);

  const handleDeleteOrganization = React.useCallback(() => {
    if (hasAccessToStatusEdit && organizationId) {
      startDeletingOrg();
      dispatch(organizationDetailActions.deleteOrganization(organizationId));
    }
  }, [dispatch, organizationId, hasAccessToStatusEdit, startDeletingOrg]);

  const handleApproveUser = React.useCallback(() => {
    // this action is only allowed if there's a single user selected
    if (hasAccessToApproveUser && selectedUsers && selectedUsers[0]) {
      startApprovingUser();
      dispatch(
        organizationDetailActions.approveUser(selectedUsers[0].userId, () => {
          selectedUsersActions.deselectAll();
        })
      );
    }
  }, [dispatch, selectedUsers, hasAccessToApproveUser, selectedUsersActions, startApprovingUser]);

  const isUserApprovalFlowDisabled = React.useMemo(() => {
    const singleUser = selectedUsers.length === 1 && selectedUsers[0];

    return (
      selectedUsers.length !== 1 ||
      (singleUser && (singleUser.type === UserType.Admin || singleUser.status !== UserStatus.Waiting))
    );
  }, [selectedUsers]);

  const handlePrint = React.useCallback(() => {
    if (organizationId && organizationName) {
      startPrinting();
      dispatch(
        organizationDetailActions.getPrintDetail({
          fileName: generateUniqueFileName('detail', organizationName, 'pdf'),
          organizationIds: [organizationId],
        })
      );
    }
  }, [dispatch, organizationId, organizationName, startPrinting]);

  const isUserSuspendDisabled = React.useMemo(
    () =>
      selectedUsers.length !== 1 ||
      selectedUsers.filter((user) => user.type === UserType.Admin).length !== 0 ||
      selectedUsers.filter((user) => user.status === UserStatus.Suspended).length !== 0,
    [selectedUsers]
  );

  const handleSuspendUser = React.useCallback(() => {
    if (hasAccessToSuspendUser && !isUserSuspendDisabled && selectedUsers[0].userId) {
      startSuspendingUser();
      dispatch(
        organizationDetailActions.deleteUser(selectedUsers[0].userId, () => {
          selectedUsersActions.deselectAll();
        })
      );
    }
  }, [
    dispatch,
    hasAccessToSuspendUser,
    isUserSuspendDisabled,
    selectedUsers,
    selectedUsersActions,
    startSuspendingUser,
  ]);

  const isResendInvitationDisabled = React.useMemo(
    () => selectedUsers.length !== 1 || (selectedUsers.length === 1 && selectedUsers[0].status !== UserStatus.Pending),
    [selectedUsers]
  );

  const handleResendInvitation = React.useCallback(() => {
    if (selectedUsers.length === 1 && selectedUsers[0].status === UserStatus.Pending) {
      dispatch(organizationDetailActions.resendInvitation(selectedUsers[0].userId));
    }
  }, [dispatch, selectedUsers]);

  const isNotSuspendend = (user: User) => user.status !== UserStatus.Suspended;
  const canAddMoreUsers = React.useMemo(() => {
    return Boolean(users && users.filter((user) => isNotSuspendend(user)).length < (maxUsers || DEFAULT_MAX_USERS));
  }, [users, maxUsers]);

  return (
    <Header>
      <FlexRow>
        {hasAccessToOverview && (
          <IconButton tooltip={t('global:general.back')} onClick={handleBackClick} tooltipLeftAligned>
            <BackArrow />
          </IconButton>
        )}

        <IconButton isLoading={isPrinting} tooltip={t('tooltips.print')} onClick={handlePrint} tooltipLeftAligned>
          <Print />
        </IconButton>

        {hasAccessToBaseEdit && (
          <Modal content={EditOrganizationModal}>
            {(toggleModal) => (
              <IconButton onClick={toggleModal} tooltip={t('tooltips.edit')} tooltipLeftAligned>
                <Settings />
              </IconButton>
            )}
          </Modal>
        )}

        {hasAccessToStatusEdit && (
                status === OrganizationStatus.ReEvaluation ||
                status === OrganizationStatus.ToCorrect) && (
          <Modal
            content={DifferenceModal}
            contentProps={{ organizationId, organizationDifference }}
            size={ModalSizes.FullScreen}
          >
            {(toggleModal) => (
              <IconButton onClick={toggleModal} tooltip={t('tooltips.difference')} tooltipLeftAligned>
                <Difference />
              </IconButton>
            )}
          </Modal>
        )}

        {hasAccessToStatusEdit && activeView === OrganizationDetailView.GENERAL_INFO && (
          <React.Fragment>
            {status &&
              (status === OrganizationStatus.Waiting ||
                status === OrganizationStatus.Pending ||
                status === OrganizationStatus.ReEvaluation ||
                status === OrganizationStatus.ToCorrect) && (
                <CombinedActionWrapper
                  disabled={status === OrganizationStatus.Pending || status === OrganizationStatus.ToCorrect}
                >
                  <ApproveConfirmation callback={handleApproveOrganization}>
                    <IconButton
                      disabled={status === OrganizationStatus.Pending || status === OrganizationStatus.ToCorrect}
                      isLoading={isApprovingOrg}
                      tooltip={t('tooltips.approve')}
                      tooltipLeftAligned
                    >
                      <Checkmark />
                    </IconButton>
                  </ApproveConfirmation>
                  <Modal content={RejectOrganizationModal} contentProps={{ organizationId }}>
                    {(toggleModal) => (
                      <IconButton
                        disabled={status === OrganizationStatus.Pending || status === OrganizationStatus.ToCorrect}
                        onClick={toggleModal}
                        tooltip={t('tooltips.reject')}
                        tooltipLeftAligned
                      >
                        <Close />
                      </IconButton>
                    )}
                  </Modal>
                </CombinedActionWrapper>
              )}
            <SuspendConfirmation callback={handleDeleteOrganization}>
              <IconButton
                disabled={status && status === OrganizationStatus.Suspended}
                isLoading={isDeletingOrg}
                tooltip={t('tooltips.suspend')}
                tooltipLeftAligned
              >
                <Delete />
              </IconButton>
            </SuspendConfirmation>
          </React.Fragment>
        )}

        {hasAccessToFullEdit && activeView !== OrganizationDetailView.USERS && (
          <IconButton onClick={handleEditClick} tooltip={t('tooltips.edit')} disabled={editMode} tooltipLeftAligned>
            <Edit />
          </IconButton>
        )}

        {hasAccessToUserCreate && organizationId && activeView === OrganizationDetailView.USERS && (
          <Modal content={AddUserModal} contentProps={{ organizationId }}>
            {(toggleModal) => (
              <IconButton
                onClick={toggleModal}
                tooltip={t('tooltips.addUser')}
                disabled={!canAddMoreUsers}
                tooltipLeftAligned
              >
                <AddPerson />
              </IconButton>
            )}
          </Modal>
        )}
        {hasAccessToBaseEdit &&
          activeView === OrganizationDetailView.USERS &&
          users?.length === 0 &&
          status === OrganizationStatus.New && (
            <Modal content={AddUserModal} contentProps={{ organizationId, isAdmin: true }}>
              {(toggleModal) => (
                <IconButton onClick={toggleModal} tooltip={t('tooltips.addAdmin')} tooltipLeftAligned>
                  <AddPerson />
                </IconButton>
              )}
            </Modal>
          )}

        {(hasAccessToApproveUser || hasAccessToRejectUser) && activeView === OrganizationDetailView.USERS && (
          <CombinedActionWrapper disabled={isUserApprovalFlowDisabled}>
            {hasAccessToApproveUser && (
              <ApproveConfirmation callback={handleApproveUser}>
                <IconButton
                  disabled={isUserApprovalFlowDisabled}
                  isLoading={isApprovingUser}
                  tooltip={t('tooltips.approve')}
                  tooltipLeftAligned
                >
                  <ApprovePerson />
                </IconButton>
              </ApproveConfirmation>
            )}
            {hasAccessToRejectUser && (
              <Modal
                content={RejectUserModal}
                contentProps={{
                  selectedUsersActions,
                  userId: selectedUsers.length === 1 && selectedUsers[0] ? selectedUsers[0].userId : undefined,
                }}
              >
                {(toggleModal) => (
                  <IconButton
                    disabled={isUserApprovalFlowDisabled}
                    onClick={toggleModal}
                    tooltip={t('tooltips.reject')}
                    tooltipLeftAligned
                  >
                    <DeclinePerson />
                  </IconButton>
                )}
              </Modal>
            )}
          </CombinedActionWrapper>
        )}

        {hasAccessToSuspendUser && activeView === OrganizationDetailView.USERS && (
          <SuspendConfirmation callback={handleSuspendUser}>
            <IconButton
              disabled={isUserSuspendDisabled}
              isLoading={isSuspendingUser}
              tooltip={t('tooltips.suspendUser')}
              tooltipLeftAligned
            >
              <Delete />
            </IconButton>
          </SuspendConfirmation>
        )}
        {hasAccessToResendInvitation && activeView === OrganizationDetailView.USERS && (
          <IconButton
            disabled={isResendInvitationDisabled}
            isLoading={isResendingInv}
            tooltip={t('tooltips.resendInvitation')}
            onClick={handleResendInvitation}
            tooltipLeftAligned
          >
            <Mail />
          </IconButton>
        )}
      </FlexRow>
      {editMode && (
        <HeaderActions>
          <Button tertiary onClick={edited ? handleCancelClick : handleCloseClick}>
            {edited ? t('global:general.cancel') : t('global:general.close')}
          </Button>
          <SubmitButton primary onClick={handleSave} disabled={!edited} isLoading={submitButtonPending}>
            {t('global:general.save')}
          </SubmitButton>
        </HeaderActions>
      )}
    </Header>
  );
};

export default OrganizationDetailHeader;
