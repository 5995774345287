import { call, put, takeLatest } from '@redux-saga/core/effects';
import { getUnconfirmedAlerts, confirmCriticalAlert } from '../../api/criticalAlertsApi';
import { criticalAlertsActions } from '../creators';

function* criticalAlertsSaga() {
  yield takeLatest(criticalAlertsActions.getUnconfirmedCriticalAlerts, handleGetUnconfirmedCriticalAlerts);
  yield takeLatest(criticalAlertsActions.confirmCriticalAlert, handleConfirmCriticalAlert);
}

function* handleGetUnconfirmedCriticalAlerts() {
  const { request, success, failure } = criticalAlertsActions.getUnconfirmedCriticalAlertsRequest;
  yield put(request());

  try {
    const response = yield call(getUnconfirmedAlerts);
    yield put(success(response));
  } catch (e) {
    yield put(failure(e.response));
  }
}

function* handleConfirmCriticalAlert(action: ReturnType<typeof criticalAlertsActions.confirmCriticalAlert>) {
  const { request, success, failure } = criticalAlertsActions.confirmCriticalAlertRequest;
  yield put(request());

  try {
    yield call(confirmCriticalAlert, action.payload);
    yield put(success(action.payload));
  } catch (e) {
    yield put(failure(e.response));
  }
}

export default criticalAlertsSaga;
