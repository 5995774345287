import * as React from 'react';
import styled from '../../config/theme';
import { animations } from '../../config/theme/keyframes';

type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  x: number | null;
  y: number | null;
  visible: boolean;
  children?: JSX.Element;
};

const ContextMenuWrapper = React.forwardRef<HTMLDivElement, Props>(({ x, y, children, visible }, ref) => (
  <ContextMenuContentWrapper x={x} y={y} visible={visible} ref={ref}>
    {children}
  </ContextMenuContentWrapper>
));

const ContextMenuContentWrapper = styled.div<{ x: number | null; y: number | null; visible: boolean }>`
  position: fixed;
  top: ${props => props.y || 0}px;
  left: ${props => props.x || 0}px;
  z-index: 9999;
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
  animation: ${animations.fadeIn} 150ms ease-in;
`;

export default ContextMenuWrapper;
