import { getType } from 'typesafe-actions';
import { CriticalAlert } from '../../../app/types/criticalAlert';
import { CriticalAlertsActions, criticalAlertsActions } from '../creators';
import { CriticalAlertPostBody } from 'modules/flashAlerts/types';

export type CriticalAlertsModuleState = CriticalAlert[] | null;

const criticalAlertsReducer = (
  state: CriticalAlertsModuleState = null,
  action: CriticalAlertsActions,
  payload?: CriticalAlertPostBody
) => {
  switch (action.type) {
    case getType(criticalAlertsActions.getCriticalAlertsRequest.success):
      return action.payload.items;
    case getType(criticalAlertsActions.addCriticalAlertRequest.success):
      return state
        ? [{ ...action.payload.response, description: action.payload.data.description }, ...state]
        : [action.payload.response];
    case getType(criticalAlertsActions.deleteCriticalAlertRequest.success):
      return state ? state.filter((criticalAlert) => criticalAlert.guid !== action.payload) : state;
    case getType(criticalAlertsActions.editCriticalAlertRequest.success):
      return state
        ? state.map((criticalAlert) => (criticalAlert.guid === action.payload.guid ? action.payload : criticalAlert))
        : state;
    default:
      return state;
  }
};

export default criticalAlertsReducer;
