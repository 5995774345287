import { Sector } from 'modules/sector/types/sector';
import { getType } from 'typesafe-actions';
import { SectorActions, sectorActions } from '../actions/creators';

const sectorReducer = (state: Sector[] | null = null, action: SectorActions) => {
  switch (action.type) {
    case getType(sectorActions.getSectorsRequest.success):
      return action.payload.items;

    case getType(sectorActions.addSectorRequest.success):
      return state
        ? [...state, { ...action.payload, nbrOfOrganizations: 0 }]
        : [{ ...action.payload, nbrOfOrganizations: 0 }];

    case getType(sectorActions.deleteSectorRequest.success):
    case getType(sectorActions.editSectorRequest.success):
    default:
      return state;
  }
};

export default sectorReducer;
