import { FieldProps } from 'formik';
import AvailabilityTimeSlider from 'modules/app/components/availabilities/AvailabilityTimeSlider';
import * as React from 'react';
import styled from 'styled-components';
import { Label } from '../../../../components/Form/Label';

type Props = FieldProps & {
  label?: string;
};

const OnboardingTimeSlider: React.FunctionComponent<Props> = ({ label, field, ...props }: Props) => {
  return (
    <AvailabilityTimeSliderWrapper>
      {<Label htmlFor={field.name}>{label || field.name}</Label>}
      <div>
        <AvailabilityTimeSlider field={field} {...props} />
      </div>
    </AvailabilityTimeSliderWrapper>
  );
};

const AvailabilityTimeSliderWrapper = styled.div`
  > div {
    height: 40px;
    margin: 25px 0;
  }
`;

export default OnboardingTimeSlider;
