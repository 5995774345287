export const ARRAY = 'array';
export const CUSTOM = 'custom';
export const HIDDEN = 'hidden';

export const ORGANIZATIONS_URL = '/organization/v2/organizations';
export const USERS_URL = '/organization/v2/users';

export const GENERAL_DATA: string = 'general-data';
export const CTI_DATA: string = 'cti-data';
export const CONTACTS: string = 'contacts';
export const USER_DATA: string = 'user';

export const TAG = 'tag';
export const DARK_WEB = 'darkWeb';
export const EMAIL_DOMAIN = 'emailDomain';
export const MISP_EMAIL_DOMAIN = 'mispEmailDomain';
export const MISP_SECTOR = 'mispSector';

export const CONTACTS_OVERVIEW = 'contacts-overview';
export const FINAL = 'final';

export const initialAvailabilities = [{ days: [], hours: [9, 17] }];
