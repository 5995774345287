import * as React from 'react';
import styled from '../../config/theme';

type Props = {
  closeMenu?: () => void;
  children: Array<JSX.Element | false | null> | JSX.Element | false | null;
  maxHeight?: number;
  className?: string;
};

const ContextMenuContainer: React.FunctionComponent<Props> = ({ children, closeMenu, maxHeight, className }: Props) => {
  const preventScrollBubbling = (e: React.UIEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <ContextMenuContainerWrapper maxHeight={maxHeight} onScroll={preventScrollBubbling} className={className}>
      {children &&
        React.Children.map(
          Array.isArray(children) ? (children.filter(x => x) as JSX.Element[]) : children,
          (child: JSX.Element, index: number) => React.cloneElement(child, { closeMenu, key: `menu_${index}` })
        )}
    </ContextMenuContainerWrapper>
  );
};

const ContextMenuContainerWrapper = styled.div<{ maxHeight?: number }>`
  background-color: ${props => props.theme.contextMenu.background};
  box-shadow: ${props => props.theme.contextMenu.boxShadow};
  display: flex;
  flex-direction: column;
  border-radius: ${props => props.theme.contextMenu.borderRadius};
  ${props => props.maxHeight && `max-height: ${props.maxHeight}px`};
  overflow-y: auto;
`;

export default ContextMenuContainer;
