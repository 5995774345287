import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from '../../../config/store/rootReducer';
import { NestedGroup } from '../../../helpers/groupBy';
import { AccessibleModulesContext } from '../context/AccessibleModules';
import { EditableModulesContext } from '../context/EditableModules';
import { moduleActions, ModulesActionsType } from '../redux/actions/creators';
import { makeGetAvailableModules } from '../redux/selectors/modules';
import { Modules } from '../types/modules';
import { AccessRight } from '../types/user';
import { canEditModule, canReadModule } from '../util';

type ModulesDispatchProps = {
  getAvailableModules: () => void;
};

type ModulesStateToProps = {
  modules: Modules;
};

type RenderCallback = (modules: Modules) => JSX.Element | null;

type Props = {
  children: RenderCallback;
  accessRights?: NestedGroup<AccessRight>;
} & ModulesStateToProps &
  ModulesDispatchProps;

const AllowedModulesFetcher: React.FunctionComponent<Props> = ({
  accessRights,
  children,
  getAvailableModules,
  modules,
}: Props) => {
  React.useEffect(() => {
    getAvailableModules();
  }, [getAvailableModules]);

  const accessibleModules = React.useMemo(() => modules.filter(module => canReadModule(module, accessRights)), [
    modules,
    accessRights,
  ]);

  const editableModules = React.useMemo(
    () => modules.filter(module => canEditModule(module, accessRights)).map(module => module.key.toLowerCase()),
    [modules, accessRights]
  );

  const hasEditFunctionality = (module: string) => editableModules.includes(module.toLowerCase());

  return accessibleModules.length > 0 ? (
    <EditableModulesContext.Provider value={{ hasEditFunctionality }}>
      <AccessibleModulesContext.Provider value={accessibleModules.map(module => module.key)}>
        {children(accessibleModules)}
      </AccessibleModulesContext.Provider>
    </EditableModulesContext.Provider>
  ) : null;
};

const mapDispatchToProps = (dispatch: Dispatch<ModulesActionsType>) =>
  bindActionCreators(
    {
      getAvailableModules: moduleActions.getAvailableModules,
    },
    dispatch
  );

const makeMapStateToProps = () => {
  const availableModulesSelector = makeGetAvailableModules();

  return (state: RootState) => ({
    modules: availableModulesSelector(state),
  });
};

const enhance = connect(makeMapStateToProps, mapDispatchToProps);

export default enhance(AllowedModulesFetcher);
