import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from '../../../config/store/rootReducer';
import { NestedGroup } from '../../../helpers/groupBy';
import { makeIsPendingSelector } from '../../app/redux/selectors/loading';
import { UserActionsType } from '../redux/actions/creators';
import { USER_ACTION_PREFIX } from '../redux/actions/creators/user';
import { getUser } from '../redux/actions/creators/user';
import { makeGetAccessRights, makeGetAccount, makeGetIsAuthenticated } from '../redux/selectors/user';
import { AccessRight, Account } from '../types/user';

type Props = {
  children: RenderCallback;
} & DispatchProps &
  MapStateToProps;

type DispatchProps = {
  getUser: () => void;
};

type MapStateToProps = {
  accessRights?: NestedGroup<AccessRight>;
  account?: Account;
  isAuthenticated: boolean;
  isPending?: boolean;
};

export type UserManagerRenderProps = {
  accessRights?: NestedGroup<AccessRight>;
  account?: Account;
  isAuthenticated: boolean;
};

type RenderCallback = (renderProp: MapStateToProps) => JSX.Element | null;

class UserManager extends React.PureComponent<Props> {
  componentDidMount() {
    this.props.getUser();
  }

  render(): JSX.Element | null {
    const { children, accessRights, account, isAuthenticated, isPending } = this.props;
    const renderProp: UserManagerRenderProps = {
      accessRights,
      account,
      isAuthenticated,
    };

    if (children && !isPending) {
      return children(renderProp);
    } else {
      return null;
    }
  }
}

const makeMapStateToProps = () => {
  const isAuthenticatedSelect = makeGetIsAuthenticated();
  const isPendingSelect = makeIsPendingSelector(USER_ACTION_PREFIX);
  const accountSelect = makeGetAccount();
  const accessRightsSelect = makeGetAccessRights();

  return (state: RootState) => ({
    accessRights: accessRightsSelect(state),
    account: accountSelect(state),
    isAuthenticated: isAuthenticatedSelect(state),
    isPending: isPendingSelect(state),
  });
};

const mapDispatchToProps = (dispatch: Dispatch<UserActionsType>) =>
  bindActionCreators(
    {
      getUser,
    },
    dispatch
  );

const enhance = connect(makeMapStateToProps, mapDispatchToProps);

export default enhance(UserManager);
