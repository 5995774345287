import { IconButton } from 'components';
import { ArrayHelperActionWrapper, BasicTextField, EditableItem, FullViewSubBlock } from 'components/FullView';
import { Add, Delete } from 'components/icons';
import CountrySelect from 'components/Select/CountrySelect';
import { ArrayHelpers, Field, FieldProps, FormikErrors, FormikTouched } from 'formik';
import { getNestedError } from 'helpers/formikHelper';
import { newBusinessLocation } from 'modules/organization/constants';
import {
  BusinessLocationEditValues,
  GeneralInfoEditValues,
} from 'modules/organization/types/organizationDetail/general';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  location: BusinessLocationEditValues;
  index: number;
  errors: FormikErrors<GeneralInfoEditValues>;
  touched: FormikTouched<GeneralInfoEditValues>;
  arrayHelpers: ArrayHelpers;
};

const EditAddressBlock: React.FunctionComponent<Props> = ({
  location,
  index,
  errors,
  touched,
  arrayHelpers,
}: Props) => {
  const { t } = useTranslation('organization');

  const addAddress = React.useCallback(() => arrayHelpers.push(newBusinessLocation), [arrayHelpers]);
  const removeAddress = React.useCallback(() => arrayHelpers.remove(index), [arrayHelpers, index]);

  return (
    <FullViewSubBlock>
      <h3 className="sub-title">{location.name || t('detail.labels.name')}</h3>
      <ArrayHelperActionWrapper>
        <BasicTextField
          name={`businessLocations.${index}.name`}
          label={t('detail.labels.organizationLocation')}
          errors={errors}
          touched={touched}
          autoFocus={!location.addressId}
        />
        {index === 0 ? (
          <IconButton onClick={addAddress} tooltip={t('tooltips.addAddress')}>
            <Add />
          </IconButton>
        ) : (
          <IconButton onClick={removeAddress} tooltip={t('tooltips.removeAddress')}>
            <Delete />
          </IconButton>
        )}
      </ArrayHelperActionWrapper>
      <BasicTextField
        name={`businessLocations.${index}.street`}
        label={t('detail.labels.street')}
        errors={errors}
        touched={touched}
      />
      <BasicTextField
        name={`businessLocations.${index}.number`}
        label={t('detail.labels.number')}
        errors={errors}
        touched={touched}
      />
      <BasicTextField
        name={`businessLocations.${index}.zipCode`}
        label={t('detail.labels.zipCode')}
        errors={errors}
        touched={touched}
      />
      <BasicTextField
        name={`businessLocations.${index}.city`}
        label={t('detail.labels.city')}
        errors={errors}
        touched={touched}
      />
      <BasicTextField
        name={`businessLocations.${index}.state`}
        label={t('detail.labels.state')}
        errors={errors}
        touched={touched}
      />
      <EditableItem
        label={t('detail.labels.country')}
        value={
          <Field
            name={`businessLocations.${index}.countryId`}
            render={(fieldProps: FieldProps<GeneralInfoEditValues>) => (
              <CountrySelect
                {...fieldProps}
                error={getNestedError(`businessLocations.${index}.countryId`, errors, touched)}
              />
            )}
          />
        }
      />
    </FullViewSubBlock>
  );
};

export default EditAddressBlock;
