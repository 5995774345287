import { EmptyStateWrapper, IconButton } from 'components';
import { Bell, Close } from 'components/icons';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { notificationActions } from '../redux/creators';
import { makeGetNotifications } from '../redux/selectors';
import { Drawer, DrawerProps } from './Drawer';
import Notification from './Notification';
import { NotificationWrapper } from './NotificationLayout';

type Props = {
  toggleDrawer: () => void;
} & DrawerProps;

const NotificationDrawer: React.FunctionComponent<Props> = ({ open, toggleDrawer }) => {
  const notifications = useSelector(makeGetNotifications());
  const dispatch = useDispatch();
  const { t } = useTranslation('global');

  React.useEffect(() => {
    dispatch(notificationActions.getNotifications());
  }, [dispatch]);

  return (
    <Drawer open={open}>
      <header>
        <h3>{t('notifications.title')}</h3>
        <IconButton onClick={toggleDrawer}>
          <Close />
        </IconButton>
      </header>

      {notifications && notifications.length > 0 ? (
        <NotificationWrapper>
          {notifications.map(notification => (
            <Notification key={notification.notificationId} notification={notification} toggleDrawer={toggleDrawer} />
          ))}
        </NotificationWrapper>
      ) : (
        <EmptyStateWrapper>
          <Bell />
          <h1>{t('notifications.emptyTitle')}</h1>
          <p>{t('notifications.emptyDescription')}</p>
        </EmptyStateWrapper>
      )}
    </Drawer>
  );
};

export default NotificationDrawer;
