import styled from 'styled-components';

export const MarkdownWrapper = styled.div`
  table {
    border-spacing: 0 !important;
    border-collapse: collapse !important;
    border-color: inherit !important;
    display: block !important;
    width: max-content !important;
    max-width: 100% !important;
    overflow: auto !important;
  }

  tbody,
  td,
  tfoot,
  th,
  thead,
  tr {
    border-color: inherit !important;
    border-style: solid !important;
    border-width: 1px !important;
  }
`;
