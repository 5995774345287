import AxiosConfig, { RequestConfig } from '../../../config/api/AxiosConfig';
import { COUNTRIES_URL, REPOSITORIES_URL } from '../constants';
import { ReportType, Sector } from '../types';

export const getCountries = async () => {
  const requestConfig: RequestConfig = {
    url: `${COUNTRIES_URL}`,
  };

  return AxiosConfig.fetchAxiosGetRequest(requestConfig);
};

export const getReportTypes = (search?: string) => {
  const requestConfig: RequestConfig = {
    params: {
      ...(search && { search }),
    },
    url: `${REPOSITORIES_URL}/reporttypes`,
  };

  return AxiosConfig.fetchAxiosGetRequest<ReportType[]>(requestConfig);
};

export const getSectors = (search?: string) => {
  const requestConfig: RequestConfig = {
    params: {
      ...(search && { search }),
    },
    url: `${REPOSITORIES_URL}/sectors`,
  };

  return AxiosConfig.fetchAxiosGetRequest<Sector[]>(requestConfig);
};
export default { getCountries, getSectors, getReportTypes };
