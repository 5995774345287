import * as React from 'react';
import organizationApi from '../../api/organizationApi';

const useOrganizationGeneralStore = () => {
  const loadSectorOptions = React.useCallback((inputValue?: string) => organizationApi.getSectors(inputValue), []);

  return {
    loadSectorOptions,
  };
};

export default useOrganizationGeneralStore;
