import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const People: React.FunctionComponent = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
      <path d="M31.0529 16.72C34.3892 16.72 37.1059 14.1726 37.1059 11.0443V8.6757C37.1059 5.54736 34.3892 3 31.0529 3C27.7167 3 25 5.54736 25 8.6757V11.089C25.0477 14.1726 27.7643 16.72 31.0529 16.72Z" />
      <path d="M34.9 38C34.9 38 34.9 38 34.9 38C34.0895 33.6233 32.6296 29.6669 30.6408 26.3185C30.746 26.262 30.8432 26.1916 30.9283 26.1072L34.6713 21.9164C35.0383 21.5766 35.5521 21.35 36.1392 21.35C40.6896 21.35 44.6529 23.7853 45.6804 27.2398L47.8822 34.7153C48.3959 36.4143 46.7079 38 44.5061 38L34.9 38Z" />
      <path d="M30.1473 39.248L28.3025 32.9848C27.4416 30.0904 24.1211 28.0501 20.3086 28.0501C19.8166 28.0501 19.3862 28.2399 19.0787 28.5246L15.9426 32.0358C15.5122 32.4628 14.7128 32.4628 14.2824 32.0358L11.1463 28.5246C10.9003 28.2399 10.4084 28.0501 9.91644 28.0501C6.10394 28.0501 2.72189 30.0904 1.9225 32.9848L0.0777392 39.248C-0.352704 40.6715 1.06161 42.0001 2.90636 42.0001H27.3186C29.1634 42.0001 30.5777 40.6715 30.1473 39.248Z" />
      <path d="M15.1125 24.175C18.3162 24.175 20.925 21.7288 20.925 18.7248V16.4502C20.925 13.4462 18.3162 11 15.1125 11C11.9088 11 9.30001 13.4462 9.30001 16.4502V18.7677C9.34577 21.7288 11.9545 24.175 15.1125 24.175Z" />
    </svg>
  </BaseSvgWrapper>
);

export default People;
