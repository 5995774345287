import { Input, NumberInput } from 'components';
import { FormikSelect } from 'components/Form';
import { TYPE_NUMBER } from 'components/Form/Input';
import { Field, FieldProps, Form, Formik } from 'formik';
import { DEFAULT_MAX_USERS, addOrganizationInitialValues } from 'modules/organization/constants';
import { addOrganizationSchema, editOrganizationSchema } from 'modules/organization/schemas/organization';
import {
  AddOrganizationFormValues,
  EditOrganizationValues,
  OrganizationSector,
} from 'modules/organization/types/organization';
import { UserStatus } from 'modules/organization/types/organizationDetail/users';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import useOrganizationDetail from '../../redux/services/useOrganizationDetail';
import useOrganizationGeneralStore from '../../redux/services/useOrganizationGeneralStore';

type IsEditProps =
  | {
      isEdit?: true;
      initialValues?: EditOrganizationValues;
    }
  | {
      isEdit?: false;
      initialValues?: AddOrganizationFormValues;
    };

type Props = {
  onSubmit: (values: AddOrganizationFormValues) => void;
  onFormChange: () => void;
  formRef: React.Ref<any>;
} & IsEditProps;

const AddOrganizationForm: React.FunctionComponent<Props> = ({
  onSubmit,
  onFormChange,
  formRef,
  isEdit,
  initialValues,
}: Props) => {
  const { t } = useTranslation('organization');
  const { organizationUsers } = useOrganizationDetail();
  const { loadSectorOptions } = useOrganizationGeneralStore();

  const activeUsers = organizationUsers?.filter((user) => user.status !== UserStatus.Suspended).length;
  return (
    <Formik
      initialValues={
        isEdit && initialValues
          ? {
              ...initialValues,
              emailAdminUser: '',
              maxUsers: initialValues.maxUsers || DEFAULT_MAX_USERS,
            }
          : addOrganizationInitialValues
      }
      onSubmit={onSubmit}
      ref={formRef}
      validate={onFormChange}
      validationSchema={isEdit ? editOrganizationSchema(activeUsers || 1) : addOrganizationSchema}
    >
      {({ touched, errors }) => {
        return (
          <Form>
            <Field name="name">
              {({ field }: FieldProps<AddOrganizationFormValues>) => (
                <Input
                  autoFocus
                  {...field}
                  type="text"
                  label={t('add.labels.name')}
                  error={(touched.name && errors.name) || ''}
                />
              )}
            </Field>

            <Field name="maxUsers">
              {({ field, form }: FieldProps<AddOrganizationFormValues>) => (
                <NumberInput
                  {...field}
                  type={TYPE_NUMBER}
                  label={t('add.labels.maxUsers')}
                  precision={0}
                  error={(touched.maxUsers && errors.maxUsers) || ''}
                  onChange={({ numberValue }) => form.setFieldValue(field.name, numberValue)}
                />
              )}
            </Field>

            {!isEdit && (
              <Field name="emailAdminUser">
                {({ field }: FieldProps<AddOrganizationFormValues>) => (
                  <Input
                    {...field}
                    type="text"
                    label={t('add.labels.email')}
                    error={(touched.emailAdminUser && errors.emailAdminUser) || ''}
                  />
                )}
              </Field>
            )}

            <Field name="sectors">
              {(fieldProps: FieldProps) => (
                <FormikSelect
                  {...fieldProps}
                  isMulti
                  getOptionValue={(option: OrganizationSector) => option.sectorId.toString()}
                  getOptionLabel={(option: OrganizationSector) => option.name}
                  async
                  loadOptions={loadSectorOptions}
                  label={t('add.labels.sectors')}
                  error={(touched.sectors && errors.sectors) || ''}
                />
              )}
            </Field>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddOrganizationForm;
