import { call, put, takeLatest } from '@redux-saga/core/effects';
import { getElegibleOrganizations } from 'modules/app/api/superAdmin';
import { superAdminActions } from '../creators';

function* superAdminSaga() {
  yield takeLatest(superAdminActions.getElegibleOrganizations, handleGetEllegibleOrganizations);
}

function* handleGetEllegibleOrganizations() {
  const { request, success, failure } = superAdminActions.getElegibleOrganizationsRequest;

  yield put(request());
  try {
    const payload = yield call(getElegibleOrganizations);
    yield put(success(payload));
  } catch (e) {
    yield put(failure(e.response));
  }
}

export default superAdminSaga;
