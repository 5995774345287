import { call, put, takeLatest } from 'redux-saga/effects';
import MailsApi from '../../api/MailsApi';
import { MAIL_GET } from '../actions/constants/mail';
import { fetchMail, rejectFetchMail, setMail } from '../actions/creators/mail';
import { GetMailAction } from '../actions/types/mail';

function* getMailSaga() {
  yield takeLatest(MAIL_GET, handleFetchMail);
}

function* handleFetchMail(action: GetMailAction) {
  yield put(fetchMail());
  try {
    const { id } = action.payload;
    const payload = yield call(MailsApi.getMail, id);
    yield put(setMail(payload));
  } catch (e) {
    yield put(rejectFetchMail(e.response));
  }
}

export default getMailSaga;
