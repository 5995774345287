import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const Filter = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 16.5C17 20.6421 13.6421 24 9.5 24C5.35786 24 2 20.6421 2 16.5C2 12.3579 5.35786 9 9.5 9C13.6421 9 17 12.3579 17 16.5ZM5.75 16.5C5.75 18.5711 7.42893 20.25 9.5 20.25C11.5711 20.25 13.25 18.5711 13.25 16.5C13.25 14.4289 11.5711 12.75 9.5 12.75C7.42893 12.75 5.75 14.4289 5.75 16.5Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3H7V9.42676C7.78195 9.15039 8.62341 9 9.5 9C10.3766 9 11.2181 9.15039 12 9.42676V3ZM12 23.5732C11.2181 23.8496 10.3766 24 9.5 24C8.62341 24 7.78195 23.8496 7 23.5732V46H12V23.5732Z"
      />
      <path d="M31 34.5C31 38.6421 27.6421 42 23.5 42C19.3579 42 16 38.6421 16 34.5C16 30.3579 19.3579 27 23.5 27C27.6421 27 31 30.3579 31 34.5ZM19.75 34.5C19.75 36.5711 21.4289 38.25 23.5 38.25C25.5711 38.25 27.25 36.5711 27.25 34.5C27.25 32.4289 25.5711 30.75 23.5 30.75C21.4289 30.75 19.75 32.4289 19.75 34.5Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26 3H21V27.4268C21.7819 27.1504 22.6234 27 23.5 27C24.3766 27 25.2181 27.1504 26 27.4268V3ZM26 41.5732C25.2181 41.8496 24.3766 42 23.5 42C22.6234 42 21.7819 41.8496 21 41.5732V46H26V41.5732Z"
      />
      <path d="M46 22.5C46 26.6421 42.6421 30 38.5 30C34.3579 30 31 26.6421 31 22.5C31 18.3579 34.3579 15 38.5 15C42.6421 15 46 18.3579 46 22.5ZM34.75 22.5C34.75 24.5711 36.4289 26.25 38.5 26.25C40.5711 26.25 42.25 24.5711 42.25 22.5C42.25 20.4289 40.5711 18.75 38.5 18.75C36.4289 18.75 34.75 20.4289 34.75 22.5Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41 3H36V15.4268C36.7819 15.1504 37.6234 15 38.5 15C39.3766 15 40.2181 15.1504 41 15.4268V3ZM41 29.5732C40.2181 29.8496 39.3766 30 38.5 30C37.6234 30 36.7819 29.8496 36 29.5732V46H41V29.5732Z"
      />
    </svg>
  </BaseSvgWrapper>
);

export default Filter;
