import styled, { css } from 'config/theme';
import { media } from 'config/theme/utils';
import { useAccessRightsToFunctionality } from 'modules/security';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { LinkProps, NavLink } from 'react-router-dom';
import { responsive } from '../../../../helpers';

type Props = {
  className?: string;
} & LinkProps;

const NavigationLink: React.FunctionComponent<Props> = ({ className, children: icon, to, ...other }: Props) => {
  const isLaptopSM = useMediaQuery(responsive.isLaptopSM);
  const isTablet = useMediaQuery(responsive.isTablet);
  const hasAccessToOverview = useAccessRightsToFunctionality('ORG_OVERVIEW');
  const { t } = useTranslation('global');

  return (
    <StyledNavigationLink isLaptopSM={isLaptopSM} isTablet={isTablet}>
      <NavLink activeClassName="active" to={`/${to}`.toLowerCase()} exact={to === ''} {...other}>
        {icon}
        <span className="navName">
          {to === 'Organizations' && !hasAccessToOverview
            ? t(`sidebar.Organization`)
            : t(`sidebar.${to}`, { defaultValue: to })}
        </span>
      </NavLink>
    </StyledNavigationLink>
  );
};

const mobileStyling = css`
  display: block;
  height: auto;
  max-height: ${props => props.theme.sidebar.mobile.height};
  margin-bottom: 0;
  width: 100%;

  a {
    display: flex;
    flex-direction: column;
    padding: 16px 8px;
    border-top: 4px solid transparent;

    &.active,
    &.active:hover,
    &.active:focus {
      border-left: none;
      border-top: 4px solid ${props => props.theme.sidebar.background.activeHighlight};
    }

    svg {
      margin-right: 0;
    }

    span {
      font-size: 80%;
    }
  }
`;

export const StyledNavigationLink = styled('li')<{ isOpen?: boolean; isLaptopSM?: boolean; isTablet?: boolean }>`
  ${({ isOpen, isLaptopSM, isTablet, theme }) => css`
    cursor: pointer;
    list-style: none;
    overflow: hidden;
    outline: 0;
    max-height: ${isOpen ? 'auto' : '48px'};
    height: ${isOpen ? 'auto' : '48px'};
    transition: max-height 0.15s ease-out;
    margin-bottom: 23px;
    background: ${theme.sidebar.background.main};

    a {
      padding: 10px 0px 10px 25px;
      color: ${theme.sidebar.color.text};
      display: flex;
      align-items: center;
      font-size: ${theme.typography.fontSize.regular};
      text-decoration: none;
      transition: background 0.25s ease-out;
      border-left: 4px solid transparent;
      background: ${isOpen ? theme.sidebar.background.subMenuParent : 'transparent'};
      position: relative;
      font-weight: 200;

      &.active,
      &.active:hover,
      &.active:focus {
        background: ${theme.sidebar.background.active};
        border-left: 4px solid ${theme.sidebar.background.activeHighlight};
        color: ${theme.sidebar.color.activeText};
        font-family: ${theme.typography.fontFamily};
        font-weight: 500;

        i {
          color: ${theme.sidebar.color.activeText};
        }

        svg {
          fill: ${theme.sidebar.color.activeText};
        }
      }

      &:hover,
      &:focus {
        background: ${!isOpen && theme.sidebar.background.hover};
      }
    }

    > ul {
      ${isOpen && `background: ${theme.sidebar.background.submenu}`};
      margin: 0px 0 15px 0px !important;
      padding: 10px 0 !important;

      > li {
        margin-bottom: 0;
        background: ${theme.sidebar.background.submenu};

        > a {
          background: transparent;

          &:hover {
            background: ${theme.sidebar.background.subMenuParent};
          }

          ${media.laptopLG`
            padding-left: 42px;
            svg {
              width: 20px;
              height: 20px;
            }
          `}
          ${media.desktop`
            padding-left: 42px;
            svg {
              width: 20px;
              height: 20px;
            }
          `}
        }
      }
    }

    ${media.mobile`
    ${mobileStyling}

    a span:last-child {
      display: none;
    }
  `};

    ${media.tablet`
      ${mobileStyling}
  `};

    span.dropper svg {
      fill: ${theme.sidebar.color.icon};
      width: 20px;
      height: 20px;
      position: absolute;
      margin: 0;
      top: 14px;
      right: 10px;
    }

    svg {
      margin-right: 25px;
      fill: ${theme.sidebar.color.icon};
    }

    ${isLaptopSM &&
      css`
        height: ${isOpen ? 'auto' : '60px'};
        max-height: ${isOpen ? 'auto' : '60px'};

        a {
          height: 60px;
          padding: 10px 0px 10px 20px;
        }
        > ul > li > a {
          padding: 10px 0px 10px 20px;
        }
      `}
    ${isTablet &&
      css`
        height: auto;
        max-height: auto;
        a {
          height: auto;
          padding: 16px 0;
        }
      `}
  `}
`;

export default NavigationLink;
