import * as React from 'react';
import { Link } from 'react-router-dom';
import Button, { ButtonProps } from './Button';

type Props = {
  to: string;
} & ButtonProps;

const LinkButton: React.FunctionComponent<Props> = ({ to, ...props }: Props) => (
  <Link to={to}>
    <Button {...props} />
  </Link>
);

export default LinkButton;
