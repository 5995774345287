import { ContentLoader, SkeletonGenerator } from 'components';
import styled from 'config/theme';
import * as React from 'react';

const BlockLoadingState: React.FunctionComponent = () => (
  <BlockWrapper>
    <LoadingStateWrapper>
      <SkeletonGenerator count={1}>
        <ContentLoader height={100}>
          <rect x="160" y="8" rx="5" ry="5" width="70" height="10" />
          <rect x="250" y="8" rx="5" ry="5" width="80" height="10" />
          <rect x="150" y="35" rx="5" ry="5" width="80" height="10" />
          <rect x="250" y="35" rx="5" ry="5" width="80" height="10" />
          <rect x="155" y="62" rx="5" ry="5" width="75" height="10" />
          <rect x="250" y="62" rx="5" ry="5" width="80" height="10" />
        </ContentLoader>
      </SkeletonGenerator>
    </LoadingStateWrapper>
  </BlockWrapper>
);

const LoadingStateWrapper = styled.section`
  background: ${props => props.theme.paper.mainInfo.background};
  color: ${props => props.theme.paper.mainInfo.color.main};
  max-width: 600px;
  margin-left: auto;
  padding: 20px 0;
`;

const BlockWrapper = styled.div`
  border-top: ${props => props.theme.fullView.border};
  padding: 20px 0;
`;

export default BlockLoadingState;
