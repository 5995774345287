import { combineReducers } from 'redux';
import { MailFilters } from '../../types/mails';
import filterReducer from './filters';
import mailReducer, { MailState } from './mail';
import mailsReducer, { MailsState } from './mails';

export type MailModuleState = {
  mail: MailState;
  mails: MailsState;
  filters: MailFilters;
};

const mailModuleReducer = combineReducers({
  filters: filterReducer,
  mail: mailReducer,
  mails: mailsReducer,
});

export default mailModuleReducer;
