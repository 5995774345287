import i18n from 'config/i18n';
import { IpRangeFormat, IpVersion, KeywordType } from 'modules/app/types';
import * as Yup from 'yup';
import { ContactInfoEditValues } from '../types/organizationDetail/contacts';
import { CTIDataEditValues } from '../types/organizationDetail/cti';
import { AddUserValues, RejectFormValues } from '../types/organizationDetail/organizationDetail';
import { ContactType } from 'modules/app/types/contact';
import { UpgradeUserRole } from '../types/organizationDetail/users';

export const addUserSchema: Yup.ObjectSchema<AddUserValues> = Yup.object().shape({
  email: Yup.string().required().email(),
});

export const rejectSchema: Yup.ObjectSchema<RejectFormValues> = Yup.object().shape({
  comment: Yup.string().required(),
});

const ipRangeSchema = Yup.array().of(
  Yup.object().shape({
    description: Yup.string().required(),
    format: Yup.mixed()
      .required()
      .oneOf([...Object.values(IpRangeFormat)]),
    ipAddresses: Yup.string()
      .required()
      .when('format', {
        is: IpRangeFormat.Range,
        then: Yup.string()
          .required()
          .matches(
            /((25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)-(25[0-5]|2[0-4]\d|[01]?\d\d?))/,
            () => i18n.t('validation:organization.ipAddress.format.range')
          ),
      })
      .when('format', {
        is: IpRangeFormat.CommaSeparated,
        then: Yup.string()
          .required()
          .matches(
            /((25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)(,\n|,?$))/,
            () => i18n.t('validation:organization.ipAddress.format.comma')
          ),
      })
      .when('format', {
        is: IpRangeFormat.Cidr,
        then: Yup.string()
          .required()
          .matches(/^([0-9]{1,3}\.){3}[0-9]{1,3}(\/([0-9]|[1-2][0-9]|3[0-2]))?$/, () =>
            i18n.t('validation:organization.ipAddress.format.cidr')
          ),
      }),
    ipRangeId: Yup.string().required(),
    ipVersion: Yup.mixed().oneOf([IpVersion.Ipv4, IpVersion.Ipv6]).required().nullable(),
  })
);

export const generalInfoSchema: Yup.ObjectSchema = Yup.object().shape({
  alertFeedEmail: Yup.string().email(),
  alertPhone: Yup.string()
    .max(50)
    .matches(/^((\+[1-9]{2,3}[.\s]?){1}(\d{1,3}){1}(\s?\d{2,3}){2,4})?$/, () =>
      i18n.t('validation:string.phoneNumber')
    ),
  businessLocations: Yup.array()
    .of(
      Yup.object().shape({
        addressId: Yup.string(),
        city: Yup.string().required().max(200).trim(),
        countryId: Yup.string().nullable().required(),
        guid: Yup.string(),
        name: Yup.string().required().max(100).trim(),
        number: Yup.string().required().trim().max(20),
        state: Yup.string().max(200),
        street: Yup.string().required().max(200).trim(),
        zipCode: Yup.string().required().max(20).trim(),
      })
    )
    .min(1),
  whiteIpRanges: ipRangeSchema.when('mispToMisp', {
    is: 'true',
    then: ipRangeSchema.required().min(1),
  }),
  publicIpRanges: ipRangeSchema.when('accountNeeded', {
    is: 'false',
    then: ipRangeSchema.required().min(1),
  }),
  mispLogoFile: Yup.object()
    .nullable()
    .when('mispToMisp', {
      is: 'true',
      then: Yup.object()
        .shape({
          base64: Yup.string(),
          file: Yup.mixed<File>().nullable(),
          name: Yup.string().when('mispToMisp', {
            is: 'true',
            then: Yup.string().required(),
          }),
        })
        .required(),
    }),
  mispOrganizationGuid: Yup.string()
    .matches(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i, () =>
      i18n.t('validation:string.guid')
    )
    .when('mispToMisp', {
      is: 'true',
      then: Yup.string()
        .matches(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i, () =>
          i18n.t('validation:string.guid')
        )
        .required(),
    }),
  mispOrganizationName: Yup.string().when('mispToMisp', {
    is: (mispToMisp) => mispToMisp !== 'null',
    then: Yup.string().required(),
  }),
  mispPocEmail: Yup.string().email(),
  mispPocPgpFile: Yup.mixed().when('mispToMisp', {
    is: 'true',
    then: Yup.mixed().required(),
  }),
  mispToMisp: Yup.string().required(),
  tlsEmail: Yup.string().email(),
  mispDescription: Yup.string().when('mispToMisp', {
    is: (mispToMisp) => mispToMisp !== 'null',
    then: Yup.string().required(),
  }),
  mispCountryId: Yup.string()
    .when('mispToMisp', {
      is: (mispToMisp) => mispToMisp !== 'null',
      then: Yup.string().required(() => i18n.t('validation:required')),
    })
    .nullable(),
  mispSectors: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string(),
        keywordId: Yup.string(),
        type: Yup.mixed<KeywordType>(),
      })
    )
    .when('mispToMisp', {
      is: (mispToMisp) => mispToMisp !== 'null',
      then: Yup.array()
        .of(
          Yup.object().shape({
            name: Yup.string(),
            keywordId: Yup.string(),
            type: Yup.mixed<KeywordType>(),
          })
        )
        .min(1, ({ min }) => i18n.t('validation:array.min', { min })),
    }),
  mispDomains: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().matches(/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/, () =>
          i18n.t('validation:onboarding.domainName')
        ),
        keywordId: Yup.string(),
        type: Yup.mixed<KeywordType>(),
      })
    )
    .when('mispToMisp', {
      is: (mispToMisp) => mispToMisp !== 'null',
      then: Yup.array()
        .of(
          Yup.object().shape({
            name: Yup.string().matches(
              /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/,
              () => i18n.t('validation:onboarding.domainName')
            ),
            keywordId: Yup.string(),
            type: Yup.mixed<KeywordType>(),
          })
        )
        .min(1, ({ min }) => i18n.t('validation:array.min', { min })),
    }),
  mispExtraEmail: Yup.string()
    .email(() => i18n.t('validation:string.email'))
    .when('mispToMisp', {
      is: (mispToMisp) => mispToMisp !== 'null',
      then: Yup.string().required(() => i18n.t('validation:required')),
    }),
  accountNeeded: Yup.string().when('mispToMisp', {
    is: 'false',
    then: Yup.string()
      .matches(/[true|false]/)
      .required(() => i18n.t('validation:required')),
  }),
});

export const ctiDataSchema: Yup.ObjectSchema<CTIDataEditValues> = Yup.object().shape({
  certificates: Yup.array().of(Yup.mixed()),
  darkweb: Yup.array().of(
    Yup.object().shape({
      keywordId: Yup.string().required(),
      name: Yup.string().required(),
      type: Yup.mixed().oneOf([KeywordType.DarkWeb]).required(),
    })
  ),
  domains: Yup.array().of(
    Yup.object().shape({
      domainId: Yup.string().required(),
      domainName: Yup.string()
        .required()
        .max(200)
        .trim()
        .matches(/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/, () =>
          i18n.t('validation:organization.domainName')
        ),
      emailAbuse: Yup.string().max(200).email().required(),
      fullyQualifiedDomainName: Yup.string(),
      hostingCompany: Yup.string().max(200).required(),
      registrar: Yup.string().required().max(200),
    })
  ),
  emailDomains: Yup.array()
    .of(
      Yup.object().shape({
        keywordId: Yup.string().required(),
        name: Yup.string().required(),
        type: Yup.mixed().oneOf([KeywordType.EmailDomain]).required(),
      })
    )
    .min(1),
  ipRanges: Yup.array().of(
    Yup.object().shape({
      description: Yup.string().required(),
      format: Yup.mixed()
        .required()
        .oneOf([...Object.values(IpRangeFormat)]),
      ipAddresses: Yup.string()
        .required()
        .when('format', {
          is: IpRangeFormat.Range,
          then: Yup.string()
            .required()
            .matches(
              /((25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)-(25[0-5]|2[0-4]\d|[01]?\d\d?))/,
              () => i18n.t('validation:organization.ipAddress.format.range')
            ),
        })
        .when('format', {
          is: IpRangeFormat.CommaSeparated,
          then: Yup.string()
            .required()
            .matches(
              /((25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)(,\n|,?$))/,
              () => i18n.t('validation:organization.ipAddress.format.comma')
            ),
        })
        .when('format', {
          is: IpRangeFormat.Cidr,
          then: Yup.string()
            .required()
            .matches(/^([0-9]{1,3}\.){3}[0-9]{1,3}(\/([0-9]|[1-2][0-9]|3[0-2]))?$/, () =>
              i18n.t('validation:organization.ipAddress.format.cidr')
            ),
        }),
      ipRangeId: Yup.string().required(),
      ipVersion: Yup.mixed().oneOf([IpVersion.Ipv4, IpVersion.Ipv6]).required().nullable(),
    })
  ),
  tags: Yup.array().of(
    Yup.object().shape({
      keywordId: Yup.string().required(),
      name: Yup.string().required(),
      type: Yup.mixed().oneOf([KeywordType.Tag]).required(),
    })
  ),
});

export const contactInfoSchema: Yup.ObjectSchema<ContactInfoEditValues> = Yup.object().shape({
  contacts: Yup.array().of(
    Yup.object().shape({
      availabilities: Yup.array().of(
        Yup.object().shape({
          days: Yup.array().of(Yup.string()).min(1),
          hours: Yup.array().of(Yup.number()).min(2).max(2),
        })
      ),
      businessLocations: Yup.array().of(Yup.string()).required().min(1),
      contactId: Yup.string(),
      email: Yup.string().required(),
      firstName: Yup.string().required(),
      info: Yup.string().max(500),
      lastName: Yup.string().required(),
      pgpFile: Yup.mixed(),
      phoneBusiness: Yup.string()
        .required()
        .max(50)
        .matches(/^(\+[1-9]{2,3}[.\s]?){1}(\d{1,3}){1}(\s?\d{2,3}){2,4}$/, () =>
          i18n.t('validation:string.phoneNumber')
        ),
      phoneMobile: Yup.string()
        .required('Phone number is required')
        .max(50)
        .matches(/^(\+[1-9]{2,3}[.\s]?){1}(\d{1,3}){1}(\s?\d{2,3}){2,4}$/, () =>
          i18n.t('validation:string.phoneNumber')
        ),
      phoneOrder: Yup.number(),
      phonePrivate: Yup.string(),
      skype: Yup.string().max(100),
      treemaId: Yup.string().max(50),
      types: Yup.array()
        .of(Yup.mixed().oneOf([...Object.values(ContactType)]))
        .nullable()
        .required()
        .min(1),
    })
  ),
});

export const upgradeUserSchema = Yup.object().shape({
  role: Yup.string()
    .oneOf([...Object.values(UpgradeUserRole)])
    .required(),
});
