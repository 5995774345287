import { DayOptionType } from 'modules/app/types';
import * as React from 'react';
import { components } from 'react-select';
import { SelectComponentsProps } from 'react-select/base';

const SortedDaysSelectInput = ({ selectProps, ...props }: SelectComponentsProps) => {
  const sortedDays =
    selectProps &&
    selectProps.value &&
    (selectProps.value as DayOptionType[]).sort((a: any, b: any) => a.value - b.value);

  return (
    // @ts-ignore
    <components.Input {...props} value={sortedDays && sortedDays.map((t: DayOptionType) => t.shortLabel).join(', ')} />
  );
};

export default SortedDaysSelectInput;
