import styled from 'config/theme';
import * as React from 'react';
import { Translation } from 'react-i18next';
import { LinkButton } from '../';
import { NoListItemSelectedWrapper } from './';

const FourOFour = styled.span`
  font-size: ${props => props.theme.typography.fontSize.huge_9};
  font-weight: 200;
  color: ${props => props.theme.palette.neutral.shade3};
`;

const NotFound: React.FunctionComponent = () => {
  return (
    <NoListItemSelectedWrapper>
      <Translation ns="global">
        {t => (
          <React.Fragment>
            <FourOFour>404</FourOFour>
            <h1>{t('notFound.title')}</h1>
            <p>{t('notFound.subText')}</p>
            <LinkButton primary to={'/'}>
              {t('notFound.primaryButton')}
            </LinkButton>
          </React.Fragment>
        )}
      </Translation>
    </NoListItemSelectedWrapper>
  );
};

export default NotFound;

// TODO: probably has to be adjusted when implemented
