import React from 'react';
import { ErrorText } from '../Form/ErrorText';
import { Label } from '../Form/Label';
import { Dropzone } from './Dropzone';
import FileInput, { ConvertedFile } from './FileInput';
import FileItem from './FileItem';
import FileItemContainer from './FileItemContainer';
import FileUploadContent from './FileUploadContent';
import { isSingleFile } from './isSingleFileHelper';

type Props = {
  onChange: (files: ConvertedFile[] | ConvertedFile) => void;
  isMulti?: boolean;
  initialValues?: ConvertedFile | ConvertedFile[];
  acceptedFileTypes?: string[];
  onFileClick?: () => void;
  label?: string;
  isDownloadable?: boolean;
  inputId: string;
  error: string;
  maxFileSize?: number;
};
export type ConvertedFileWithId = ConvertedFile & { id: string };

const FileUpload: React.FunctionComponent<Props> = ({
  onChange,
  isMulti,
  initialValues,
  acceptedFileTypes,
  onFileClick,
  label,
  isDownloadable,
  inputId,
  error,
  maxFileSize,
}) => {
  const initialValuesWithId = React.useMemo(
    () =>
      initialValues
        ? isSingleFile(initialValues)
          ? [{ ...initialValues, id: '0' }]
          : initialValues.map((values, index) => ({ ...values, id: `0-${index}` }))
        : [],
    [initialValues]
  );

  const [files, setFiles] = React.useState<ConvertedFileWithId[]>(initialValuesWithId);
  const [uploadCount, setUploadCount] = React.useState<number>(1);

  const addFileIds = React.useCallback(
    (newFiles: ConvertedFile[]) =>
      newFiles.map((file, index) => ({
        ...file,

        id: `${uploadCount}-${index}`,
      })),
    [uploadCount]
  );

  const removeFileIds = (newFiles: ConvertedFileWithId[]): ConvertedFile[] =>
    newFiles.map((file) => {
      const { id, ...rest } = file;

      return rest;
    });

  const handleNewFiles = React.useCallback(
    (newFiles: ConvertedFile[]) => {
      setUploadCount((count) => count + 1);

      const result = isMulti ? [...files, ...addFileIds(newFiles)] : addFileIds(newFiles);
      setFiles(result);
      onChange(isMulti ? removeFileIds(result) : removeFileIds(result)[0]);
    },
    [setUploadCount, isMulti, files, addFileIds, setFiles, onChange]
  );

  const handleDelete = React.useCallback(
    (fileId: string) => {
      const result = files.filter((file) => file.id !== fileId);
      setFiles(result);
      onChange(isMulti ? removeFileIds(result) : { base64: '', file: null, name: '' });
    },

    [setFiles, files, onChange, isMulti]
  );

  return (
    <div>
      {label && <Label>{label}</Label>}
      <Dropzone>
        <FileUploadContent />
        <FileInput
          id={inputId}
          acceptedFileTypes={acceptedFileTypes}
          isMulti={isMulti}
          onChange={handleNewFiles}
          names={files.map((file) => file.name)}
          maxFileSize={maxFileSize}
        />
      </Dropzone>
      <FileItemContainer>
        {files.map((file) => (
          <FileItem
            isDownloadable={isDownloadable}
            onDownload={onFileClick}
            handleDelete={handleDelete}
            key={file.id}
            id={file.id}
            name={file.name}
          />
        ))}
      </FileItemContainer>
      {error && <ErrorText block={true}>{error}</ErrorText>}
    </div>
  );
};

export default FileUpload;
