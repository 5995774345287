import * as React from 'react';
import { useDispatch } from 'react-redux';
import { SuccessCallback } from 'types';
import { getType } from 'typesafe-actions';
import { useActionIsPending } from '../../../../hooks';
import { UpgradeUserRole, User, UserStatus } from '../../types/organizationDetail/users';
import { organizationDetailActions } from '../actions/creators';

const useUserStore = () => {
  const dispatch = useDispatch();

  const [isResettingPw, startResettingPw] = useActionIsPending(getType(organizationDetailActions.userResetPassword));
  const handleResetPassword = React.useCallback(
    (user: User) => {
        startResettingPw();
        dispatch(organizationDetailActions.userResetPassword(user.guid));
    },
    [dispatch, startResettingPw]
  );

  const [isResetting2FA, startResetting2FA] = useActionIsPending(
    getType(organizationDetailActions.userResetAuthenticator)
  );
  const handleResetAuthenticator = React.useCallback(
    (user: User) => {
        startResetting2FA();
        dispatch(organizationDetailActions.userResetAuthenticator(user.guid));
    },
    [dispatch, startResetting2FA]
  );

  const [isMakingAdmin, startMakingAdmin] = useActionIsPending(getType(organizationDetailActions.setUserToAdmin));
  const handleMakeUserAdmin = React.useCallback(
    (user: User) => {
      if (user.status !== UserStatus.Pending) {
        startMakingAdmin();
        dispatch(organizationDetailActions.setUserToAdmin(user.userId));
      }
    },
    [dispatch, startMakingAdmin]
  );

  const [isUpgradingUser, startUpgradingUser] = useActionIsPending(getType(organizationDetailActions.upgradeUser));

  const handleUpgradeUser = React.useCallback(
    (guid: string, role: UpgradeUserRole, successCallback?: SuccessCallback) => {
      startUpgradingUser();
      dispatch(organizationDetailActions.upgradeUser(guid, role, successCallback));
    },
    [dispatch, startUpgradingUser]
  );

  return {
    isResettingPw,
    handleResetPassword,
    isResetting2FA,
    handleResetAuthenticator,
    isMakingAdmin,
    handleMakeUserAdmin,
    isUpgradingUser,
    handleUpgradeUser,
  };
};

export default useUserStore;
