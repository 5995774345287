import { Contact } from 'modules/organization/types/organizationDetail/contacts';
import { CTIData } from 'modules/organization/types/organizationDetail/cti';
import { GeneralInfo } from 'modules/organization/types/organizationDetail/general';
import { User } from 'modules/organization/types/organizationDetail/users';
import { combineReducers, Reducer } from 'redux';
import { OrganizationDetailActions } from '../../actions/creators';
import contactsReducer from './contactsReducer';
import ctiReducer from './ctiReducer';
import generalInfoReducer from './generalInfoReducer';
import usersReducer from './usersReducer';

export type OrganizationDetailState = {
  contacts: Contact[] | null;
  generalInfo: GeneralInfo | null;
  cti: CTIData | null;
  users: User[] | null;
};

const organizationDetailReducer: Reducer<OrganizationDetailState, OrganizationDetailActions> = combineReducers({
  contacts: contactsReducer,
  cti: ctiReducer,
  generalInfo: generalInfoReducer,
  users: usersReducer,
});

export default organizationDetailReducer;
