import { formatSortingForApi } from 'helpers/sortingFormatter';
import { searchActions } from 'modules/app/redux/creators';
import organizationApi from 'modules/organization/api/organizationApi';
import { ORGANIZATION_FILTER_KEY } from 'modules/organization/constants';
import { AddOrganizationValues, Organization } from 'modules/organization/types/organization';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { filterActions, organizationActions, pageInfoActions, sortingActions } from '../actions/creators';
import {
  makeGetOrganizationFilters,
  makeGetOrganizationPageInfo,
  makeGetOrganizationSorting,
} from '../selectors/organization';

function* organizationSaga() {
  yield takeLatest(
    [
      organizationActions.getOrganizations,
      filterActions.setSectorFilter,
      filterActions.setStatusFilter,
      pageInfoActions.setPageSize,
      pageInfoActions.setPage,
      sortingActions.setSorting,
    ],
    handleGetFilteredOrganizations
  );
  yield takeLatest(
    [filterActions.setSectorFilter, filterActions.setStatusFilter, pageInfoActions.setPageSize],
    handleGetFilteredOrganizationsWithPage1
  );
  yield takeLatest(searchActions.setSearch, handleSearchChange);
  yield takeLatest(organizationActions.addOrganization, handleAddOrganization);
}

function* handleGetFilteredOrganizationsWithPage1() {
  yield put(pageInfoActions.setPage(1));
}

function* handleGetFilteredOrganizations() {
  yield put(organizationActions.getOrganizationsRequest.request());
  const filters = yield select(makeGetOrganizationFilters());
  const pageInfo = yield select(makeGetOrganizationPageInfo());
  const sorting = yield select(makeGetOrganizationSorting());
  try {
    const payload = yield call(organizationApi.getOrganizations, pageInfo, filters, formatSortingForApi(sorting));
    yield put(organizationActions.getOrganizationsRequest.success(payload));
  } catch (e) {
    yield put(organizationActions.getOrganizationsRequest.failure(e.response));
  }
}

function* handleSearchChange(action: ReturnType<typeof searchActions.setSearch>) {
  if (action.payload.filterKey === ORGANIZATION_FILTER_KEY) {
    yield put(pageInfoActions.setPage(1));
  }
}

function* handleAddOrganization(action: ReturnType<typeof organizationActions.addOrganization>) {
  yield put(organizationActions.addOrganizationRequest.request());
  try {
    const sectors = action.payload.sectors.map((sector) => sector.sectorId);
    const newOrganization: AddOrganizationValues = {
      ...action.payload,
      sectors: sectors,
    };
    const payload: Organization = yield call(organizationApi.addOrganization, newOrganization);

    const { hasNextPage } = yield select(makeGetOrganizationPageInfo());
    yield put(organizationActions.addOrganizationRequest.success(hasNextPage ? null : payload));
    action.successCallback();
    yield call(handleGetFilteredOrganizations);
  } catch (e) {
    yield put(organizationActions.addOrganizationRequest.failure(e.response));
  }
}

export default organizationSaga;
