import { TenantTheme } from '../tenants/';

const iconButtonTheme = (theme: TenantTheme) => ({
  background: {
    hover: theme.palette.neutral.shade3,
  },
  color: {
    disabled: theme.palette.neutral.shade4,
    hover: theme.palette.neutral.shade9,
    normal: theme.palette.neutral.shade7,
  },
  margin: '0 2px',
  padding: '5px 20px',
  size: {
    button: '42px',
    icon: '26px',
  },
  tooltip: {
    background: theme.palette.neutral.shade6,
    borderRadius: theme.globals.borderRadiusSmall,
    boxShadow: '1px 2px 14px rgba(0, 0, 0, 0.1), 1px 3px 4px rgba(0, 0, 0, 0.12)',
    color: theme.palette.neutral.shade2,
    fontSize: theme.typography.fontSize.small_5,
    padding: '5px 10px',
  },
});

export default iconButtonTheme;
