import { getType } from 'typesafe-actions';
import { FaqCategory } from '../../types/helpCenter';
import { HelpCenterActions, helpCenterActions } from '../creators';

const faqCategoriesReducer = (state: FaqCategory[] = [], action: HelpCenterActions) => {
  switch (action.type) {
    case getType(helpCenterActions.getFaqCategoriesRequest.success):
      return action.payload;

    default:
      return state;
  }
};

export default faqCategoriesReducer;
