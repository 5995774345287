import { TenantTheme } from '../tenants/';

const onboardingTheme = (theme: TenantTheme) => ({
  input: {
    background: theme.palette.neutral.shade1,
    backgroundDisabled: theme.palette.neutral.shade2,
  },
});

export default onboardingTheme;
