import { combineReducers } from 'redux';
import { FaqCategory, FaqsDetail } from '../../types/helpCenter';
import faqCategoriesReducer from './faqCategories';
import faqsReducer from './faqsReducer';

export type helpCenternModuleState = {
  faqCategories: FaqCategory[];
  faqsDetail: FaqsDetail | null;
};

const helpCenterModuleReducer = combineReducers({
  faqCategories: faqCategoriesReducer,
  faqsDetail: faqsReducer,
});

export default helpCenterModuleReducer;
