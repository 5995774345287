import { ActionType } from 'typesafe-actions';
import * as filterAction from './filters';
import * as organizationAction from './organization';
import * as organizationDetailAction from './organizationDetail';
import * as pageInfoAction from './pageInfo';
import * as profileUpdateAction from './profileUpdate';
import * as sortingAction from './sorting';

export const ORGANIZATIONS_ACTION_PREFIX = 'ORGANIZATIONS';
export const ORGANIZATIONS_DIFFERENCE_ACTION_PREFIX = 'ORGANIZATION_DETAIL_DIFFERENCE';
export const ORGANIZATION_DETAIL_GENERAL_INFO_ACTION_PREFIX = 'ORGANIZATION_DETAIL_GENERAL_INFO';
export const ORGANIZATION_DETAIL_CONTACTS_ACTION_PREFIX = 'ORGANIZATION_DETAIL_CONTACTS';
export const ORGANIZATION_DETAIL_CTI_ACTION_PREFIX = 'ORGANIZATION_DETAIL_CTI';
export const ORGANIZATION_DETAIL_USERS_ACTION_PREFIX = 'ORGANIZATION_DETAIL_USERS';
export const PROFILE_UPDATE_PREFIX = 'PROFILE_UPDATE';

export const organizationActions = { ...organizationAction };
export const filterActions = { ...filterAction };
export const pageInfoActions = { ...pageInfoAction };
export const organizationDetailActions = { ...organizationDetailAction };
export const sortingActions = { ...sortingAction };
export const profileUpdateActions = { ...profileUpdateAction };

export type OrganizationActions = ActionType<typeof organizationAction>;
export type FilterActions = ActionType<typeof filterAction>;
export type PageInfoActions = ActionType<typeof pageInfoAction>;
export type OrganizationDetailActions = ActionType<typeof organizationDetailAction>;
export type SortingActions = ActionType<typeof sortingAction>;
export type ProfileUpdateActions = ActionType<typeof profileUpdateAction>;
