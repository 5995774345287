import { push } from 'connected-react-router';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { RootState } from '../../../../config/store/rootReducer';
import onboardingApi from '../../api/onboardingApi';
import { CONTACTS, CONTACTS_OVERVIEW, CTI_DATA, FINAL, GENERAL_DATA, USER_DATA } from '../../constants';
import { FormActions, formActions, onBoardingActions } from '../actions';
import {
  makeGetContactDataFields,
  makeGetCtiDataFields,
  makeGetGeneralDataFields,
  makeGetUserData,
} from '../selectors/onBoarding';
import { makeGetOrganizationId, makeGetUserId } from '../selectors/user';

function* setOnBoardingSaga() {
  yield takeLatest(
    [formActions.setMobileOnBoardingFormFields, formActions.setDesktopOnBoardingFormFields],
    handleSubmitOnBoarding
  );
}

function* handleRouteChange(incrementCount: number = 1, stepSubIndex?: string) {
  const location = yield select((state: RootState) => state.router.location.pathname);

  if (stepSubIndex !== FINAL) {
    yield put(
      push(`/activate/${parseInt(location.split('/')[2], 0) + incrementCount}${stepSubIndex ? `/${stepSubIndex}` : ''}`)
    );
  } else {
    yield put(push(`/activate/${stepSubIndex}`));
  }
}

function* handleSubmitOnBoarding(action: FormActions) {
  const organizationId = yield select(makeGetOrganizationId());

  if (action.payload.step === undefined) {
    return;
  }
  switch (action.payload.submitParam) {
    case GENERAL_DATA:
      yield call(
        handleGeneralDataSubmit,
        organizationId,
        action.type === getType(formActions.setMobileOnBoardingFormFields),
        action.payload.step
      );
      break;
    case CONTACTS:
      yield call(handleContactSubmit, organizationId, action);
      break;
    case CONTACTS_OVERVIEW:
      yield call(handleMobileContactsArray);
      break;
    case CTI_DATA:
      yield call(handleCtiDataSubmit, organizationId);
      break;
    case USER_DATA:
      yield call(handleUserSubmit);
      break;
    default:
      yield call(handleRouteChange, 1, action.payload.stepSubIndex);

      return;
  }
}

function* handleGeneralDataSubmit(organizationId: string, isMobile: boolean, step: string) {
  yield put(onBoardingActions.postGeneralDataRequest.request());
  const data = yield select(makeGetGeneralDataFields());
  const skipIpRangeStep = !data.mispToMisp && isMobile;

  const submitStep = !isMobile || step === 'ipRanges' || !data.mispToMisp;

  if (submitStep) {
    try {
      const payload = yield call(onboardingApi.postOrganizationGeneralData, organizationId, data);
      yield put(onBoardingActions.postGeneralDataRequest.success(payload));
      yield call(handleRouteChange, skipIpRangeStep ? 2 : undefined);
    } catch (e) {
      yield put(onBoardingActions.postGeneralDataRequest.failure(e.response));
    }
  } else {
    yield call(handleRouteChange, skipIpRangeStep ? 2 : undefined);
  }
}

function* handleContactSubmit(organizationId: string, action: FormActions) {
  yield put(onBoardingActions.postContactDataRequest.request());
  const data = yield select(makeGetContactDataFields());
  const location = yield select((state: RootState) => state.router.location.pathname);

  try {
    const payload = yield call(onboardingApi.postOrganizationContactData, organizationId, data);
    yield put(onBoardingActions.postContactDataRequest.success(payload));

    if (action.type === 'ONBOARDING_SET_MOBILE_FORM_FIELDS') {
      // if we have a stepSubIndex it means we are in the mobile onBoarding flow and we actually have to return to our contacts overview
      yield put(push(`/activate/${parseInt(location.split('/')[2], 0) + 4}`));
    } else {
      yield call(handleRouteChange);
    }
  } catch (e) {
    yield put(onBoardingActions.postContactDataRequest.failure(e.response));
  }
}

function* handleCtiDataSubmit(organizationId: string) {
  yield put(onBoardingActions.postCtiDataRequest.request());
  const data = yield select(makeGetCtiDataFields());

  try {
    const payload = yield call(onboardingApi.postOrganizationCtiData, organizationId, data);
    yield put(onBoardingActions.postCtiDataRequest.success(payload));
    yield call(handleRouteChange, undefined, FINAL);
  } catch (e) {
    yield put(onBoardingActions.postCtiDataRequest.failure(e.response));
  }
}

function* handleUserSubmit() {
  yield put(onBoardingActions.putUserDataRequest.request());
  const data = yield select(makeGetUserData());
  const userId = yield select(makeGetUserId());

  try {
    const payload = yield call(onboardingApi.putUserData, userId, data);
    yield put(onBoardingActions.putUserDataRequest.success(payload));
    yield call(handleRouteChange, undefined, FINAL);
  } catch (e) {
    yield put(onBoardingActions.putUserDataRequest.failure(e.response));
  }
}

function* handleMobileContactsArray() {
  const location = yield select((state: RootState) => state.router.location.pathname);
  yield put(push(`/activate/${parseInt(location.split('/')[2], 0) - 3}`)); // ToDo make the 3 dynamic
}
export default setOnBoardingSaga;
