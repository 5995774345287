import { loadScene } from 'components/async/scene';
import { Module } from 'modules/security/types/modules';
import * as React from 'react';
import { Route } from 'react-router-dom';
import ModuleRoutes from 'components/routing/ModuleRoutes';

type Props = {
  allowedModules: Module[];
};

export const ACTIVITIES_MODULE_KEY = 'activities';
export const ACTIVITIES_BASE_ROUTE = '/activities';

const activitiesOverviewScene = loadScene('activities', 'ActivitiesScene');

const ActivityRoutes: React.FunctionComponent<Props> = ({ allowedModules }: Props) => {
  return (
    <ModuleRoutes allowedModules={allowedModules} moduleKey={ACTIVITIES_MODULE_KEY}>
      <Route path={[ACTIVITIES_BASE_ROUTE]} exact component={activitiesOverviewScene} />
    </ModuleRoutes>
  );
};

export default ActivityRoutes;
