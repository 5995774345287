import * as React from 'react';
import styled from '../../config/theme';

type Props = {
  title: string;
  children: JSX.Element[] | null;
  closeMenu?: () => void;
};

const ContextMenuBlock: React.FunctionComponent<Props> = ({ title, children, closeMenu }: Props) => (
  <ContextMenuBlockWrapper>
    <h6>{title}</h6>
    <div>
      {children &&
        children.map((child: JSX.Element, index: number) =>
          React.cloneElement(child, { closeMenu, key: `menu_block_${index}` })
        )}
    </div>
  </ContextMenuBlockWrapper>
);

const ContextMenuBlockWrapper = styled.div`
  > h6 {
    font-size: ${props => props.theme.contextMenu.fontSize.blockTitle};
    color: ${props => props.theme.contextMenu.color.blockTitle};
    font-weight: ${props => props.theme.contextMenu.fontWeight.blockTitle};
    text-transform: uppercase;
    margin: 20px 20px 5px;
    letter-spacing: 1.5px;
  }

  &:first-child {
    > h6 {
      margin-top: 10px;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
  }
`;

export default ContextMenuBlock;
