import * as React from 'react';
import globalInfoApi from '../../../app/api/globalInfoApi';

const useGlobalInfoStore = () => {
  const loadSectors = React.useCallback((inputValue?: string) => globalInfoApi.getSectors(inputValue), []);
  const loadReports = React.useCallback((inputValue?: string) => globalInfoApi.getReportTypes(inputValue), []);

  return {
    loadSectors,
    loadReports,
  };
};

export default useGlobalInfoStore;
