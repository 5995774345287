import { TenantTheme } from '../tenants';

const notificationsTheme = (theme: TenantTheme) => ({
  drawer: {
    background: theme.palette.neutral.shade0,
    border: `2px solid ${theme.palette.neutral.shade2}`,
    fontSize: {
      header: theme.typography.fontSize.medium_6,
    },
    fontWeight: {
      header: 600,
    },
    transition: 'left 0.2s cubic-bezier(0.67, 0.49, 0.15, 0.82)',
  },
  notification: {
    background: {
      hover: theme.palette.neutral.shade1,
    },
    color: {
      content: theme.palette.neutral.shade8,
      time: theme.palette.neutral.shade4,
    },
    fontSize: {
      main: theme.typography.fontSize.small_5,
      time: theme.typography.fontSize.small_4,
    },
  },
  pill: {
    background: theme.palette.danger.shade4,
    color: theme.palette.danger.shade1,
  },
});

export default notificationsTheme;
