import 'core-js/stable';
import 'normalize.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { debounce } from 'ts-debounce';
import App from './modules/app/containers/App';
import * as serviceWorker from './serviceWorker';

const setVhProperty = debounce(() => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', vh + 'px');
}, 300);

setVhProperty();
window.addEventListener('resize', setVhProperty);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
