import * as React from 'react';
import ReactDOM from 'react-dom';
import ModalContainer from './ModalContainer';

// TODO: type content to only allow ModalContent + Possibly type contentProps and pass this typing down internally so there is autocomplete for these props
type Props = {
  content: React.ComponentType<any>;
  children?: (toggleModal: ToggleModalType) => JSX.Element;
  contentProps?: any;
  size?: ModalSizes;
  isVisible?: boolean;
  backdrop?: true | false | 'static';
};

type State = {
  isOpen: boolean;
  keepOpen: boolean;
};

export type ToggleModalType = () => void;

export enum ModalSizes {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  FullScreen = 'fullScreen',
}

class Modal extends React.PureComponent<Props, State> {
  state = { isOpen: false, keepOpen: false };

  toggleModal = () => {
    this.setState((prevState: State) => ({
      isOpen: prevState.keepOpen ? true : !prevState.isOpen,
    }));
  };

  closeModal = () => {
    this.setState({ isOpen: false });
  };

  toggleKeepOpen = () => {
    this.setState((prevState: State) => ({ keepOpen: !prevState.keepOpen }));
  };

  handleModalClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.isVisible !== this.props.isVisible) {
      this.setState({
        isOpen: this.props.isVisible || false,
      });
    }
  }

  render() {
    const { children, isVisible, ...modalContainerProps } = this.props;

    return (
      <React.Fragment>
        {children && children(this.toggleModal)}

        {this.state.isOpen &&
          ReactDOM.createPortal(
            <ModalContainer
              {...modalContainerProps}
              content={this.props.content}
              toggleModal={this.toggleModal}
              closeModal={this.closeModal}
              toggleKeepOpen={this.toggleKeepOpen}
              handleClick={this.handleModalClick}
            />,
            document.getElementById('modal') as HTMLElement
          )}
      </React.Fragment>
    );
  }
}

export default Modal;
