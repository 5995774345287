import i18n from 'config/i18n';
import * as Yup from 'yup';
import CreatableSelect from '../../components/select/CreatableSelect';
import { InputField } from '../../types';
import { CUSTOM } from '../index';

const emailDomainFields: InputField[] = [
  {
    component: CreatableSelect,
    initialValue: [],
    label: 'emailDomains.formLabels.domains',
    name: 'emailDomains',
    type: CUSTOM,
    width: {
      desktop: 12,
    },
  },
];

const emailDomainValidationSchema = Yup.object().shape({
  emailDomains: Yup.array()
    .of(
      Yup.string().matches(/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/, () =>
        i18n.t('validation:onboarding.domainName')
      )
    )
    .min(1, ({ min }) => i18n.t('validation:array.min', { min })),
});

const headerTranslations = {
  content: 'emailDomains.content',
  title: 'emailDomains.title',
};

export const emailDomainStep = {
  fields: emailDomainFields,
  headerTranslations,
  key: 'emailDomains',
  validationSchema: emailDomainValidationSchema,
};
