import { createSelector } from 'reselect';
import { RootState } from '../../../../config/store/rootReducer';
import { Account } from '../../../security/types/user';

const getOrganizationId = (state: RootState): string => (state.security.user.account as Account).organizationId;

export const makeGetOrganizationId = () =>
  createSelector(getOrganizationId, (organizationId: string) => organizationId);

const getUserId = (state: RootState): string => (state.security.user.account as Account).userId;

export const makeGetUserId = () => createSelector(getUserId, (userId: string) => userId);
