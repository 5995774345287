import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const Auth: React.FunctionComponent = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 25.0645C7 29.0546 9.90512 32.0322 13.7786 32.0322C16.714 32.0322 19.1047 30.2158 20.073 27.2382H24.461V30.1861C24.461 30.7518 24.9149 31.1985 25.4899 31.1985H26.9122C27.4871 31.1985 27.9411 30.7518 27.9411 30.1861V27.2382H29.1515L29.1515 28.6675C29.1515 29.2332 29.6054 29.6799 30.1804 29.6799H31.6027C32.1777 29.6799 32.6316 29.2332 32.6316 28.6675V27.2382H33.8421C34.417 27.2382 34.871 26.7915 34.871 26.2258V23.9032C34.871 23.3374 34.417 22.8908 33.8421 22.8908L20.1033 22.8908C19.1047 19.9131 16.7442 18.0967 13.7786 18.0967C9.90512 18.0967 7 21.0744 7 25.0645ZM13.8451 28.1302C15.4706 28.1302 16.7883 26.7576 16.7883 25.0644C16.7883 23.3712 15.4706 21.9986 13.8451 21.9986C12.2196 21.9986 10.9019 23.3712 10.9019 25.0644C10.9019 26.7576 12.2196 28.1302 13.8451 28.1302Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6452 34.3548V37.9412H39V11.4706H15.6452V15.7742H11.6452V9C11.6452 5.68629 14.3315 3 17.6452 3H37C40.3137 3 43 5.68629 43 9V41.129C43 44.4427 40.3137 47.129 37 47.129H17.6452C14.3315 47.129 11.6452 44.4427 11.6452 41.129V34.3548H15.6452Z"
      />
    </svg>
  </BaseSvgWrapper>
);

export default Auth;
