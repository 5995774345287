import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const UserUpgrade: React.FunctionComponent = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 8.00001C14 5.79001 12.21 4.00001 10 4.00001C7.79 4.00001 6 5.79001 6 8.00001C6 10.21 7.79 12 10 12C12.21 12 14 10.21 14 8.00001ZM2 18V20H18V18C18 15.34 12.67 14 10 14C7.33 14 2 15.34 2 18Z" />
      <path d="M18 7.99V14.5H20V7.99L23 7.99L19 4L15 7.99H18Z" />
    </svg>
  </BaseSvgWrapper>
);

export default UserUpgrade;
