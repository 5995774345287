import { FlexRow, IconButton, Input, Modal, TableData, TableRow, TextWrap } from 'components';
import CustomConfirmation from 'components/ConfirmationPopup/CustomConfirmation';
import { Auth, Key, MakeAdmin } from 'components/icons';
import UserUpgrade from 'components/icons/UserUpgrade';
import useSuperAdminStore from 'modules/app/redux/services/useSuperAdminStore';
import { User, UserStatus } from 'modules/organization/types/organizationDetail/users';
import { IdentityAccessRights, useAccessRightsToFunctionality, useAccountInfo } from 'modules/security';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { UserType } from '../../../../../security/types/user';
import useUserStore from '../../../../redux/services/useUserStore';
import UserStatusBadge from './UserStatusBadge';

type Props = {
  user: User;
  selected: boolean;
  toggleIsSelected: (id: string | number) => void;
};

const UserUpgradeModal = React.lazy(() => import('./UserUpgradeModal'));

const UserRow: React.FunctionComponent<Props> = ({ user, selected, toggleIsSelected }: Props) => {
  const { t } = useTranslation('organization');
  const accountInfo = useAccountInfo();
  const hasAccessToReset = useAccessRightsToFunctionality(IdentityAccessRights.IdentityReset);
  const hasAccessToMakeAdmin = useAccessRightsToFunctionality(IdentityAccessRights.MakeAdmin);
  const hasAccessToUpgradeUser = useAccessRightsToFunctionality(IdentityAccessRights.UpgradeUser);
  const { isCompanyAllowedToUpgradeUser } = useSuperAdminStore();
  const {
    handleResetPassword,
    isResettingPw,
    isResetting2FA,
    handleResetAuthenticator,
    handleMakeUserAdmin,
    isMakingAdmin,
    isUpgradingUser,
  } = useUserStore();

  const isNotSuperAdmin = accountInfo?.userType !== UserType.SuperAdmin;

  const handleCheckboxChange = React.useCallback(() => {
    toggleIsSelected(user.userId);
  }, [user.userId, toggleIsSelected]);

  const userInactive = user.status === UserStatus.Suspended || user.status === UserStatus.Upgraded;

  const canNotBeAdmin = user.status !== UserStatus.Activated || user.type !== UserType.User;

  return (
    <TableRow active={selected}>
      {isNotSuperAdmin ? null : (
        <TableData>
          <Input type="checkbox" name={user.userId.toString()} onChange={handleCheckboxChange} checked={selected} />
        </TableData>
      )}
      <TableData>
        <TextWrap>{`${user.name || ''} ${user.firstName || ''}`}</TextWrap>
      </TableData>
      <TableData>
        <TextWrap>{user.email}</TextWrap>
      </TableData>
      <TableData>
        <div>{user.type}</div>
      </TableData>
      <TableData>
        <div>{user.department}</div>
      </TableData>
      <TableData>
        <UserStatusBadge status={user.status} />
      </TableData>
      {(hasAccessToReset || hasAccessToMakeAdmin) && (
        <TableData className="actions">
          <FlexRow>
            {hasAccessToMakeAdmin && (
              <CustomConfirmation
                description={t('detail.userRow.descriptionAdmin')}
                confirmText={t('detail.userRow.confirmAdmin')}
                callback={() => handleMakeUserAdmin(user)}
              >
                <IconButton
                  isLoading={isMakingAdmin}
                  disabled={canNotBeAdmin || isMakingAdmin || !hasAccessToMakeAdmin}
                  tooltip={t('detail.userRow.makeAdmin')}
                >
                  <MakeAdmin />
                </IconButton>
              </CustomConfirmation>
            )}
            {hasAccessToReset && (
              <React.Fragment>
                <IconButton
                  isLoading={isResetting2FA}
                  disabled={!user.mfaIsActive || userInactive || isResetting2FA || !hasAccessToReset}
                  onClick={() => handleResetAuthenticator(user)}
                  tooltip={t('detail.userRow.resetAuthenticator')}
                >
                  <Auth />
                </IconButton>
              </React.Fragment>
            )}
            <IconButton
              isLoading={isResettingPw}
              disabled={!user.passwordIsSet || userInactive || isResettingPw || !hasAccessToReset}
              onClick={() => handleResetPassword(user)}
              tooltip={t('detail.userRow.resetPassword')}
            >
              <Key />
            </IconButton>
            {hasAccessToUpgradeUser && isCompanyAllowedToUpgradeUser && (
              <Modal content={UserUpgradeModal} contentProps={{ user }}>
                {(toggleModal) => (
                  <IconButton
                    isLoading={isUpgradingUser}
                    disabled={
                      user.status !== UserStatus.Activated ||
                      user.type === UserType.Admin ||
                      isUpgradingUser ||
                      !hasAccessToUpgradeUser
                    }
                    onClick={toggleModal}
                    tooltip={t('tooltips.upgrade')}
                  >
                    <UserUpgrade />
                  </IconButton>
                )}
              </Modal>
            )}
          </FlexRow>
        </TableData>
      )}
    </TableRow>
  );
};

export default UserRow;
