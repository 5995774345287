import * as Yup from 'yup';
import CreatableSelect from '../../components/select/CreatableSelect';
import { InputField } from '../../types';
import { CTI_DATA, CUSTOM } from '../index';

const keywordsFields: InputField[] = [
  {
    component: CreatableSelect,
    initialValue: [],
    label: 'keywords.formLabels.darkWebSearch',
    name: 'darkWebSearch',
    type: CUSTOM,
    width: {
      desktop: 12,
    },
  },
];

const keywordsValidationSchema = Yup.object().shape({
  darkWebSearch: Yup.array().of(Yup.string()),
});

const headerTranslations = {
  content: 'keywords.content',
  title: 'keywords.title',
};

export const keywordsStep = {
  fields: keywordsFields,
  headerTranslations,
  key: 'keywords',
  submitParam: CTI_DATA,
  validationSchema: keywordsValidationSchema,
};
