import { ALERTS_URL } from './endpoints';
import AxiosConfig, { RequestConfig } from 'config/api/AxiosConfig';
import { EditRuleSubmitValues, NewRuleSubmitValues } from '../types/alertRules';

const getAlerts = async () => {
  const requestConfig: RequestConfig = {
    url: ALERTS_URL,
  };

  return AxiosConfig.fetchAxiosGetRequest(requestConfig);
};

const toggleAllAlerts = async (enabled: boolean) => {
  const requestConfig: RequestConfig = {
    params: {
      enabled,
    },
    url: ALERTS_URL,
  };

  return AxiosConfig.fetchAxiosPutRequest(requestConfig);
};

const deleteAlertRule = async (alertId: string) => {
  const requestConfig: RequestConfig = {
    url: `${ALERTS_URL}/rules/${alertId}`,
  };

  return AxiosConfig.fetchAxiosDeleteRequest(requestConfig);
};

const addAlertRule = async (alertRule: NewRuleSubmitValues) => {
  const requestConfig: RequestConfig = {
    data: alertRule,
    url: `${ALERTS_URL}/rules`,
  };

  return AxiosConfig.fetchAxiosPostRequest(requestConfig);
};

const editAlertRule = async (alertRule: EditRuleSubmitValues) => {
  const requestConfig: RequestConfig = {
    data: alertRule,
    url: `${ALERTS_URL}/rules`,
  };

  return AxiosConfig.fetchAxiosPutRequest(requestConfig);
};

export default { getAlerts, toggleAllAlerts, deleteAlertRule, addAlertRule, editAlertRule };
