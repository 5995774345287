import { HttpErrorResponse } from '../../../../../types';
import { GetMailsResponse } from '../../../types/mails';
import {
  MAILS_CLEARED,
  MAILS_FETCHED_MORE,
  MAILS_GET,
  MAILS_MERGED,
  MAILS_REJECTED,
  MAILS_REQUEST,
  MAILS_SUCCESS,
} from '../constants/mails';
import {
  ClearMailsAction,
  FetchMailsAction,
  FetchMoreMailsAction,
  GetMailsAction,
  MergeMailsAction,
  RejectFetchMailsAction,
  SetMailsAction,
} from '../types/mails';

export const getMails = (page?: number, searchValue?: string): GetMailsAction => ({
  payload: {
    page,
    searchValue,
  },
  type: MAILS_GET,
});

export const fetchMoreMails = (page: number, searchValue?: string): FetchMoreMailsAction => ({
  payload: {
    page,
    searchValue,
  },
  type: MAILS_FETCHED_MORE,
});

export const fetchMails = (): FetchMailsAction => ({
  type: MAILS_REQUEST,
});

export const rejectFetchMails = (error: HttpErrorResponse): RejectFetchMailsAction => ({
  payload: error,
  type: MAILS_REJECTED,
});

export const setMails = (payload: GetMailsResponse): SetMailsAction => ({
  payload,
  type: MAILS_SUCCESS,
});

export const mergeMails = (payload: GetMailsResponse): MergeMailsAction => ({
  payload,
  type: MAILS_MERGED,
});

export const clearMails = (): ClearMailsAction => ({
  type: MAILS_CLEARED,
});
