import * as React from 'react';
import { NestedGroup } from '../../../helpers/groupBy';
import { AllowedModulesFetcher, UserManager } from '../services';
import { UserManagerRenderProps } from '../services/UserManager';
import { Modules } from '../types/modules';
import { AccessRight, Account } from '../types/user';
import UnauthenticatedFlow from './UnauthenticatedFlow';

type RenderCallBackProps = {
  accessRights?: NestedGroup<AccessRight>;
  account?: Account;
  modules: Modules;
  isAuthenticated: boolean;
};

type RenderCallback = ({ modules, isAuthenticated }: RenderCallBackProps) => JSX.Element;

type Props = {
  children: RenderCallback;
};

const SecureModules = (props: Props): JSX.Element => {
  const { children } = props;

  return (
    <UserManager>
      {({ isAuthenticated, account, accessRights }: UserManagerRenderProps) => (
        <React.Fragment>
          {isAuthenticated ? (
            <AllowedModulesFetcher accessRights={accessRights}>
              {(modules: Modules) =>
                children({
                  account,
                  isAuthenticated,
                  modules,
                })
              }
            </AllowedModulesFetcher>
          ) : (
            <UnauthenticatedFlow children={children} />
          )}
        </React.Fragment>
      )}
    </UserManager>
  );
};

export default SecureModules;
