import { SortState } from 'components/Table/SortToggle';
import { HttpErrorResponse, HttpResponse, SortValue } from 'types';
import { createAction, createAsyncAction } from 'typesafe-actions';
import { Activity, GetActivitiesParameters } from '../../types';

export const getActivities = createAction(
  'ACTIVITIES_GET',
  (action) => (params?: GetActivitiesParameters) => action(params)
);
export const getActivitiesRequest = createAsyncAction(
  'ACTIVITIES_REQUEST',
  'ACTIVITIES_SUCCESS',
  'ACTIVITIES_REJECTED'
)<undefined, HttpResponse<Activity>, HttpErrorResponse>();

export const setPage = createAction('ACTIVITIES_PAGE_SET', (action) => (pageIndex: number) => action(pageIndex));
export const setPageSize = createAction('ACTIVITIES_PAGE_SIZE_SET', (action) => (pageSize: number) => action(pageSize));

export const setSorting = createAction(
  'ACTIVITIES_SORT',
  (action) => (name: string, state: SortState, secondary?: boolean) => {
    const payload: SortValue = { name, state, secondary };

    return action(payload);
  }
);

export const clearActivities = createAction('ACTIVITIES_CLEARED');
