import { mapSingleFile } from 'helpers/singleFileUploadHelper';
import {
  availabilityTimesMatch,
  mapAvailabilitiesToBackendValues,
} from 'modules/app/components/availabilities/formatAvailabilityHelper';
import organizationDetailApi from 'modules/organization/api/organizationDetail';
import { Contact, ContactPostValues } from 'modules/organization/types/organizationDetail/contacts';
import { identityApi } from 'modules/security';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { organizationDetailActions } from '../actions/creators';
import { makeGetContacts } from '../selectors/organizationDetail';

function* organizationDetailSaga() {
  yield takeLatest(organizationDetailActions.getGeneralInfo, handleGetGeneralInfo);
  yield takeLatest(organizationDetailActions.getContacts, handleGetContacts);
  yield takeLatest(organizationDetailActions.updateOrganization, handleUpdateOrganization);
  yield takeLatest(organizationDetailActions.getCTIData, handleGetCTI);
  yield takeLatest(organizationDetailActions.getPrintDetail, handleGetPrintDetail);
  yield takeLatest(organizationDetailActions.getUsers, handleGetUsers);
  yield takeLatest(organizationDetailActions.getDifference, handleGetDifference);
  yield takeLatest(organizationDetailActions.addUser, handleAddUser);
  yield takeLatest(organizationDetailActions.addAdmin, handleAddAdmin);
  yield takeLatest(organizationDetailActions.approve, handleApprove);
  yield takeLatest(organizationDetailActions.reject, handleReject);
  yield takeLatest(organizationDetailActions.userResetPassword, handleUserResetPassword);
  yield takeLatest(organizationDetailActions.userResetAuthenticator, handleUserResetAuthenticator);
  yield takeLatest(organizationDetailActions.updateGeneralInfo, handleUpdateGeneralInfo);
  yield takeLatest(organizationDetailActions.updateContacts, handleUpdateContacts);
  yield takeLatest(organizationDetailActions.updateCTIData, handleUpdateCTIData);
  yield takeLatest(organizationDetailActions.deleteOrganization, handleDeleteOrganization);
  yield takeLatest(organizationDetailActions.approveUser, handleApproveUser);
  yield takeLatest(organizationDetailActions.rejectUser, handleRejectUser);
  yield takeLatest(organizationDetailActions.deleteUser, handleDeleteUser);
  yield takeLatest(organizationDetailActions.resendInvitation, handleResendUserInvitation);
  yield takeLatest(organizationDetailActions.setUserToAdmin, handleSetUserToAdmin);
  yield takeLatest(organizationDetailActions.upgradeUser, handleUpgradeUser);
}

function* handleGetGeneralInfo(action: ReturnType<typeof organizationDetailActions.getGeneralInfo>) {
  yield put(organizationDetailActions.getGeneralInfoRequest.request());
  try {
    const payload = yield call(organizationDetailApi.getGeneralInfo, action.payload);
    yield put(organizationDetailActions.getGeneralInfoRequest.success(payload));
  } catch (e) {
    yield put(organizationDetailActions.getGeneralInfoRequest.failure(e.response));
  }
}

function* handleUpdateOrganization(action: ReturnType<typeof organizationDetailActions.updateOrganization>) {
  yield put(organizationDetailActions.updateOrganizationRequest.request());
  try {
    const payload = yield call(organizationDetailApi.updateOrganization, action.payload.id, action.payload.values);
    yield put(organizationDetailActions.updateOrganizationRequest.success(payload));
    action.successCallback();
  } catch (e) {
    yield put(organizationDetailActions.updateOrganizationRequest.failure(e.response));
  }
}

function* handleDeleteOrganization(action: ReturnType<typeof organizationDetailActions.deleteOrganization>) {
  const { request, success, failure } = organizationDetailActions.deleteOrganizationRequest;
  yield put(request());
  try {
    yield call(organizationDetailApi.deleteOrganization, action.payload);
    yield put(organizationDetailActions.clearOrganizationDetail());
    yield put(organizationDetailActions.getGeneralInfo(action.payload));
    yield put(success());
  } catch (e) {
    yield put(failure(e.response));
  }
}

function* handleGetContacts(action: ReturnType<typeof organizationDetailActions.getContacts>) {
  yield put(organizationDetailActions.getContactsRequest.request());
  try {
    const payload = yield call(organizationDetailApi.getContacts, action.payload);
    yield put(organizationDetailActions.getContactsRequest.success(payload));
  } catch (e) {
    yield put(organizationDetailActions.getContactsRequest.failure(e.response));
  }
}

function* handleGetCTI(action: ReturnType<typeof organizationDetailActions.getCTIData>) {
  yield put(organizationDetailActions.getCTIRequest.request());
  try {
    const payload = yield call(organizationDetailApi.getCTIData, action.payload);
    yield put(organizationDetailActions.getCTIRequest.success(payload));
  } catch (e) {
    yield put(organizationDetailActions.getCTIRequest.failure(e.response));
  }
}

function* handleGetPrintDetail(action: ReturnType<typeof organizationDetailActions.getPrintDetail>) {
  const { request, success, failure } = organizationDetailActions.getPrintDetailRequest;
  yield put(request());
  try {
    yield call(organizationDetailApi.printDetail, action.payload);
    yield put(success());
    if (action.successCallback) {
      action.successCallback();
    }
  } catch (e) {
    yield put(failure(e.response));
  }
}

function* handleGetUsers(action: ReturnType<typeof organizationDetailActions.getUsers>) {
  yield put(organizationDetailActions.getUsersRequest.request());
  try {
    const payload = yield call(organizationDetailApi.getUsers, action.payload);
    yield put(organizationDetailActions.getUsersRequest.success(payload));
  } catch (e) {
    yield put(organizationDetailActions.getUsersRequest.failure(e.response));
  }
}

function* handleGetDifference(action: ReturnType<typeof organizationDetailActions.getDifference>) {
  yield put(organizationDetailActions.getDifferenceRequest.request());
  try {
    const payload = yield call(organizationDetailApi.getDifference, action.payload);
    yield put(organizationDetailActions.getDifferenceRequest.success(payload));
  } catch (e) {
    yield put(organizationDetailActions.getDifferenceRequest.failure(e.response));
  }
}

function* handleAddUser(action: ReturnType<typeof organizationDetailActions.addUser>) {
  yield put(organizationDetailActions.addUserRequest.request());
  try {
    const payload = yield call(organizationDetailApi.addUser, action.payload.organizationId, action.payload.values);
    yield put(organizationDetailActions.addUserRequest.success(payload));
    action.successCallback();
  } catch (e) {
    yield put(organizationDetailActions.addUserRequest.failure(e.response));
  }
}

function* handleAddAdmin(action: ReturnType<typeof organizationDetailActions.addAdmin>) {
  yield put(organizationDetailActions.addAdminRequest.request());
  try {
    const payload = yield call(organizationDetailApi.addAdmin, action.payload.organizationId, action.payload.values);
    yield put(organizationDetailActions.addAdminRequest.success(payload));
    action.successCallback();
  } catch (e) {
    yield put(organizationDetailActions.addAdminRequest.failure(e.response));
  }
}

function* handleApprove(action: ReturnType<typeof organizationDetailActions.approve>) {
  yield put(organizationDetailActions.approveRequest.request());
  try {
    const payload = yield call(organizationDetailApi.approveOrganization, action.payload);
    yield put(organizationDetailActions.approveRequest.success(payload));
  } catch (e) {
    yield put(organizationDetailActions.approveRequest.failure(e.response));
  }
}

function* handleReject(action: ReturnType<typeof organizationDetailActions.reject>) {
  const { request, success, failure } = organizationDetailActions.rejectRequest;
  yield put(request());
  try {
    const payload = yield call(
      organizationDetailApi.rejectOrganization,
      action.payload.organizationId,
      action.payload.comment
    );
    yield put(success(payload));
    action.successCallback();
  } catch (e) {
    yield put(failure(e.response));
  }
}

function* handleUserResetPassword(action: ReturnType<typeof organizationDetailActions.userResetPassword>) {
  const { request, success, failure } = organizationDetailActions.userResetPasswordRequest;
  yield put(request());
  try {
    yield call(identityApi.resetUserPassword, action.payload);
    yield put(success());
  } catch (e) {
    yield put(failure(e.response));
  }
}

function* handleUserResetAuthenticator(action: ReturnType<typeof organizationDetailActions.userResetAuthenticator>) {
  const { request, success, failure } = organizationDetailActions.userResetAuthenticatorRequest;
  yield put(request());
  try {
    yield call(identityApi.resetUserAuthenticator, action.payload);
    yield put(success());
  } catch (e) {
    yield put(failure(e.response));
  }
}

function* handleUpdateGeneralInfo(action: ReturnType<typeof organizationDetailActions.updateGeneralInfo>) {
  yield put(organizationDetailActions.updateGeneralInfoRequest.request());
  try {
    const payload = yield call(organizationDetailApi.updateGeneralInfo, action.payload.id, action.payload.values);
    action.successCallback();
    yield put(organizationDetailActions.updateGeneralInfoRequest.success(payload));
  } catch (e) {
    yield put(organizationDetailActions.updateGeneralInfoRequest.failure(e.response));
  }
}

function* handleUpdateContacts(action: ReturnType<typeof organizationDetailActions.updateContacts>) {
  yield put(organizationDetailActions.updateContactsRequest.request());
  try {
    const contactData: Contact[] = yield select(makeGetContacts()) || [];
    const newContactData = action.payload.values.contacts.map((contact) => ({
      ...contact,
      availabilities: mapAvailabilitiesToBackendValues(contact.availabilities),
    }));
    // This is what clean code looks like, don't even think about commenting
    // For each availability, we will check if the original contact data has a contact with an availability with the same
    // day of the week. If it does, we check if the start and end times match, if it does we return the original availability (no change)
    // if the times don't match, we take the existing ID and day and update the start and end times.
    // For new contacts or new availabilities (new day), we use availabilityId 0 to insert the new data
    const withIds = newContactData.map((contact) => ({
      ...contact,
      availabilities: contact.availabilities.map((availability) => {
        const originalContact = contactData.find((c) => c.contactId === contact.contactId);
        if (originalContact) {
          const originalContactOriginalAvailabilityDay = originalContact.availabilities.find(
            (a) => a.day === availability.day
          );
          if (
            originalContactOriginalAvailabilityDay &&
            availabilityTimesMatch(originalContactOriginalAvailabilityDay, availability)
          ) {
            return originalContactOriginalAvailabilityDay;
          }

          return {
            ...availability,
            availabilityId: originalContactOriginalAvailabilityDay
              ? originalContactOriginalAvailabilityDay.availabilityId
              : '0',
          };
        }

        return availability;
      }),
    }));

    const postValues: ContactPostValues[] = withIds.map((contact) => {
      const { pgpFile, ...restValues } = contact;

      return {
        ...restValues,
        ...mapSingleFile('pgpFileId', 'PgpFileName', 'PgpContent', pgpFile || undefined),
      };
    });

    const payload = yield call(organizationDetailApi.updateContacts, action.payload.id, postValues);
    yield put(organizationDetailActions.updateContactsRequest.success(payload));
    action.successCallback();
  } catch (e) {
    yield put(organizationDetailActions.updateContactsRequest.failure(e.response));
  }
}

function* handleUpdateCTIData(action: ReturnType<typeof organizationDetailActions.updateCTIData>) {
  yield put(organizationDetailActions.updateCTIDataRequest.request());
  try {
    const payload = yield call(organizationDetailApi.updateCTIData, action.payload.id, action.payload.values);
    yield put(organizationDetailActions.updateCTIDataRequest.success(payload));
    action.successCallback();
  } catch (e) {
    yield put(organizationDetailActions.updateCTIDataRequest.failure(e.response));
  }
}

function* handleApproveUser(action: ReturnType<typeof organizationDetailActions.approveUser>) {
  yield put(organizationDetailActions.approveUserRequest.request());
  try {
    const payload = yield call(organizationDetailApi.approveUser, action.payload.id);
    yield put(organizationDetailActions.approveUserRequest.success(payload));
    action.successCallback();
  } catch (e) {
    yield put(organizationDetailActions.approveUserRequest.failure(e.response));
  }
}

function* handleRejectUser(action: ReturnType<typeof organizationDetailActions.rejectUser>) {
  const { request, success, failure } = organizationDetailActions.rejectUserRequest;
  yield put(request());
  try {
    const payload = yield call(organizationDetailApi.rejectUser, action.payload.id, action.payload.comment);
    yield put(success(payload));
    action.successCallback();
  } catch (e) {
    yield put(failure(e.response));
  }
}

function* handleDeleteUser(action: ReturnType<typeof organizationDetailActions.deleteUser>) {
  const { request, success, failure } = organizationDetailActions.deleteUserRequest;
  yield put(request());
  try {
    yield call(organizationDetailApi.deleteUser, action.payload.id);
    yield put(success(action.payload.id));
    action.successCallback();
  } catch (e) {
    yield put(failure(e.response));
  }
}

function* handleResendUserInvitation(action: ReturnType<typeof organizationDetailActions.resendInvitation>) {
  const { request, success, failure } = organizationDetailActions.resendInvitationRequest;
  yield put(request());
  try {
    yield call(organizationDetailApi.resendInvitation, action.payload);
    yield put(success());
  } catch (e) {
    yield put(failure(e.response));
  }
}

function* handleSetUserToAdmin(action: ReturnType<typeof organizationDetailActions.setUserToAdmin>) {
  const { request, success, failure } = organizationDetailActions.setUserToAdminRequest;
  yield put(request());

  try {
    const payload = yield call(organizationDetailApi.setUserToAdmin, action.payload.userId);
    yield put(success(payload));
  } catch (e) {
    yield put(failure(e.response));
  }
}

function* handleUpgradeUser({
  payload: { guid, role, successCallback },
}: ReturnType<typeof organizationDetailActions.upgradeUser>) {
  const { request, success, failure } = organizationDetailActions.upgradeUserRequest;
  yield put(request());

  try {
    yield call(organizationDetailApi.upgradeUser, guid, role);
    yield put(success(guid));
    successCallback?.();
  } catch (e) {
    yield put(failure(e.response));
  }
}

export default organizationDetailSaga;
