import { arrayUtils } from '../../../../helpers/';
import { GetMailsResponse } from '../../types/mails';
import { MAILS_CLEARED, MAILS_MERGED, MAILS_SUCCESS } from '../actions/constants/mails';
import { MailsActions } from '../actions/types/mails';

export type MailsState = Partial<GetMailsResponse>;

const mailsReducer = (state: MailsState = {}, action: MailsActions) => {
  switch (action.type) {
    case MAILS_SUCCESS:
      return action.payload;

    case MAILS_MERGED:
      const items = arrayUtils.removeDuplicates([...(state.items || []), ...action.payload.items], 'emailId');

      return { ...action.payload, items };

    case MAILS_CLEARED:
      return (state = {});

    default:
      return state;
  }
};

export default mailsReducer;
