import { SortState } from 'components/Table/SortToggle';
import { SortValue } from 'types';
import { getType } from 'typesafe-actions';
import { SortingActions, sortingActions } from '../../creators';

const sortingReducer = (
  state: SortValue[] = [{ name: 'uploadDate', state: SortState.Desc }],
  action: SortingActions
) => {
  switch (action.type) {
    case getType(sortingActions.setSorting):
      if (!action.payload.secondary) {
        const existingSort = state.find(s => s.name === action.payload.name && s.state !== SortState.None);
        if (existingSort && existingSort.secondary) {
          return [...state.filter(s => !s.secondary), { ...action.payload, secondary: existingSort.secondary }];
        }

        return [...state.filter(s => s.secondary), action.payload];
      }
      // if there is no primary sorting, sort the secondary as primary (no secondary only sorting)
      if (state.filter(s => !s.secondary).length === 0) {
        return [{ ...action.payload, secondary: false }];
      }

      return [...state.filter(s => !s.secondary), action.payload];
    default:
      return state;
  }
};

export default sortingReducer;
