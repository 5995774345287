import { ConvertedFile } from 'components/Dropzone/FileInput';

export const mapSingleFile = (
  idProp: string,
  nameProp: string,
  contentProp: string,
  file?: ConvertedFile & { [id: string]: any }
) => {
  if (!file) {
    return {};
  }
  if (!file[idProp]) {
    return file.file && { [nameProp]: file.name, [contentProp]: file.base64 };
  }

  if (file.name === '') {
    return {};
  }

  return {
    [idProp]: file[idProp],
    [nameProp]: file.name,
    [contentProp]: file.base64 === '' ? undefined : file.base64,
  };
};
