import axios, { AxiosRequestConfig, ResponseType } from 'axios';
import deepmerge from 'deepmerge';
import Qs from 'qs';

export type RequestConfig<T = {}> = {
  method?: RequestMethodType;
  url: string;
  params?: T;
  data?: any;
  pageSize?: number;
  headers?: any;
  responseType?: ResponseType;
};

export type RequestMethodType = 'GET' | 'POST' | 'PUT' | 'DELETE';

class AxiosConfig {
  private defaultRequestConfig: AxiosRequestConfig = {
    baseURL: process.env.REACT_APP_API_ROOT,
    paramsSerializer: (params) => Qs.stringify(params, { arrayFormat: 'repeat' }),
    withCredentials: true,
  };

  private getRequestConfig: AxiosRequestConfig = {
    ...this.defaultRequestConfig,
    method: 'GET',
  };

  private getPagedRequestConfig: AxiosRequestConfig = {
    ...this.defaultRequestConfig,
    method: 'GET',
    params: {
      pageSize: 20,
    },
  };

  private postRequestConfig: AxiosRequestConfig = {
    ...this.defaultRequestConfig,
    method: 'POST',
  };

  private putRequestConfig: AxiosRequestConfig = {
    ...this.defaultRequestConfig,
    method: 'PUT',
  };

  private deleteRequestConfig: AxiosRequestConfig = {
    ...this.defaultRequestConfig,
    method: 'DELETE',
  };

  public fetchAxiosGetRequest<T = {}>(config?: RequestConfig): Promise<T> {
    return this.fetchRequest(this.getRequestConfig, config);
  }

  public fetchAxiosPagedGetRequest<T = {}>(config?: RequestConfig): Promise<T> {
    return this.fetchRequest(this.getPagedRequestConfig, config);
  }

  public fetchAxiosPostRequest<T = {}>(config?: RequestConfig): Promise<T> {
    return this.fetchRequest(this.postRequestConfig, config);
  }

  public fetchAxiosPutRequest<T = {}>(config?: RequestConfig): Promise<T> {
    return this.fetchRequest(this.putRequestConfig, config);
  }

  public fetchAxiosDeleteRequest<T = {}>(config?: RequestConfig): Promise<T> {
    return this.fetchRequest(this.deleteRequestConfig, config);
  }

  // TODO: Remove this when backend returns everything in correct data format
  public async fetchAxiosRequestTemp<T = {}>(config?: RequestConfig): Promise<T> {
    const result = await axios.request({ ...this.defaultRequestConfig, ...config });

    return result.data;
  }

  public async fetchAxiosDownloadRequest<T = {}>(config: RequestConfig, fileName: string): Promise<void> {
    const result = await axios.request({ ...this.defaultRequestConfig, ...config, responseType: 'blob' });

    if (typeof (window.navigator as any).msSaveBlob !== 'undefined') {
      // https://github.com/kennethjiang/js-file-download/blob/master/file-download.js
      // IE workaround for "HTML7007: One or more blob URLs were
      // revoked by closing the blob for which they were created.
      // These URLs will no longer resolve as the data backing
      // the URL has been freed."
      (window.navigator as any).msSaveBlob(new Blob([result.data]), fileName);
    } else {
      const url = window.URL.createObjectURL(new Blob([result.data]));
      const tempDownloadLink = document.createElement('a');
      tempDownloadLink.style.display = 'none';
      tempDownloadLink.href = url;
      tempDownloadLink.setAttribute('download', fileName);

      // Safari thinks _blank anchor are pop ups. We only want to set _blank
      // target if the browser does not support the HTML5 download attribute.
      // This allows you to download files in desktop safari if pop up blocking
      // is enabled.
      if (typeof tempDownloadLink.download === 'undefined') {
        tempDownloadLink.setAttribute('target', '_blank');
      }

      document.body.appendChild(tempDownloadLink);
      tempDownloadLink.click();
      document.body.removeChild(tempDownloadLink);
      window.URL.revokeObjectURL(url);
    }
  }

  private async fetchRequest<T = {}>(baseConfig: AxiosRequestConfig, config?: RequestConfig): Promise<T> {
    const result = await axios.request(deepmerge(baseConfig, config || {}));

    return result.data.response;
  }
}

export default new AxiosConfig();
