import { Select } from 'components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Audience, audienceOptions } from '../../constants';

type Props = {
  audiences?: Audience[];
  filterAction: (audiences: Audience[]) => void;
};

const AudienceFilters: React.FunctionComponent<Props> = ({ audiences, filterAction }) => {
  const { t } = useTranslation('global');
  const dispatch = useDispatch();

  const changeHandler = React.useCallback(
    selected => {
      dispatch(filterAction(selected || []));
    },
    [dispatch, filterAction]
  );

  return (
    <div>
      <h5>{t('sidebar.filters.audience')}</h5>
      <Select
        defaultOptions
        getOptionLabel={(option: Audience) => option}
        getOptionValue={(option: Audience) => option}
        isMulti
        onChange={changeHandler}
        options={audienceOptions}
        value={audiences}
        isCapitalized
      />
    </div>
  );
};

export default AudienceFilters;
