import { OrganizationUpdateStep } from 'modules/organization/types/organization';
import { HttpErrorResponse } from 'types';
import { createAction, createAsyncAction } from 'typesafe-actions';

export const getProfileUpdateStep = createAction('PROFILE_UPDATE_STEP_GET', (action) => (id: string) => action(id));
export const getProfileUpdateStepRequest = createAsyncAction(
  'PROFILE_UPDATE_STEP_GET_REQUEST',
  'PROFILE_UPDATE_STEP_GET_SUCCESS',
  'PROFILE_UPDATE_STEP_GET_REJECTED'
)<undefined, OrganizationUpdateStep, HttpErrorResponse>();
