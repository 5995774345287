import * as React from 'react';
import { Route } from 'react-router-dom';
import { loadScene } from '../../../components/async/scene';
import ModuleRoutes from '../../../components/routing/ModuleRoutes';
import { Module } from '../../security/types/modules';

type Props = {
  allowedModules: Module[];
};

const reportTypeOverviewScene = loadScene('reportType', 'ReportTypeScene');

const ReportTypeRoutes: React.FunctionComponent<Props> = ({ allowedModules }: Props) => {
  return (
    <ModuleRoutes allowedModules={allowedModules} moduleKey={REPORTTYPE_MODULE_KEY}>
      <Route path={REPORTTYPE_BASE_ROUTE} exact component={reportTypeOverviewScene} />
    </ModuleRoutes>
  );
};

export const REPORTTYPE_BASE_ROUTE = '/reporttypes';
export const REPORTTYPE_MODULE_KEY = 'reporttypes';
export default ReportTypeRoutes;
