import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const ApprovePerson: React.FunctionComponent = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.55 22.3C24.2705 22.3 27.3 19.4593 27.3 15.9707V13.3293C27.3 9.84072 24.2705 7 20.55 7C16.8295 7 13.8 9.84072 13.8 13.3293V16.0205C13.8531 19.4593 16.8827 22.3 20.55 22.3Z" />
      <path d="M32.6926 24.8676L29.1533 21.1944L27 23.3366L32.6927 29L47 16.2958L44.9996 14L32.6926 24.8676Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.398 27.1591C25.3115 27.2176 25.2306 27.2819 25.1559 27.351L21.514 31.4286C21.0142 31.9245 20.0858 31.9245 19.586 31.4286L15.9441 27.351C15.6584 27.0204 15.0871 26.8 14.5159 26.8C10.0884 26.8 6.1609 29.1694 5.23257 32.5306L3.09028 39.8041C2.59041 41.4571 4.23284 43 6.37513 43H34.7249C36.8672 43 38.5096 41.4571 38.0097 39.8041L35.8674 32.5306C35.7468 32.1252 35.5847 31.7341 35.385 31.36L32.9594 33.4324C32.5622 33.7718 31.9708 33.7482 31.6018 33.3784L25.398 27.1591Z"
      />
    </svg>
  </BaseSvgWrapper>
);

export default ApprovePerson;
