import { Field, FieldProps, Form, Formik, FormikProps } from 'formik';
import { TFunction } from 'i18next';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Input, SubmitButton } from '../../../components';
import { LoginData, LoginFormValues } from '../types/login';

type Props = {
  isPending: boolean;
  handleSubmit: (formData: LoginData) => void;
  locationState: any;
} & WithTranslation;

class LoginForm extends React.PureComponent<Props> {
  ObjectSchema?: Yup.ObjectSchema<LoginFormValues>;

  getObjectSchema = (t: TFunction): Yup.ObjectSchema<LoginFormValues> =>
    Yup.object().shape({
      password: Yup.string().required(t('validation:required')),
      username: Yup.string()
        .email(t('validation:string.email'))
        .required(t('validation:required')),
    });

  componentWillMount() {
    this.ObjectSchema = this.getObjectSchema(this.props.t);
  }

  render(): JSX.Element {
    const { handleSubmit, locationState, isPending, t } = this.props;

    return (
      <Formik
        initialValues={{ password: '', username: '' }}
        validationSchema={this.ObjectSchema}
        validateOnChange={false}
        onSubmit={(values: LoginFormValues) =>
          handleSubmit({
            password: values.password,
            returnUrl: (locationState && locationState.referrer) || '/',
            username: values.username,
          })
        }
        render={({ touched, errors, isSubmitting }: FormikProps<LoginFormValues>) => (
          <Form>
            <Field
              name="username"
              render={({ field }: FieldProps<LoginFormValues>) => (
                <Input
                  {...field}
                  error={(touched.username && errors.username) || ''}
                  type="text"
                  label={t('login_email')}
                />
              )}
            />
            <Field
              name="password"
              render={({ field }: FieldProps<LoginFormValues>) => (
                <Input
                  {...field}
                  error={(touched.password && errors.password) || ''}
                  type="password"
                  label={t('login_password')}
                />
              )}
            />
            <SubmitButton primary block type="submit" isLoading={isPending && isSubmitting}>
              {t('login_submit')}
            </SubmitButton>
          </Form>
        )}
      />
    );
  }
}

export default withTranslation(['security', 'validation'])(LoginForm);
