import { ActionType } from 'typesafe-actions';
import * as inactiveUsersAction from './inactiveUsers';
import * as pageInfoAction from './pageInfo';
import * as sortingAction from './sorting';
import * as filterAction from './filters';

export const INACTIVE_USERS_ACTION_PREFIX = 'INACTIVE_USERS';

export const inactiveUsersActions = { ...inactiveUsersAction };
export const pageInfoActions = { ...pageInfoAction };
export const sortingActions = { ...sortingAction };
export const filterActions = { ...filterAction };

export type InactiveUsersActions = ActionType<typeof inactiveUsersAction>;
export type PageInfoActions = ActionType<typeof pageInfoAction>;
export type SortingActions = ActionType<typeof sortingAction>;
export type FilterActions = ActionType<typeof filterAction>;
