import superAdminReducer, { SuperAdminState } from 'modules/app/redux/reducers/superAdmin';
import { combineReducers } from 'redux';
import organizationDetailReducer, { OrganizationDetailState } from './organizationDetail';
import organizationsReducer, { OrganizationsState } from './organizations';
import profileUpdateReducer from './profileUpdate';
import { OrganizationUpdateStep } from '../../types/organization';

export type OrganizationModuleState = {
  organization: OrganizationsState;
  organizationDetail: OrganizationDetailState;
  globalInfo: SuperAdminState;
  profileUpdate: OrganizationUpdateStep | null;
};

const organizationModuleReducer = combineReducers({
  organization: organizationsReducer,
  organizationDetail: organizationDetailReducer,
  globalInfo: superAdminReducer,
  profileUpdate: profileUpdateReducer,
});

export default organizationModuleReducer;
