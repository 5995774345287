import i18n from 'config/i18n';
import * as Yup from 'yup';
import { IpRangeFormat, IpVersion } from '../../../app/types';
import IpFormatSelect from '../../components/select/IpFormatSelect';
import IpVersionSelect from '../../components/select/IpVersionSelect';
import { InputField } from '../../types';
import { CUSTOM } from '../index';

const ipVersionFields: InputField[] = [
  {
    addLabel: 'Add IP range',
    fields: [
      {
        component: IpFormatSelect,
        label: 'ipVersion.formLabels.format',
        name: 'format',
        type: CUSTOM,
        width: {
          desktop: 6,
        },
      },
      {
        component: IpVersionSelect,
        label: 'ipVersion.formLabels.ipVersion',
        name: 'ipVersion',
        type: CUSTOM,
        width: {
          desktop: 6,
        },
      },
      {
        label: 'ipVersion.formLabels.ipAddresses',
        name: 'ipAddresses',
        type: 'text',
        width: {
          desktop: 6,
        },
      },
      {
        label: 'ipVersion.formLabels.description',
        name: 'description',
        type: 'text',
        width: {
          desktop: 6,
        },
      },
    ],
    initialValue: null,
    name: 'ipRanges',
    removeLabel: 'Remove IP range',
    type: 'array',
    width: {
      desktop: 12,
    },
  },
];

const ipVersionValidationSchema = Yup.object().shape({
  ipRanges: Yup.array().of(
    Yup.object().shape({
      description: Yup.string().required(() => i18n.t('validation:required')),
      format: Yup.mixed()
        .required(() => i18n.t('validation:required'))
        .oneOf([...Object.values(IpRangeFormat)], ({ values }) => i18n.t('validation:oneOf', { values })),
      ipAddresses: Yup.string()
        .required(() => i18n.t('validation:required'))
        .when('format', {
          is: IpRangeFormat.Range,
          then: Yup.string()
            .required(() => i18n.t('validation:required'))
            .matches(
              /((25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)-(25[0-5]|2[0-4]\d|[01]?\d\d?))/,
              () => i18n.t('validation:organization.ipAddress.format.range')
            ),
        })
        .when('format', {
          is: IpRangeFormat.CommaSeparated,
          then: Yup.string()
            .required(() => i18n.t('validation:required'))
            .matches(
              /((25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)(,\n|,?$))/,
              () => i18n.t('validation:organization.ipAddress.format.comma')
            ),
        })
        .when('format', {
          is: IpRangeFormat.Cidr,
          then: Yup.string()
            .required(() => i18n.t('validation:required'))
            .matches(/^([0-9]{1,3}\.){3}[0-9]{1,3}(\/([0-9]|[1-2][0-9]|3[0-2]))?$/, () =>
              i18n.t('validation:organization.ipAddress.format.cidr')
            ),
        }),
      ipVersion: Yup.mixed()
        .oneOf([IpVersion.Ipv4, IpVersion.Ipv6], ({ values }) => i18n.t('validation:oneOf', { values }))
        .required(() => i18n.t('validation:required'))
        .nullable(),
    })
  ),
});

const headerTranslations = {
  content: 'ipVersion.content',
  title: 'ipVersion.title',
};

export const ipv4Step = {
  fields: ipVersionFields,
  headerTranslations,
  key: 'ipRanges',
  validationSchema: ipVersionValidationSchema,
};
