import { Select } from 'components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import useGlobalInfoStore from '../../redux/services/useGlobalInfoStore';
import { ReportType } from '../../types';

type Props = {
  reportTypes?: ReportType[];
  filterAction: (reportTypes: ReportType[]) => void;
};

const ReportTypeFilters: React.FunctionComponent<Props> = ({
  reportTypes: selectedReportTypes,
  filterAction,
}: Props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { loadReports } = useGlobalInfoStore();

  const changeHandler = React.useCallback(
    selected => {
      dispatch(filterAction(selected || []));
    },
    [dispatch, filterAction]
  );

  return (
    <div>
      <h5>{t('sidebar.filters.reportType')}</h5>
      <Select
        async
        defaultOptions
        loadOptions={loadReports}
        isMulti
        value={selectedReportTypes}
        getOptionValue={(option: ReportType) => option.reportTypeId.toString()}
        getOptionLabel={(option: ReportType) => option.name}
        onChange={changeHandler}
      />
    </div>
  );
};

export default ReportTypeFilters;
