import { getType } from 'typesafe-actions';
import { Certificate } from '../../types/certificates';
import { CertificatesAction, certificatesActions } from '../creators';

export type CertificatesState = Certificate[];

const certificatesReducer = (state: CertificatesState = [], action: CertificatesAction) => {
  switch (action.type) {
    case getType(certificatesActions.postCertificatesRequest.success):
      return [...state, action.payload];
    case getType(certificatesActions.setCertificates):
      return action.payload;
    default:
      return state;
  }
};

export default certificatesReducer;
