import { createAction, createAsyncAction } from 'typesafe-actions';
import { HttpErrorData } from '../../../../types';

export const downloadPgpKey = createAction(
  'DOWNLOAD_PGP_KEY_GET',
  (action) => (pgpFileId: string, fileName: string) => action({ pgpFileId, fileName })
);
export const downloadPgpKeyRequest = createAsyncAction(
  'DOWNLOAD_PGP_KEY_GET_REQUEST',
  'DOWNLOAD_PGP_KEY_GET_SUCCESS',
  'DOWNLOAD_PGP_KEY_GET_REJECTED'
)<any, any, HttpErrorData>();
