import { RootState } from 'config/store/rootReducer';
import { createSelector } from 'reselect';
import { PaginationInfo, SortValue } from 'types';
import { InactiveUser, InactiveUsersFilters } from '../../types';

const getInactiveUsers = (state: RootState): InactiveUser[] | null => state.inactiveUsers.inactiveUsers;
export const makeGetInactiveUsers = () =>
  createSelector(getInactiveUsers, (inactiveUsers: InactiveUser[] | null) => inactiveUsers);

const getFilters = (state: RootState): InactiveUsersFilters => state.inactiveUsers.filters;
export const makeGetInactiveUsersFilters = () => createSelector(getFilters, (filters: InactiveUsersFilters) => filters);

const getPaginationInfo = (state: RootState): PaginationInfo => state.inactiveUsers.pageInfo;
export const makeGetInactiveUsersPageInfo = () =>
  createSelector(getPaginationInfo, (pageInfo: PaginationInfo) => pageInfo);

const getSortingInfo = (state: RootState): SortValue[] => state.inactiveUsers.sorting;
export const makeGetInactiveUsersSorting = () => createSelector(getSortingInfo, (sorting: SortValue[]) => sorting);
