import * as React from 'react';
import { Route } from 'react-router-dom';
import { loadScene } from '../../../components/async/scene';
import ModuleRoutes from '../../../components/routing/ModuleRoutes';
import { Module } from '../../security/types/modules';
import { MAIL_MODULE_KEY } from '../constants/mails';

type Props = {
  allowedModules: Module[];
};

const mailScene = loadScene('mail', 'MailScene');

export const MAILS_BASE_ROUTES = '/mails';

const MailRoutes: React.FunctionComponent<Props> = ({ allowedModules }: Props) => {
  return (
    <ModuleRoutes allowedModules={allowedModules} moduleKey={MAIL_MODULE_KEY}>
      <Route path={[MAILS_BASE_ROUTES, '/mails/:id']} exact component={mailScene} />
    </ModuleRoutes>
  );
};

export default MailRoutes;
