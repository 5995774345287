import { TextArea } from 'components';
import { Field, FieldProps, Form, Formik } from 'formik';
import { rejectSchema } from 'modules/organization/schemas/organizationDetail';
import { RejectFormValues } from 'modules/organization/types/organizationDetail/organizationDetail';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  onSubmit: (values: RejectFormValues) => void;
  onFormChange: () => void;
  formRef: React.Ref<any>;
};

const RejectOrganizationForm: React.FunctionComponent<Props> = ({ onSubmit, onFormChange, formRef }: Props) => {
  const { t } = useTranslation('organization');

  return (
    <Formik
      initialValues={{ comment: '' }}
      onSubmit={onSubmit}
      ref={formRef}
      validate={onFormChange}
      validationSchema={rejectSchema}
      render={({ touched, errors }) => (
        <Form>
          <Field
            name="comment"
            render={({ field }: FieldProps<RejectFormValues>) => (
              <TextArea
                autoFocus
                {...field}
                label={t('detail.reject.comment')}
                error={(touched.comment && errors.comment) || ''}
              />
            )}
          />
        </Form>
      )}
    />
  );
};

export default RejectOrganizationForm;
