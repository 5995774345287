import styled from 'config/theme';
import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { css } from 'styled-components';
import { debounce } from 'ts-debounce';
import { certPalette } from '../../config/theme/palettes/cert';
import Tooltip from '../Tooltip';
import { MarkdownWrapper } from 'components/markdown';

type Props = {
  capitalize?: boolean;
  listCount?: number;
  list?: string[];
  bold?: boolean;
  variant?: keyof typeof certPalette;
  content?: any;
  markdown?: string;
};

const TextWrap = styled.span<Props>`
  ${({ theme, bold, variant, capitalize, listCount }) => css`
    font-weight: ${bold ? '600' : 'regular'};
    color: ${variant ? theme.palette[variant].shade5 : theme.palette.neutral.shade8};
    text-transform: ${capitalize ? 'capitalize' : 'none'};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    margin-right: ${listCount ? '40px' : 0};

    ${listCount &&
    css`
      &:before {
        content: '${listCount}';
        position: absolute;
        right: 8px;
        padding: 0px 8px;
        border-radius: 16px;
        background: ${theme.table.color.badge};
      }
    `}
  `};
`;

const TextWrapComponent: React.FunctionComponent<Props> = ({ listCount, markdown, list, ...rest }) => {
  const wrapRef: React.MutableRefObject<HTMLDivElement | null> = React.useRef(null);
  const [isTruncated, setIsTruncated] = React.useState(false);
  const [position, setPosition] = React.useState({ active: false, left: 0, top: 0 });

  React.useLayoutEffect(() => {
    setTimeout(() => {
      if (wrapRef.current && wrapRef.current.clientWidth < wrapRef.current.scrollWidth) {
        setIsTruncated(true);
      }
    }, 300);
    window.addEventListener('resize', debouncedTruncatedSet);

    return () => window.removeEventListener('resize', debouncedTruncatedSet);
    // eslint-disable-next-line
  }, []);

  const debouncedTruncatedSet = debounce(() => {
    if (wrapRef.current) {
      setIsTruncated(wrapRef.current.clientWidth < wrapRef.current.scrollWidth);
    }
  }, 300);

  const handleMouseEnter = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (isTruncated) {
      const pos = e.currentTarget.getBoundingClientRect();
      const { left, top } = pos;
      setPosition({
        active: true,
        left,
        top: top + 32,
      });
    }
  };

  const handleMouseLeave = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (isTruncated) {
      setPosition({
        active: false,
        left: 0,
        top: 0,
      });
    }
  };

  return (
    <React.Fragment>
      <TextWrap
        ref={wrapRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        listCount={isTruncated ? listCount : undefined}
        {...rest}
      />
      {isTruncated && position.active && (
        <Tooltip leftAligned style={{ top: position.top, left: position.left }}>
          {markdown ? (
            <MarkdownWrapper>
              <ReactMarkdown remarkPlugins={[remarkGfm]}>{markdown}</ReactMarkdown>
            </MarkdownWrapper>
          ) : list ? (
            list.map((item, index) => (
              <li key={index + item}>
                {item} <br />
              </li>
            ))
          ) : (
            rest.content || rest.children
          )}
        </Tooltip>
      )}
    </React.Fragment>
  );
};

export default TextWrapComponent;
