import * as React from 'react';

export type MultiSelectActions = {
  deselectAll: () => void;
  selectAll: () => void;
  toggleId: (id: string | number) => void;
  deselectId: (id: string | number) => void;
};

const useMultiSelect = <T>(list: T[], identifyingProp: string, initialValue: T[] = []): [T[], MultiSelectActions] => {
  const [selected, setSelected] = React.useState<T[]>(initialValue);

  const toggleId = (id: string | number) => {
    const item = list.find(x => x[identifyingProp] === id);
    if (item) {
      const selectedItem = selected.find(x => x[identifyingProp] === id);

      selectedItem ? setSelected(selected.filter(x => x[identifyingProp] !== id)) : setSelected([...selected, item]);
    }
  };

  const deselectId = (id: string | number) => {
    const item = list.find(x => x[identifyingProp] === id);
    if (item) {
      setSelected(selected.filter(x => x[identifyingProp] !== id));
    }
  };

  const selectAll = () => {
    selected.length === list.length ? deselectAll() : setSelected(list);
  };

  const deselectAll = () => setSelected([]);

  return [selected, { toggleId, selectAll, deselectAll, deselectId }];
};

export default useMultiSelect;
