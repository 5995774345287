import { Select } from 'components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Priority, priorityOptions } from '../../../app/constants';

type Props = {
  priorities?: Priority[];
  filterAction: (priority: Priority[]) => void;
};

const PriorityFilters: React.FunctionComponent<Props> = ({ priorities, filterAction }) => {
  const { t } = useTranslation('global');

  const dispatch = useDispatch();

  const changeHandler = React.useCallback(
    selected => {
      dispatch(filterAction(selected || []));
    },
    [dispatch, filterAction]
  );

  return (
    <div>
      <h5>{t('sidebar.filters.priority')}</h5>

      <Select
        getOptionLabel={(option: Priority) => option}
        getOptionValue={(option: Priority) => option}
        isCapitalized
        isMulti
        onChange={changeHandler}
        options={priorityOptions}
        value={priorities || []}
      />
    </div>
  );
};

export default PriorityFilters;
