import * as React from 'react';
import { IconButton } from '../../../../components';
import { Delete, Edit } from '../../../../components/icons';
import styled from '../../../../config/theme';
import { OnboardingContact } from '../../types/onboarding';
import { InfoWrapper } from './ContactCardInfoWrapper';
import ContactCardWrapper from './ContactCardWrapper';

type Props = {
  contact: OnboardingContact;
  onClick?: () => void;
  handleDelete?: (e: React.MouseEvent) => void;
  canBeDeleted: boolean;
};

const ContactCard: React.FunctionComponent<Props> = ({ contact, children, onClick, handleDelete, canBeDeleted }) => {
  return (
    <ContactCardWrapper>
      <InfoWrapper>
        <div className="name-icons">
          <h4>{`${contact.firstName} ${contact.lastName}`}</h4>
          <IconWrapper className="action-menu">
            <IconButton onClick={onClick}>
              <Edit />
            </IconButton>
            {canBeDeleted && (
              <IconButton onClick={handleDelete}>
                <Delete />
              </IconButton>
            )}
          </IconWrapper>
        </div>
        {children}
      </InfoWrapper>
    </ContactCardWrapper>
  );
};

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export default ContactCard;
