import React from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from '../../config/theme';
import { AnimationProps } from '../../config/theme/types';
import { Wrapper } from './components';

const EmptyStateWrapper = styled(Wrapper)<AnimationProps>`
  > p {
    margin: 20px auto 0;
  }

  > h1 {
    margin: 16px 0 0 0;
  }

  > span > svg {
    fill: ${props => props.theme.palette.neutral.shade2};
  }

  ${props => props.state === 'entering' && 'opacity: 0.01;'};
  ${props => props.state === 'entered' && 'opacity: 2; transition: opacity 200ms 100ms;'};
`;

const TransitionedWrapper: React.FunctionComponent = ({ children, ...rest }) => (
  <CSSTransition in timeout={300} mountOnEnter appear>
    {state => (
      <EmptyStateWrapper state={state} {...rest}>
        {children}
      </EmptyStateWrapper>
    )}
  </CSSTransition>
);

export { TransitionedWrapper as EmptyStateWrapper };
