import styled from 'config/theme';
import { media } from 'config/theme/utils';
import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { AnimationProps } from '../../config/theme/types';

type Props = {
  animated?: boolean;
};

const Header = styled.header<AnimationProps & Props>`
  align-items: center;
  background-color: ${props => props.theme.header.background};
  border-bottom: ${props => props.theme.header.borderBottom};
  display: flex;
  flex-shrink: 0;
  height: ${props => props.theme.header.height};
  max-height: ${props => props.theme.header.height};
  padding: ${props => props.theme.header.padding};
  position: sticky;
  top: 0;
  width: 100%;
  justify-content: space-between;
  z-index: 99;

  ${props => media.mobile`
    padding-left: 8px;
    padding-right: 8px;
    width: calc(100% - ${props.theme.header.height});
    margin-left: auto;
  `};

  ${() => media.desktop`
    padding-right: 8px;
    > div:nth-child(2) { 
      margin-right: 16px;
      }
  `};

  ${props => props.state === 'entering' && props.animated && props.theme.header.animation.entering};
  ${props => props.state === 'entered' && props.animated && props.theme.header.animation.entered};
`;

const TransitionedHeader: React.FunctionComponent<Props> = props => (
  <CSSTransition in timeout={200} mountOnEnter appear>
    {state => <Header state={state} {...props} />}
  </CSSTransition>
);

export default TransitionedHeader;
