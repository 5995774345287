import * as React from 'react';
import styled, { css } from '../../config/theme';

export type ButtonProps = {
  block?: boolean;
  danger?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  primary?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  type?: 'button' | 'reset' | 'submit' | undefined;
};

const Button = styled.button<ButtonProps>`
  border-radius: ${props => props.theme.button.borderRadius};
  font-size: ${props => props.theme.button.fontSize};
  padding: ${props => props.theme.button.padding};
  font-family: ${props => props.theme.typography.fontFamily};
  transition: ${props => props.theme.button.transition};
  font-weight: 500;
  position: relative;

  border: none;
  cursor: pointer;
  display: flex;
  text-align: center;
  justify-content: center;

  ${props =>
    !props.disabled &&
    css`
      &:focus,
      &:hover {
        transform: translateY(-1px);
      }
    `};

  &:active {
    transform: none;
  }
  

  ${props => props.block && 'width: 100%;'};
  ${props => props.disabled && 'opacity: 0.6; cursor: not-allowed;'};

  ${props =>
    props.isLoading &&
    css`
      padding-right: 50px;
      opacity: 0.7;
      pointer-events: none;

      > svg#loader {
        stroke: ${props.theme.palette.neutral.shade0};
      }
    `};

  ${props =>
    props.primary &&
    `background: ${props.theme.button.background.primary}; 
     box-shadow: ${props.theme.button.boxShadow.primary};
     color: ${props.theme.button.color.primary};`}

  ${props =>
    props.secondary &&
    `background: ${props.theme.button.background.secondary}; 
     box-shadow: ${props.theme.button.boxShadow.secondary};
     color: ${props.theme.button.color.secondary};`}

  ${props =>
    props.tertiary &&
    `background: ${props.theme.button.background.tertiary}; 
      color: ${props.theme.button.color.tertiary};
      box-shadow: none;`}

  ${props =>
    props.danger &&
    `background: ${props.theme.button.background.danger}; 
      box-shadow: ${props.theme.button.boxShadow.danger};
      color: ${props.theme.button.color.danger};`}

  &:active:hover,
  &:hover {
    ${props => props.tertiary && `background: ${props.theme.button.background.tertiaryHover}`};
    ${props => props.primary && `background: ${props.theme.button.background.primaryHover}`};
    ${props => props.secondary && `background: ${props.theme.button.background.secondaryHover}`};
  }
`;

const SubmitButton: React.FunctionComponent<ButtonProps> = ({ isLoading, children, disabled, ...other }) => (
  <Button disabled={disabled} isLoading={isLoading} type="submit" {...other}>
    {children}
    {isLoading && (
      <svg
        id="loader"
        width="30"
        height="30"
        viewBox="0 0 44 44"
        xmlns="http://www.w3.org/2000/svg"
        style={{ position: 'absolute', right: 10, top: 6 }}
      >
        <g fill="none" fillRule="evenodd" strokeWidth="3">
          <circle cx="22" cy="22" r="1">
            <animate
              attributeName="r"
              begin="0s"
              dur="1.8s"
              values="1; 20"
              calcMode="spline"
              keyTimes="0; 1"
              keySplines="0.165, 0.84, 0.44, 1"
              repeatCount="indefinite"
            />
            <animate
              attributeName="stroke-opacity"
              begin="0s"
              dur="1.8s"
              values="1; 0"
              calcMode="spline"
              keyTimes="0; 1"
              keySplines="0.3, 0.61, 0.355, 1"
              repeatCount="indefinite"
            />
          </circle>
          <circle cx="22" cy="22" r="1">
            <animate
              attributeName="r"
              begin="-0.9s"
              dur="1.8s"
              values="1; 20"
              calcMode="spline"
              keyTimes="0; 1"
              keySplines="0.165, 0.84, 0.44, 1"
              repeatCount="indefinite"
            />
            <animate
              attributeName="stroke-opacity"
              begin="-0.9s"
              dur="1.8s"
              values="1; 0"
              calcMode="spline"
              keyTimes="0; 1"
              keySplines="0.3, 0.61, 0.355, 1"
              repeatCount="indefinite"
            />
          </circle>
        </g>
      </svg>
    )}
  </Button>
);

export { SubmitButton };

export default Button;
