import { getType } from 'typesafe-actions';
import { Country } from '../../../../types/countries';
import { globalInfoActions, GlobalInfoActions } from '../creators';

export type GlobalInfoState = {
  countries: Country[];
};

const initialState = {
  countries: [],
};

const globalInfoReducer = (state: GlobalInfoState = initialState, action: GlobalInfoActions) => {
  switch (action.type) {
    case getType(globalInfoActions.getCountriesRequest.success):
      return {
        ...state,
        countries: action.payload,
      };
    default:
      return state;
  }
};

export default globalInfoReducer;
