import { SuperUser } from 'modules/superUsers/types';
import { getType } from 'typesafe-actions';
import { superUsersActions, SuperUsersActions } from '../creators';

export type SuperUsersModuleState = SuperUser[] | null;

const superUsersReducer = (state: SuperUsersModuleState = null, action: SuperUsersActions) => {
  switch (action.type) {
    case getType(superUsersActions.getSuperUsersRequest.success):
      return action.payload.items;
    case getType(superUsersActions.addSuperUserRequest.success):
      return state ? [...state, action.payload] : state;
    case getType(superUsersActions.downgradeSuperUserRequest.success):
      return state ? state.filter(superUser => superUser.guid !== action.payload) : state;
    case getType(superUsersActions.disableSuperUserRequest.success):
      return state
        ? state.map(superUser => (superUser.guid === action.payload ? { ...superUser, enabled: false } : superUser))
        : state;
    case getType(superUsersActions.enableSuperUserRequest.success):
      return state
        ? state.map(superUser => (superUser.guid === action.payload ? { ...superUser, enabled: true } : superUser))
        : state;
    default:
      return state;
  }
};

export default superUsersReducer;
