import { FieldProps, getIn } from 'formik';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ConvertedFile } from '../../../components/Dropzone/FileInput';
import FileUpload from '../../../components/Dropzone/FileUpload';
import { isSingleFile } from '../../../components/Dropzone/isSingleFileHelper';
import { getNestedError } from '../../../helpers/formikHelper';
import { pgpKeyActions } from '../redux/creators';
import { FileInstance } from '../types/pgpKey';

type Props = { label?: string; acceptedFileTypes?: string[] } & FieldProps;

const PGPKeyUpload: React.FunctionComponent<Props> = ({ field, form, label, acceptedFileTypes }) => {
  const dispatch = useDispatch();
  const pgpKey = getIn(form.values, field.name) as FileInstance;
  const { t } = useTranslation('onboarding');

  const handleChange = (files: ConvertedFile | ConvertedFile[]) => {
    if (isSingleFile(files) && files.file) {
      form.setFieldValue(field.name, { ...pgpKey, ...files });
    } else {
      form.setFieldValue(field.name, files);
    }
  };
  const handleDownload = () => {
    if (pgpKey.id) {
      dispatch(pgpKeyActions.downloadPgpKey(pgpKey.id, pgpKey.name));
    }
  };

  return (
    <FileUpload
      inputId={field.name}
      label={t(label || '')}
      onFileClick={handleDownload}
      isDownloadable={Boolean(pgpKey.id)}
      acceptedFileTypes={acceptedFileTypes}
      isMulti={false}
      onChange={handleChange}
      initialValues={pgpKey.id ? [{ name: pgpKey.name, file: null, base64: '' }] : []}
      error={getNestedError(field.name, form.errors, form.touched)?.name}
    />
  );
};

export default PGPKeyUpload;
