import AxiosConfig, { RequestConfig } from '../../../config/api/AxiosConfig';
import { ACCOUNT_URL } from './endpoints';

class AccountApi {
  public async getAccount() {
    const requestConfig: RequestConfig = {
      url: `${ACCOUNT_URL}`,
    };

    return AxiosConfig.fetchAxiosGetRequest(requestConfig);
  }

  public async getAccessRights() {
    const requestConfig: RequestConfig = {
      url: `${ACCOUNT_URL}/accessrights`,
    };

    return AxiosConfig.fetchAxiosGetRequest(requestConfig);
  }
}

export default new AccountApi();
