export const MAILS_GET: 'MAILS_GET' = 'MAILS_GET';
export const MAILS_FETCHED_MORE: 'MAILS_FETCHED_MORE' = 'MAILS_FETCHED_MORE';
export const MAILS_REQUEST: 'MAILS_REQUEST' = 'MAILS_REQUEST';
export const MAILS_SUCCESS: 'MAILS_SUCCESS' = 'MAILS_SUCCESS';
export const MAILS_MERGED: 'MAILS_MERGED' = 'MAILS_MERGED';
export const MAILS_REJECTED: 'MAILS_REJECTED' = 'MAILS_REJECTED';
export const MAILS_CLEARED: 'MAILS_CLEARED' = 'MAILS_CLEARED';

export type MAILS_GET_TYPE = typeof MAILS_GET;
export type MAILS_FETCHED_MORE_TYPE = typeof MAILS_FETCHED_MORE;
export type MAILS_REQUEST_TYPE = typeof MAILS_REQUEST;
export type MAILS_SUCCESS_TYPE = typeof MAILS_SUCCESS;
export type MAILS_MERGED_TYPE = typeof MAILS_MERGED;
export type MAILS_REJECTED_TYPE = typeof MAILS_REJECTED;
export type MAILS_CLEARED_TYPE = typeof MAILS_CLEARED;

export const MAILS_ACTION_PREFIX = 'MAILS';
