import { DatePickerInput } from '@unbrace/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { dateParser, formatAsDate } from '../../helpers/dateFormatter';

type Props = {
  dateFrom: Date | undefined;
  dateUntil: Date | undefined;
  handleDateFrom: (date: Date | undefined) => void;
  handleDateUntil: (date: Date | undefined) => void;
  filterTranslation?: string;
};

const DateFilters: React.FunctionComponent<Props> = ({
  dateFrom,
  dateUntil,
  handleDateFrom,
  handleDateUntil,
  filterTranslation,
}) => {
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, callback: (date: Date | undefined) => void) => {
    const parsedValue = dateParser(event.target.value);
    if (parsedValue || !event.target.value.trim()) {
      return callback(parsedValue);
    }
  };

  return (
    <React.Fragment>
      <h5>{filterTranslation ?? t('general.dates')}</h5>
      <DatePickerInput
        value={formatAsDate(dateFrom)}
        inputDebounceTimeOut={1000}
        placeholder={''}
        inputProps={{
          onChange: (event: React.ChangeEvent<HTMLInputElement>) => handleChange(event, handleDateFrom),
          name: 'dateFrom',
          label: t('general.from'),
          inlineLabel: true,
          autoComplete: 'off',
          style: { marginBottom: 8 },
          isClearable: true,
          onClear: () => handleDateFrom(undefined),
        }}
        dayPickerProps={{
          onDayClick: handleDateFrom,
        }}
      />
      <DatePickerInput
        value={formatAsDate(dateUntil)}
        inputDebounceTimeOut={1000}
        placeholder={''}
        inputProps={{
          onChange: (event: React.ChangeEvent<HTMLInputElement>) => handleChange(event, handleDateUntil),
          name: 'dateUntil',
          label: t('general.until'),
          inlineLabel: true,
          autoComplete: 'off',
          style: { marginBottom: 8 },
          isClearable: true,
          onClear: () => handleDateUntil(undefined),
        }}
        dayPickerProps={{
          onDayClick: handleDateUntil,
        }}
      />
    </React.Fragment>
  );
};

export default DateFilters;
