import { SuperUserRole } from 'modules/superUsers/types';
import { createAction } from 'typesafe-actions';

export const setRoleFilter = createAction('SUPER_USER_SET_FILTER_ROLE', action => (value: SuperUserRole[]) =>
  action(value)
);

export const setOnlyEnabledFilter = createAction('SUPER_USER_SET_FILTER_ONLY_ENABLED', action => (value: boolean) =>
  action(value)
);
