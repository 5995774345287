import * as React from 'react';
import styled from '../../config/theme';
import Checkbox from './Checkbox';
import { ErrorText } from './ErrorText';
import { FieldContainer } from './FieldContainer';
import { Label } from './Label';
import RadioButton from './RadioButton';
import { RelativeContainer } from 'components';

export const TYPE_CHECKBOX = 'checkbox';
export const TYPE_EMAIL = 'email';
export const TYPE_FILE = 'file';
export const TYPE_NUMBER = 'number';
export const TYPE_PASSWORD = 'password';
export const TYPE_TEL = 'tel';
export const TYPE_TEXT = 'text';
export const TYPE_RADIO = 'radio';

export type InputType =
  | typeof TYPE_CHECKBOX
  | typeof TYPE_EMAIL
  | typeof TYPE_FILE
  | typeof TYPE_NUMBER
  | typeof TYPE_PASSWORD
  | typeof TYPE_TEL
  | typeof TYPE_TEXT
  | typeof TYPE_RADIO;

export type InputProps = {
  accept?: string;
  autocomplete?: 'on' | 'off';
  autoFocus?: boolean;
  checked?: boolean;
  className?: string;
  defaultChecked?: boolean;
  defaultValue?: string;
  disabled?: boolean;
  error?: string;
  errorAsBlock?: boolean;
  label?: string;
  multiple?: boolean;
  name: string;
  noLabel?: boolean;
  onBlur?: (e?: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (args?: any) => void;
  placeholder?: string;
  type: InputType;
  value?: string | number;
  ref?: React.Ref<HTMLInputElement>;
  id?: string;
  textAlign?: 'left' | 'right';
  addonWidth?: number;
  addonRight?: React.ReactNode;
};

const InputField: React.FunctionComponent<InputProps> = React.forwardRef((props: InputProps, ref: React.Ref<any>) => {
  const { autocomplete, autoFocus, error, name, noLabel, multiple, placeholder, type, label, errorAsBlock, textAlign } =
    props;

  if (type === TYPE_CHECKBOX) {
    return <Checkbox {...props} error={error} name={name} />;
  }

  if (type === TYPE_RADIO) {
    return <RadioButton {...props} value={props.value !== undefined ? props.value.toString() : undefined} />;
  }

  return (
    <FieldContainer>
      {!noLabel && <Label htmlFor={name}>{label || name}</Label>}
      <RelativeContainer>
        <Input
          {...props}
          autoComplete={autocomplete}
          autoFocus={autoFocus}
          hasError={Boolean(error)}
          id={name}
          multiple={TYPE_FILE && multiple}
          placeholder={placeholder}
          ref={ref}
          type={type}
          textAlign={textAlign}
        />
        {props.addonRight && props.addonRight}
      </RelativeContainer>
      {error && <ErrorText block={errorAsBlock}>{error}</ErrorText>}
    </FieldContainer>
  );
});

export const Input = styled.input<{ hasError?: boolean; textAlign?: 'left' | 'right' }>`
  background: ${(props) => props.theme.form.background.main};
  border-radius: ${(props) => props.theme.form.borderRadius.input};
  border: ${(props) => (!props.hasError ? props.theme.form.border.input.main : props.theme.form.border.input.error)};
  box-shadow: ${(props) => props.theme.form.boxShadow.main};
  color: ${(props) => props.theme.form.color.input.main};
  margin: ${(props) => props.theme.form.margin.main};
  padding: ${(props) => props.theme.form.padding.main};
  transition: box-shadow 0.15s ease-in, border 0.15s ease-in;
  width: 100%;

  ${(props) => `text-align: ${props.textAlign || 'left'}`};

  &::placeholder {
    color: ${(props) => props.theme.form.color.input.placeholder};
  }

  &:focus {
    border: ${(props) => (!props.hasError ? props.theme.form.border.input.focus : props.theme.form.border.input.error)};
    box-shadow: ${(props) =>
      !props.hasError ? props.theme.form.boxShadow.focus : props.theme.form.boxShadow.focusError};
  }

  &[disabled] {
    background: ${(props) => props.theme.form.background.disabled};
    cursor: not-allowed;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ${(props) =>
    props.type === 'checkbox' &&
    `
    box-shadow: none;
    width: auto;
    display: flex;
    align-self: flex-start;
  `};
`;

export default InputField;
