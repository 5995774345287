import * as Yup from 'yup';
import i18n from 'config/i18n';
import { AddOrganizationFormValues, OrganizationSector } from '../types/organization';

const sectorSchema: Yup.ObjectSchema<OrganizationSector> = Yup.object().shape({
  name: Yup.string().required(),
  sectorId: Yup.string().required(),
});

export const addOrganizationSchema: Yup.ObjectSchema<AddOrganizationFormValues> = Yup.object().shape({
  emailAdminUser: Yup.string().email(),
  name: Yup.string().trim().max(200).required(),
  sectors: Yup.array().of(sectorSchema).min(1),
  maxUsers: Yup.number()
    .required()
    .positive()
    .min(1, () => i18n.t('organization:errors.minUsers')),
});

export const editOrganizationSchema = (currentNumberOfUsers: number) => {
  return Yup.object().shape({
    name: Yup.string().trim().max(200).required(),
    sectors: Yup.array().of(sectorSchema).min(1),
    maxUsers: Yup.number()
      .required()
      .positive()
      .min(currentNumberOfUsers, () => i18n.t('organization:errors.maxUsers', { currentNumberOfUsers })),
  });
};
