import { Select } from 'components';
import { FieldProps } from 'formik';
import * as React from 'react';
import { OptionTypeBase, Props as ReactSelectProps, ValueType } from 'react-select';
import styled, { css } from '../../config/theme';
import { ErrorText } from './ErrorText';
import { Label } from './Label';

type Props<T extends OptionTypeBase> = FieldProps &
  ReactSelectProps<T> & {
    label?: string;
    options?: T[];
    isCapitalized?: boolean;
    errorAsBlock?: boolean;
    getOptionValue: (option: T) => string;
    zIndex?: number;
    className?: string;
    noMargin?: boolean;
  };

const FormikSelect = <T extends {}>({
  isCapitalized,
  options,
  field,
  form,
  theme,
  label,
  noMargin,
  errorAsBlock,
  getOptionValue,
  zIndex,
  className,
  ...props
}: Props<T>) => {
  const getValue = React.useCallback(
    (opts: T[], val: any): T[] => {
      if (props.async) {
        return val;
      }
      if (Array.isArray(val)) {
        return opts.filter((o) => val.includes(getOptionValue(o)));
      } else {
        return opts.filter((o) => val === getOptionValue(o));
      }
    },
    [props.async, getOptionValue]
  );

  const onChange = React.useCallback(
    (value: ValueType<T>) => {
      if (props.async) {
        return form.setFieldValue(field.name, value || []);
      }
      if (Array.isArray(value)) {
        return form.setFieldValue(
          field.name,
          value.map((val: T) => getOptionValue(val))
        );
      } else {
        return form.setFieldValue(field.name, value && getOptionValue(value as T));
      }
    },
    [form, props.async, field, getOptionValue]
  );

  const onBlur = React.useCallback(() => {
    form.setFieldTouched(field.name);
  }, [field, form]);

  return (
    <SelectWrapper className={className}>
      {label && <Label htmlFor={field.name}>{label}</Label>}
      <StyledSelect noMargin={noMargin} className="formik-select" isCapitalized={isCapitalized}>
        <Select
          {...props}
          getOptionValue={getOptionValue}
          options={options}
          name={field.name}
          value={getValue(options || [], field.value)}
          onChange={onChange}
          onBlur={onBlur}
          menuPosition="fixed"
        />
      </StyledSelect>
      {props.error && <ErrorText block={errorAsBlock}>{props.error}</ErrorText>}
    </SelectWrapper>
  );
};

export const StyledSelect = styled.div<{ isCapitalized?: boolean; noMargin?: boolean }>`
  ${(props) =>
    props.isCapitalized &&
    css`
      text-transform: capitalize;
    `};
  margin: ${(props) => (props.noMargin ? '0' : '8px 0 25px')};
`;

export const SelectWrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
`;

export default FormikSelect;
