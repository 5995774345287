import { call, put, takeLatest } from 'redux-saga/effects';
import MailsApi from '../../api/MailsApi';
import { MAIL_RESEND } from '../actions/constants/mail';
import { rejectResendMail, successResendMail } from '../actions/creators/mail';
import { ResendMailAction } from '../actions/types/mail';

function* resendMailSaga() {
  yield takeLatest(MAIL_RESEND, handleResendMail);
}

function* handleResendMail(action: ResendMailAction) {
  try {
    const { id, to, cc, bcc } = action.payload;
    yield call(MailsApi.resendMail, id, to, cc, bcc);
    yield put(successResendMail());
  } catch (e) {
    yield put(rejectResendMail(e.response));
  }
}

export default resendMailSaga;
