import { getType } from 'typesafe-actions';
import { LOCAL_STORAGE_SIDEBAR_COLLAPSED_KEY } from '../../constants';
import { sidebarActions, SidebarActions } from '../creators';

const sidebarReducer = (
  state: boolean = window.localStorage.getItem(LOCAL_STORAGE_SIDEBAR_COLLAPSED_KEY) === 'true',
  action: SidebarActions
) => {
  switch (action.type) {
    case getType(sidebarActions.toggleSidebarCollapsed):
      return !state;
    default:
      return state;
  }
};

export default sidebarReducer;
