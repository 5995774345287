import { Close, Search as SearchIcon } from 'components/icons';
import { responsive } from 'helpers';
import { searchActions } from 'modules/app/redux/creators';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { debounce } from 'ts-debounce';
import SearchWrapper, { ClearButton } from './SearchWrapper';

type Props = {
  filterKey: string;
  placeholder?: string;
  initialValue?: string;
};

const Search: React.FunctionComponent<Props> = ({ filterKey, placeholder, initialValue }: Props) => {
  const [inputValue, setInputValue] = React.useState(initialValue || '');
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isPhone = useMediaQuery(responsive.isPhone);

  const handleDebounceTextChange = React.useCallback(
    debounce(textValue => {
      dispatch(searchActions.setSearch(textValue, filterKey));
    }, 340),
    [dispatch, filterKey]
  );

  const inputChangeHandler = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setInputValue(value);
      handleDebounceTextChange(value);
    },
    [setInputValue, handleDebounceTextChange]
  );

  const handleClearValue = React.useCallback(() => {
    setInputValue('');
    handleDebounceTextChange('');
  }, [setInputValue, handleDebounceTextChange]);

  return (
    <SearchWrapper className="search">
      <React.Fragment>
        <SearchIcon />
        <input
          onChange={inputChangeHandler}
          placeholder={placeholder || t('searchPlaceholder')}
          type="text"
          value={inputValue}
          autoFocus={!isPhone}
        />
        <ClearButton onClick={handleClearValue}>
          <Close />
        </ClearButton>
      </React.Fragment>
    </SearchWrapper>
  );
};

export default Search;
