import styled from 'config/theme';
import { animations } from 'config/theme/keyframes';

type Props = {
  leftAligned?: boolean;
};

const Tooltip = styled.span<Props>`
  position: fixed;
  transform: ${(props) => (props.leftAligned ? 'none' : 'translateX(-50%)')};
  opacity: 0;
  color: ${(props) => props.theme.iconButton.tooltip.color};
  font-size: ${(props) => props.theme.iconButton.tooltip.fontSize};
  background: ${(props) => props.theme.iconButton.tooltip.background};
  box-shadow: ${(props) => props.theme.iconButton.tooltip.boxShadow};
  padding: ${(props) => props.theme.iconButton.tooltip.padding};
  border-radius: ${(props) => props.theme.iconButton.tooltip.borderRadius};
  animation: ${animations.fadeIn} 0.1s ease-in forwards;
  z-index: 100;

  > li {
    list-style-type: none;
  }
`;

export default Tooltip;
