export const MAIL_GET: 'MAIL_GET' = 'MAIL_GET';
export const MAIL_REQUEST: 'MAIL_REQUEST' = 'MAIL_REQUEST';
export const MAIL_SUCCESS: 'MAIL_SUCCESS' = 'MAIL_SUCCESS';
export const MAIL_REJECTED: 'MAIL_REJECTED' = 'MAIL_REJECTED';
export const MAIL_RESEND: 'MAIL_RESEND' = 'MAIL_RESEND';
export const MAIL_RESEND_SUCCESS: 'MAIL_RESEND_SUCCESS' = 'MAIL_RESEND_SUCCESS';
export const MAIL_RESEND_REJECTED: 'MAIL_RESEND_REJECTED' = 'MAIL_RESEND_REJECTED';

export type MAIL_GET_TYPE = typeof MAIL_GET;
export type MAIL_REQUEST_TYPE = typeof MAIL_REQUEST;
export type MAIL_SUCCESS_TYPE = typeof MAIL_SUCCESS;
export type MAIL_REJECTED_TYPE = typeof MAIL_REJECTED;
export type MAIL_RESEND_TYPE = typeof MAIL_RESEND;
export type MAIL_RESEND_SUCCESS_TYPE = typeof MAIL_RESEND_SUCCESS;
export type MAIL_RESEND_REJECTED_TYPE = typeof MAIL_RESEND_REJECTED;

export const MAIL_ACTION_PREFIX = 'MAIL';
