import { TenantTheme } from '../tenants/';

const headerTheme = (theme: TenantTheme) => ({
  animation: {
    entered: 'opacity: 1; transform: none; transition: opacity 150ms 50ms, transform 200ms 0ms ease-in',
    entering: 'opacity: 0.01; transform: translateX(-1px)',
  },
  background: theme.palette.neutral.shade1,
  borderBottom: `solid 2px ${theme.palette.neutral.shade2}`,
  color: {
    disabled: theme.palette.neutral.shade4,
    hover: theme.palette.neutral.shade9,
    normal: theme.palette.neutral.shade7,
  },
  height: '60px',
  padding: '5px 20px',
});

export default headerTheme;
