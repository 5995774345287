import styled from 'config/theme';

export const ClearButton = styled.button`
  border: none;
  position: absolute;
  top: 19px;
  right: 10px;
  cursor: pointer;
  background: ${props => props.theme.palette.neutral.shade0};
  box-shadow: -10px 0 15px ${props => props.theme.palette.neutral.shade0};

  > span > svg {
    height: 18px;
    width: 18px;
    fill: ${props => props.theme.palette.neutral.shade4};

    &:hover,
    &:focus,
    &:active {
      fill: ${props => props.theme.palette.neutral.shade5};
    }
  }
`;

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: ${props => props.theme.header.height};
  padding: 10px;
  background-color: ${props => props.theme.palette.neutral.shade0};
  flex-shrink: 0;
  border-bottom: solid 2px ${props => props.theme.palette.neutral.shade2};
  color: ${props => props.theme.palette.neutral.shade3};
  position: sticky;
  top: 0;

  input {
    height: 2.1rem;
    background: transparent;
    font-size: ${props => props.theme.typography.fontSize.small_5};
    border: none;
    transition: width 0.3s ease-out;
    width: 200px;
    box-sizing: border-box;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: ${props => props.theme.palette.neutral.shade8};

    &::placeholder {
      color: ${props => props.theme.form.color.input.placeholder};
    }
  }

  > span > svg {
    transition: all 0.2s linear;
    margin-right: 10px;
    cursor: pointer;
    fill: ${props => props.theme.palette.neutral.shade4};
    width: 21px;
    height: 21px;
  }
`;

export default SearchWrapper;
