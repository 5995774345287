import { RouteLeavingGuard } from 'components';
import { FullViewBlock } from 'components/FullView';
import { FieldArray, Form, Formik } from 'formik';
import { mapAvailabilitiesToFrontendValues } from 'modules/app/components/availabilities/formatAvailabilityHelper';
import { organizationDetailActions } from 'modules/organization/redux/actions/creators';
import { contactInfoSchema } from 'modules/organization/schemas/organizationDetail';
import {
  Contact,
  ContactEditValues,
  ContactInfoEditValues,
} from 'modules/organization/types/organizationDetail/contacts';
import { BusinessLocation } from 'modules/organization/types/organizationDetail/general';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ContactEdit from './ContactEdit';

type Props = {
  contacts: Contact[];
  formRef: React.RefObject<any>;
  setHasChanges: (hasChanges: boolean) => void;
  organizationId: string;
  setEditMode: (editMode: boolean) => void;
  businessLocations: BusinessLocation[];
  hasChanges: boolean;
  successCallback?: Function;
};

const ContactInfoEdit: React.FunctionComponent<Props> = ({
  contacts,
  formRef,
  setHasChanges,
  organizationId,
  setEditMode,
  businessLocations,
  hasChanges,
  successCallback,
}: Props) => {
  const { t } = useTranslation(['organization', 'validation']);
  const dispatch = useDispatch();

  const successCallbackOnUpdate = () => {
    successCallback?.();
    setEditMode(false);
  };

  React.useEffect(() => {
    return () => {
      setEditMode(false);
    };
  }, [setEditMode]);

  const onSubmit = React.useCallback(
    (values: ContactInfoEditValues) => {
      dispatch(organizationDetailActions.updateContacts(organizationId, values, successCallbackOnUpdate));
    },

    [dispatch, organizationId]
  );

  const validateForm = React.useCallback(
    (values: ContactInfoEditValues) => {
      setHasChanges(true);
    },
    [setHasChanges]
  );

  return (
    <React.Fragment>
      <RouteLeavingGuard when={hasChanges} />

      <Formik
        initialValues={{
          contacts: contacts.map((c) => ({
            ...c,
            availabilities: mapAvailabilitiesToFrontendValues(c.availabilities),
            businessLocations: c.businessLocations.map((businessLocation) => businessLocation.addressId.toString()),
            pgpFile: c.pgpFileId
              ? {
                  base64: '',
                  file: null,
                  name: c.pgpFileName || '',
                  pgpFileId: c.pgpFileId,
                }
              : null,
            pgpFileId: undefined,
            pgpFileName: undefined,
            types: c.types || [],
          })),
        }}
        onSubmit={onSubmit}
        ref={formRef}
        validate={validateForm}
        validationSchema={contactInfoSchema}
        render={({ touched, errors, values }) => (
          <Form>
            <FieldArray
              name="contacts"
              render={(arrayHelpers) => (
                <FullViewBlock amountOfButtons={1}>
                  <h2 className="block-title">{t('detail.blocks.contacts')}</h2>

                  <div className="block-data">
                    {values.contacts &&
                      values.contacts.length > 0 &&
                      values.contacts.map((contact: ContactEditValues, index: number) => (
                        <ContactEdit
                          key={index}
                          contact={contact}
                          index={index}
                          errors={errors}
                          touched={touched}
                          arrayHelpers={arrayHelpers}
                          businessLocations={businessLocations}
                        />
                      ))}
                  </div>
                </FullViewBlock>
              )}
            />
          </Form>
        )}
      />
    </React.Fragment>
  );
};

export default ContactInfoEdit;
