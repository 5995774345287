import AxiosConfig, { RequestConfig } from 'config/api/AxiosConfig';
import { CONTACTS, CTI_DATA, GENERAL_DATA, ORGANIZATIONS_URL, USERS_URL } from '../constants';
import { PutContactDataBody } from '../types/onboarding';

const postOrganizationGeneralData = async (id: string, data: any) => {
  const requestConfig: RequestConfig = {
    data,
    url: `${ORGANIZATIONS_URL}/${id}/${GENERAL_DATA}`,
  };

  return AxiosConfig.fetchAxiosPostRequest(requestConfig);
};

const getOrganizationGeneralData = async (id: string) => {
  const requestConfig: RequestConfig = {
    url: `${ORGANIZATIONS_URL}/${id}`,
  };

  return AxiosConfig.fetchAxiosGetRequest(requestConfig);
};

const postOrganizationContactData = async (id: string, data: any) => {
  const requestConfig: RequestConfig = {
    data,
    url: `${ORGANIZATIONS_URL}/${id}/${CONTACTS}`,
  };

  return AxiosConfig.fetchAxiosPostRequest(requestConfig);
};

const getOrganizationContacts = async (id: string) => {
  const requestConfig: RequestConfig = {
    url: `${ORGANIZATIONS_URL}/${id}/${CONTACTS}`,
  };

  return AxiosConfig.fetchAxiosGetRequest(requestConfig);
};

const getOrganizationCtiData = async (id: string) => {
  const requestConfig: RequestConfig = {
    url: `${ORGANIZATIONS_URL}/${id}/${CTI_DATA}`,
  };

  return AxiosConfig.fetchAxiosGetRequest(requestConfig);
};

const postOrganizationCtiData = async (id: string, data: any) => {
  const requestConfig: RequestConfig = {
    data,
    url: `${ORGANIZATIONS_URL}/${id}/${CTI_DATA}`,
  };

  return AxiosConfig.fetchAxiosPostRequest(requestConfig);
};

const putUserData = async (id: string, data: PutContactDataBody) => {
  const requestConfig: RequestConfig = {
    data: {
      ...data,
      userId: id,
    },
    url: `${USERS_URL}`,
  };

  return AxiosConfig.fetchAxiosPutRequest(requestConfig);
};

export default {
  getOrganizationContacts,
  getOrganizationCtiData,
  getOrganizationGeneralData,
  postOrganizationContactData,
  postOrganizationCtiData,
  postOrganizationGeneralData,
  putUserData,
};
