import { Button, ModalContent, SubmitButton } from 'components';
import { TextArea } from 'components/Form';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useActionIsPending } from 'hooks';
import { repositoryActions } from 'modules/repository/redux/creators';
import { reportQuestion as reportQuestionType } from 'modules/repository/redux/creators/repository';
import { questionSchema } from 'modules/repository/schemas/repository';
import { QuestionFormikValues } from 'modules/repository/types/repository';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getType } from 'typesafe-actions';

type Props = {
  closeModal: () => void;
  reportId: string;
  reportTitle: string;
};

const QuestionModal: React.FunctionComponent<Props> = ({ closeModal, reportId, reportTitle }: Props) => {
  const { t } = useTranslation(['global', 'repository']);
  const dispatch = useDispatch();
  const [isReporting, startReporting] = useActionIsPending(getType(reportQuestionType));

  const reportQuestion = React.useCallback(
    (values: QuestionFormikValues) => {
      startReporting();
      dispatch(
        repositoryActions.reportQuestion({ reportId, reportTitle, language: 'en', content: values.content }, closeModal)
      );
    },
    [dispatch, startReporting, reportId, reportTitle, closeModal]
  );

  return (
    <Formik initialValues={{ content: '' }} onSubmit={reportQuestion} validationSchema={questionSchema}>
      {({ touched, errors, handleSubmit }) => (
        <ModalContent>
          <ModalContent.Header>
            <h1>{t('repository:question.title')}</h1>
            <h5>{t('repository:question.subTitle', { reportTitle })}</h5>
          </ModalContent.Header>
          <ModalContent.Body>
            <Form>
              <Field name="content">
                {({ field }: FieldProps<QuestionFormikValues>) => (
                  <TextArea
                    autoFocus
                    {...field}
                    label={t('repository:question.label')}
                    error={(touched.content && errors.content) || ''}
                  />
                )}
              </Field>
            </Form>
          </ModalContent.Body>
          <ModalContent.Footer>
            <Button disabled={isReporting} onClick={closeModal} tertiary>
              {t('general.close')}
            </Button>
            <SubmitButton isLoading={isReporting} onClick={() => handleSubmit()} primary disabled={isReporting}>
              {t('general.submit')}
            </SubmitButton>
          </ModalContent.Footer>
        </ModalContent>
      )}
    </Formik>
  );
};

export default QuestionModal;
