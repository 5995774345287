import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const PriorityHigh: React.FunctionComponent = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px">
      <path d="M0 0h24v24H0z" fill="none" />
      <circle cx="12" cy="19" r="2" />
      <path d="M10 3h4v12h-4z" />
    </svg>
  </BaseSvgWrapper>
);

export default PriorityHigh;
