import { media } from 'config/theme/utils';
import * as React from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from '../../config/theme';
import { AnimationProps } from '../../config/theme/types';

type Props = {
  children: React.ReactNode[] | React.ReactNode | null;
  noPadding?: boolean;
  noSidePadding?: boolean;
  onlyBottomPadding?: boolean;
  ref?: React.Ref<any>;
};

const Section = styled('section')<Props & AnimationProps>`
  background-color: ${(props) => props.theme.paper.backgroundColor};
  border-radius: ${(props) => props.theme.paper.borderRadius};
  box-shadow: ${(props) => props.theme.paper.boxShadow.regular};
  margin: ${(props) => props.theme.paper.margin};
  padding: ${(props) => props.theme.paper.padding.vertical} ${(props) => props.theme.paper.padding.vertical};
  overflow: initial;

  ${() => media.tablet`
    overflow: auto;
    margin: 12px;
    padding: 24px;
  `};

  ${() => media.mobile`
    overflow: auto;
    margin: 0;
    padding: 24px;
    border-radius: 0;
  `};

  ${() => media.laptopSM`
    margin: 16px;
    padding: 24px;
  `}

  ${(props) => props.noSidePadding && `padding: ${props.theme.paper.padding.vertical} 0`}
  ${(props) => props.noPadding && `padding: 0`};
  ${(props) => props.onlyBottomPadding && `padding: 0 0 ${props.theme.paper.padding.vertical} 0`};

  ${(props) => props.state === 'entering' && props.theme.paper.animation.entering};
  ${(props) => props.state === 'entered' && props.theme.paper.animation.entered};
`;

const Paper: React.FunctionComponent<Props> = React.forwardRef((props: Props, ref: React.Ref<any>) => (
  <CSSTransition in timeout={200} mountOnEnter appear>
    {(state) => <Section ref={ref} state={state} {...props} />}
  </CSSTransition>
));

export default Paper;
