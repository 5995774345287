import { formatSortingForApi } from 'helpers/sortingFormatter';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { searchActions } from '../../../app/redux/creators';
import ReportTypesApi from '../../api/ReportTypesApi';
import { REPORTTYPE_FILTER_KEY } from '../../constants';
import { pageInfoActions, reportTypeActions, sortingActions } from '../creators';
import {
  makeGetReportTypeFilters,
  makeGetReportTypePageInfo,
  makeGetReportTypeSorting,
} from '../selectors/reportTypes';

function* reportTypeSaga() {
  yield takeLatest(
    [pageInfoActions.setPageSize, pageInfoActions.setPage, reportTypeActions.getReportTypes, sortingActions.setSorting],
    handleGetReportTypes
  );
  yield takeLatest([pageInfoActions.setPageSize], handleGetReportTypesWithPage1);
  yield takeLatest(reportTypeActions.addReportType, handleAddReportType);
  yield takeLatest(reportTypeActions.deleteReportType, handleDeleteReportType);
  yield takeLatest(reportTypeActions.editReportType, handleEditReportType);
  yield takeLatest(searchActions.setSearch, handleSearchChange);
}

function* handleGetReportTypes() {
  const { request, success, failure } = reportTypeActions.getReportTypesRequest;
  yield put(request());
  try {
    const filters = yield select(makeGetReportTypeFilters());
    const pageInfo = yield select(makeGetReportTypePageInfo());
    const sorting = yield select(makeGetReportTypeSorting());
    const payload = yield call(ReportTypesApi.getReportTypes, pageInfo, filters, formatSortingForApi(sorting));
    yield put(success(payload));
  } catch (e) {
    yield put(failure(e.response));
  }
}
function* handleGetReportTypesWithPage1() {
  yield put(pageInfoActions.setPage(1));
}

function* handleAddReportType(action: ReturnType<typeof reportTypeActions.addReportType>) {
  const { request, success, failure } = reportTypeActions.addReportTypeRequest;
  yield put(request());
  try {
    const payload = yield call(ReportTypesApi.addReportType, action.payload.name);
    yield put(success(payload));
    action.successCallback();
    yield call(handleGetReportTypes);
  } catch (e) {
    yield put(failure(e.response));
  }
}

function* handleEditReportType(action: ReturnType<typeof reportTypeActions.editReportType>) {
  const { request, success, failure } = reportTypeActions.editReportTypeRequest;
  yield put(request());
  try {
    const payload = yield call(ReportTypesApi.editReportType, action.payload);
    yield put(success(payload));
    action.successCallback();
    yield call(handleGetReportTypes);
  } catch (e) {
    yield put(failure(e.response));
  }
}

function* handleDeleteReportType(action: ReturnType<typeof reportTypeActions.deleteReportType>) {
  const { request, success, failure } = reportTypeActions.deleteReportTypeRequest;
  yield put(request());
  try {
    yield call(ReportTypesApi.deleteReportType, action.payload.id);
    yield put(success());
    yield call(handleGetReportTypes);
  } catch (e) {
    yield put(failure(e.response));
  }
}

function* handleSearchChange(action: ReturnType<typeof searchActions.setSearch>) {
  if (action.payload.filterKey === REPORTTYPE_FILTER_KEY) {
    yield put(pageInfoActions.setPage(1));
  }
}

export default reportTypeSaga;
