import AxiosConfig, { RequestConfig } from '../../../config/api/AxiosConfig';
import { PaginationInfo } from '../../../types';
import { Sector } from '../../app/types';
import { EditReportTypeValues } from '../../reportType/types/reportTypes';
import { CriticalAlertPostBody, CriticalAlertPutBody, CriticalAlertsFilters } from '../types';
import { CRITICAL_ALERTS_URL } from './endpoints';

export const getCriticalAlerts = async (
  pageInfo: Pick<PaginationInfo, 'pageIndex' | 'pageSize'>,
  filters?: CriticalAlertsFilters,
  sorting?: string[]
) => {
  const params = {
    pageIndex: pageInfo.pageIndex,
    pageSize: pageInfo.pageSize,
    ...(filters && {
      ...(filters.audience && { audiences: filters.audience }),
      ...(filters.dateFrom && { dateFrom: filters.dateFrom }),
      ...(filters.dateUntil && { dateUntil: filters.dateUntil }),
      ...(filters.priority && { priorities: filters.priority }),
      ...(filters.reportType && { types: filters.reportType.map((type: EditReportTypeValues) => type.reportTypeId) }),
      ...(filters.search && { search: filters.search }),
      ...(filters.sectors && { sectors: filters.sectors.map((sector: Sector) => sector.sectorId) }),
      ...(filters.tlpCode && { tlpCodes: filters.tlpCode }),
    }),
    ...(sorting && { orderBys: sorting }),
  };

  const requestConfig: RequestConfig = {
    params,
    url: CRITICAL_ALERTS_URL,
  };

  return AxiosConfig.fetchAxiosPagedGetRequest(requestConfig);
};

export const addCriticalAlert = async (criticalAlert: CriticalAlertPostBody) => {
  const requestConfig: RequestConfig = {
    url: CRITICAL_ALERTS_URL,
    data: criticalAlert,
  };

  return AxiosConfig.fetchAxiosPostRequest(requestConfig);
};

export const removeCriticalAlert = async (guid: string) => {
  const requestConfig: RequestConfig = {
    url: `${CRITICAL_ALERTS_URL}/${guid}`,
  };

  return AxiosConfig.fetchAxiosDeleteRequest(requestConfig);
};

export const editCriticalAlert = async (criticalAlert: CriticalAlertPutBody) => {
  const requestConfig: RequestConfig = {
    url: `${CRITICAL_ALERTS_URL}/${criticalAlert.guid}`,
    data: criticalAlert,
  };

  return AxiosConfig.fetchAxiosPutRequest(requestConfig);
};

export const getUnconfirmedAlerts = async () => {
  const requestConfig: RequestConfig = {
    url: `${CRITICAL_ALERTS_URL}/unconfirmed`,
  };

  return AxiosConfig.fetchAxiosGetRequest(requestConfig);
};

export const confirmCriticalAlert = async (id: string) => {
  const requestConfig: RequestConfig = {
    url: `${CRITICAL_ALERTS_URL}/${id}/confirm`,
  };

  return AxiosConfig.fetchAxiosPostRequest(requestConfig);
};
