import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const Question: React.FunctionComponent = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" viewBox="0 0 50 50">
      <path d="M 25.808594 40.5 L 23.394531 40.5 C 21.691406 40.5 20.3125 41.882812 20.3125 43.582031 L 20.3125 46.917969 C 20.3125 48.617188 21.691406 50 23.394531 50 L 25.808594 50 C 27.507812 50 28.890625 48.617188 28.890625 46.917969 L 28.890625 43.582031 C 28.890625 41.875 27.507812 40.5 25.808594 40.5 Z M 25.808594 40.5 " />
      <path d="M 8.78125 14.683594 L 13.234375 15.238281 C 14.648438 15.414062 15.988281 14.601562 16.5 13.273438 C 17.140625 11.59375 18.035156 10.265625 19.179688 9.300781 C 20.804688 7.933594 22.824219 7.246094 25.238281 7.246094 C 27.742188 7.246094 29.734375 7.902344 31.214844 9.210938 C 32.695312 10.527344 33.429688 12.101562 33.429688 13.945312 C 33.429688 15.269531 33.011719 16.484375 32.179688 17.582031 C 31.640625 18.28125 29.980469 19.75 27.210938 21.996094 C 24.4375 24.242188 22.59375 26.265625 21.667969 28.066406 C 20.722656 29.90625 20.394531 31.945312 20.3125 33.988281 C 20.238281 35.742188 21.636719 37.199219 23.394531 37.199219 L 25.910156 37.199219 C 27.515625 37.199219 28.839844 35.972656 28.984375 34.378906 C 29.085938 33.234375 29.277344 32.367188 29.566406 31.773438 C 30.046875 30.761719 31.296875 29.425781 33.300781 27.761719 C 37.179688 24.550781 39.707031 22.011719 40.894531 20.140625 C 42.078125 18.277344 42.671875 16.296875 42.671875 14.207031 C 42.671875 10.429688 41.0625 7.117188 37.84375 4.269531 C 34.625 1.421875 30.289062 0 24.84375 0 C 19.664062 0 15.484375 1.402344 12.300781 4.214844 C 9.585938 6.609375 7.9375 9.445312 7.359375 12.71875 C 7.175781 13.671875 7.832031 14.566406 8.78125 14.683594 Z M 8.78125 14.683594 " />
    </svg>
  </BaseSvgWrapper>
);

export default Question;
