import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import styled from '../../config/theme';
import { ModuleEditAccessContext } from '../../modules/security';
import { Add } from '../icons';

type Props = {
  addUrl?: string;
} & RouteComponentProps;

const AddButton: React.FunctionComponent<Props> = ({ addUrl, history }: Props) => {
  const pushUrl = React.useCallback(() => addUrl && history.push(addUrl), [addUrl, history]);

  return (
    <ModuleEditAccessContext.Consumer>
      {({ canEdit }) =>
        canEdit && (
          <AddFabIconWrapper onClick={pushUrl}>
            <Add />
          </AddFabIconWrapper>
        )
      }
    </ModuleEditAccessContext.Consumer>
  );
};

const AddFabIconWrapper = styled.span`
  background: ${props => props.theme.palette.success.shade4};
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.08), 1px 1px 25px rgba(0, 0, 0, 0.08);
  width: 35px;
  min-height: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all ease-in-out 0.25s;

  top: calc(${props => props.theme.header.height} - 18px);
  margin: -18px 18px 0 auto;
  position: sticky;

  svg {
    fill: ${props => props.theme.palette.success.shade1};
    width: 25px;
    height: 25px;
  }

  &:focus,
  &:hover {
    /* TODO Improve hover styles */
    background: ${props => props.theme.palette.success.shade5};
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.18), 1px 1px 25px rgba(0, 0, 0, 0.08);
  }
`;

export default withRouter(AddButton);
