import { TableData, TableRow } from 'components/Table';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '../../config/theme';
import { ChevronDown, ChevronUp } from '../icons';

type Props = {
  baseSize: number;
  children: JSX.Element[];
  className?: string;
  forTable?: boolean;
  tableSize?: number;
};

const ShowMore: React.FunctionComponent<Props> = ({ children, baseSize, className, forTable, tableSize }) => {
  const { t } = useTranslation('global');
  const [showMore, setShowMore] = React.useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const ShowLessDiv: JSX.Element = (
    <ShowMoreToggle key={children.length} onClick={toggleShowMore} className={className}>
      <ChevronUp />
      {t('showMore.showLess')}
    </ShowMoreToggle>
  );
  const ShowMoreDiv: JSX.Element = (
    <ShowMoreToggle key={children.length} onClick={toggleShowMore} className={className}>
      <ChevronDown />
      {t('showMore.showMore')}
    </ShowMoreToggle>
  );

  return (
    children && (
      <React.Fragment>
        {!showMore ? children.slice(0, baseSize) : children}
        {showMore ? (
          <React.Fragment>
            {forTable ? (
              <NoHoverTableRow>
                <TableData colspan={tableSize}>{ShowLessDiv}</TableData>
              </NoHoverTableRow>
            ) : (
              [ShowLessDiv]
            )}
          </React.Fragment>
        ) : (
          children.length > baseSize && (
            <React.Fragment>
              {forTable ? (
                <NoHoverTableRow>
                  <TableData colspan={tableSize}>{ShowMoreDiv}</TableData>
                </NoHoverTableRow>
              ) : (
                [ShowMoreDiv]
              )}
            </React.Fragment>
          )
        )}
      </React.Fragment>
    )
  );
};

const NoHoverTableRow = styled(TableRow)`
  &:hover {
    background-color: inherit;
  }
`;

const ShowMoreToggle = styled.div`
  align-items: end;
  color: ${props => props.theme.showMore.color};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 200;

  svg {
    fill: ${props => props.theme.showMore.color};
    height: 20px;
    margin-right: 20px;
    width: 20px;
  }
`;

export default ShowMore;
