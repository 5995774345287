import { ActionType } from 'typesafe-actions';
import * as filterAction from './filters';
import * as pageInfoAction from './pageInfo';
import * as criticalAlertsAction from './criticalAlerts';
import * as sortingAction from './sorting';

export const CRITICAL_ALERTS_ACTION_PREFIX = 'CRITICAL_ALERTS';
export const REPOSITORY_REPORT_TYPE_ACTION_PREFIX = 'REPOSITORY_REPORT_TYPE';
export const REPOSITORY_SECTOR_ACTION_PREFIX = 'REPOSITORY_SECTOR';

export const filterActions = { ...filterAction };
export const pageInfoActions = { ...pageInfoAction };
export const criticalAlertsActions = { ...criticalAlertsAction };
export const sortingActions = { ...sortingAction };

export type FilterActions = ActionType<typeof filterAction>;
export type PageInfoActions = ActionType<typeof pageInfoAction>;
export type CriticalAlertsActions = ActionType<typeof criticalAlertsAction>;
export type SortingActions = ActionType<typeof sortingAction>;
