import { Action } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { GenericAction } from '../../../../types';
import logoutApi from '../../api/LogoutApi';
import { logoutActions } from '../actions/creators';

function* logoutSaga() {
  yield takeLatest(getType(logoutActions.initiateLogout), initiateLogout);
  yield takeLatest((action: Action) => /REJECTED$/.test(action.type), handleUnauthorized);
}

function* initiateLogout() {
  const { request, success, failure } = logoutActions.logoutRequest;
  yield put(request());

  try {
    const redirect: string | undefined = yield call(logoutApi.logout);
    yield put(success());
    window.location.href = (redirect && new URL(decodeURIComponent(redirect)).href) || '/';
  } catch (e) {
    yield put(failure(e.response));
  }
}

function handleUnauthorized(action: Action) {
  const { payload } = action as GenericAction;
  if (payload && payload.status && payload.status === 401) {
    window.location.href = '/';
  }
}

export default logoutSaga;
