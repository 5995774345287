import { IpRangeFormat, IpRangeEditValues, IpType } from 'modules/app/types';
import { AddOrganizationFormValues } from '../types/organization';
import { ContactEditValues } from '../types/organizationDetail/contacts';
import { Domain } from '../types/organizationDetail/cti';
import { BusinessLocationEditValues } from '../types/organizationDetail/general';

export const DATETIME_REGEX = /^[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}:[0-9]{2}(\.[0-9]{1,3})?[0-9]{4}$/;

export const ORGANIZATION_FILTER_KEY = 'organization';

export const DEFAULT_MAX_USERS = 5;

export const addOrganizationInitialValues: AddOrganizationFormValues = {
  emailAdminUser: '',
  name: '',
  sectors: [],
  maxUsers: 5,
};

export const newBusinessLocation: BusinessLocationEditValues = {
  city: '',
  countryId: null,
  name: '',
  number: '',
  state: '',
  street: '',
  zipCode: '',
};

export const newDomain: Domain = {
  domainId: '0',
  domainName: '',
  emailAbuse: '',
  fullyQualifiedDomainName: '',
  hostingCompany: '',
  registrar: '',
};

export const newIpRange = (type: IpType): IpRangeEditValues => ({
  description: '',
  format: IpRangeFormat.Range,
  ipAddresses: '',
  ipRangeId: '0',
  ipVersion: null,
  rangeType: type,
});

export const newContact: ContactEditValues = {
  availabilities: [{ days: [], hours: [9, 17] }],
  businessLocations: [],
  contactId: '0',
  email: '',
  firstName: '',
  info: '',
  lastName: '',
  pgpFile: null,
  phoneBusiness: '',
  phoneMobile: '',
  phoneOrder: 0,
  phonePrivate: '',
  skype: '',
  treemaId: '',
  types: [],
};

export enum OrganizationAccessRights {
  EditAndCreateOfBasicInfo = 'ORG_BASE',
  EditOfStatus = 'ORG_STATUS_EDIT',
  EditOfFullDetail = 'ORG_DETAIL_EDIT',
  CreateOfUsers = 'ORG_USER_CREATE',
  EditOfUsers = 'ORG_USER_EDIT',
  ActivateOfUsers = 'ORG_USER_ACTIVATE',
  RejectOfUsers = 'ORG_USER_REJECT',
  SuspendOfUsers = 'ORG_USER_SUSPEND',
  AccessToOverview = 'ORG_OVERVIEW',
  InviteUsers = 'ORG_USER_INVITE',
}
