import { searchActions } from 'modules/app/redux/creators';
import { identityApi } from 'modules/security';
import {
  addSuperUser,
  disableSuperUser,
  downgradeSuperUser,
  enableSuperUser,
  getSuperUsers,
} from 'modules/superUsers/adapters/superUsersApi';
import { SUPER_USERS_FILTER_KEY } from 'modules/superUsers/constants';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { formatSortingForApi } from '../../../../helpers/sortingFormatter';
import { filterActions, pageInfoActions, sortingActions, superUsersActions } from '../creators';
import { makeGetSuperUsersFilters, makeGetSuperUsersPageInfo, makeGetSuperUsersSorting } from '../selectors/superUsers';

function* superUsersSaga() {
  yield takeLatest(
    [
      pageInfoActions.setPage,
      pageInfoActions.setPageSize,
      superUsersActions.getSuperUsers,
      sortingActions.setSorting,
      filterActions.setRoleFilter,
      filterActions.setOnlyEnabledFilter,
    ],
    handleGetSuperUsers
  );
  yield takeLatest([pageInfoActions.setPageSize], handleGetSuperUsersWithPage1);
  yield takeLatest(searchActions.setSearch, handleSearchChange);
  yield takeLatest(superUsersActions.addSuperUser, handleAddSuperUser);
  yield takeLatest(superUsersActions.disableSuperUser, handleDisableSuperUser);
  yield takeLatest(superUsersActions.enableSuperUser, handleEnableSuperUser);
  yield takeLatest(superUsersActions.reset2FA, handleReset2FA);
  yield takeLatest(superUsersActions.downgradeSuperUser, handleDowngradeSuperUser);
}

function* handleGetSuperUsers() {
  const { request, success, failure } = superUsersActions.getSuperUsersRequest;
  yield put(request());
  try {
    const filters = yield select(makeGetSuperUsersFilters());
    const pageInfo = yield select(makeGetSuperUsersPageInfo());
    const sorting = yield select(makeGetSuperUsersSorting());
    const payload = yield call(getSuperUsers, pageInfo, filters, formatSortingForApi(sorting));
    yield put(success(payload));
  } catch (e) {
    yield put(failure(e.response));
  }
}
function* handleGetSuperUsersWithPage1() {
  yield put(pageInfoActions.setPage(1));
}

function* handleSearchChange(action: ReturnType<typeof searchActions.setSearch>) {
  if (action.payload.filterKey === SUPER_USERS_FILTER_KEY) {
    yield put(pageInfoActions.setPage(1));
  }
}

function* handleAddSuperUser(action: ReturnType<typeof superUsersActions.addSuperUser>) {
  const { payload, successCallback } = action;
  const { request, success, failure } = superUsersActions.addSuperUserRequest;
  yield put(request());
  try {
    const response = yield call(addSuperUser, payload);
    yield put(success(response));
    successCallback?.();
  } catch (e) {
    yield put(failure(e.response));
  }
}

function* handleDisableSuperUser(action: ReturnType<typeof superUsersActions.disableSuperUser>) {
  const { payload, successCallback } = action;
  const { request, success, failure } = superUsersActions.disableSuperUserRequest;
  yield put(request());
  try {
    yield call(disableSuperUser, payload);
    yield put(success(payload));
    successCallback?.();
  } catch (e) {
    yield put(failure(e.response));
  }
}

function* handleEnableSuperUser(action: ReturnType<typeof superUsersActions.enableSuperUser>) {
  const { payload, successCallback } = action;
  const { request, success, failure } = superUsersActions.enableSuperUserRequest;
  yield put(request());
  try {
    yield call(enableSuperUser, payload);
    yield put(success(payload));
    successCallback?.();
  } catch (e) {
    yield put(failure(e.response));
  }
}

function* handleReset2FA(action: ReturnType<typeof superUsersActions.reset2FA>) {
  const { request, success, failure } = superUsersActions.reset2FARequest;
  yield put(request());
  try {
    yield call(identityApi.resetUserAuthenticator, action.payload);
    yield put(success());
  } catch (e) {
    yield put(failure(e.response));
  }
}

function* handleDowngradeSuperUser(action: ReturnType<typeof superUsersActions.downgradeSuperUser>) {
  const { request, success, failure } = superUsersActions.downgradeSuperUserRequest;
  yield put(request());
  try {
    yield call(downgradeSuperUser, action.payload);
    yield put(success(action.payload));
  } catch (e) {
    yield put(failure(e.response));
  }
}

export default superUsersSaga;
