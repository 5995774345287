import { combineReducers } from 'redux';
import alertReducer, { AlertState } from './alerts';
import criticalAlertsReducer, { CriticalAlertsState } from './criticalAlerts';

export type AlertsModuleState = {
  alerts: AlertState;
  criticalAlerts: CriticalAlertsState;
};

const alertsReducer = combineReducers<AlertsModuleState>({
  alerts: alertReducer,
  criticalAlerts: criticalAlertsReducer,
});

export default alertsReducer;
