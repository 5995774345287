import { DatePickerInput } from '@unbrace/components';
import { Input } from 'components';
import { FormikSelect, FormFlexRow } from 'components/Form';
import FormikFileUpload from 'components/Form/FormikFileUpload';
import { Field, FieldProps, Form, Formik } from 'formik';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorText } from '../../../../components/Form/ErrorText';
import { dateParser, formatAsDate } from '../../../../helpers/dateFormatter';
import useGlobalInfoStore from '../../../app/redux/services/useGlobalInfoStore';
import { ReportType, Sector } from '../../../app/types';
import { Audience, audienceOptions, Priority, priorityOptions, TlpCode, tlpCodeOptions } from '../../../app/constants';
import { editRepositorySchema } from '../../schemas/repository';
import { EditRepositoryValues } from '../../types/repository';

type Props = {
  formRef: React.Ref<any>;
  initialValues: any;
  onFormChange: () => void;
  onSubmit: (values: EditRepositoryValues) => void;
};

const EditRepositoryForm: React.FunctionComponent<Props> = ({
  formRef,
  initialValues,
  onFormChange,
  onSubmit,
}: Props) => {
  const { t } = useTranslation('repository');
  const { loadReports, loadSectors } = useGlobalInfoStore();
  const today = new Date();

  const createReportType = {
    name: initialValues.reportType,
    numberOfReports: initialValues.numberOfReports,
    reportTypeId: initialValues.reportTypeId,
  };

  const editRepositoryInitValues: EditRepositoryValues = initialValues && {
    ...initialValues,
    reportDate: dateParser(formatAsDate(initialValues.reportDate)),
    report: createReportType,
    sendMail: false,
    file: {
      base64: '',
      file: null,
      name: initialValues.fileName,
    },
  };

  const handleDateChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (fieldName: string, value: Date | undefined) => void
  ) => {
    const parsedValue = dateParser(event.target.value);
    if (parsedValue || !event.target.value.trim()) {
      return setFieldValue('reportDate', parsedValue);
    }
  };

  return (
    <Formik
      initialValues={editRepositoryInitValues}
      onSubmit={onSubmit}
      ref={formRef}
      validate={onFormChange}
      validationSchema={() => editRepositorySchema(t('alerts.illegal'))}
      render={({ touched, errors, setFieldValue }) => {
        return (
          <Form>
            <Field name="description">
              {({ field }: FieldProps<EditRepositoryValues>) => (
                <Input
                  autoFocus
                  {...field}
                  type="text"
                  label={t('edit.labels.description')}
                  error={(touched.description && errors.description) || ''}
                />
              )}
            </Field>
            <Field name="sectors">
              {(fieldProps: FieldProps) => (
                <FormikSelect
                  {...fieldProps}
                  isMulti
                  async
                  getOptionValue={(option: Sector) => option.sectorId.toString()}
                  getOptionLabel={(option: Sector) => option.name}
                  loadOptions={loadSectors}
                  label={t('edit.labels.sectors')}
                  error={(touched.sectors && errors.sectors) || ''}
                />
              )}
            </Field>
            <FormFlexRow>
              <Field name="report">
                {(fieldProps: FieldProps) => (
                  <FormikSelect
                    {...fieldProps}
                    async
                    getOptionValue={(option: ReportType) => option.reportTypeId.toString()}
                    getOptionLabel={(option: ReportType) => option.name}
                    loadOptions={loadReports}
                    label={t('edit.labels.reportType')}
                    error={(touched.reportTypeId && errors.reportTypeId) || ''}
                    zIndex={20}
                  />
                )}
              </Field>
              <Field name="audience">
                {(fieldProps: FieldProps) => (
                  <FormikSelect
                    {...fieldProps}
                    error={(touched.audience && errors.audience) || ''}
                    getOptionLabel={(option: Audience) => option}
                    getOptionValue={(option: Audience) => option}
                    isCapitalized
                    label={t('edit.labels.audience')}
                    options={audienceOptions}
                  />
                )}
              </Field>
            </FormFlexRow>
            <FormFlexRow>
              <Field name="tlpCode">
                {(fieldProps: FieldProps) => (
                  <FormikSelect
                    {...fieldProps}
                    error={(touched.tlpCode && errors.tlpCode) || ''}
                    getOptionLabel={(option: TlpCode) => t(`global:tlpCode.${option.toLowerCase()}`)}
                    getOptionValue={(option: TlpCode) => option}
                    isCapitalized
                    label={t('edit.labels.tlpCode')}
                    options={tlpCodeOptions}
                  />
                )}
              </Field>
              <Field name="priority">
                {(fieldProps: FieldProps) => (
                  <FormikSelect
                    {...fieldProps}
                    error={(touched.priority && errors.priority) || ''}
                    getOptionLabel={(option: Priority) => option}
                    getOptionValue={(option: Priority) => option}
                    isCapitalized
                    label={t('edit.labels.priority')}
                    options={priorityOptions}
                  />
                )}
              </Field>
            </FormFlexRow>
            <Field name="reportDate">
              {({ field }: FieldProps) => (
                <React.Fragment>
                  <DatePickerInput
                    value={field.value}
                    inputDebounceTimeOut={1000}
                    placeholder={''}
                    inputProps={{
                      name: 'reportDate',
                      label: t('edit.labels.dateReported'),
                      inlineLabel: false,
                      autoComplete: 'off',
                      style: { marginBottom: 8 },
                      isClearable: false,
                      onChange: (event: React.ChangeEvent<HTMLInputElement>) => handleDateChange(event, setFieldValue),
                    }}
                    dayPickerProps={{
                      disabledDays: { after: today },
                      onDayClick: (day) => setFieldValue('reportDate', day),
                    }}
                  />
                  {errors.reportDate && <ErrorText block>{t('validation:date')}</ErrorText>}
                </React.Fragment>
              )}
            </Field>
            <Field name="file">
              {({ field, form }: FieldProps) => (
                <FormikFileUpload acceptedFileTypes={['.html', '.pdf']} field={field} form={form} maxFileSize={100} />
              )}
            </Field>
          </Form>
        );
      }}
    />
  );
};

export default EditRepositoryForm;
