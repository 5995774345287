import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ContextMenuBlock, ContextMenuContainer, ContextMenuItem } from '../../../components';
import { SETTINGS_BASE_ROUTE } from '../../settings/routes/SettingsRoutes';

type Props = {
  closeMenu?: VoidFunction;
  handleLogout: VoidFunction;
  handleResetPassword: VoidFunction;
  userName?: string;
};

const ProfileMenuContent: React.FC<Props> = ({ closeMenu, handleLogout, handleResetPassword, userName }: Props) => {
  const { t } = useTranslation('security');
  const history = useHistory();

  const goToSettings = () => history.push(SETTINGS_BASE_ROUTE);

  return (
    <ContextMenuContainer closeMenu={closeMenu}>
      <ContextMenuBlock title={userName ? userName : t('profile.user')}>
        <ContextMenuItem text={t('settings')} action={goToSettings} />
        <ContextMenuItem text={t('profile.reset')} action={handleResetPassword} />
        <ContextMenuItem text={t('profile.logout')} action={handleLogout} />
      </ContextMenuBlock>
    </ContextMenuContainer>
  );
};

export default ProfileMenuContent;
