import { DatePickerInput, Divider, Spacer } from '@unbrace/components';
import { Input } from 'components';
import { FormikSelect, FormFlexRow, TextArea } from 'components/Form';
import FormikFileUpload from 'components/Form/FormikFileUpload';
import { Field, FieldProps, Form, Formik, getIn } from 'formik';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { dateParser, formatAsDate } from 'helpers/dateFormatter';
import { TYPE_CHECKBOX, TYPE_TEXT } from '../../../../components/Form/Input';
import useGlobalInfoStore from '../../../app/redux/services/useGlobalInfoStore';
import { ReportType, Sector } from '../../../app/types';
import { Audience, audienceOptions, Priority, priorityOptions, TlpCode, tlpCodeOptions } from '../../../app/constants';
import { addRepositorySchema } from '../../schemas/repository';
import { AddRepositoryValues } from '../../types/repository';
import { ErrorText } from '../../../../components/Form/ErrorText';

type Props = {
  formRef: React.Ref<any>;
  onFormChange: () => void;
  onSubmit: (values: AddRepositoryValues) => void;
};

const AddRepositoryForm: React.FunctionComponent<Props> = ({ formRef, onFormChange, onSubmit }: Props) => {
  const { t } = useTranslation('repository');
  const { loadSectors, loadReports } = useGlobalInfoStore();
  const today = new Date();

  const addRepositoryInitValues: AddRepositoryValues = {
    audience: undefined,
    description: '',
    file: undefined,
    priority: undefined,
    report: undefined,
    reportTypeId: undefined,
    sectors: [],
    tlpCode: undefined,
    reportDate: dateParser(formatAsDate(today)),
    addCriticalAlert: false,
    title: '',
    criticalAlertDescription: '',
    sendMail: true,
  };

  const handleDateChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (fieldName: string, value: Date | undefined) => void
  ) => {
    const parsedValue = dateParser(event.target.value);
    if (parsedValue || !event.target.value.trim()) {
      return setFieldValue('reportDate', parsedValue);
    }
  };

  return (
    <Formik
      initialValues={addRepositoryInitValues}
      onSubmit={onSubmit}
      ref={formRef}
      validate={onFormChange}
      validationSchema={() => addRepositorySchema(t('alerts.illegal'))}
      render={({ values, touched, errors, setFieldValue }) => (
        <Form>
          <Field name="description">
            {({ field }: FieldProps<AddRepositoryValues>) => (
              <Input
                autoFocus
                {...field}
                type="text"
                label={t('edit.labels.description')}
                error={(touched.description && errors.description) || ''}
              />
            )}
          </Field>
          <Field name="sectors">
            {(fieldProps: FieldProps) => (
              <FormikSelect
                {...fieldProps}
                isMulti
                async
                getOptionValue={(option: Sector) => option.sectorId.toString()}
                getOptionLabel={(option: Sector) => option.name}
                loadOptions={loadSectors}
                label={t('edit.labels.sectors')}
                error={(touched.sectors && errors.sectors) || ''}
              />
            )}
          </Field>
          <FormFlexRow>
            <Field name="report">
              {(fieldProps: FieldProps) => (
                <FormikSelect
                  {...fieldProps}
                  async
                  getOptionValue={(option: ReportType) => option.reportTypeId.toString()}
                  getOptionLabel={(option: ReportType) => option.name}
                  loadOptions={loadReports}
                  label={t('edit.labels.reportType')}
                  error={touched.report && getIn(errors, 'report.reportTypeId')}
                />
              )}
            </Field>
            <Field name="audience">
              {(fieldProps: FieldProps) => (
                <FormikSelect
                  {...fieldProps}
                  error={(touched.audience && errors.audience) || ''}
                  getOptionLabel={(option: Audience) => option}
                  getOptionValue={(option: Audience) => option}
                  isCapitalized
                  label={t('edit.labels.audience')}
                  options={audienceOptions}
                />
              )}
            </Field>
          </FormFlexRow>
          <FormFlexRow>
            <Field name="tlpCode">
              {(fieldProps: FieldProps) => (
                <FormikSelect
                  {...fieldProps}
                  error={(touched.tlpCode && errors.tlpCode) || ''}
                  getOptionLabel={(option: TlpCode) => t(`global:tlpCode.${option.toLowerCase()}`)}
                  getOptionValue={(option: TlpCode) => option}
                  isCapitalized
                  label={t('edit.labels.tlpCode')}
                  options={tlpCodeOptions}
                />
              )}
            </Field>
            <Field name="priority">
              {(fieldProps: FieldProps) => (
                <FormikSelect
                  {...fieldProps}
                  error={(touched.priority && errors.priority) || ''}
                  getOptionLabel={(option: Priority) => option}
                  getOptionValue={(option: Priority) => option}
                  isCapitalized
                  label={t('edit.labels.priority')}
                  options={priorityOptions}
                />
              )}
            </Field>
          </FormFlexRow>
          <Field name="reportDate">
            {({ field }: FieldProps) => (
              <React.Fragment>
                <DatePickerInput
                  value={field.value}
                  inputDebounceTimeOut={1000}
                  placeholder={''}
                  inputProps={{
                    name: 'reportDate',
                    label: t('edit.labels.dateReported'),
                    inlineLabel: false,
                    style: { marginBottom: 8 },
                    autoComplete: 'off',
                    isClearable: false,
                    onChange: (event: React.ChangeEvent<HTMLInputElement>) => handleDateChange(event, setFieldValue),
                  }}
                  dayPickerProps={{
                    disabledDays: { after: today },
                    onDayClick: (day) => setFieldValue('reportDate', day),
                  }}
                />
                {errors.reportDate && <ErrorText block>{t('validation:date')}</ErrorText>}
              </React.Fragment>
            )}
          </Field>
          <Field name="file">
            {({ field, form }: FieldProps) => (
              <FormikFileUpload acceptedFileTypes={['.html', '.pdf']} field={field} form={form} maxFileSize={100} />
            )}
          </Field>
          <Divider />
          <Spacer top />
          <Field name={'addCriticalAlert'}>
            {({ field }: FieldProps<AddRepositoryValues>) => (
              <Input
                {...field}
                type={TYPE_CHECKBOX}
                label={t('edit.labels.addCriticalAlert')}
                error={(touched.addCriticalAlert && errors.addCriticalAlert) || ''}
              />
            )}
          </Field>
          <Spacer bottom />
          {values.addCriticalAlert && (
            <React.Fragment>
              <Field name={'title'}>
                {({ field }: FieldProps<AddRepositoryValues>) => (
                  <Input
                    {...field}
                    type={TYPE_TEXT}
                    label={t('edit.labels.title')}
                    error={(touched.title && errors.title) || ''}
                  />
                )}
              </Field>
              <Field name={'criticalAlertDescription'}>
                {({ field }: FieldProps<AddRepositoryValues>) => (
                  <TextArea
                    {...field}
                    label={t('edit.labels.criticalAlertDescription')}
                    error={(touched.criticalAlertDescription && errors.criticalAlertDescription) || ''}
                  />
                )}
              </Field>
            </React.Fragment>
          )}
        </Form>
      )}
    />
  );
};

export default AddRepositoryForm;
