import { IconButton } from 'components';
import { Close, Filter, List } from 'components/icons';
import styled from 'config/theme';
import { AnimationProps } from 'config/theme/types';
import * as React from 'react';

export type IsOpenProps = {
  isOpen: boolean;
};

export const ToggleWrapper = styled.div<IsOpenProps & AnimationProps>`
  position: absolute;
  top: 0;
  left: ${(props) => (props.isOpen ? `calc(${props.theme.sidebar.mobile.width} - ${props.theme.header.height})` : '0')};
  transition: ${(props) => props.theme.sidebar.mobile.transition};
  z-index: 200;
  height: ${(props) => props.theme.header.height};
  width: ${(props) => props.theme.header.height};
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: ${(props) => props.theme.header.borderBottom};
  border-left: ${(props) => (props.isOpen ? props.theme.header.borderBottom : 'none')};
  border-bottom: ${(props) => props.theme.header.borderBottom};
  background: ${(props) => props.theme.sidebar.background.main};

  ${(props) => props.state === 'entering' && props.theme.header.animation.entering};
  ${(props) =>
    props.state === 'entered' && `${props.theme.header.animation.entered} ${props.theme.sidebar.mobile.transition}`};
`;

type Props = {
  forItemBar?: boolean;
  forNavigation?: boolean;
  children: (isOpen: boolean) => React.ReactNode | React.ReactNode[];
  setOverrideIsOpen?: (booleanValue?: boolean) => void;
  overrideIsOpen?: boolean;
};

const MobileToggle: React.FunctionComponent<AnimationProps & Props> = ({
  state: animationState,
  forItemBar,
  forNavigation,
  children,
  setOverrideIsOpen,
  overrideIsOpen,
}) => {
  const [isOpen, toggleOpen] = React.useState(forItemBar || false);
  const handleToggle = React.useCallback(() => {
    toggleOpen(!isOpen);
    if (setOverrideIsOpen) {
      setOverrideIsOpen();
    }
  }, [isOpen, toggleOpen, setOverrideIsOpen]);

  return (
    <React.Fragment>
      <ToggleWrapper state={animationState} isOpen={overrideIsOpen ?? isOpen}>
        <IconButton onClick={handleToggle}>
          {overrideIsOpen ?? isOpen ? <Close /> : forItemBar || forNavigation ? <List /> : <Filter />}
        </IconButton>
      </ToggleWrapper>
      {children(isOpen)}
    </React.Fragment>
  );
};

export default MobileToggle;
