import {
  ContextMenuOrientation,
  ORIENTATION_BOTTOM_LEFT,
  ORIENTATION_BOTTOM_RIGHT,
  ORIENTATION_TOP_LEFT,
  ORIENTATION_TOP_RIGHT,
} from './constants';

export type Coordinates = {
  x: number;
  y: number;
};

export const setClickedPosition = (
  e: React.MouseEvent,
  orientation: ContextMenuOrientation,
  setX: (val: number) => void,
  setY: (val: number) => void
) => {
  if (e.type === 'contextmenu') {
    setX(e.pageX);
    setY(e.pageY);
  }
  if (e.type === 'click') {
    switch (orientation) {
      case ORIENTATION_BOTTOM_RIGHT:
        setX(e.currentTarget.getBoundingClientRect().left);
        setY(e.currentTarget.getBoundingClientRect().bottom + 5);
        break;
      case ORIENTATION_BOTTOM_LEFT:
        setX(e.currentTarget.getBoundingClientRect().right);
        setY(e.currentTarget.getBoundingClientRect().bottom + 5);
        break;
      case ORIENTATION_TOP_RIGHT:
        setX(e.currentTarget.getBoundingClientRect().left);
        setY(e.currentTarget.getBoundingClientRect().top - 5);
        break;
      case ORIENTATION_TOP_LEFT:
        setX(e.currentTarget.getBoundingClientRect().right);
        setY(e.currentTarget.getBoundingClientRect().top - 5);
        break;
      default:
        break;
    }
  }
};

export const calculateCoordinates = (
  clickedX: number,
  clickedY: number,
  width: number,
  height: number,
  orientation: ContextMenuOrientation
): Coordinates => {
  let coords: Coordinates = { x: 0, y: 0 };
  switch (orientation) {
    case ORIENTATION_BOTTOM_RIGHT:
      coords = { x: clickedX, y: clickedY };
      break;
    case ORIENTATION_BOTTOM_LEFT:
      coords = { x: clickedX - width, y: clickedY };
      break;
    case ORIENTATION_TOP_RIGHT:
      coords = { x: clickedX, y: clickedY - height };
      break;
    case ORIENTATION_TOP_LEFT:
      coords = { x: clickedX - width, y: clickedY - height };
      break;
    default:
      break;
  }

  return preventOffscreen(coords, width, height);
};

const preventOffscreen = ({ x, y }: Coordinates, width: number, height: number): Coordinates => {
  if (x + width > document.body.clientWidth) {
    return { x: document.body.clientWidth - 5 - width, y };
  }
  if (x < 0) {
    return { x: x + width, y };
  }
  if (y + height > document.body.clientHeight && height < document.body.clientHeight) {
    return { x, y: y - height };
  }
  if (y < 0) {
    return { x, y: y + height };
  }

  return { x, y };
};

export const calculateMaxHeight = (x: number): number =>
  document.body.clientHeight > 450 ? 450 : document.body.clientHeight - x - 20;
