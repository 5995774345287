import AxiosConfig, { RequestConfig } from 'config/api/AxiosConfig';
import { UserType } from 'modules/security/types/user';
import { EditOrganizationValues } from '../types/organization';
import { ContactPostValues } from '../types/organizationDetail/contacts';
import { CTIData } from '../types/organizationDetail/cti';
import { GeneralInfoPostValues } from '../types/organizationDetail/general';
import { AddUserValues, PrintValues } from '../types/organizationDetail/organizationDetail';
import { UpgradeUserRole } from '../types/organizationDetail/users';
import { ADMIN_URL, ORGANIZATIONS_URL, USERS_URL } from './enpoints';

const getGeneralInfo = async (id: string) => {
  const requestConfig: RequestConfig = {
    url: `${ORGANIZATIONS_URL}/${id}`,
  };

  return AxiosConfig.fetchAxiosPagedGetRequest(requestConfig);
};

const updateOrganization = async (id: string, { name, sectors, maxUsers }: EditOrganizationValues) => {
  const requestConfig: RequestConfig = {
    data: {
      name,
      organizationId: id,
      sectors: sectors.map((sector) => sector.sectorId),
      maxUsers,
    },
    url: `${ORGANIZATIONS_URL}/${id}`,
  };

  return AxiosConfig.fetchAxiosPutRequest(requestConfig);
};

const deleteOrganization = async (organizationId: string) => {
  const requestConfig: RequestConfig = {
    url: `${ORGANIZATIONS_URL}/${organizationId}`,
  };

  return AxiosConfig.fetchAxiosDeleteRequest(requestConfig);
};

const getContacts = async (id: string) => {
  const requestConfig: RequestConfig = {
    url: `${ORGANIZATIONS_URL}/${id}/contacts`,
  };

  return AxiosConfig.fetchAxiosPagedGetRequest(requestConfig);
};

const getCTIData = async (id: string) => {
  const requestConfig: RequestConfig = {
    url: `${ORGANIZATIONS_URL}/${id}/cti-data`,
  };

  return AxiosConfig.fetchAxiosPagedGetRequest(requestConfig);
};

const printDetail = async (values: PrintValues) => {
  const requestConfig: RequestConfig = {
    params: {
      organizationIds: values.organizationIds,
    },
    url: `${ORGANIZATIONS_URL}/detail/document`,
  };

  return AxiosConfig.fetchAxiosDownloadRequest(requestConfig, values.fileName);
};

const setUserToAdmin = async (userId: string) => {
  const requestConfig: RequestConfig = {
    url: `${ORGANIZATIONS_URL}/set-admin`,
    params: {
      userId,
    },
  };

  return AxiosConfig.fetchAxiosPostRequest(requestConfig);
};

const getUsers = async (id: string) => {
  const requestConfig: RequestConfig = {
    url: `${ORGANIZATIONS_URL}/${id}/users`,
  };

  return AxiosConfig.fetchAxiosPagedGetRequest(requestConfig);
};

const getDifference = async (id: string) => {
  const requestConfig: RequestConfig = {
    url: `${ORGANIZATIONS_URL}/${id}/differences`,
  };

  return AxiosConfig.fetchAxiosGetRequest(requestConfig);
};

const addUser = async (organizationId: string, values: AddUserValues) => {
  const requestConfig: RequestConfig = {
    data: {
      email: values.email,
      organizationId,
      type: UserType.User,
    },
    url: `${USERS_URL}`,
  };

  return AxiosConfig.fetchAxiosPostRequest(requestConfig);
};

const addAdmin = async (organizationId: string, values: AddUserValues) => {
  const requestConfig: RequestConfig = {
    data: {
      email: values.email,
      organizationId,
    },
    url: `${USERS_URL}/ovi-poc`,
  };

  return AxiosConfig.fetchAxiosPostRequest(requestConfig);
};

const approveOrganization = async (id: string) => {
  const requestConfig: RequestConfig = {
    url: `${ORGANIZATIONS_URL}/${id}/activate`,
  };

  return AxiosConfig.fetchAxiosPostRequest(requestConfig);
};

const rejectOrganization = async (id: string, comment: string) => {
  const requestConfig: RequestConfig = {
    data: {
      comment,
    },
    url: `${ORGANIZATIONS_URL}/${id}/reject`,
  };

  return AxiosConfig.fetchAxiosPostRequest(requestConfig);
};

const updateGeneralInfo = async (id: string, values: GeneralInfoPostValues) => {
  const requestConfig: RequestConfig = {
    data: values,
    url: `${ORGANIZATIONS_URL}/${id}/general-data`,
  };

  return AxiosConfig.fetchAxiosPostRequest(requestConfig);
};

const updateContacts = async (id: string, contacts: ContactPostValues[]) => {
  const requestConfig: RequestConfig = {
    data: contacts,
    url: `${ORGANIZATIONS_URL}/${id}/contacts`,
  };

  return AxiosConfig.fetchAxiosPostRequest(requestConfig);
};

const updateCTIData = async (id: string, values: CTIData) => {
  const requestConfig: RequestConfig = {
    data: values,
    url: `${ORGANIZATIONS_URL}/${id}/cti-data`,
  };

  return AxiosConfig.fetchAxiosPostRequest(requestConfig);
};

const approveUser = async (id: string) => {
  const requestConfig: RequestConfig = {
    url: `${USERS_URL}/${id}/activate`,
  };

  return AxiosConfig.fetchAxiosPostRequest(requestConfig);
};

const upgradeUser = async (userGuid: string, role: UpgradeUserRole) => {
  const requestConfig: RequestConfig = {
    url: `${ADMIN_URL}/entities/upgrade`,
    params: {
      userGuid,
      role,
    },
  };

  return AxiosConfig.fetchAxiosPostRequest(requestConfig);
};

const rejectUser = async (id: string, comment: string) => {
  const requestConfig: RequestConfig = {
    data: {
      comment,
    },
    url: `${USERS_URL}/${id}/reject`,
  };

  return AxiosConfig.fetchAxiosPostRequest(requestConfig);
};

const deleteUser = async (id: string) => {
  const requestConfig: RequestConfig = {
    url: `${USERS_URL}/${id}`,
  };

  return AxiosConfig.fetchAxiosDeleteRequest(requestConfig);
};

const resendInvitation = async (userId: string) => {
  const requestConfig: RequestConfig = {
    url: `${USERS_URL}/${userId}/resend-invite`,
  };

  return AxiosConfig.fetchAxiosPostRequest(requestConfig);
};

export default {
  addUser,
  approveOrganization,
  approveUser,
  deleteOrganization,
  deleteUser,
  getCTIData,
  getContacts,
  getGeneralInfo,
  getUsers,
  printDetail,
  rejectOrganization,
  rejectUser,
  resendInvitation,
  updateCTIData,
  updateContacts,
  updateGeneralInfo,
  updateOrganization,
  setUserToAdmin,
  upgradeUser,
  addAdmin,
  getDifference,
};
