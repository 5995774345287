import { BasicTextField } from 'components/FullView';
import { ArrayHelpers, FormikErrors, FormikTouched } from 'formik';
import { IpType } from 'modules/app/types';
import { newIpRange } from 'modules/organization/constants';
import { CTIDataEditValues } from 'modules/organization/types/organizationDetail/cti';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  errors: FormikErrors<CTIDataEditValues>;
  touched: FormikTouched<CTIDataEditValues>;
  arrayHelpers: ArrayHelpers;
  name: 'whiteIpRanges' | 'publicIpRanges' | 'ipRanges';
};

const IpRangePlaceholder: React.FunctionComponent<Props> = ({ errors, touched, arrayHelpers, name }: Props) => {
  const { t } = useTranslation('organization');

  const rangeType = React.useCallback(() => {
    switch (name) {
      case 'whiteIpRanges':
        return IpType.whiteList;
      case 'publicIpRanges':
        return IpType.publicList;
      case 'ipRanges':
      default:
        return IpType.ctiData;
    }
  }, [name]);

  const addIpRange = React.useCallback(() => arrayHelpers.push(newIpRange(rangeType())), [arrayHelpers, rangeType]);

  return (
    <div onClick={addIpRange}>
      <BasicTextField name={name} label={t('detail.labels.ipVersion')} errors={errors} touched={touched} errorAsBlock />
    </div>
  );
};

export default IpRangePlaceholder;
