import { getType } from 'typesafe-actions';
import { Modules } from '../../types/modules';
import { moduleActions, ModulesActionsType } from '../actions/creators';

export type ModulesState = Modules;

const modulesReducer = (state: ModulesState = [], action: ModulesActionsType) => {
  switch (action.type) {
    case getType(moduleActions.fetchAvailableModules.success):
      return action.payload;
    default:
      return state;
  }
};

export default modulesReducer;
