import { getType } from 'typesafe-actions';
import { InactiveUser } from '../../types';
import { InactiveUsersActions, inactiveUsersActions } from '../creators';

export type InactiveUsersModuleState = InactiveUser[] | null;

const inactiveUsersReducer = (state: InactiveUsersModuleState = null, action: InactiveUsersActions) => {
  switch (action.type) {
    case getType(inactiveUsersActions.getInactiveUsersRequest.success):
      return action.payload.items;
    case getType(inactiveUsersActions.sendReminderRequest.success):
    default:
      return state;
  }
};

export default inactiveUsersReducer;
