import { combineReducers } from 'redux';
import { Alert } from '../../types/alertRules';
import alertRulesReducer from './alertRules';
import sidebarReducer from './sidebar';

export type SettingsModuleState = {
  alertRules: Alert | null;
  sidebarCollapsed: boolean;
};

const settingsModuleReducer = combineReducers({
  alertRules: alertRulesReducer,
  sidebarCollapsed: sidebarReducer,
});

export default settingsModuleReducer;
