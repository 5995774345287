import { DownloadLink } from 'components';
import { FullViewItem, FullViewSubBlock } from 'components/FullView';
import styled, { css } from 'config/theme';
import { media } from 'config/theme/utils';
import { capitalizeString } from 'helpers/stringUtils';
import { ORGANIZATIONS_URL } from 'modules/organization/api/enpoints';
import { Contact as ContactType } from 'modules/organization/types/organizationDetail/contacts';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Availabilities from './Availabilities';

type Props = {
  contact: ContactType;
};

const Contact: React.FunctionComponent<Props> = ({ contact }: Props) => {
  const { t } = useTranslation('organization');

  return (
    <FullViewSubBlock key={contact.contactId}>
      <Name>{`${contact.firstName || ''} ${contact.lastName || ''}`}</Name>
      <FullViewItem
        label={t('detail.labels.contactType')}
        value={contact.types.map(type => capitalizeString(type)).join(', ')}
      />
      <FullViewItem label={t('detail.labels.businessEmail')} value={contact.email} />
      <FullViewItem label={t('detail.labels.skype')} value={contact.skype} />
      <FullViewItem label={t('detail.labels.businessPhone')} value={contact.phoneBusiness} />
      <FullViewItem label={t('detail.labels.officePhone')} value={contact.phoneMobile} />
      <FullViewItem label={t('detail.labels.phonePrivate')} value={contact.phonePrivate} />
      {contact.pgpFileId && contact.pgpFileName && (
        <FullViewItem
          label={t('detail.labels.mispPocPgpFileId')}
          value={
            <DownloadLink
              text={contact.pgpFileName || ''}
              to={`${process.env.REACT_APP_API_ROOT}${ORGANIZATIONS_URL}/files/${contact.pgpFileId}/download`}
            />
          }
        />
      )}
      <FullViewItem label={t('detail.labels.treemaId')} value={contact.treemaId} />
      <FullViewItem label={t('detail.labels.info')} value={contact.info} />
      {contact.businessLocations.length > 0 && (
        <FullViewItem
          label={t('detail.labels.address')}
          value={`${contact.businessLocations.map(
            address =>
              `${address.name}\n${address.street} ${contact.businessLocations[0].number}\n${contact.businessLocations[0].city}, ${contact.businessLocations[0].zipCode}\n${contact.businessLocations[0].country.name}`
          )}`}
        />
      )}
      {contact.availabilities.length > 0 && (
        <FullViewItem
          capitalize
          label={t('detail.labels.availabilities')}
          value={contact.availabilities.length > 0 ? <Availabilities availabilities={contact.availabilities} /> : null}
        />
      )}
    </FullViewSubBlock>
  );
};

const mobileStyling = css`
  margin: 0;
  transform: none;
  text-align: left;
  margin-bottom: 16px;
`;

export const Name = styled.h3`
  color: ${props => props.theme.fullView.color.primary};
  font-size: ${props => props.theme.fullView.fontSize.blockTitle};
  font-weight: 500;
  margin-bottom: 10px;
  margin-right: 320px;
  text-align: center;
  text-transform: uppercase;
  transform: translateX(50%);

  ${() => media.mobile`
    ${mobileStyling}
  `}

  ${() => media.tablet`
    ${mobileStyling}
  `}

  ${() => media.laptopSM`
    ${mobileStyling}
  `}

  ${() => media.desktop`
      margin-right: 350px;
  `}
`;

export default Contact;
