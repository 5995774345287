import { format, isToday, parse } from 'date-fns';

export const formatAsDate = (dateString?: string | Date): string => {
  if (!dateString) {
    return '';
  }

  return format(dateString, 'DD/MM/YYYY');
};

export const formatAsDayDate = (dateString?: string): string => {
  if (!dateString) {
    return '';
  }

  return format(dateString, 'ddd - DD/MM/YYYY');
};

export const formatAsDateTime = (dateString?: string | Date): string => {
  if (!dateString) {
    return '';
  }

  return format(dateString, 'DD/MM/YYYY - HH:mm:ss');
};

export const formatAsFullDateTime = (dateString?: string | Date): string => {
  if (!dateString) {
    return '';
  }

  return format(dateString, 'ddd - DD/MM/YYYY - HH:mm:ss');
};

export const getTime = (dateString?: string, short = false): string => {
  if (!dateString) {
    return '';
  }

  return short ? format(dateString, 'HH:mm') : format(dateString, 'HH:mm:ss');
};

export const getMonthAndDay = (dateString: string): string => {
  if (!dateString) {
    return '';
  }

  return format(dateString, 'MMM D');
};

export const recentToHourLaterToDate = (dateString: string): string => {
  if (!dateString) {
    return '';
  }

  if (isToday(dateString)) {
    return format(dateString, 'HH:mm');
  } else if (!isToday(dateString)) {
    return format(dateString, `D MMM 'YY`);
  } else {
    return '';
  }
};

export const toFullDate = (dateString: string): string => {
  if (!dateString) {
    return '';
  }
  return format(dateString, `D MMM 'YY`);
};

// Checks if date is in DD/MM/YYYY or DD-MM-YYYY format, does not check for leap years
const BASIC_DATE_REGEX = new RegExp(/^(0[1-9]|[12][0-9]|3[01])[/-](0[1-9]|1[012])[/-](\d{4})$/);

export const dateParser = (dateString: string): Date | undefined => {
  const match = dateString.match(BASIC_DATE_REGEX);
  if (match) {
    const [day, month, year] = match.slice(1);

    return parse(`${year}-${month}-${day}T11:00:00Z`);
  }

  return undefined;
};

export default { formatAsDate, formatAsFullDateTime, getTime, getMonthAndDay, formatAsDateTime, dateParser };
