import AxiosConfig, { RequestConfig } from '../../../config/api/AxiosConfig';
import { FEED_BASE_URL } from './endpoints';

export const getFeed = async (nextPageId?: string) => {
  const requestConfig: RequestConfig = {
    url: `${FEED_BASE_URL}/feed-content`,
    params: {
      continuation: nextPageId,
    },
  };

  return AxiosConfig.fetchAxiosGetRequest(requestConfig);
};
