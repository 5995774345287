import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const DeclinePerson: React.FunctionComponent = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.55 22.3C24.2705 22.3 27.3 19.4593 27.3 15.9707V13.3293C27.3 9.84072 24.2705 7 20.55 7C16.8295 7 13.8 9.84072 13.8 13.3293V16.0205C13.8531 19.4593 16.8827 22.3 20.55 22.3Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.3123 26.8169C25.8546 26.8743 25.4551 27.074 25.1559 27.351L21.514 31.4286C21.0142 31.9245 20.0858 31.9245 19.586 31.4286L15.9441 27.351C15.6584 27.0204 15.0871 26.8 14.5159 26.8C10.0884 26.8 6.1609 29.1694 5.23257 32.5306L3.09028 39.8041C2.59041 41.4571 4.23284 43 6.37513 43H34.7249C36.8672 43 38.5096 41.4571 38.0097 39.8041L35.8674 32.5306C35.659 31.83 35.3265 31.1724 34.8906 30.5705L33.0691 32.2399C32.6739 32.6021 32.0634 32.5883 31.6851 32.2086L26.3123 26.8169Z"
      />
      <path d="M44.8393 25.6585L39.874 20.5L44.8393 15.3415C45.0505 15.122 45.0505 14.7744 44.8393 14.5732L43.3427 13.0183L38.0077 18.561L32.6551 13L31.1585 14.5549C30.9472 14.7744 30.9472 15.122 31.1585 15.3232L36.1237 20.4817L31.1761 25.6585C30.9648 25.878 30.9648 26.2256 31.1761 26.4268L32.6727 27.9817L38.0077 22.439L43.3603 28L44.8569 26.4451C45.0505 26.2256 45.0505 25.878 44.8393 25.6585Z" />
    </svg>
  </BaseSvgWrapper>
);

export default DeclinePerson;
