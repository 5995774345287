import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const Cross: React.FunctionComponent = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg width="53" height="52" viewBox="0 0 53 52" xmlns="http://www.w3.org/2000/svg">
      <path d="M26.0008 0C11.6644 0 0 11.6636 0 26C0 40.3364 11.6644 52 26.0008 52C40.3372 52 52.0016 40.3364 52.0016 26C52.0016 11.6636 40.3372 0 26.0008 0ZM26.0008 48.8564C13.3973 48.8564 3.14361 38.6035 3.14361 26C3.14361 13.3965 13.3973 3.14361 26.0008 3.14361C38.6043 3.14361 48.858 13.3965 48.858 26C48.858 38.6035 38.6043 48.8564 26.0008 48.8564ZM34.2905 15.4878L26.0008 23.7775L17.7111 15.4878L15.4886 17.7103L23.7783 26L15.4886 34.2897L17.7111 36.5122L26.0008 28.2225L34.2905 36.5122L36.513 34.2897L28.2233 26L36.513 17.7103L34.2905 15.4878Z" />
    </svg>
  </BaseSvgWrapper>
);

export default Cross;
