import { ARRAY } from '../constants';
import { InputField } from '../types';

export const createInitialFormikValuesFromFields = (
  fields: InputField[],
  formFields: { [key: string]: any }
): { [key: string]: any } =>
  fields.reduce(
    (initialValues, field) => ({
      ...initialValues,
      [field.name]: getFormikValues(field, formFields),
    }),
    {}
  );

const getFormikValues = (field: InputField, values: { [key: string]: any }) => {
  if (values[field.name]) {
    return values[field.name].length === 0 && field.fields && field.initialValue !== null
      ? [createInitialFormikValuesFromFields(field.fields as InputField[], values)]
      : values[field.name];
  } else {
    return field.initialValue
      ? field.initialValue
      : field.type === ARRAY
      ? [createInitialFormikValuesFromFields(field.fields as InputField[], {})]
      : '';
  }
};
