import * as React from 'react';
import { Input } from '../';
import styled from '../../config/theme';
import { TYPE_CHECKBOX } from '../Form/Input';

type Props = {
  label: string;
  name: string;
  onChange: (newValue: boolean) => void;
  value?: boolean;
};

const FilterItem: React.FunctionComponent<Props> = ({ label, name, onChange, value }: Props) => {
  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => onChange(!value);

  return (
    <FilterItemWrapper>
      <Input type={TYPE_CHECKBOX} label={label} name={name} checked={value || false} onChange={changeHandler} />
    </FilterItemWrapper>
  );
};

const FilterItemWrapper = styled.div`
  position: relative;
  margin: 12px 0;
`;

export default FilterItem;
