import { InactiveUser, SendReminderValues } from 'modules/inactiveUsers/types';
import { HttpErrorData, HttpErrorResponse, HttpResponse, SuccessCallback } from 'types';
import { createAction, createAsyncAction, createCustomAction } from 'typesafe-actions';

export const getInactiveUsers = createAction('INACTIVE_USERS_GET');
export const getInactiveUsersRequest = createAsyncAction(
  'INACTIVE_USERS_REQUEST',
  'INACTIVE_USERS_SUCCESS',
  'INACTIVE_USERS_REJECTED'
)<undefined, HttpResponse<InactiveUser>, HttpErrorData>();

export const sendReminder = createCustomAction(
  'INACTIVE_USERS_SEND_REMINDER',
  (type) => (params: SendReminderValues, successCallback?: SuccessCallback) => ({
    payload: params,
    successCallback,
    type,
  })
);
export const sendReminderRequest = createAsyncAction(
  'INACTIVE_USERS_SEND_REMINDER_REQUEST',
  'INACTIVE_USERS_SEND_REMINDER_SUCCESS',
  'INACTIVE_USERS_SEND_REMINDER_REJECTED'
)<undefined, undefined, HttpErrorResponse>();
