import { EditOrganizationValues } from 'modules/organization/types/organization';
import { Contact, ContactInfoEditValues } from 'modules/organization/types/organizationDetail/contacts';
import { CTIData } from 'modules/organization/types/organizationDetail/cti';
import { GeneralInfo, GeneralInfoPostValues } from 'modules/organization/types/organizationDetail/general';
import {
  AddUserValues,
  DifferenceResponse,
  OrganizationChangeResponse,
  PrintValues,
} from 'modules/organization/types/organizationDetail/organizationDetail';
import { UpgradeUserRole, User } from 'modules/organization/types/organizationDetail/users';
import { HttpErrorResponse, SuccessCallback } from 'types';
import { createAction, createAsyncAction, createCustomAction } from 'typesafe-actions';

export const getGeneralInfo = createAction(
  'ORGANIZATION_DETAIL_GENERAL_INFO_GET',
  (action) => (id: string) => action(id)
);
export const getContacts = createAction('ORGANIZATION_DETAIL_CONTACTS_GET', (action) => (id: string) => action(id));
export const getCTIData = createAction('ORGANIZATION_DETAIL_CTI_GET', (action) => (id: string) => action(id));
export const getUsers = createAction('ORGANIZATION_DETAIL_USERS_GET', (action) => (id: string) => action(id));
export const getDifference = createAction('ORGANIZATION_DETAIL_DIFFERENCE', (action) => (id: string) => action(id));
export const addUser = createCustomAction(
  'ORGANIZATION_USER_ADD',
  (type) => (organizationId: string, values: AddUserValues, successCallback: SuccessCallback) => ({
    payload: { organizationId, values },
    successCallback,
    type,
  })
);
export const addAdmin = createCustomAction(
  'ORGANIZATION_USER_ADD_ADMIN',
  (type) => (organizationId: string, values: AddUserValues, successCallback: SuccessCallback) => ({
    payload: { organizationId, values },
    successCallback,
    type,
  })
);
export const approve = createAction(
  'ORGANIZATION_APPROVE',
  (action) => (organizationId: string) => action(organizationId)
);
export const reject = createCustomAction(
  'ORGANIZATION_REJECT',
  (type) => (organizationId: string, comment: string, successCallback: SuccessCallback) => ({
    payload: { organizationId, comment },
    successCallback,
    type,
  })
);
export const approveUser = createCustomAction(
  'ORGANIZATION_DETAIL_USER_APPROVE',
  (type) => (id: string, successCallback: SuccessCallback) => ({
    payload: { id },
    successCallback,
    type,
  })
);
export const rejectUser = createCustomAction(
  'ORGANIZATION_DETAIL_USER_REJECT',
  (type) => (id: string, comment: string, successCallback: SuccessCallback) => ({
    payload: { id, comment },
    successCallback,
    type,
  })
);
export const deleteUser = createCustomAction(
  'ORGANIZATION_DETAIL_USER_DELETE',
  (type) => (id: string, successCallback: SuccessCallback) => ({
    payload: { id },
    successCallback,
    type,
  })
);
export const updateGeneralInfo = createCustomAction(
  'ORGANIZATION_DETAIL_GENERAL_INFO_UPDATE',
  (type) => (id: string, values: GeneralInfoPostValues, successCallback: SuccessCallback) => ({
    payload: { id, values },
    successCallback,
    type,
  })
);
export const updateContacts = createCustomAction(
  'ORGANIZATION_DETAIL_CONTACTS_UPDATE',
  (type) => (id: string, values: ContactInfoEditValues, successCallback: SuccessCallback) => ({
    payload: { id, values },
    successCallback,
    type,
  })
);
export const updateCTIData = createCustomAction(
  'ORGANIZATION_DETAIL_CTI_UPDATE',
  (type) => (id: string, values: CTIData, successCallback: SuccessCallback) => ({
    payload: { id, values },
    successCallback,
    type,
  })
);

export const getPrintDetail = createCustomAction(
  'ORGANIZATION_DETAIL_PRINT',
  (type) => (values: PrintValues, successCallback?: SuccessCallback) => ({ type, payload: values, successCallback })
);

export const clearOrganizationDetail = createAction('ORGANIZATION_DETAIL_CLEARED');

export const updateOrganization = createCustomAction(
  'ORGANIZATION_UPDATE',
  (type) => (id: string, values: EditOrganizationValues, successCallback: SuccessCallback) => ({
    payload: { id, values },
    successCallback,
    type,
  })
);

export const resendInvitation = createAction(
  'ORGANIZATION_USER_INVITE_RESEND',
  (action) => (userId: string) => action(userId)
);

export const updateOrganizationRequest = createAsyncAction(
  'ORGANIZATION_UPDATE_REQUEST',
  'ORGANIZATION_UPDATE_SUCCESS',
  'ORGANIZATION_UPDATE_REJECTED'
)<undefined, GeneralInfo, HttpErrorResponse>();

export const deleteOrganization = createAction(
  'ORGANIZATION_DELETE',
  (action) => (organizationId: string) => action(organizationId)
);
export const deleteOrganizationRequest = createAsyncAction(
  'ORGANIZATION_DELETE_REQUEST',
  'ORGANIZATION_DELETE_SUCCESS',
  'ORGANIZATION_DELETE_REJECTED'
)<undefined, undefined, HttpErrorResponse>();

export const getGeneralInfoRequest = createAsyncAction(
  'ORGANIZATION_DETAIL_GENERAL_INFO_REQUEST',
  'ORGANIZATION_DETAIL_GENERAL_INFO_SUCCESS',
  'ORGANIZATION_DETAIL_GENERAL_INFO_REJECTED'
)<undefined, GeneralInfo, HttpErrorResponse>();

export const getContactsRequest = createAsyncAction(
  'ORGANIZATION_DETAIL_CONTACTS_REQUEST',
  'ORGANIZATION_DETAIL_CONTACTS_SUCCESS',
  'ORGANIZATION_DETAIL_CONTACTS_REJECTED'
)<undefined, Contact[], HttpErrorResponse>();

export const getCTIRequest = createAsyncAction(
  'ORGANIZATION_DETAIL_CTI_REQUEST',
  'ORGANIZATION_DETAIL_CTI_SUCCESS',
  'ORGANIZATION_DETAIL_CTI_REJECTED'
)<undefined, CTIData, HttpErrorResponse>();

export const getPrintDetailRequest = createAsyncAction(
  'ORGANIZATION_DETAIL_PRINT_REQUEST',
  'ORGANIZATION_DETAIL_PRINT_SUCCESS',
  'ORGANIZATION_DETAIL_PRINT_REJECTED'
)<undefined, undefined, HttpErrorResponse>();

export const getUsersRequest = createAsyncAction(
  'ORGANIZATION_DETAIL_USERS_REQUEST',
  'ORGANIZATION_DETAIL_USERS_SUCCESS',
  'ORGANIZATION_DETAIL_USERS_REJECTED'
)<undefined, User[], HttpErrorResponse>();

export const getDifferenceRequest = createAsyncAction(
  'ORGANIZATION_DETAIL_DIFFERENCE_REQUEST',
  'ORGANIZATION_DETAIL_DIFFERENCE_SUCCESS',
  'ORGANIZATION_DETAIL_DIFFERENCE_REJECTED'
)<undefined, DifferenceResponse, HttpErrorResponse>();

export const addUserRequest = createAsyncAction(
  'ORGANIZATION_USER_ADD_REQUEST',
  'ORGANIZATION_USER_ADD_SUCCESS',
  'ORGANIZATION_USER_ADD_REJECTED'
)<undefined, User, HttpErrorResponse>();

export const addAdminRequest = createAsyncAction(
  'ORGANIZATION_USER_ADD_ADMIN_REQUEST',
  'ORGANIZATION_USER_ADD_ADMIN_SUCCESS',
  'ORGANIZATION_USER_ADD_ADMIN_REJECTED'
)<undefined, User, HttpErrorResponse>();

export const approveRequest = createAsyncAction(
  'ORGANIZATION_APPROVE_REQUEST',
  'ORGANIZATION_APPROVE_SUCCESS',
  'ORGANIZATION_APPROVE_REJECTED'
)<undefined, GeneralInfo, HttpErrorResponse>();

export const rejectRequest = createAsyncAction(
  'ORGANIZATION_REJECT_REQUEST',
  'ORGANIZATION_REJECT_SUCCESS',
  'ORGANIZATION_REJECT_REJECTED'
)<undefined, GeneralInfo, HttpErrorResponse>();

export const userResetPassword = createAction(
  'ORGANIZATION_USER_RESET_PASSWORD',
  (action) => (guid: string) => action(guid)
);
export const userResetPasswordRequest = createAsyncAction(
  'ORGANIZATION_USER_RESET_PASSWORD_REQUEST',
  'ORGANIZATION_USER_RESET_PASSWORD_SUCCESS',
  'ORGANIZATION_USER_RESET_PASSWORD_REJECTED'
)<undefined, undefined, HttpErrorResponse>();

export const userResetAuthenticator = createAction(
  'ORGANIZATION_USER_RESET_AUTHENTICATOR',
  (action) => (guid: string) => action(guid)
);
export const userResetAuthenticatorRequest = createAsyncAction(
  'ORGANIZATION_USER_RESET_AUTHENTICATOR_REQUEST',
  'ORGANIZATION_USER_RESET_AUTHENTICATOR_SUCCESS',
  'ORGANIZATION_USER_RESET_AUTHENTICATOR_REJECTED'
)<undefined, undefined, HttpErrorResponse>();

export const updateGeneralInfoRequest = createAsyncAction(
  'ORGANIZATION_DETAIL_GENERAL_INFO_UPDATE_REQUEST',
  'ORGANIZATION_DETAIL_GENERAL_INFO_UPDATE_SUCCESS',
  'ORGANIZATION_DETAIL_GENERAL_INFO_UPDATE_REJECTED'
)<undefined, GeneralInfo, HttpErrorResponse>();

export const updateContactsRequest = createAsyncAction(
  'ORGANIZATION_DETAIL_CONTACTS_UPDATE_REQUEST',
  'ORGANIZATION_DETAIL_CONTACTS_UPDATE_SUCCESS',
  'ORGANIZATION_DETAIL_CONTACTS_UPDATE_REJECTED'
)<undefined, Contact[], HttpErrorResponse>();

export const updateCTIDataRequest = createAsyncAction(
  'ORGANIZATION_DETAIL_CTI_UPDATE_REQUEST',
  'ORGANIZATION_DETAIL_CTI_UPDATE_SUCCESS',
  'ORGANIZATION_DETAIL_CTI_UPDATE_REJECTED'
)<undefined, CTIData, HttpErrorResponse>();

export const approveUserRequest = createAsyncAction(
  'ORGANIZATION_DETAIL_USER_APPROVE_REQUEST',
  'ORGANIZATION_DETAIL_USER_APPROVE_SUCCESS',
  'ORGANIZATION_DETAIL_USER_APPROVE_REJECTED'
)<undefined, User, HttpErrorResponse>();

export const rejectUserRequest = createAsyncAction(
  'ORGANIZATION_DETAIL_USER_REJECT_REQUEST',
  'ORGANIZATION_DETAIL_USER_REJECT_SUCCESS',
  'ORGANIZATION_DETAIL_USER_REJECT_REJECTED'
)<undefined, User, HttpErrorResponse>();

export const deleteUserRequest = createAsyncAction(
  'ORGANIZATION_DETAIL_USER_DELETE_REQUEST',
  'ORGANIZATION_DETAIL_USER_DELETE_SUCCESS',
  'ORGANIZATION_DETAIL_USER_DELETE_REJECTED'
)<undefined, string, HttpErrorResponse>();

export const setUserToAdmin = createCustomAction('ORGANIZATION_DETAIL_USER_TOADMIN', (type) => (userId: string) => ({
  payload: { userId },
  type,
}));

export const setUserToAdminRequest = createAsyncAction(
  'ORGANIZATION_DETAIL_USER_TOADMIN_REQUEST',
  'ORGANIZATION_DETAIL_USER_TOADMIN_SUCCESS',
  'ORGANIZATION_DETAIL_USER_TOADMIN_REJECTED'
)<undefined, OrganizationChangeResponse, HttpErrorResponse>();

export const resendInvitationRequest = createAsyncAction(
  'ORGANIZATION_USER_INVITE_RESEND_REQUEST',
  'ORGANIZATION_USER_INVITE_RESEND_SUCCESS',
  'ORGANIZATION_USER_INVITE_RESEND_REJECTED'
)<undefined, undefined, HttpErrorResponse>();

export const upgradeUser = createAction(
  'UPGRADE_USER',
  (action) => (guid: string, role: UpgradeUserRole, successCallback?: SuccessCallback) =>
    action({ guid, role, successCallback })
);
export const upgradeUserRequest = createAsyncAction(
  'UPGRADE_USER_REQUEST',
  'UPGRADE_USER_SUCCESS',
  'UPGRADE_USER_REJECTED'
)<undefined, string, HttpErrorResponse>();
