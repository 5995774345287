import AxiosConfig, { RequestConfig } from '../../../config/api/AxiosConfig';
import { ADMIN_URL } from './endpoints';

export const getElegibleOrganizations = async () => {
  const requestConfig: RequestConfig = {
    url: `${ADMIN_URL}/superadmins/organizations/elegible`,
  };

  return AxiosConfig.fetchAxiosGetRequest(requestConfig);
};
