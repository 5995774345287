import {
  AddRepositoryValues,
  DeleteRepositoryValues,
  DownloadRepositoryValues,
  EditRepositoryValues,
  MarkRepositoryReadUnreadValues,
  ReportQuestionValues,
  Repository,
  ViewOrDownloadType,
} from 'modules/repository/types/repository';
import { HttpErrorData, HttpResponse, SuccessCallback } from 'types';
import { createAction, createAsyncAction, createCustomAction } from 'typesafe-actions';

export const getRepositories = createAction('REPOSITORIES_GET');
export const getRepositoriesRequest = createAsyncAction(
  'REPOSITORIES_REQUEST',
  'REPOSITORIES_SUCCESS',
  'REPOSITORIES_REJECTED'
)<undefined, HttpResponse<Repository>, HttpErrorData>();

export const addRepository = createCustomAction(
  'REPOSITORIES_ADD',
  (type) => (values: AddRepositoryValues, successCallback: SuccessCallback) => ({
    payload: values,
    successCallback,
    type,
  })
);
export const addRepositoryRequest = createAsyncAction(
  'REPOSITORIES_ADD_REQUEST',
  'REPOSITORIES_ADD_SUCCESS',
  'REPOSITORIES_ADD_REJECTED'
)<undefined, Repository, HttpErrorData>();

export const reportQuestion = createCustomAction(
  'REPOSITORIES_QUESTION',
  (type) => (values: ReportQuestionValues, successCallback: SuccessCallback) => ({
    payload: values,
    successCallback,
    type,
  })
);
export const reportQuestionRequest = createAsyncAction(
  'REPOSITORIES_QUESTION_REQUEST',
  'REPOSITORIES_QUESTION_SUCCESS',
  'REPOSITORIES_QUESTION_REJECTED'
)<undefined, undefined, HttpErrorData>();

export const editRepository = createCustomAction(
  'REPOSITORIES_EDIT',
  (type) => (values: EditRepositoryValues, successCallback: SuccessCallback) => ({
    payload: values,
    successCallback,
    type,
  })
);
export const editRepositoryRequest = createAsyncAction(
  'REPOSITORIES_EDIT_REQUEST',
  'REPOSITORIES_EDIT_SUCCESS',
  'REPOSITORIES_EDIT_REJECTED'
)<undefined, undefined, HttpErrorData>();

export const deleteRepository = createAction(
  'REPOSITORIES_DELETE',
  (action) => (values: DeleteRepositoryValues) => action(values)
);
export const deleteRepositoryRequest = createAsyncAction(
  'REPOSITORIES_DELETE_REQUEST',
  'REPOSITORIES_DELETE_SUCCESS',
  'REPOSITORIES_DELETE_REJECTED'
)<undefined, undefined, HttpErrorData>();

export const downloadRepository = createAction(
  'REPOSITORIES_DOWNLOAD',
  (action) => (values: DownloadRepositoryValues, type: ViewOrDownloadType) => action({ values, type })
);
export const downloadRepositoryRequest = createAsyncAction(
  'REPOSITORIES_DOWNLOAD_REQUEST',
  'REPOSITORIES_DOWNLOAD_SUCCESS',
  'REPOSITORIES_DOWNLOAD_REJECTED'
)<undefined, string, HttpErrorData>();

export const simpleDownloadRepository = createAction(
  'REPOSITORIES_SIMPLE_DOWNLOAD',
  (action) => (guid: string) => action(guid)
);
export const simpleDownloadRepositoryRequest = createAsyncAction(
  'REPOSITORIES_SIMPLE_DOWNLOAD_REQUEST',
  'REPOSITORIES_SIMPLE_DOWNLOAD_SUCCESS',
  'REPOSITORIES_SIMPLE_DOWNLOAD_REJECTED'
)<undefined, undefined, HttpErrorData>();

export const markRepositoryReadUnread = createAction(
  'REPOSITORIES_MARK_READ_UNREAD',
  (action) => (values: MarkRepositoryReadUnreadValues) => action(values)
);

export const markRepositoryReadUnreadRequest = createAsyncAction(
  'REPOSITORIES_MARK_READ_UNREAD_REQUEST',
  'REPOSITORIES_MARK_READ_UNREAD_SUCCESS',
  'REPOSITORIES_MARK_READ_UNREAD_REJECTED'
)<undefined, Repository, HttpErrorData>();
