export enum UserType {
  Admin = 'admin',
  Analyst = 'analyst',
  SuperAdmin = 'superAdmin',
  User = 'user',
}

export type GenericAccount = {
  email: string;
  guid: string;
  mfaIsActive?: boolean;
  name: string;
  organizationId: string;
  passwordIsSet?: boolean;
  userFirstName: string;
  userId: string;
  userLastName: string;
  userStatus: UserStatus;
  userType: UserType;
};

export type ResetPasswordValues = {
  userGuid: string;
};

export type AdminAccount = GenericAccount & {
  organizationGuid: string;
  organizationId: string;
  organizationName: string;
};

export type Account = GenericAccount | AdminAccount;

export type AccessRight = {
  accessCode: string;
  accessType: string;
  role: string;
  roleType: string;
  valueCode: string;
  valueType: string;
};

export enum UserStatus {
  Active = 'activated',
  Pending = 'pending',
  Suspended = 'suspended',
  Validating = 'waiting',
}

export type AccessRights = AccessRight[];
