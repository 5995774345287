import { isBefore } from 'date-fns';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getType } from 'typesafe-actions';
import { useActionIsPending } from '../../../../hooks';
import { useAccessRightsToFunctionality } from '../../../security';
import { MODULE_READ } from '../../../security/util';
import { FLASH_ALERTS_BACKEND_MODULE_KEY } from '../../constants';
import { Alert } from '../../types/alert';
import { alertActions, criticalAlertsActions } from '../creators';
import { makeGetCriticalAlerts } from '../selectors/alerts';

const useAlertsStore = () => {
  const dispatch = useDispatch();
  const unconfirmedCriticalAlerts = useSelector(makeGetCriticalAlerts());
  const sortedUnconfirmedCriticalAlerts = unconfirmedCriticalAlerts?.sort((a, b) =>
    isBefore(a.uploadDate, b.uploadDate) ? 1 : -1
  );
  const [isConfirming, startConfirming] = useActionIsPending(getType(criticalAlertsActions.confirmCriticalAlert));
  const [initialUnconfirmedCriticalAlertsLength, setInitialUnconfirmedCriticalAlertsLength] = React.useState<number>(0);
  const hasAccessToFlashAlerts = useAccessRightsToFunctionality(MODULE_READ, FLASH_ALERTS_BACKEND_MODULE_KEY);

  const getUnconfirmedAlerts = React.useCallback(() => {
    if (hasAccessToFlashAlerts) {
      dispatch(criticalAlertsActions.getUnconfirmedCriticalAlerts());
    }
  }, [dispatch, hasAccessToFlashAlerts]);

  const confirmCriticalAlert = React.useCallback(
    (guid: string) => {
      startConfirming();
      dispatch(criticalAlertsActions.confirmCriticalAlert(guid));
    },
    [dispatch, startConfirming]
  );

  const createAlert = React.useCallback(
    (alert: Alert) => {
      dispatch(alertActions.createAlert(alert));
    },
    [dispatch]
  );

  React.useEffect(() => {
    if (!unconfirmedCriticalAlerts) {
      getUnconfirmedAlerts();
    }
  }, [unconfirmedCriticalAlerts, getUnconfirmedAlerts]);

  React.useEffect(() => {
    if (unconfirmedCriticalAlerts && !initialUnconfirmedCriticalAlertsLength) {
      setInitialUnconfirmedCriticalAlertsLength(unconfirmedCriticalAlerts.length);
    }
  }, [unconfirmedCriticalAlerts, initialUnconfirmedCriticalAlertsLength]);

  return {
    getUnconfirmedAlerts,
    unconfirmedCriticalAlerts: sortedUnconfirmedCriticalAlerts,
    confirmCriticalAlert,
    initialUnconfirmedCriticalAlertsLength,
    createAlert,
    isConfirming,
  };
};

export default useAlertsStore;
