import { FieldProps, getIn } from 'formik';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FormikSelect } from '../../../../components/Form';
import { getNestedError } from '../../../../helpers/formikHelper';

type Props = {
  label: string;
} & FieldProps;

const IpVersionSelect: React.FunctionComponent<Props> = ({ field, form, label }: Props) => {
  const { t } = useTranslation('global');
  const ipVersions = [
    {
      label: t('ipVersions.ipv4'),
      value: 'ipv4',
    },
    {
      label: t('ipVersions.ipv6'),
      value: 'ipv6',
    },
  ];

  return (
    <FormikSelect
      field={field}
      form={form}
      label={label}
      value={getIn(form.values, field.name)}
      getOptionValue={option => option.value}
      getOptionLabel={option => option.label}
      error={getNestedError(field.name, form.errors, form.touched)}
      options={ipVersions}
    />
  );
};

export default IpVersionSelect;
