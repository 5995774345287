import { RootState } from 'config/store/rootReducer';
import { SuperUser, SuperUsersFilters } from 'modules/superUsers/types';
import { createSelector } from 'reselect';
import { PaginationInfo, SortValue } from 'types';

const getSuperUsers = (state: RootState): SuperUser[] | null => state.superUsers.superUsers;
export const makeGetSuperUsers = () => createSelector(getSuperUsers, (superUsers: SuperUser[] | null) => superUsers);

const getFilters = (state: RootState): SuperUsersFilters => state.superUsers.filters;
export const makeGetSuperUsersFilters = () => createSelector(getFilters, (filters: SuperUsersFilters) => filters);

const getPaginationInfo = (state: RootState): PaginationInfo => state.superUsers.pageInfo;
export const makeGetSuperUsersPageInfo = () =>
  createSelector(getPaginationInfo, (pageInfo: PaginationInfo) => pageInfo);

const getSortingInfo = (state: RootState): SortValue[] => state.superUsers.sorting;
export const makeGetSuperUsersSorting = () => createSelector(getSortingInfo, (sorting: SortValue[]) => sorting);
