import { IconButton } from 'components';
import { ArrayHelperActionWrapper } from 'components/FullView';
import { Add, Delete } from 'components/icons';
import styled from 'config/theme';
import { ArrayHelpers, Field, FieldProps } from 'formik';
import AvailabilityDaysSelect from 'modules/app/components/availabilities/AvailabilityDaysSelect';
import AvailabilityTimeSlider from 'modules/app/components/availabilities/AvailabilityTimeSlider';
import { AvailabilityEditValues } from 'modules/organization/types/organizationDetail/contacts';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  availability: AvailabilityEditValues;
  availabilityIndex: number;
  contactIndex: number;
  arrayHelpers: ArrayHelpers;
  disabledDays: string[];
};

const AvailabilityEdit: React.FunctionComponent<Props> = ({
  availability,
  availabilityIndex,
  arrayHelpers,
  contactIndex,
  disabledDays,
}: Props) => {
  const { t } = useTranslation('organization');

  const addAvailability = React.useCallback(() => arrayHelpers.push({ days: [], hours: [9, 17] }), [arrayHelpers]);
  const removeAvailability = React.useCallback(
    () => arrayHelpers.remove(availabilityIndex),
    [arrayHelpers, availabilityIndex]
  );

  return (
    <div>
      <ArrayHelperActionWrapper fixedButtonPosition>
        <Field
          name={`contacts.${contactIndex}.availabilities.${availabilityIndex}.days`}
          render={(fieldProps: FieldProps) => <AvailabilityDaysSelect {...fieldProps} disabledDays={disabledDays} />}
        />
        {availabilityIndex === 0 ? (
          <IconButton onClick={addAvailability} tooltip={t('tooltips.addAvailability')}>
            <Add />
          </IconButton>
        ) : (
          <IconButton onClick={removeAvailability} tooltip={t('tooltips.removeAvailability')}>
            <Delete />
          </IconButton>
        )}
      </ArrayHelperActionWrapper>

      <Field
        name={`contacts.${contactIndex}.availabilities.${availabilityIndex}.hours`}
        render={(fieldProps: FieldProps) => (
          <SliderWrapper>
            <AvailabilityTimeSlider {...fieldProps} />
          </SliderWrapper>
        )}
      />
    </div>
  );
};

const SliderWrapper = styled.div`
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

export default AvailabilityEdit;
