import React from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { AnimationProps } from '../../../../config/theme/types';
import { media } from '../../../../config/theme/utils';

type Props = {
  onClick?: () => void;
};

const ContactCardWrapper = styled('div')<Props & AnimationProps>`
  background: ${props => props.theme.palette.neutral.shade1};
  border-radius: 7px;
  box-shadow: 2px 6px 34px rgba(0, 0, 0, 0.08), 1px 3px 6px rgba(0, 0, 0, 0.04);
  display: inline-flex;
  flex: 0 0 auto;
  margin: 0 15px 15px 0;
  padding: 20px 20px;
  position: relative;
  transition: 0.3s box-shadow ease-in-out;
  width: 100%;

  .name-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 42px;
  }

  .action-menu {
    
    flex-shrink: 0;
  }

  &:hover {
    box-shadow: 2px 6px 34px rgba(0, 0, 0, 0.12), 1px 3px 6px rgba(0, 0, 0, 0.1);
    transition: 0.3s box-shadow ease-in-out;

    .action-menu {
      display: inline-flex;
    }
  }

  ${media.laptopLG`
    width: calc(50% - 15px);
  `}

  ${media.laptopSM`
    width: calc(100% - 15px);
  `}

  ${media.tablet`
    width: calc(100% - 15px);
  `}

  ${props => props.state === 'entering' && props.theme.paper.animation.entering};
  ${props => props.state === 'entered' && props.theme.paper.animation.entered};
`;

const TransitionedWrapper: React.FunctionComponent<Props> = ({ children, ...rest }) => (
  <CSSTransition in timeout={300} mountOnEnter appear>
    {state => (
      <ContactCardWrapper state={state} {...rest}>
        {children}
      </ContactCardWrapper>
    )}
  </CSSTransition>
);

export default TransitionedWrapper;
