import { TenantTheme } from '../tenants';

const mailTheme = (theme: TenantTheme) => ({
  content: {
    attachment: {
      background: {
        basic: theme.palette.neutral.shade0,
        normal: theme.palette.neutral.shade2,
      },
      borderRadius: theme.globals.borderRadiusSmall,
      color: {
        hover: theme.palette.primary.shade5,
        icon: theme.palette.neutral.shade4,
        normal: theme.palette.neutral.shade9,
      },
      fontSize: {
        icon: theme.typography.fontSize.regular,
        item: theme.typography.fontSize.regular,
      },
    },
    detail: {
      fontSize: {
        content: theme.typography.fontSize.regular,
        header: theme.typography.fontSize.medium_6,
      },
      fontWeight: {
        header: 600,
      },
    },
    header: {
      color: {
        mailAddress: theme.palette.primary.shade4,
        mailAddressHover: theme.palette.primary.shade5,
        main: theme.palette.neutral.shade4,
        timestamp: theme.palette.neutral.shade4,
      },
      fontWeight: 500,
    },
  },
});

export default mailTheme;
