import { HttpErrorResponse, SuccessCallback } from 'types';
import { createAction, createAsyncAction, createCustomAction } from 'typesafe-actions';
import { ContactFormType, FaqCategory, FaqsDetail } from '../../types/helpCenter';

export const submitContactForm = createCustomAction(
  'CONTACTFORM_SUBMIT',
  type => (values: ContactFormType, successCallback: SuccessCallback) => ({ payload: values, successCallback, type })
);
export const submitContactFormRequest = createAsyncAction(
  'CONTACTFORM_SUBMIT_REQUEST',
  'CONTACTFORM_SUBMIT_SUCCESS',
  'CONTACTFORM_SUBMIT_REJECTED'
)<undefined, undefined, HttpErrorResponse>();

export const getFaqCategories = createAction('FAQ_CATEGORIES_GET', action => () => action());
export const getFaqCategoriesRequest = createAsyncAction(
  'FAQ_CATEGORIES_REQUEST',
  'FAQ_CATEGORIES_SUCCESS',
  'FAQ_CATEGORIES_REJECTED'
)<undefined, FaqCategory[], HttpErrorResponse>();

export const getFaqCategoryDetail = createAction('FAQ_CATEGORY_DETAIL_GET', action => (slug: string) => action(slug));
export const getFaqCategoryDetailRequest = createAsyncAction(
  'FAQ_CATEGORY_DETAIL_REQUEST',
  'FAQ_CATEGORY_DETAIL_SUCCESS',
  'FAQ_CATEGORY_DETAIL_REJECTED'
)<undefined, FaqsDetail, HttpErrorResponse>();
