import { OrganizationSector, OrganizationStatus } from 'modules/organization/types/organization';
import { createAction } from 'typesafe-actions';

export const setSectorFilter = createAction(
  'ORGANIZATION_SET_FILTER_SECTOR',
  action => (sectors: OrganizationSector[]) => action(sectors)
);
export const setStatusFilter = createAction('ORGANIZATION_SET_FILTER_STATUS', action => (status: OrganizationStatus) =>
  action(status)
);
