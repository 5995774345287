import i18n from 'config/i18n';
import * as Yup from 'yup';
import { TYPE_TEXT } from '../../../../components/Form/Input';
import { InputField } from '../../types';

const tlsFields: InputField[] = [
  {
    label: 'tls.formLabels.email',
    name: 'tlsEmail',
    type: TYPE_TEXT,
    width: {
      desktop: 6,
    },
  },
  {
    label: 'tls.formLabels.phone',
    name: 'alertPhone',
    type: TYPE_TEXT,
    width: {
      desktop: 6,
    },
  },
];

const tlsValidationSchema = Yup.object().shape({
  alertPhone: Yup.string()
    .max(50)
    .matches(/^((\+[1-9]{2,3}[.\s]?){1}(\d{1,3}){1}(\s?\d{2,3}){2,4})?$/, () =>
      i18n.t('validation:string.phoneNumber')
    ),
  tlsEmail: Yup.string().email(() => i18n.t('validation:string.email')),
});

const headerTranslations = {
  content: 'tls.content',
  title: 'tls.title',
};

export const tlsStep = {
  fields: tlsFields,
  headerTranslations,
  key: 'tls',
  validationSchema: tlsValidationSchema,
};
