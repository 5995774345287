import { createSelector } from 'reselect';
import { RootState } from '../../../../config/store/rootReducer';
import { NestedGroup } from '../../../../helpers/groupBy';
import { AccessRight, Account, UserStatus } from '../../types/user';

const getIsAuthenticatedState = (state: RootState): boolean => state.security.user.isAuthenticated;
const getAccount = (state: RootState): Account | undefined => state.security.user.account;
const getAccessRights = (state: RootState): NestedGroup<AccessRight> | undefined => state.security.user.accessRights;

export const makeGetIsAuthenticated = () =>
  createSelector(getIsAuthenticatedState, (isAuthenticated: boolean) => isAuthenticated);

export const makeGetAccount = () => createSelector(getAccount, (account: Account | undefined) => account);

export const makeGetAccessRights = () =>
  createSelector(getAccessRights, (accessRights: NestedGroup<AccessRight> | undefined) => accessRights);

export const makeGetUserStatus = () =>
  createSelector(getAccount, (account: Account | undefined): UserStatus | undefined => account && account.userStatus);
export const makeGetUserType = () =>
  createSelector(getAccount, (account: Account | undefined): string | undefined => account && account.userType);
