import { UserType } from 'modules/security/types/user';

export type User = {
  department: string;
  email: string;
  firstName: string;
  guid: string;
  language: string;
  mfaIsActive: boolean;
  name: string;
  organizationId: string;
  passwordIsSet: boolean;
  status: UserStatus;
  type: UserType;
  userId: string;
};

export enum UserStatus {
  Activated = 'activated',
  Pending = 'pending',
  Suspended = 'suspended',
  Upgraded = 'upgraded',
  Waiting = 'waiting',
}

export enum UpgradeUserRole {
  Analyst = 'analyst',
  SuperAdmin = 'superAdmin',
}

export type UserUpgradeFormikValues = {
  role: UpgradeUserRole | undefined;
};
