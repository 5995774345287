import { SearchAction, searchActions } from 'modules/app/redux/creators';
import { ORGANIZATION_FILTER_KEY } from 'modules/organization/constants';
import { OrganizationFilters } from 'modules/organization/types/organization';
import { getType } from 'typesafe-actions';
import { FilterActions, filterActions } from '../../actions/creators';

const filterReducer = (state: OrganizationFilters = {}, action: SearchAction | FilterActions) => {
  switch (action.type) {
    case getType(searchActions.setSearch):
      return action.payload.filterKey === ORGANIZATION_FILTER_KEY
        ? { ...state, search: action.payload.searchText }
        : state;

    case getType(searchActions.clearSearch):
      const newState = { ...state };
      delete newState.search;

      return newState;

    case getType(filterActions.setSectorFilter):
      const isEmpty = action.payload.length === 0;
      if (isEmpty) {
        const copy = { ...state };
        delete copy.sector;

        return copy;
      } else {
        return { ...state, sector: action.payload };
      }

    case getType(filterActions.setStatusFilter):
      const isActive = state.status && state.status.includes(action.payload);
      const newStatuses = state.status
        ? isActive
          ? state.status.filter(status => status !== action.payload)
          : [...state.status, action.payload]
        : [action.payload];

      return { ...state, status: newStatuses };

    default:
      return state;
  }
};

export default filterReducer;
