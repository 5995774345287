import { FieldProps, FormikValues, getIn } from 'formik';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from '../../../../components/Form';
import { ErrorText } from '../../../../components/Form/ErrorText';
import { StyledSelect, SelectWrapper } from '../../../../components/Form/FormikSelect';
import { Label } from '../../../../components/Form/Label';

type Props = {
  label?: string;
} & FieldProps &
  FormikValues;

type Option = {
  label: string;
  value: string;
};

const createOption = (label: string): Option => ({
  label,
  value: label,
});

const CreatableSelect: React.FunctionComponent<Props> = ({ field, form, label }) => {
  const [inputValue, setInputValue] = React.useState('');
  const formValue = getIn(form.values, field.name);
  const value = formValue ? formValue.map((val: string) => createOption(val)) : [];
  const { t } = useTranslation('onboarding');

  const handleKeyDown = React.useCallback(
    (event: any) => {
      if (!inputValue || formValue.includes(inputValue)) {
        return;
      }
      switch (event.key) {
        case 'Enter':
        case 'Tab':
          const options = [...value, createOption(inputValue)];
          event.preventDefault();
          form.setFieldValue(field.name, options.length > 0 ? options.map((option) => option.value) : []);
          setInputValue('');
      }
    },
    [field, form, formValue, value, inputValue]
  );

  const handleChange = React.useCallback(
    (changeValues: any) => {
      form.setFieldValue(field.name, changeValues ? changeValues.map((val: Option) => val.value) : []);
    },
    [form, field]
  );

  const onBlur = React.useCallback(() => {
    if (!inputValue || formValue.includes(inputValue) || inputValue === '') {
      return;
    }
    const options = [...value, createOption(inputValue)];
    form.setFieldValue(field.name, options.length > 0 ? options.map((option) => option.value) : []);
    setInputValue('');
  }, [field, form, inputValue, value, formValue]);

  return (
    <SelectWrapper>
      {label && <Label htmlFor={field.name}>{t(label)}</Label>}
      <StyledSelect>
        <Select
          isMulti={true}
          creatable={true}
          isClearable
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          onInputChange={(text: string) => setInputValue(text)}
          onBlur={onBlur}
          field={field}
          form={form}
          inputValue={inputValue}
          menuIsOpen={false}
          components={{
            DropdownIndicator: null,
          }}
          placeholder={t('step.creatable_select')}
          error={getIn(form.errors, field.name)}
          value={value ? value : []}
        />
      </StyledSelect>
      {getIn(form.errors, field.name) && <ErrorText>{getIn(form.errors, field.name)}</ErrorText>}
    </SelectWrapper>
  );
};

export default CreatableSelect;
