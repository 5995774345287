import styled from 'config/theme';
import * as React from 'react';
import { FullViewBlockItem } from './FullViewElement';
import { Label, Value } from './ItemComponents';

type Props = {
  label: string;
  value: JSX.Element;
  positionLabelTop?: boolean;
};

const EditableItem: React.FunctionComponent<Props> = ({ label, value, positionLabelTop }: Props) => {
  return (
    <Wrapper key={label}>
      <Label bold htmlFor={label}>
        {label}:
      </Label>
      <Value>{value}</Value>
    </Wrapper>
  );
};

const Wrapper = styled(FullViewBlockItem)`
  ${props => !props.center && `> label { margin-top: 10px; };`};
`;

export default EditableItem;
