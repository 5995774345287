import * as React from 'react';
import { Translation } from 'react-i18next';
import Button from '../Button/Button';
import { ModalContent } from './index';

type Props = {
  closeModal: (e: React.MouseEvent) => void;
  handleConfirmNavigationClick: (e: React.MouseEvent) => void;
};

const UnsavedChangesModal = ({ closeModal, handleConfirmNavigationClick }: Props) => (
  <Translation ns="global">
    {t => (
      <ModalContent>
        <ModalContent.Header>
          <h1>{t('unsavedChanges.title')}</h1>
        </ModalContent.Header>
        <ModalContent.Body>{t('unsavedChanges.description')}</ModalContent.Body>
        <ModalContent.Footer>
          <Button tertiary onClick={closeModal}>
            {t('unsavedChanges.cancel')}
          </Button>
          <Button onClick={handleConfirmNavigationClick} danger type="submit">
            {t('unsavedChanges.leavePage')}
          </Button>
        </ModalContent.Footer>
      </ModalContent>
    )}
  </Translation>
);

export default UnsavedChangesModal;
