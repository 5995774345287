import { call, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import modulesApi from '../../api/ModulesApi';
import { Modules } from '../../types/modules';
import { moduleActions } from '../actions/creators';

function* getAvailableModulesSaga() {
  yield takeLatest(getType(moduleActions.getAvailableModules), fetchAvailableModules);
}

function* fetchAvailableModules() {
  const { request, success, failure } = moduleActions.fetchAvailableModules;
  yield put(request());
  try {
    const payload: Modules = yield call(modulesApi.getModules);
    yield put(success(payload));
  } catch (e) {
    yield put(failure(e.response));
  }
}

export default getAvailableModulesSaga;
