import * as Yup from 'yup';
import { InputField } from '../../types';
import { ARRAY } from '../index';
import { contactHoursStep } from './contactHours';
import { contactStep } from './howToReach';
import { profileStep } from './profile';

export const contactFields: InputField[] = [
  {
    addLabel: 'Add another contact',
    name: 'contacts',
    steps: [profileStep, contactStep, contactHoursStep],
    type: 'array',
    width: {
      desktop: 12,
    },
  },
];
const validation = Yup.object().shape({
  contacts: Yup.array(),
});

export const headerTranslations = {
  content: 'contacts.content',
  title: 'contacts.title',
};

export const contactsStep = {
  fields: contactFields,
  headerTranslations,
  key: 'contacts',
  type: ARRAY,
  validationSchema: validation,
};
