import { createAction } from 'typesafe-actions';
import { SubmitOnBoardingParam } from '../../types';

type SetFormFields = {
  fields: { [key: string]: any };
  step: string | undefined;
  submitParam?: SubmitOnBoardingParam;
  stepSubIndex?: string;
};

export const setMobileOnBoardingFormFields = createAction(
  'ONBOARDING_SET_MOBILE_FORM_FIELDS',
  action => (fields: SetFormFields) => action(fields)
);

export const setDesktopOnBoardingFormFields = createAction(
  'ONBOARDING_SET_DESKTOP_FORM_FIELDS',
  action => (fields: SetFormFields) => action(fields)
);
