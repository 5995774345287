import * as Yup from 'yup';
import CerticatesUpload from '../../../app/components/CertificatesUpload';
import { InputField } from '../../types';
import { CUSTOM } from '../index';

const certificatesFields: InputField[] = [
  {
    component: CerticatesUpload,
    label: 'certificates.formLabels.certificate',
    name: 'certificates',
    type: CUSTOM,
    width: {
      desktop: 12,
    },
  },
];

const certificatesValidationSchema = Yup.object().shape({
  certificates: Yup.array().of(Yup.mixed()),
});

const headerTranslations = {
  content: 'certificates.content',
  title: 'certificates.title',
};

export const certificatesStep = {
  fields: certificatesFields,
  headerTranslations,
  key: 'certificates',
  validationSchema: certificatesValidationSchema,
};
