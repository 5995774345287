import { IsOpenProps, MobileToggle } from 'components/Sidebar';
import { media } from 'config/theme/utils';
import { responsive } from 'helpers';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { CSSTransition } from 'react-transition-group';
import styled from '../../config/theme';
import { AnimationProps } from '../../config/theme/types';

const ItemSidebarWrapper = styled.div<AnimationProps>`
  background-color: ${props => props.theme.itemSidebar.background.main};
  border-right: ${props => props.theme.itemSidebar.border.right};
  display: flex;
  flex-direction: column;
  font-size: 10px;
  max-height: 100vh;
  min-width: ${props => props.theme.sidebar.width.large};
  overflow: auto;
  position: relative;
  width: ${props => props.theme.sidebar.width.large};
  flex-grow: 1;

  ${props => media.desktop`
    width: ${props.theme.sidebar.width.huge};
    min-width: ${props.theme.sidebar.width.huge};
  `};

  /* Scrollbar */
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background: ${props => props.theme.scrollbar.track};
  }
  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.scrollbar.thumb};
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${props => props.theme.scrollbar.thumbHover};
  }
  ::-webkit-scrollbar-button {
    display: ${props => props.theme.scrollbar.buttonDisplay};
  }

  > span.count {
    position: fixed;
    top: calc(${props => props.theme.header.height} - ${props => props.theme.itemSidebar.fontSize.count} + 1px);
    padding: 0px 8px;
    border-radius: ${props => props.theme.itemSidebar.border.radiusCount};
    margin-left: 7px;
    font-size: ${props => props.theme.itemSidebar.fontSize.count};
    color: ${props => props.theme.itemSidebar.color.count};
    background: ${props => props.theme.itemSidebar.background.count};
  }

  .infinite-scroll-component {
    > * {
      flex-shrink: 0;
    }

    > *:not(:last-child) {
      border-bottom: ${props => props.theme.itemSidebar.border.itemBottom};
    }
  }

  .search {
    position: sticky;
    top: 0;
  }

  .loading-text {
    color: ${props => props.theme.itemSidebar.color.loading};
    font-weight: 200;
    text-align: center;
  }

  ${props => props.state === 'entering' && props.theme.itemSidebar.animation.entering};
  ${props => props.state === 'entered' && props.theme.itemSidebar.animation.entered};
`;

const MobileItemSidebarWrapper = styled(ItemSidebarWrapper)<IsOpenProps>`
  position: absolute;
  width: ${props => props.theme.sidebar.mobile.width};
  top: 0;
  left: ${props => (props.isOpen ? '0' : '-100%')};
  bottom: ${props => props.theme.sidebar.mobile.heightSmall};
  transition: left 0.2s cubic-bezier(0.67, 0.49, 0.15, 0.82);
  z-index: 100;
`;

const TransitionedWrapper: React.FunctionComponent<{ id: string } & React.RefAttributes<
  HTMLDivElement
>> = React.forwardRef(({ children, ...rest }, ref) => {
  const isPhone = useMediaQuery(responsive.isPhone);

  return (
    <CSSTransition in timeout={200} mountOnEnter appear>
      {state =>
        isPhone ? (
          <MobileToggle state={state} forItemBar>
            {isOpen => (
              <MobileItemSidebarWrapper isOpen={isOpen} state={state} {...rest} ref={ref}>
                {children}
              </MobileItemSidebarWrapper>
            )}
          </MobileToggle>
        ) : (
          <ItemSidebarWrapper state={state} {...rest} ref={ref}>
            {children}
          </ItemSidebarWrapper>
        )
      }
    </CSSTransition>
  );
});

export default TransitionedWrapper;
