import { getType } from 'typesafe-actions';
import { ActivitiesActions, activitiesActions } from '../creators';
import { Activity } from '../../types';

const ActivitiesReducer = (state: Activity[] | null = null, action: ActivitiesActions) => {
  switch (action.type) {
    case getType(activitiesActions.getActivitiesRequest.success):
      return action.payload.items;

    case getType(activitiesActions.clearActivities):
      return null;

    default:
      return state;
  }
};

export default ActivitiesReducer;
