import * as React from 'react';

type EditableModulesContext = {
  hasEditFunctionality: (module: string) => boolean;
};

export const EditableModulesContext = React.createContext<EditableModulesContext>({
  hasEditFunctionality: (module: string) => false,
});

type ModuleEditAccessContext = {
  canEdit: boolean;
  canEditOnModule: (module: string) => boolean;
};

export const ModuleEditAccessContext = React.createContext<ModuleEditAccessContext>({
  canEdit: false,
  canEditOnModule: (module: string) => false,
});

type Props = {
  module: string;
  children: JSX.Element;
};

export const ModuleEditAccessProvider: React.FunctionComponent<Props> = ({ module, children }) => (
  <EditableModulesContext.Consumer>
    {({ hasEditFunctionality }) => (
      <ModuleEditAccessContext.Provider
        value={{
          canEdit: hasEditFunctionality(module),
          canEditOnModule: hasEditFunctionality,
        }}
      >
        {children}
      </ModuleEditAccessContext.Provider>
    )}
  </EditableModulesContext.Consumer>
);
