import { AddOrganizationFormValues, Organization } from 'modules/organization/types/organization';
import { HttpErrorResponse, HttpResponse, SuccessCallback } from 'types';
import { createAction, createAsyncAction, createCustomAction } from 'typesafe-actions';

export const getOrganizations = createAction('ORGANIZATIONS_GET');
export const getOrganizationsRequest = createAsyncAction(
  'ORGANIZATIONS_REQUEST',
  'ORGANIZATIONS_SUCCESS',
  'ORGANIZATIONS_REJECTED'
)<undefined, HttpResponse<Organization>, HttpErrorResponse>();

export const addOrganization = createCustomAction(
  'ORGANIZATION_ADD',
  (type) => (values: AddOrganizationFormValues, successCallback: SuccessCallback) => ({
    payload: values,
    successCallback,
    type,
  })
);
export const addOrganizationRequest = createAsyncAction(
  'ORGANIZATION_ADD_REQUEST',
  'ORGANIZATION_ADD_SUCCESS',
  'ORGANIZATION_ADD_REJECTED'
)<undefined, Organization | null, HttpErrorResponse>();
