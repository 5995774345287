import { createAction, createAsyncAction, createCustomAction } from 'typesafe-actions';
import { HttpErrorData, SuccessCallback } from '../../../../types';
import { CriticalAlert } from '../../types/criticalAlert';

export const getUnconfirmedCriticalAlerts = createAction('UNCONFIRMED_CRITICAL_ALERTS_GET');
export const getUnconfirmedCriticalAlertsRequest = createAsyncAction(
  'UNCONFIRMED_CRITICAL_ALERTS_REQUEST',
  'UNCONFIRMED_CRITICAL_ALERTS_SUCCESS',
  'UNCONFIRMED_CRITICAL_ALERTS_REJECTED'
)<undefined, CriticalAlert[], HttpErrorData>();

export const getCriticalAlert = createAction('CRITICAL_ALERT_GET');
export const getCriticalAlertRequest = createAsyncAction(
  'CRITICAL_ALERT_REQUEST',
  'CRITICAL_ALERT_SUCCESS',
  'CRITICAL_ALERT_REJECTED'
)<undefined, CriticalAlert, HttpErrorData>();

export const confirmCriticalAlert = createCustomAction(
  'CRITICAL_ALERT_CONFIRM_POST',
  type => (guid: string, successCallback?: SuccessCallback) => ({
    payload: guid,
    successCallback,
    type,
  })
);
export const confirmCriticalAlertRequest = createAsyncAction(
  'CRITICAL_ALERT_CONFIRM_POST_REQUEST',
  'CRITICAL_ALERT_CONFIRM_POST_SUCCESS',
  'CRITICAL_ALERT_CONFIRM_POST_REJECTED'
)<undefined, string, HttpErrorData>();
