import { combineReducers } from 'redux';
import { PaginationInfo, SortValue } from '../../../../types';
import { ActivitiesFilters, Activity } from '../../types';
import activitiesReducer from './activities';
import pageInfoReducer from './pageInfo';
import sortingReducer from './sorting';
import filterReducer from './filters';

export type ActivitiesModuleState = {
  activities: Activity[] | null;
  filters: ActivitiesFilters;
  pageInfo: PaginationInfo;
  sorting: SortValue[];
};

const activitiesModuleReducer = combineReducers({
  activities: activitiesReducer,
  filters: filterReducer,
  pageInfo: pageInfoReducer,
  sorting: sortingReducer,
});

export default activitiesModuleReducer;
