import { GeneralInfo } from './general';
import { User } from './users';

export enum OrganizationDetailView {
  GENERAL_INFO,
  CONTACTS,
  CTI,
  USERS,
}

export type AddUserValues = {
  email: string;
};

export type RejectFormValues = {
  comment: string;
};

export type PrintValues = {
  organizationIds: string[];
  fileName: string;
};

export type OrganizationChangeResponse = {
  users: User[];
} & GeneralInfo;

export type DifferenceResponse = {
  differenceGroups: DifferenceGroup[];
};

export type DifferenceGroup = {
  action: 'create' | 'update' | 'delete';
  name: string;
  type: string;
  differences: any;
};

export type Difference = {
  changeType: string;
  newValue: string;
  oldValue: string;
};
