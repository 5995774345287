import { getType } from 'typesafe-actions';
import { mapAvailabilitiesToFrontendValues } from '../../../app/components/availabilities/formatAvailabilityHelper';
import { DARK_WEB, EMAIL_DOMAIN, initialAvailabilities, MISP_EMAIL_DOMAIN, MISP_SECTOR, TAG } from '../../constants';
import { desktopSteps } from '../../constants/steps';
import { Contact } from '../../types/onboarding';
import { formActions, FormActions, OnBoardingActions, onBoardingActions } from '../actions';

export type OnboardingState = {
  formFields: {
    [key: string]: any;
  };
  stepValidation: {
    [key: string]: {
      [key: string]: boolean;
    };
  };
};

const initialState = {
  formFields: {},
  stepValidation: desktopSteps.reduce(
    (desktopStep: { [key: string]: any }, mobileStep, index) => ({
      ...desktopStep,
      [index]: mobileStep.steps.reduce(
        (mobileStepsKeys: { [key: string]: boolean }, step) => ({
          ...mobileStepsKeys,
          [step.key]: false,
        }),
        {}
      ),
    }),
    {}
  ),
};

const onboardingReducer = (state: OnboardingState = initialState, action: FormActions | OnBoardingActions) => {
  switch (action.type) {
    case getType(formActions.setMobileOnBoardingFormFields):
      return {
        ...state,
        formFields: {
          ...state.formFields,
          ...action.payload.fields,
        },
        stepValidation:
          action.payload.step !== undefined
            ? Object.keys(state.stepValidation).reduce(
                (result, key) => ({
                  ...result,
                  [key]: {
                    ...result[key],
                    [action.payload.step as string]: true,
                  },
                }),
                state.stepValidation
              )
            : state.stepValidation,
      };

    case getType(formActions.setDesktopOnBoardingFormFields):
      return {
        ...state,
        formFields: {
          ...state.formFields,
          ...action.payload.fields,
        },
        stepValidation: {
          ...state.stepValidation,
          [action.payload.step as string]: Object.keys(state.stepValidation[action.payload.step as string]).reduce(
            (result, key) => ({ ...result, [key]: true }),
            {}
          ),
        },
      };

    case getType(onBoardingActions.getGeneralDataRequest.success):
    case getType(onBoardingActions.postGeneralDataRequest.success):
      const { businessLocations, ipRanges, name, mispOrganizationGuid, mispToMisp, accountNeeded, ...generalData } =
        action.payload;

      return {
        ...state,
        formFields: {
          ...generalData,
          ...state.formFields,
          businessLocations: businessLocations.map((location) => ({
            ...location,
            countryId: location.country.countryId,
          })),
          generalIpRanges: ipRanges.filter((item) => item.rangeType === 'whiteList'),
          publicIpRanges: ipRanges.filter((item) => item.rangeType === 'publicList'),
          mispLogoFile: {
            id: action.payload.mispLogoFileId,
            name: action.payload.mispLogoFileName,
          },
          mispPocPgpFile: {
            id: action.payload.mispPocPgpFileId,
            name: action.payload.mispPocPgpFileName,
          },
          mispToMisp: mispToMisp === null ? 'null' : mispToMisp !== undefined ? mispToMisp.toString() : null,
          accountNeeded: accountNeeded !== undefined ? accountNeeded.toString() : null,
          mispOrganizationGuid: accountNeeded ? mispOrganizationGuid : undefined,
          sector: action.payload.keywords
            .filter((keyword) => keyword.type === MISP_SECTOR)
            .map((keyword) => keyword.name),
          domainRestrictions: action.payload.keywords
            .filter((keyword) => keyword.type === MISP_EMAIL_DOMAIN)
            .map((keyword) => keyword.name),
        },
      };
    case getType(onBoardingActions.getContactsRequest.success):
      return {
        ...state,
        formFields: {
          contacts: action.payload.map((contact: Contact) => ({
            ...contact,
            availabilities:
              mapAvailabilitiesToFrontendValues(contact.availabilities).length !== 0
                ? mapAvailabilitiesToFrontendValues(contact.availabilities)
                : initialAvailabilities, // ToDo check if there isn't a better option with back-end
            businessLocationIds: contact.businessLocations.map((businessLocation) =>
              businessLocation.addressId.toString()
            ),
            pgpKey: {
              id: contact.pgpFileId,
              name: contact.pgpFileName,
            },
            phoneBusiness: contact.phoneBusiness,
            phoneMobile: contact.phoneMobile,
          })),
          ...state.formFields,
        },
      };

    case getType(onBoardingActions.getCtiDataRequest.success):
      return {
        ...state,
        formFields: {
          ...state.formFields,
          ...action.payload,
          certificates: action.payload.certificates,
          darkWebSearch: action.payload.keywords
            .filter((keyword) => keyword.type === DARK_WEB)
            .map((keyword) => keyword.name),
          emailDomains: action.payload.keywords
            .filter((keyword) => keyword.type === EMAIL_DOMAIN)
            .map((keyword) => keyword.name),
          ipRanges: action.payload.ipRanges,
          tags: action.payload.keywords.filter((keyword) => keyword.type === TAG).map((keyword) => keyword.name),
        },
      };
    case getType(onBoardingActions.setUserData):
      return {
        ...state,
        formFields: {
          ...state.formFields,
          firstName: action.payload.userFirstName,
          name: action.payload.userLastName,
        },
      };
    default:
      return state;
  }
};

export default onboardingReducer;
