import { combineReducers } from 'redux';
import { PaginationInfo, SortValue } from 'types';
import superUsersReducer, { SuperUsersModuleState } from './superUsers';
import filterReducer from './filters';
import pageInfoReducer from './pageInfo';
import sortingReducer from './sorting';
import { SuperUsersFilters } from 'modules/superUsers/types';

export type SuperUsersState = {
  filters: SuperUsersFilters;
  pageInfo: PaginationInfo;
  superUsers: SuperUsersModuleState;
  sorting: SortValue[];
};

const superUsersModuleReducer = combineReducers({
  filters: filterReducer,
  pageInfo: pageInfoReducer,
  superUsers: superUsersReducer,
  sorting: sortingReducer,
});

export default superUsersModuleReducer;
