import i18n from 'config/i18n';
import * as Yup from 'yup';
import CountrySelect from '../../../../components/Select/CountrySelect';
import { InputField } from '../../types';
import { CUSTOM } from '../index';

const addressesFields: InputField[] = [
  {
    addLabel: 'Add another organization location',
    fields: [
      {
        label: 'addresses.formLabels.name',
        name: 'name',
        placeholder: 'addresses.formPlaceholders.name',
        type: 'text',
        width: {
          desktop: 12,
        },
      },
      {
        label: 'addresses.formLabels.street',
        name: 'street',
        type: 'text',
        width: {
          desktop: 4,
          tablet: 6,
        },
      },
      {
        label: 'addresses.formLabels.number',
        name: 'number',
        type: 'text',
        width: {
          desktop: 4,
          tablet: 6,
        },
      },
      {
        label: 'addresses.formLabels.zipCode',
        name: 'zipCode',
        type: 'text',
        width: {
          desktop: 4,
          tablet: 6,
        },
      },
      {
        label: 'addresses.formLabels.city',
        name: 'city',
        type: 'text',
        width: {
          desktop: 4,
          tablet: 6,
        },
      },
      {
        label: 'addresses.formLabels.state',
        name: 'state',
        type: 'text',
        width: {
          desktop: 4,
          tablet: 6,
        },
      },
      {
        component: CountrySelect,
        label: 'addresses.formLabels.country',
        name: 'countryId',
        type: CUSTOM,
        width: {
          desktop: 4,
          tablet: 6,
        },
      },
    ],
    name: 'businessLocations',
    type: 'array',
    width: {
      desktop: 12,
    },
  },
];

const addressesValidationSchema = Yup.object().shape({
  businessLocations: Yup.array()
    .of(
      Yup.object().shape({
        city: Yup.string()
          .required(() => i18n.t('validation:required'))
          .max(200)
          .trim(),
        countryId: Yup.string().required(() => i18n.t('validation:required')),
        name: Yup.string()
          .required(() => i18n.t('validation:required'))
          .max(100)
          .trim(),
        number: Yup.string()
          .required(() => i18n.t('validation:required'))
          .trim()
          .max(20),
        state: Yup.string().max(200),
        street: Yup.string()
          .required(() => i18n.t('validation:required'))
          .max(200)
          .trim(),
        zipCode: Yup.string()
          .required(() => i18n.t('validation:required'))
          .max(20)
          .trim(),
      })
    )
    .min(1, ({ min }) => i18n.t('validation:array.min', { min })),
});

const headerTranslations = {
  content: 'addresses.content',
  title: 'addresses.title',
};

export const addressesStep = {
  fields: addressesFields,
  headerTranslations,
  key: 'businessLocations',
  validationSchema: addressesValidationSchema,
};
