import { EmptyStateWrapper } from 'components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {};

const UsersEmptyState: React.FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation('organization');

  return (
    <EmptyStateWrapper>
      <p>{t('detail.usersEmptyState')}</p>
    </EmptyStateWrapper>
  );
};

export default UsersEmptyState;
