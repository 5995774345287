import { PaginationInfo } from 'types';
import { getType } from 'typesafe-actions';
import { DEFAULT_PAGEINFO } from '../../../../constants';
import { PageInfoActions, pageInfoActions, SectorActions, sectorActions } from '../actions/creators';

const pageInfoReducer = (state: PaginationInfo = DEFAULT_PAGEINFO, action: PageInfoActions | SectorActions) => {
  switch (action.type) {
    case getType(sectorActions.getSectorsRequest.success):
      const { items, ...rest } = action.payload;

      return { ...state, ...rest };
    case getType(pageInfoActions.setPage):
      return { ...state, pageIndex: action.payload };

    case getType(pageInfoActions.setPageSize):
      return { ...state, pageSize: action.payload };

    default:
      return state;
  }
};

export default pageInfoReducer;
