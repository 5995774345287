import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getType } from 'typesafe-actions';
import ContactInfoEdit from '../../../../organization/components/detail/content/contacts/ContactInfoEdit';
import {
  ORGANIZATION_DETAIL_CONTACTS_ACTION_PREFIX,
  ORGANIZATION_DETAIL_GENERAL_INFO_ACTION_PREFIX,
  organizationDetailActions,
} from '../../../../organization/redux/actions/creators';
import { makeGetContacts, makeGetGeneralInfo } from '../../../../organization/redux/selectors/organizationDetail';
import { UpdateStep } from '../../../../organization/types/organization';
import { makeActionRequestIsPendingSelector, makeIsPendingSelector } from '../../../redux/selectors/loading';
import ModalContainer from './ModalContainer';

type Props = {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setUpdateStep: React.Dispatch<React.SetStateAction<UpdateStep>>;
  organizationId: string;
};
const ContactsStep = ({ setUpdateStep, setShowModal, organizationId }: Props) => {
  const { t } = useTranslation('organization');

  const dispatch = useDispatch();
  const formRef = useRef<any>(null);

  const generalInfo = useSelector(makeGetGeneralInfo());
  const contacts = useSelector(makeGetContacts());

  const generalInfoPending = useSelector(makeIsPendingSelector(ORGANIZATION_DETAIL_GENERAL_INFO_ACTION_PREFIX));
  const contactsPending = useSelector(makeIsPendingSelector(ORGANIZATION_DETAIL_CONTACTS_ACTION_PREFIX));
  const isUpdatingContacts = useSelector(
    makeActionRequestIsPendingSelector(getType(organizationDetailActions.updateContacts))
  );

  useEffect(() => {
    if (!generalInfo) {
      dispatch(organizationDetailActions.getGeneralInfo(organizationId));
    }
  }, [organizationId, dispatch, generalInfo]);

  useEffect(() => {
    if (!contacts) {
      dispatch(organizationDetailActions.getContacts(organizationId));
    }
  }, [organizationId, dispatch, contacts]);

  const handleConfirm = useCallback(() => {
    if (formRef && formRef.current && formRef.current.submitForm) {
      formRef.current.submitForm();
    }
  }, [formRef]);

  const handlePostPone = () => {
    setShowModal(false);
  };

  const handleGoPreviousStep = () => {
    setUpdateStep(UpdateStep.needsUpdate);
  };

  const ModalBody = () =>
    generalInfoPending || contactsPending ? (
      <div>{t('updateModal.loading')}</div>
    ) : (
      contacts && (
        <ContactInfoEdit
          formRef={formRef}
          organizationId={organizationId}
          contacts={contacts}
          businessLocations={generalInfo ? generalInfo.businessLocations : []}
          successCallback={() => setUpdateStep(UpdateStep.contactsCompleted)}
          setHasChanges={() => {}}
          setEditMode={() => {}}
          hasChanges={true}
        />
      )
    );

  return (
    contacts && (
      <ModalContainer
        modalBody={<ModalBody />}
        modaltitle={t('updateModal.contact.title')}
        primaryBtnHandler={handleConfirm}
        primaryBtnText={t('updateModal.contact.approve')}
        primaryBtnLoading={isUpdatingContacts}
        secondaryBtnHandler={handlePostPone}
        secondaryBtnText={t('updateModal.postpone')}
        tertiaryBtnHandler={handleGoPreviousStep}
        tertiaryBtnText={t('updateModal.backToGeneralData')}
      />
    )
  );
};
export default ContactsStep;
