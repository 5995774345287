import React from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from '../../../config/theme';
import { AnimationProps } from '../../../config/theme/types';
import { media } from '../../../config/theme/utils';

const Wrapper = styled.section<AnimationProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  flex-grow: 1;

  > h1 {
    color: ${props => props.theme.palette.neutral.shade7};
    font-size: ${props => props.theme.typography.fontSize.medium_6};
    font-weight: 500;
    letter-spacing: initial;

    ${props => media.mobile`
      font-size: ${props.theme.typography.fontSize.small_4};
    `};

    ${props => media.tablet`
      font-size: ${props.theme.typography.fontSize.regular};
    `};

    ${props => media.laptopSM`
      font-size: ${props.theme.typography.fontSize.medium_2};
    `};

    ${props => media.laptopLG`
      font-size: ${props.theme.typography.fontSize.medium_5};
    `};
  }

  > p {
    color: ${props => props.theme.palette.neutral.shade5};
    font-size: ${props => props.theme.typography.fontSize.regular};
    text-align: center;
    max-width: 450px;

    ${props => media.mobile`
      font-size: ${props.theme.typography.fontSize.small_4};
      max-width: 200px;
    `};

    ${props => media.tablet`
      font-size: ${props.theme.typography.fontSize.small_5};
      max-width: 300px;
    `};

    ${props => media.laptopSM`
      font-size: ${props.theme.typography.fontSize.small_5};
      max-width: 380px;
    `};

    ${props => media.laptopLG`
      font-size: ${props.theme.typography.fontSize.regular};
      max-width: 400px;
    `};
  }

  > span > svg {
    width: 100px;
    height: 100px;
    fill: ${props => props.theme.palette.neutral.shade3};

    ${() => media.mobile`
    width: 60px;
    height: 60px;
    `};

    ${() => media.tablet`
    width: 70px;
    height: 70px;
    `};

    ${() => media.laptopSM`
    width: 80px;
    height: 80px;
    `};

    ${() => media.laptopLG`
    width: 100px;
    height: 100px;
    `};
  }

  button {
    margin: 16px auto 0;
  }

  ${props => props.state === 'entering' && 'opacity: 0.01;'};
  ${props => props.state === 'entered' && 'opacity: 1; transition: opacity 150ms 50ms;'};
`;

const TransitionedWrapper: React.FunctionComponent = ({ children, ...rest }) => (
  <CSSTransition in timeout={150} mountOnEnter appear>
    {state => (
      <Wrapper state={state} {...rest}>
        {children}
      </Wrapper>
    )}
  </CSSTransition>
);

export default TransitionedWrapper;
