import { FlexRow, IconButton, Input, Modal, Row, TableData, TableRow, TextWrap } from 'components';
import { TYPE_CHECKBOX } from 'components/Form/Input';
import { ModalSizes } from 'components/Modal/Modal';
import { Download, Edit, MarkEmailRead, MarkEmailUnread, Question, Visibility } from 'components/icons';
import { toFullDate } from 'helpers/dateFormatter';
import { handleTableRowClick } from 'helpers/eventHandler';
import { Repository } from 'modules/repository/types/repository';
import { ModuleEditAccessContext } from 'modules/security';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getType } from 'typesafe-actions';
import { useActionIsPending } from '../../../../hooks';
import { repositoryActions } from '../../redux/creators';
import { downloadRepository } from '../../redux/creators/repository';
import useRepositoryGeneralStore from '../../redux/services/userRepositoryGeneralStore';
import { REPOSITORY_BASE_ROUTE } from '../../routes';
import EditRepositoryModal from '../edit/EditRepositoryModal';
import PriorityBadge from './PriorityBadge';
import * as S from './styles';
import QuestionModal from './QuestionModal';

type Props = {
  isPending?: boolean;
  repository: Repository;
  selected: boolean;
  toggleIsSelected: (id: string | number) => void;
};

const RepositoryRow: React.FunctionComponent<Props> = ({
  isPending,
  repository,
  selected,
  toggleIsSelected,
}: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation(['repository', 'global']);

  const moduleAccess = React.useContext(ModuleEditAccessContext);

  const [isDownloading, startDownloading] = useActionIsPending(getType(downloadRepository));
  const { markRepositoryReadUnread } = useRepositoryGeneralStore();

  const { audience, description, reportId, reportType, tlpCode, sectors, guid, priority, fileName, read } = repository;

  const handleDownload = React.useCallback(() => {
    startDownloading();
    dispatch(repositoryActions.downloadRepository({ guid, fileName }, 'download'));
  }, [dispatch, guid, fileName, startDownloading]);

  const checkBoxClickHandler = React.useCallback(() => {
    toggleIsSelected(reportId);
  }, [reportId, toggleIsSelected]);

  const handleGoToReport = React.useCallback(() => history.push(`${REPOSITORY_BASE_ROUTE}/${guid}`), [guid, history]);

  const handleGoToDetail = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      handleTableRowClick(e, () => handleGoToReport(), true);
    },
    [handleGoToReport]
  );

  const handleMarkReadUnread = () => {
    markRepositoryReadUnread({ id: reportId, read: !repository.read });
  };

  return (
    <TableRow isPending={isPending} active={selected} onClick={handleGoToDetail}>
      <TableData>
        <Input type={TYPE_CHECKBOX} name={reportId.toString()} onChange={checkBoxClickHandler} checked={selected} />
      </TableData>
      <TableData>{!read && <S.ReadUnreadIndicator />}</TableData>
      <TableData>
        <Row>
          <TextWrap bold={!read} variant={!read ? 'primary' : undefined}>
            {description}
          </TextWrap>
        </Row>
        <Row className="color-secondary">
          <TextWrap
            list={sectors.map((sector) => sector.name)}
            listCount={sectors.length > 1 ? sectors.length : undefined}
          >
            {sectors.map((sector) => sector.name).join(', ')}
          </TextWrap>
        </Row>
      </TableData>
      <TableData>
        <TextWrap>{reportType}</TextWrap>
      </TableData>
      <TableData>
        <Row>
          <TextWrap capitalize>{audience}</TextWrap>
        </Row>
        <Row className="color-secondary">
          <TextWrap capitalize>{t(`global:tlpCode.${tlpCode.toLowerCase()}`)}</TextWrap>
        </Row>
      </TableData>
      <TableData>
        <PriorityBadge priority={priority}>{priority}</PriorityBadge>
      </TableData>
      <TableData>
        <TextWrap>{toFullDate(repository.uploadDate)}</TextWrap>
      </TableData>
      <TableData>
        <TextWrap>{toFullDate(repository.reportDate)}</TextWrap>
      </TableData>
      <TableData className="actions">
        <FlexRow>
          <IconButton
            disabled={isDownloading}
            isLoading={isDownloading}
            onClick={handleDownload}
            tooltip={t('actions.download')}
          >
            <Download />
          </IconButton>
          <IconButton onClick={handleGoToReport} tooltip={t('actions.viewPdf')}>
            <Visibility active={false} />
          </IconButton>
          <IconButton
            onClick={handleMarkReadUnread}
            tooltip={t(repository.read ? 'actions.markUnread' : 'actions.markRead')}
          >
            {repository.read ? <MarkEmailUnread /> : <MarkEmailRead />}
          </IconButton>
          {moduleAccess.canEdit && (
            <React.Fragment>
              <Modal size={ModalSizes.Medium} contentProps={repository} content={EditRepositoryModal}>
                {(toggleModal) => (
                  <IconButton tooltip={t('global:general.edit')} onClick={toggleModal}>
                    <Edit />
                  </IconButton>
                )}
              </Modal>
            </React.Fragment>
          )}
          <Modal contentProps={{ reportId: guid, reportTitle: description }} content={QuestionModal}>
            {(toggleModal) => (
              <IconButton tooltip={t('repository:question.tooltip')} onClick={toggleModal}>
                <Question />
              </IconButton>
            )}
          </Modal>
        </FlexRow>
      </TableData>
    </TableRow>
  );
};

export default RepositoryRow;
