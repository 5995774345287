import { getType } from 'typesafe-actions';
import { FeedResponse } from '../../types';
import { FeedActions, feedActions } from '../creators';

export type FeedsModuleState = FeedResponse | null;

const feedsReducer = (state: FeedsModuleState = null, action: FeedActions) => {
  switch (action.type) {
    case getType(feedActions.getFeedRequest.success):
      return action.payload;
    default:
      return state;
  }
};

export default feedsReducer;
