import { ActionType } from 'typesafe-actions';
import * as pageInfoAction from './pageInfo';
import * as sectorAction from './sector';
import * as sortingAction from './sorting';

export const SECTOR_ACTION_PREFIX = 'SECTORS';

export const sectorActions = { ...sectorAction };
export const pageInfoActions = { ...pageInfoAction };
export const sortingActions = { ...sortingAction };

export type SectorActions = ActionType<typeof sectorAction>;
export type PageInfoActions = ActionType<typeof pageInfoAction>;
export type SortingActions = ActionType<typeof sortingAction>;
