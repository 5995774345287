import { getType } from 'typesafe-actions';
import { Alert } from '../../types/alertRules';
import { AlertRuleActions, alertRulesActions } from '../creators';

const alertRulesReducer = (state: Alert | null = null, action: AlertRuleActions) => {
  switch (action.type) {
    case getType(alertRulesActions.getAlertsRequest.success):
    case getType(alertRulesActions.toggleAlertsRequest.success):
      return action.payload;

    case getType(alertRulesActions.addAlertRuleRequest.success):
      return state && { ...state, rules: [...state.rules, action.payload] };

    case getType(alertRulesActions.editAlertRuleRequest.success):
      return (
        state && {
          ...state,
          rules: state.rules.map(rule => (rule.alertRuleId === action.payload.alertRuleId ? action.payload : rule)),
        }
      );

    case getType(alertRulesActions.deleteAlertRuleRequest.success):
    default:
      return state;
  }
};

export default alertRulesReducer;
