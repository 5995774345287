import * as React from 'react';
import { Module } from '../../modules/security/types/modules';

type Props = {
  allowedModules: Module[];
  moduleKey: string;
  children: JSX.Element;
};

const ModuleRoutes: React.FunctionComponent<Props> = ({ allowedModules, moduleKey, children }: Props) => {
  const lookForKeyInModule = React.useCallback(
    (module: Module): boolean => {
      if (module.key.toLocaleLowerCase() === moduleKey.toLocaleLowerCase()) {
        return true;
      } else if (module.subModules) {
        return module.subModules.some(lookForKeyInModule);
      }

      return false;
    },
    [moduleKey]
  );

  const isModuleAvailable = React.useMemo(() => allowedModules.some(lookForKeyInModule), [
    allowedModules,
    lookForKeyInModule,
  ]);

  return isModuleAvailable ? children : null;
};

export default ModuleRoutes;
