import AxiosConfig, { RequestConfig } from '../../../config/api/AxiosConfig';
import { PaginationInfo } from '../../../types';
import { EditReportTypeValues, ReportTypefilters } from '../types/reportTypes';
import { REPORTTYPES_URL } from './endpoints';

const getReportTypes = async (pageInfo: PaginationInfo, filters?: ReportTypefilters, sorting?: string[]) => {
  const params = {
    pageIndex: pageInfo.pageIndex,
    pageSize: pageInfo.pageSize,
    ...(filters && { ...(filters.search && { search: filters.search }) }),
    ...(sorting && { orderBys: sorting }),
  };

  const requestConfig: RequestConfig = {
    params,
    url: REPORTTYPES_URL,
  };

  return AxiosConfig.fetchAxiosPagedGetRequest(requestConfig);
};

const addReportType = (name: string) => {
  const requestConfig: RequestConfig = {
    data: {
      name,
    },
    url: REPORTTYPES_URL,
  };

  return AxiosConfig.fetchAxiosPostRequest(requestConfig);
};

const editReportType = (reportType: EditReportTypeValues) => {
  const { reportTypeId, name } = reportType;
  const requestConfig: RequestConfig = {
    data: {
      name,
      reportTypeId,
    },
    url: `${REPORTTYPES_URL}/${reportTypeId}`,
  };

  return AxiosConfig.fetchAxiosPutRequest(requestConfig);
};

const deleteReportType = (id: string) => {
  const requestConfig: RequestConfig = {
    params: {
      id,
    },
    url: `${REPORTTYPES_URL}/${id}`,
  };

  return AxiosConfig.fetchAxiosDeleteRequest(requestConfig);
};

export default { addReportType, getReportTypes, deleteReportType, editReportType };
