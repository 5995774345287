import { Flexer } from '@unbrace/components';
import { Button, Input, ModalContent, SubmitButton } from 'components';
import useRepositoryGeneralStore from 'modules/repository/redux/services/userRepositoryGeneralStore';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { SuccessCallback } from '../../../../types';
import useAlertsStore from '../../../app/redux/services/useAlertsStore';
import { AlertType } from '../../../app/types/alert';
import { criticalAlertsActions } from '../../../flashAlerts/redux/creators';
import { repositoryActions } from '../../redux/creators';
import { AddRepositoryValues, hasEveryAddRepositoryValues } from '../../types/repository';
import AddRepositoryForm from './AddRepositoryForm';

type Props = {
  closeModal: () => void;
};

const AddRepositoryModal: React.FunctionComponent<Props> = ({ closeModal }: Props) => {
  const { t } = useTranslation(['global', 'repository']);
  const dispatch = useDispatch();
  const formRef: React.RefObject<any> = React.useRef(null);
  const [sendMail, setSendMail] = React.useState(true);
  const [formEdited, setFormEdited] = React.useState(false);
  const { isAdding, startAdding } = useRepositoryGeneralStore();
  const { createAlert } = useAlertsStore();

  const handleFormChange = React.useCallback(() => setFormEdited(true), []);

  const handleCloseModal = React.useCallback(() => {
    setFormEdited(false);
    closeModal();
  }, [setFormEdited, closeModal]);

  const handleCancelClick = React.useCallback(() => {
    formRef.current.resetForm();
    handleCloseModal();
  }, [formRef, handleCloseModal]);

  const handleSubmitClick = React.useCallback(() => {
    formRef.current.handleSubmit();
  }, [formRef]);

  const handleAddCriticalAlert = React.useCallback(
    (values: Required<AddRepositoryValues>, cb: SuccessCallback) => {
      const { addCriticalAlert, criticalAlertDescription, reportDate, report, file, ...restValues } = values;
      dispatch(
        criticalAlertsActions.addCriticalAlert(
          {
            ...restValues,
            description: values.criticalAlertDescription,
            criticalAlertDate: values.reportDate as Date,
            sectors: values.sectors.map((sector) => sector.sectorId),
            reportTypeId: values.report.reportTypeId,
          },
          cb
        )
      );
    },
    [dispatch]
  );

  const handleSubmit = React.useCallback(
    (values: AddRepositoryValues) => {
      if (values && values.report) {
        startAdding();

        dispatch(
          repositoryActions.addRepository({ ...values, reportTypeId: values.report.reportTypeId, sendMail }, () => {
            if (values.addCriticalAlert && hasEveryAddRepositoryValues(values)) {
              handleAddCriticalAlert(values, () => {
                handleCloseModal();
                createAlert({ message: t('alerts.success'), type: AlertType.Success });
              });
            } else {
              handleCloseModal();
              createAlert({ message: t('alerts.success'), type: AlertType.Success });
            }
          })
        );
      }
    },
    [dispatch, startAdding, handleAddCriticalAlert, handleCloseModal, createAlert, t, sendMail]
  );

  return (
    <ModalContent>
      <ModalContent.Header>
        <h1>{t('repository:add.title')}</h1>
        <h5>{t('repository:add.description')}</h5>
      </ModalContent.Header>
      <ModalContent.Body>
        <AddRepositoryForm onSubmit={handleSubmit} formRef={formRef} onFormChange={handleFormChange} />
      </ModalContent.Body>
      <ModalContent.Footer>
        <FooterWrapper>
          <Input
            name="sendMail"
            label={t('repository:add.sendMail')}
            type={'checkbox'}
            checked={sendMail}
            onChange={() => setSendMail(!sendMail)}
          />
          <Flexer>
            <Button disabled={isAdding} onClick={handleCancelClick} tertiary>
              {t('general.close')}
            </Button>
            <SubmitButton isLoading={isAdding} onClick={handleSubmitClick} primary type="submit" disabled={!formEdited}>
              {t('general.add')}
            </SubmitButton>
          </Flexer>
        </FooterWrapper>
      </ModalContent.Footer>
    </ModalContent>
  );
};

export default AddRepositoryModal;

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;
`;
