import { Slider as MaterialSlider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { theme } from 'config/theme';
import * as React from 'react';

const Slider = (props: any) => <MaterialSlider {...props} ThumbComponent={SliderThumb} />;

const SliderWrapper = withStyles({
  active: {},
  rail: {
    color: '#d8d8d8',
    height: 3,
    opacity: 1,
  },
  root: {
    color: theme.default.palette.primary.shade5,
    height: 3,
  },
  thumb: {
    '& .bars': {
      '& .bar': {
        backgroundColor: 'currentColor',
        display: 'inline-block !important',
        height: 9,
        marginLeft: 1,
        marginRight: 1,

        width: 1,
      },
    },
    '& .tooltip': {
      border: '1px solid currentColor',
      borderRadius: 7,
      display: 'block',
      padding: '2px 7px',
      position: 'absolute',
      top: -40,
    },
    '&:focus,&:hover,&$active': {
      boxShadow: '#ccc 0px 2px 3px 1px',
    },
    backgroundColor: '#fff',
    border: '1px solid currentColor',
    boxShadow: '#ebebeb 0px 2px 2px',
    height: 27,
    marginLeft: -13,
    marginTop: -12,
    width: 27,
  },
  track: {
    height: 3,
  },
})(Slider);

const SliderThumb = (props: {}) => (
  <span {...props}>
    <span className="tooltip">{props['aria-valuetext']}</span>
    <span className="bars">
      <span className="bar" />
      <span className="bar" />
      <span className="bar" />
    </span>
  </span>
);

export default SliderWrapper;
