import { SearchAction, searchActions } from 'modules/app/redux/creators';
import { getType } from 'typesafe-actions';
import { REPORTTYPE_FILTER_KEY } from '../../constants';
import { ReportTypefilters } from '../../types/reportTypes';

const filterReducer = (state: ReportTypefilters = {}, action: SearchAction) => {
  switch (action.type) {
    case getType(searchActions.setSearch):
      return action.payload.filterKey === REPORTTYPE_FILTER_KEY
        ? { ...state, search: action.payload.searchText }
        : state;

    case getType(searchActions.clearSearch):
      const newState = { ...state };
      delete newState.search;

      return newState;

    default:
      return state;
  }
};

export default filterReducer;
