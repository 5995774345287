import * as React from 'react';
import { Translation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { LogoutManager, SecurityTypes, useAccountInfo } from '..';
import { ContextMenu, IconButton } from '../../../components';
import { ORIENTATION_TOP_RIGHT } from '../../../components/ContextMenu/constants';
import { AccountCircle } from '../../../components/icons';
import { userActions } from '../redux/actions/creators';
import ProfileMenuContent from './ProfileMenuContent';

type Props = {
  isMobile?: boolean;
};

const ProfileMenu: React.FunctionComponent<Props> = ({ isMobile }) => {
  const dispatch = useDispatch();
  const accountInfo = useAccountInfo();
  const resetPassword = () => {
    if (accountInfo) {
      dispatch(userActions.resetPassword());
    }
  };

  return (
    <Translation ns="security">
      {t => (
        <LogoutManager>
          {({ handleLogout }: SecurityTypes) => (
            <ContextMenu
              content={
                <ProfileMenuContent
                  userName={accountInfo && accountInfo.name}
                  handleResetPassword={resetPassword}
                  handleLogout={handleLogout}
                />
              }
              orientation={ORIENTATION_TOP_RIGHT}
              enhanceWithOnContext={false}
            >
              {({ toggleMenu }) =>
                isMobile ? (
                  <a href="/" onClick={toggleMenu}>
                    <AccountCircle />
                    <span>{t('profile.tooltip')}</span>
                  </a>
                ) : (
                  <IconButton tooltip={t('profile.tooltip')} tooltipPositionUp onClick={toggleMenu}>
                    <AccountCircle />
                  </IconButton>
                )
              }
            </ContextMenu>
          )}
        </LogoutManager>
      )}
    </Translation>
  );
};

export default ProfileMenu;
