export type Alert = {
  message: string;
  type: AlertType;
};

export type AlertWithIdentifier = {
  message: string;
  id: string;
  type: AlertType;
  noAutoClose?: boolean;
};

export enum AlertType {
  Warning,
  Danger,
  Success,
  Default,
}
