import { call, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import accountApi from '../../api/AccountApi';
import identityApi from '../../api/IdentityApi';
import { AccessRights, Account } from '../../types/user';
import { userAccessRightsActions, userActions } from '../actions/creators';

function* userSaga() {
  yield takeLatest(getType(userActions.getUser), fetchUser);
  yield takeLatest(getType(userActions.resetPassword), handleResetPassword);
}

export function* fetchUser() {
  yield put(userActions.fetchUser.request());
  try {
    const accountPayload: Account = yield call(accountApi.getAccount);
    if (accountPayload) {
      yield put(userActions.fetchUser.success(accountPayload));
      yield call(fetchUserAccessRights);
    } else {
      yield put(
        userActions.fetchUser.failure({
          data: { statusCode: 400, isSuccesful: false, errorMessage: 'Could not get user' },
          status: 400,
        })
      );
    }
  } catch (e) {
    yield put(userActions.fetchUser.failure(e.response));
  }
}

function* fetchUserAccessRights() {
  yield put(userAccessRightsActions.fetchUserAccessRights.request());
  try {
    const accessRightsPayload: AccessRights = yield call(accountApi.getAccessRights);
    yield put(userAccessRightsActions.fetchUserAccessRights.success(accessRightsPayload));
  } catch (e) {
    yield put(userAccessRightsActions.fetchUserAccessRights.failure(e.response));
  }
}

function* handleResetPassword(action: ReturnType<typeof userActions.resetPassword>) {
  const { request, success, failure } = userActions.resetPasswordRequest;
  yield put(request());
  try {
    yield call(identityApi.resetAccountPassword);
    yield put(success());
  } catch (e) {
    yield put(failure(e.response));
  }
}

export default userSaga;
