import { AddSectorValues, DeleteSectorValues, EditSectorValues, Sector } from 'modules/sector/types/sector';
import { HttpErrorData, HttpErrorResponse, HttpResponse, SuccessCallback } from 'types';
import { createAction, createAsyncAction, createCustomAction } from 'typesafe-actions';

export const getSectors = createAction('SECTORS_GET');
export const getSectorsRequest = createAsyncAction('SECTORS_REQUEST', 'SECTORS_SUCCESS', 'SECTORS_REJECTED')<
  undefined,
  HttpResponse<Sector>,
  HttpErrorData
>();

export const addSector = createCustomAction(
  'SECTOR_ADD',
  type => (values: AddSectorValues, successCallback: SuccessCallback) => ({ type, payload: values, successCallback })
);
export const addSectorRequest = createAsyncAction('SECTOR_ADD_REQUEST', 'SECTOR_ADD_SUCCESS', 'SECTOR_ADD_REJECTED')<
  undefined,
  Sector,
  HttpErrorResponse
>();

export const editSector = createCustomAction(
  'SECTOR_EDIT',
  type => (values: EditSectorValues, successCallback: SuccessCallback) => ({ type, payload: values, successCallback })
);
export const editSectorRequest = createAsyncAction(
  'SECTOR_EDIT_REQUEST',
  'SECTOR_EDIT_SUCCESS',
  'SECTOR_EDIT_REJECTED'
)<undefined, undefined, HttpErrorResponse>();

export const deleteSector = createAction('SECTOR_DELETE', action => (values: DeleteSectorValues) => action(values));
export const deleteSectorRequest = createAsyncAction(
  'SECTOR_DELETE_REQUEST',
  'SECTOR_DELETE_SUCCESS',
  'SECTOR_DELETE_REJECTED'
)<undefined, undefined, HttpErrorResponse>();
