import { SearchAction, searchActions } from 'modules/app/redux/creators';
import { INACTIVE_USERS_FILTER_KEY } from 'modules/inactiveUsers/constants';
import { InactiveUsersFilters } from 'modules/inactiveUsers/types';
import { getType } from 'typesafe-actions';
import { FilterActions, filterActions } from '../creators';

const filterReducer = (
  state: InactiveUsersFilters = { lastActiveDate: undefined, status: [] },
  action: SearchAction | FilterActions
) => {
  const stateCopy = { ...state };

  switch (action.type) {
    case getType(searchActions.setSearch):
      return action.payload.filterKey === INACTIVE_USERS_FILTER_KEY
        ? { ...state, search: action.payload.searchText }
        : state;

    case getType(searchActions.clearSearch):
      delete stateCopy.search;

      return stateCopy;

    case getType(filterActions.setLastActiveDate):
      return { ...state, lastActiveDate: action.payload };

    case getType(filterActions.setStatusFilter):
      const isActive = state.status && state.status.includes(action.payload);
      const newStatuses = state.status
        ? isActive
          ? state.status.filter((status) => status !== action.payload)
          : [...state.status, action.payload]
        : [action.payload];

      return { ...state, status: newStatuses };

    default:
      return state;
  }
};

export default filterReducer;
