import { SortState } from 'components/Table/SortToggle';
import { SortValue } from 'types';

export const formatSortingForApi = (sortData: SortValue[]): string[] => {
  return sortData
    .sort((a, b) => customSortBecauseTypescriptSucks(a.secondary, b.secondary))
    .map(s => {
      if (s.state === SortState.Asc) {
        return s.name.toLowerCase();
      }
      if (s.state === SortState.Desc) {
        return `${s.name.toLowerCase()}_desc`;
      }

      return '';
    })
    .filter(s => s !== '');
};

const customSortBecauseTypescriptSucks = (a?: boolean, b?: boolean): number => {
  if (!b && a) {
    return 1;
  }
  if (b && !a) {
    return -1;
  }

  return 0;
};
