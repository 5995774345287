import identityApi from './api/IdentityApi';
import { AccessRightsRoute, ProfileMenu } from './components';
import { IdentityAccessRights } from './constants';
import { SecureModules } from './containers';
import { ModuleEditAccessContext, ModuleEditAccessProvider } from './context/EditableModules';
import { useAccessRightsToFunctionality, useAccountInfo } from './hooks';
import { LogoutManager } from './services';
import { LogoutManagerRenderProps } from './services/LogoutManager';

export {
  AccessRightsRoute,
  LogoutManager,
  ModuleEditAccessContext,
  ModuleEditAccessProvider,
  ProfileMenu,
  SecureModules,
  useAccessRightsToFunctionality,
  useAccountInfo,
  identityApi,
  IdentityAccessRights,
};

export type SecurityTypes = LogoutManagerRenderProps;
