import { FullViewBlock, FullViewItem, FullViewSubBlock } from 'components/FullView';
import { makeIsPendingSelector } from 'modules/app/redux/selectors/loading';
import { KeywordType } from 'modules/app/types';
import {
  ORGANIZATION_DETAIL_CTI_ACTION_PREFIX,
  organizationDetailActions,
} from 'modules/organization/redux/actions/creators';
import { makeGetCTIData } from 'modules/organization/redux/selectors/organizationDetail';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import BlockLoadingState from '../BlockLoadingState';
import CTIInfoEdit from './CTIInfoEdit';

type Props = {
  editMode: boolean;
  formRef: React.RefObject<any>;
  setHasChanges: (hasChanges: boolean) => void;
  organizationId: string;
  setEditMode: (editMode: boolean) => void;
  hasChanges: boolean;
};

const CTIInfo: React.FunctionComponent<Props> = ({
  editMode,
  formRef,
  setHasChanges,
  organizationId,
  setEditMode,
  hasChanges,
}: Props) => {
  const { t } = useTranslation('organization');
  const CTIData = useSelector(makeGetCTIData());
  const isPending = useSelector(makeIsPendingSelector(ORGANIZATION_DETAIL_CTI_ACTION_PREFIX));
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!CTIData) {
      dispatch(organizationDetailActions.getCTIData(organizationId));
    }
  }, [organizationId, dispatch, CTIData]);

  return CTIData && !isPending ? (
    editMode ? (
      <CTIInfoEdit
        formRef={formRef}
        ctiData={CTIData}
        setHasChanges={setHasChanges}
        organizationId={organizationId}
        setEditMode={setEditMode}
        hasChanges={hasChanges}
      />
    ) : (
      <React.Fragment>
        {CTIData.domains.length > 0 && (
          <FullViewBlock>
            <h2 className="block-title">{t('detail.blocks.domains')}</h2>
            <div className="block-data">
              {CTIData.domains.map((domain) => (
                <FullViewSubBlock key={domain.domainId}>
                  <h3 className="sub-title">{domain.domainName}</h3>
                  <FullViewItem
                    label={t('detail.labels.registrar')}
                    value={domain.registrar ? domain.registrar.toString() : undefined}
                  />
                  <FullViewItem label={t('detail.labels.hostingCompany')} value={domain.hostingCompany} />
                  <FullViewItem label={t('detail.labels.emailAbuse')} value={domain.emailAbuse} />
                </FullViewSubBlock>
              ))}
            </div>
          </FullViewBlock>
        )}
        {CTIData.keywords.filter((k) => k.type === KeywordType.EmailDomain).length > 0 && (
          <FullViewBlock>
            <h2 className="block-title">{t('detail.blocks.emailDomains')}</h2>
            <div className="block-data">
              <FullViewItem
                label={t('detail.labels.domains')}
                value={CTIData.keywords
                  .filter((k) => k.type === KeywordType.EmailDomain)
                  .map((keyword) => keyword.name)
                  .join(', ')}
              />
            </div>
          </FullViewBlock>
        )}
        {CTIData.ipRanges.length > 0 && (
          <FullViewBlock>
            <h2 className="block-title">{t('detail.blocks.ipRanges')}</h2>
            <div className="block-data">
              {CTIData.ipRanges.map((ipRange) => (
                <FullViewSubBlock key={ipRange.ipRangeId}>
                  <FullViewItem label={t('detail.labels.ipAddresses')} value={ipRange.ipAddresses} />
                  <FullViewItem label={t('detail.labels.ipVersion')} value={ipRange.ipVersion} />
                  <FullViewItem label={t('detail.labels.description')} value={ipRange.description} />
                </FullViewSubBlock>
              ))}
            </div>
          </FullViewBlock>
        )}
        {CTIData.certificates.length > 0 && (
          <FullViewBlock>
            <h2 className="block-title">{t('detail.blocks.certificates')}</h2>
            <div className="block-data">
              {CTIData?.certificates.map((certificate) => (
                <FullViewSubBlock key={certificate.certificateId}>
                  <h3 className="sub-title">{certificate.fileName}</h3>
                  {/*certificate.subject.alternativeNames???*/}
                  <FullViewItem label={t('detail.labels.certificates.commonName')} value={certificate.commonName} />
                  <FullViewItem label={t('detail.labels.certificates.subject')} value={certificate.subject} />
                  <FullViewItem label={t('detail.labels.certificates.organization')} value={certificate.organization} />
                  <FullViewItem
                    label={t('detail.labels.certificates.organizationUnit')}
                    value={certificate.organizationUnit}
                  />
                  <FullViewItem label={t('detail.labels.certificates.locality')} value={certificate.locality} />
                  <FullViewItem label={t('detail.labels.certificates.state')} value={certificate.state} />
                  <FullViewItem label={t('detail.labels.certificates.country')} value={certificate.country} />
                  <FullViewItem label={t('detail.labels.certificates.validFrom')} value={certificate.validFrom} />
                  <FullViewItem label={t('detail.labels.certificates.validUntil')} value={certificate.validUntil} />
                  <FullViewItem label={t('detail.labels.certificates.issuer')} value={certificate.issuer} />
                  {/*certificate.type? */}
                  <FullViewItem label={t('detail.labels.certificates.serialNumber')} value={certificate.serialNumber} />
                </FullViewSubBlock>
              ))}
            </div>
          </FullViewBlock>
        )}
        {CTIData.keywords.filter((k) => k.type !== KeywordType.EmailDomain).length > 0 && (
          <FullViewBlock>
            <h2 className="block-title">{t('detail.blocks.keywords')}</h2>
            <div className="block-data">
              <FullViewItem
                label={t('detail.labels.darkWeb')}
                value={CTIData.keywords
                  .filter((k) => k.type === KeywordType.DarkWeb)
                  .map((keyword) => keyword.name)
                  .join(', ')}
              />
            </div>
          </FullViewBlock>
        )}
      </React.Fragment>
    )
  ) : (
    <BlockLoadingState />
  );
};

export default CTIInfo;
