import { TenantTheme } from '../tenants/';

const itemSidebar = (theme: TenantTheme) => ({
  animation: {
    entered:
      'opacity: 1; transition: opacity 200ms 50ms, transform 200ms 0ms ease-in, min-width 200ms cubic-bezier(0.67,0.49,0.15,0.82) 350ms, width 200ms cubic-bezier(0.67,0.49,0.15,0.82) 350ms; transform: none',
    entering: 'opacity: 0.01; transform: scale(0.99)',
  },
  background: {
    count: theme.palette.neutral.shade0,
    item: {
      active: theme.palette.primary.shade1,
      hover: theme.palette.neutral.shade1,
    },
    main: theme.palette.neutral.shade0,
  },
  border: {
    itemBottom: `solid 1px ${theme.palette.neutral.shade2}`,
    radiusCount: theme.globals.borderRadiusSmall,
    right: `solid 2px ${theme.palette.neutral.shade2}`,
  },
  color: {
    count: theme.palette.neutral.shade4,
    item: {
      header: theme.palette.neutral.shade9,
      subText: theme.palette.neutral.shade4,
      text: theme.palette.neutral.shade5,
    },
    loading: theme.palette.neutral.shade4,
    secondary: theme.palette.neutral.shade4,
  },
  fontSize: {
    count: theme.typography.fontSize.small_4,
    header: theme.typography.fontSize.regular,
    headerStrong: theme.typography.fontSize.regular,
    icon: theme.typography.fontSize.medium_5,
    subText: theme.typography.fontSize.small_4,
    text: theme.typography.fontSize.small_5,
  },
});

export default itemSidebar;
