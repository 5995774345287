import * as React from 'react';
import { loadScene } from 'components/async/scene';
import { Route } from 'react-router-dom';

export const SETTINGS_BASE_ROUTE = '/settings';
export const ALERTS_SETTINGS_ROUTE = '/alerts';

const settingsOverviewScene = loadScene('settings', 'SettingsScene');

const SettingsRoutes: React.FC = () => {
  return <Route path={SETTINGS_BASE_ROUTE} exact component={settingsOverviewScene} />;
};

export default SettingsRoutes;
