import { Button, ModalContent, SubmitButton } from 'components';
import { EditOrganizationValues } from 'modules/organization/types/organization';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import useOrganizationDetail from '../../redux/services/useOrganizationDetail';
import AddOrganizationForm from './AddOrganizationForm';

type Props = {
  closeModal: () => void;
};

const EditOrganizationModal: React.FunctionComponent<Props> = ({ closeModal }: Props) => {
  const { t } = useTranslation(['organization', 'global']);
  const { orgIsUpdating, updateOrganization, organization } = useOrganizationDetail();

  const formRef: React.RefObject<any> = React.useRef(null);
  const [formEdited, setFormEdited] = React.useState(false);

  const handleFormChange = React.useCallback(() => setFormEdited(true), []);

  const handleFormCallback = React.useCallback(() => {
    setFormEdited(false);
    closeModal();
  }, [setFormEdited, closeModal]);

  const handleCancelClick = React.useCallback(() => {
    formRef.current.resetForm();
    handleFormCallback();
  }, [handleFormCallback]);

  const handleSubmitClick = React.useCallback(() => {
    formRef.current.handleSubmit();
  }, [formRef]);

  const handleSubmit = React.useCallback(
    (values: EditOrganizationValues) => {
      if (organization) {
        updateOrganization(organization.organizationId, values, handleFormCallback);
      }
    },
    [organization, updateOrganization, handleFormCallback]
  );

  return (
    <ModalContent>
      <ModalContent.Header>
        <h1>{t('add.editTitle')}</h1>
      </ModalContent.Header>
      <ModalContent.Body className="visibleOverflow">
        {organization && (
          <AddOrganizationForm
            onSubmit={handleSubmit}
            formRef={formRef}
            onFormChange={handleFormChange}
            isEdit
            initialValues={organization}
          />
        )}
      </ModalContent.Body>
      <ModalContent.Footer>
        <Button disabled={orgIsUpdating} onClick={handleCancelClick} tertiary>
          {t('global:general.close')}
        </Button>
        <SubmitButton
          isLoading={orgIsUpdating}
          onClick={handleSubmitClick}
          primary
          type="submit"
          disabled={!formEdited}
        >
          {t('global:general.save')}
        </SubmitButton>
      </ModalContent.Footer>
    </ModalContent>
  );
};

export default EditOrganizationModal;
