export type Sector = {
  sectorId: string;
  name: string;
};

export type ReportType = {
  name: string;
  numberOfReports: number;
  reportTypeId: string;
};

export enum KeywordType {
  Tag = 'tag',
  DarkWeb = 'darkWeb',
  EmailDomain = 'emailDomain',
  MispEmailDomain = 'mispEmailDomain',
  MispSector = 'mispSector',
}

export enum IpVersion {
  Ipv4 = 'ipv4',
  Ipv6 = 'ipv6',
}

export enum IpType {
  ctiData = 'ctiData',
  whiteList = 'whiteList',
  publicList = 'publicList',
}

export enum IpRangeFormat {
  Cidr = 'cidr',
  Range = 'range',
  CommaSeparated = 'commaSeparated',
}

export type IpRange = {
  ipRangeId: string;
  ipVersion: IpVersion;
  description: string;
  format: IpRangeFormat;
  ipAddresses: string;
  rangeType?: IpType;
};

export type IpRangeEditValues = {
  ipRangeId: string;
  format: IpRangeFormat;
  ipAddresses: string;
  description: string;
  ipVersion: IpVersion | null;
  rangeType?: IpType;
};

export type DayOptionType = {
  label: DaysInTheWeek;
  shortLabel: string;
  value: '0' | '1' | '2' | '3' | '4' | '5' | '6';
};

export type Availability = {
  availabilityId?: string;
  day: DaysInTheWeek;
  timeFrom: string;
  timeUntil: string;
};

export type DaysInTheWeek = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';
