import { FlexRow } from 'components';
import { makeIsPendingSelector } from 'modules/app/redux/selectors/loading';
import { Repositories } from 'modules/repository/components/content';
import { repositoryActions, REPOSITORY_ACTION_PREFIX } from 'modules/repository/redux/creators';
import { makeGetRepositories } from 'modules/repository/redux/selectors/repository';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { RepositorySidebar } from '../components/sidebar';
import { REPOSITORY_BASE_ROUTE } from '../routes';

const RepositoriesOverview: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { downloadGuid } = useParams<{ downloadGuid?: string }>();
  const repositories = useSelector(makeGetRepositories());
  const isPending = useSelector(makeIsPendingSelector(REPOSITORY_ACTION_PREFIX));

  React.useEffect(() => {
    dispatch(repositoryActions.getRepositories());
    if (downloadGuid) {
      dispatch(repositoryActions.simpleDownloadRepository(downloadGuid));
      history.push(`${REPOSITORY_BASE_ROUTE}`);
    }
  }, [dispatch, downloadGuid, history]);

  return (
    <FlexRow>
      <RepositorySidebar />
      <Repositories repositories={repositories} isPending={isPending} />
    </FlexRow>
  );
};

export default RepositoriesOverview;
