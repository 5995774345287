import React from 'react';
import { getBase64ForFile } from '../../helpers/base64handler';
import { Input } from '../Form';
import useAlertsStore from 'modules/app/redux/services/useAlertsStore';
import { AlertType } from 'modules/app/types/alert';
import { useTranslation } from 'react-i18next';

export type ConvertedFile = {
  base64: string;
  file: File | null;
  name: string;
};
type Props = {
  acceptedFileTypes?: string[];
  onChange: (files: ConvertedFile[]) => void;
  maxFileSize?: number;
  isMulti?: boolean;
  names: string[];
  id: string;
};

const FileInput: React.FunctionComponent<Props> = ({
  isMulti,
  maxFileSize,
  acceptedFileTypes,
  onChange,
  id,
  ...props
}) => {
  const { t } = useTranslation('validation');
  const { createAlert } = useAlertsStore();

  const handleFileChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const files: FileList | null = e.target.files;
      if (files && files.length > 0) {
        const base64Promises: Array<Promise<ConvertedFile> | null> = Array.from(files).map((file: File) => {
          const filesize = (file.size / 1024 / 1024).toFixed(4); // MB
          if (maxFileSize && parseFloat(filesize) > maxFileSize) {
            createAlert({ message: t('file.maxSize', { size: maxFileSize.toString() }), type: AlertType.Danger });
            return null;
          } else {
            return getBase64ForFile(file, true).then((result) => ({ base64: result, name: file.name, file }));
          }
        });
        Promise.all(base64Promises).then((values: (ConvertedFile | null)[]) => {
          const noNullFiles = values.filter((item) => item !== null) as ConvertedFile[];

          if (noNullFiles.length > 0) {
            onChange(noNullFiles);
          }
        });
      }
    },
    [onChange, maxFileSize, createAlert, t]
  );

  return (
    <div className="input-wrapper">
      <Input
        {...props}
        multiple={isMulti}
        accept={acceptedFileTypes && acceptedFileTypes.join(', ')}
        name={id}
        placeholder=""
        type="file"
        noLabel
        onChange={handleFileChange}
        value={''}
      />
    </div>
  );
};

export default FileInput;
