import { searchActions } from 'modules/app/redux/creators';
import { MAIL_FILTER_KEY } from 'modules/mail/constants/mails';
import { call, put, takeLatest } from 'redux-saga/effects';
import MailsApi from '../../api/MailsApi';
import { MAILS_FETCHED_MORE, MAILS_GET } from '../actions/constants/mails';
import { fetchMails, getMails, mergeMails, rejectFetchMails, setMails } from '../actions/creators/mails';
import { FetchMoreMailsAction, GetMailsAction } from '../actions/types/mails';

function* getMailsSaga() {
  yield takeLatest(MAILS_GET, handleFetchMails);
  yield takeLatest(MAILS_FETCHED_MORE, handleFetchMoreMails);
  yield takeLatest(searchActions.setSearch, handleSearchChange);
}

function* handleFetchMails(action: GetMailsAction) {
  yield put(fetchMails());
  try {
    const { page, searchValue } = action.payload;
    const payload = yield call(MailsApi.getMails, page, searchValue);
    yield put(setMails(payload));
  } catch (e) {
    yield put(rejectFetchMails(e.response));
  }
}

function* handleFetchMoreMails(action: FetchMoreMailsAction) {
  yield put(fetchMails());
  try {
    const { page, searchValue } = action.payload;
    const payload = yield call(MailsApi.getMails, page, searchValue);
    yield put(mergeMails(payload));
  } catch (e) {
    yield put(rejectFetchMails(e.response));
  }
}

function* handleSearchChange(action: ReturnType<typeof searchActions.setSearch>) {
  if (action.payload.filterKey === MAIL_FILTER_KEY) {
    yield put(getMails(1, action.payload.searchText));
  }
}

export default getMailsSaga;
