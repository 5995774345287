import * as Yup from 'yup';
import { Audience, ILLEGAL_NAME_REGEX, Priority, TlpCode } from '../../app/constants';
import { EditRepositoryValues, QuestionFormikValues } from '../types/repository';

export const editRepositorySchema = (illegalMessage: string): Yup.ObjectSchema =>
  Yup.object().shape({
    audience: Yup.mixed()
      .oneOf([...Object.values(Audience)])
      .required(),
    content: Yup.string(),
    description: Yup.string().required().trim().max(400).matches(ILLEGAL_NAME_REGEX, illegalMessage),
    file: Yup.object().shape({
      base64: Yup.string(),
      file: Yup.mixed(),
      name: Yup.string().trim().max(300),
    }),
    priority: Yup.mixed()
      .oneOf([...Object.values(Priority)])
      .required(),
    report: Yup.object(),
    reportId: Yup.string().required(),
    reportTypeId: Yup.string().required(),
    sectors: Yup.array()
      .min(1)
      .of(
        Yup.object().shape({
          name: Yup.string(),
          sectorId: Yup.string(),
        })
      ),
    tlpCode: Yup.mixed()
      .oneOf([...Object.values(TlpCode)])
      .required(),
    reportDate: Yup.string().required(),
    sendMail: Yup.boolean(),
  });

export const questionSchema: Yup.ObjectSchema<QuestionFormikValues> = Yup.object().shape({
  content: Yup.string().required(),
});

export const addRepositorySchema = (illegalMessage: string): Yup.ObjectSchema =>
  Yup.object().shape({
    audience: Yup.mixed()
      .oneOf([...Object.values(Audience)])
      .required(),
    description: Yup.string().required().trim().max(400).matches(ILLEGAL_NAME_REGEX, illegalMessage),
    file: Yup.object().shape({
      base64: Yup.string(),
      file: Yup.mixed().nullable(),
      name: Yup.string().trim().max(300),
    }),
    priority: Yup.mixed()
      .oneOf([...Object.values(Priority)])
      .required(),
    report: Yup.object()
      .shape({
        name: Yup.string().required(),
        reportTypeId: Yup.string().required(),
      })
      .required(),
    sectors: Yup.array()
      .min(1)
      .of(
        Yup.object().shape({
          name: Yup.string(),
          sectorId: Yup.string(),
        })
      ),
    tlpCode: Yup.mixed()
      .oneOf([...Object.values(TlpCode)])
      .required(),
    reportDate: Yup.string().required(),
    addCriticalAlert: Yup.boolean().required(),
    title: Yup.string().when('addCriticalAlert', {
      is: (val) => val,
      then: Yup.string().required(),
      otherwise: Yup.string(),
    }),
    criticalAlertDescription: Yup.string().when('addCriticalAlert', {
      is: (val) => val,
      then: Yup.string().required(),
      otherwise: Yup.string(),
    }),
  });
