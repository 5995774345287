import AxiosConfig, { RequestConfig } from '../../../config/api/AxiosConfig';
import { ORGANIZATION_FILES_DOWNLOAD } from '../constants';

type Props = {
  pgpFileId: string;
  fileName: string;
};
const downloadPgpKey = async ({ pgpFileId, fileName }: Props) => {
  const requestConfig: RequestConfig = {
    url: ORGANIZATION_FILES_DOWNLOAD(pgpFileId),
  };

  return AxiosConfig.fetchAxiosDownloadRequest(requestConfig, fileName);
};

export default { downloadPgpKey };
