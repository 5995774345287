import { IconButton } from 'components';
import { FormikSelect } from 'components/Form';
import { ArrayHelperActionWrapper, BasicTextField, EditableItem, FullViewSubBlock } from 'components/FullView';
import { Add, Delete } from 'components/icons';
import { ArrayHelpers, Field, FieldProps, FormikErrors, FormikTouched } from 'formik';
import { getNestedError } from 'helpers/formikHelper';
import { IpRangeEditValues, IpRangeFormat, IpType, IpVersion } from 'modules/app/types';
import { newIpRange } from 'modules/organization/constants';
import { CTIData } from 'modules/organization/types/organizationDetail/cti';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  ipRange: IpRangeEditValues;
  index: number;
  errors: FormikErrors<CTIData>;
  touched: FormikTouched<CTIData>;
  arrayHelpers: ArrayHelpers;
  name: 'whiteIpRanges' | 'publicIpRanges' | 'ipRanges';
};

const EditIpRange: React.FunctionComponent<Props> = ({
  ipRange,
  index,
  errors,
  touched,
  arrayHelpers,
  name,
}: Props) => {
  const { t } = useTranslation(['organization', 'global']);

  const ipFormats = [
    {
      label: t('global:ipFormats.cidr'),
      value: IpRangeFormat.Cidr,
    },
    {
      label: t('global:ipFormats.range'),
      value: IpRangeFormat.Range,
    },
    {
      label: t('global:ipFormats.CommaSeparated'),
      value: IpRangeFormat.CommaSeparated,
    },
  ];

  const rangeType = React.useCallback(() => {
    switch (name) {
      case 'whiteIpRanges':
        return IpType.whiteList;
      case 'publicIpRanges':
        return IpType.publicList;
      case 'ipRanges':
      default:
        return IpType.ctiData;
    }
  }, [name]);

  const addIpRange = React.useCallback(() => arrayHelpers.push(newIpRange(rangeType())), [arrayHelpers, rangeType]);
  const removeIpRange = React.useCallback(() => arrayHelpers.remove(index), [arrayHelpers, index]);

  return (
    <FullViewSubBlock>
      <ArrayHelperActionWrapper>
        <EditableItem
          label={t('detail.labels.ipVersion')}
          value={
            <Field
              name={`${name}.${index}.ipVersion`}
              render={(fieldProps: FieldProps) => (
                <FormikSelect
                  {...fieldProps}
                  options={[IpVersion.Ipv4, IpVersion.Ipv6]}
                  getOptionValue={(option: IpVersion) => option}
                  getOptionLabel={(option: IpVersion) => option}
                  error={getNestedError(`${name}.${index}.ipVersion`, errors, touched)}
                  autoFocus={ipRange.ipRangeId === '0'}
                />
              )}
            />
          }
        />

        {index === 0 && (
          <IconButton onClick={addIpRange} tooltip={t('tooltips.addIpRange')}>
            <Add />
          </IconButton>
        )}
        <IconButton onClick={removeIpRange} tooltip={t('tooltips.removeIpRange')}>
          <Delete />
        </IconButton>
      </ArrayHelperActionWrapper>

      <EditableItem
        label={t('detail.labels.format')}
        value={
          <Field
            name={`${name}.${index}.format`}
            render={(fieldProps: FieldProps) => (
              <FormikSelect
                {...fieldProps}
                options={ipFormats}
                getOptionValue={(option) => option.value}
                getOptionLabel={(option) => option.label}
                error={getNestedError(`${name}.${index}.format`, errors, touched)}
              />
            )}
          />
        }
      />
      <BasicTextField
        name={`${name}.${index}.ipAddresses`}
        label={t('detail.labels.ipAddresses')}
        errors={errors}
        touched={touched}
      />
      <BasicTextField
        name={`${name}.${index}.description`}
        label={t('detail.labels.description')}
        errors={errors}
        touched={touched}
      />
    </FullViewSubBlock>
  );
};

export default EditIpRange;
