import { Input, Search, Sidebar } from 'components';
import DateFilters from 'components/filters/DateFilters';
import { TYPE_CHECKBOX } from 'components/Form/Input';
import { REPOSITORY_FILTER_KEY } from 'modules/repository/constants';
import { makeGetRepositoryFilters } from 'modules/repository/redux/selectors/repository';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ReportTypeFilters from '../../../app/components/Filters/ReportTypeFilters';
import SectorFilters from '../../../app/components/Filters/SectorFilters';
import TlpCodeFilters from '../../../app/components/Filters/TlpCodeFilters';
import { filterActions } from '../../redux/creators';
import FullSearchFilter, { FullSearchInputContainer, InputWrapper } from './FullSearchFilter';
import AudienceFilters from 'modules/app/components/Filters/AudienceFilters';
import PriorityFilters from 'modules/app/components/Filters/PriorityFilters';

const RepositorySidebar: React.FunctionComponent = () => {
  const { t } = useTranslation('repository');
  const dispatch = useDispatch();
  const filters = useSelector(makeGetRepositoryFilters());

  const handleDateFrom = (date: Date | undefined) => {
    dispatch(filterActions.setDateFromFilter(date ?? undefined));
  };
  const handleDateUntil = (date: Date | undefined) => {
    dispatch(filterActions.setDateUntilFilter(date ?? undefined));
  };
  const handleFullTextFilterChange = () => {
    dispatch(filterActions.setFullTextFilter(!filters.fullText));
  };

  return (
    <Sidebar>
      <Search filterKey={REPOSITORY_FILTER_KEY} initialValue={filters.search} placeholder={t('searchPlaceholder')} />
      <FullSearchFilter>
        <FullSearchInputContainer>
          <InputWrapper>
            <Input
              name={'fullText'}
              onChange={handleFullTextFilterChange}
              type={TYPE_CHECKBOX}
              checked={filters.fullText}
            />
          </InputWrapper>
          <h5 className="fullTextInputText">{t('sidebar.fullText')}</h5>
        </FullSearchInputContainer>
        <div>
          <p>{t('sidebar.fullTextWarning')}</p>
        </div>
      </FullSearchFilter>
      <div>
        <DateFilters
          dateFrom={filters.dateFrom}
          dateUntil={filters.dateUntil}
          handleDateFrom={handleDateFrom}
          handleDateUntil={handleDateUntil}
        />
      </div>
      <ReportTypeFilters reportTypes={filters.reportType} filterAction={filterActions.setReportTypeFilter} />
      <SectorFilters sectors={filters.sectors} filterAction={filterActions.setSectorFilter} />
      <AudienceFilters audiences={filters.audience} filterAction={filterActions.setAudienceFilter} />
      <PriorityFilters priorities={filters.priority} filterAction={filterActions.setPriorityFilter} />
      <TlpCodeFilters tlpCodes={filters.tlpCode} filterAction={filterActions.setTlpCodeFilter} />
    </Sidebar>
  );
};

export default RepositorySidebar;
