import { DownloadLink, FlexCol } from 'components';
import { FullViewBlock, FullViewItem, FullViewSubBlock } from 'components/FullView';
import { ORGANIZATIONS_URL } from 'modules/organization/api/enpoints';
import { GeneralInfo as GeneralInfoType } from 'modules/organization/types/organizationDetail/general';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import BlockLoadingState from '../BlockLoadingState';
import GeneralInfoEdit from './edit/GeneralInfoEdit';
import { IpType, KeywordType } from 'modules/app/types';

type Props = {
  generalInfo: GeneralInfoType | null;
  isPending?: boolean;
  editMode: boolean;
  formRef: React.RefObject<any>;
  setHasChanges: (hasChanges: boolean) => void;
  organizationId: string;
  setEditMode: (editMode: boolean) => void;
  hasChanges: boolean;
};

const GeneralInfo: React.FunctionComponent<Props> = ({
  generalInfo,
  isPending,
  editMode,
  formRef,
  setHasChanges,
  organizationId,
  setEditMode,
  hasChanges,
}: Props) => {
  const { t } = useTranslation('organization');

  return isPending ? (
    <BlockLoadingState />
  ) : editMode && generalInfo ? (
    <GeneralInfoEdit
      formRef={formRef}
      generalInfo={generalInfo}
      setHasChanges={setHasChanges}
      organizationId={organizationId}
      setEditMode={setEditMode}
      hasChanges={hasChanges}
    />
  ) : (
    generalInfo && (
      <FlexCol full>
        <FullViewBlock>
          <h2 className="block-title">{t('detail.blocks.locations')}</h2>
          <div className="block-data">
            {generalInfo.businessLocations.map((address) => (
              <FullViewSubBlock key={address.addressId}>
                <h3 className="sub-title">{address.name}</h3>
                <FullViewItem
                  label={t('detail.labels.address')}
                  value={`${address.street} ${address.number}\n${address.city}, ${address.zipCode}\n${
                    address.state ? address.state + ' ' : ''
                  }${address.country.name}`}
                />
              </FullViewSubBlock>
            ))}
          </div>
        </FullViewBlock>
        {generalInfo.tlsEmail && (
          <FullViewBlock>
            <h2 className="block-title">{t('detail.blocks.tls')}</h2>
            <div className="block-data">
              <FullViewItem label={t('detail.labels.tlsEmail')} value={generalInfo.tlsEmail} />
              <FullViewItem label={t('detail.labels.alertPhone')} value={generalInfo.alertPhone} />
            </div>
          </FullViewBlock>
        )}
        {(generalInfo.alertFeedEmail || generalInfo.alertPhone) && (
          <FullViewBlock>
            <h2 className="block-title">{t('detail.blocks.alertFeed')}</h2>
            <div className="block-data">
              <FullViewItem label={t('detail.labels.alertFeedEmail')} value={generalInfo.alertFeedEmail} />
            </div>
          </FullViewBlock>
        )}
        <FullViewBlock>
          <h2 className="block-title">{t('detail.blocks.misp')}</h2>
          <div className="block-data">
            <FullViewItem
              label={t('detail.labels.mispToMisp')}
              value={
                generalInfo.mispToMisp === null
                  ? t('detail.mispToMispValues.null')
                  : generalInfo.mispToMisp
                  ? t('detail.mispToMispValues.true')
                  : t('detail.mispToMispValues.false')
              }
            />
            {generalInfo.accountNeeded !== undefined && (
              <FullViewItem
                label={t('detail.labels.accountNeeded')}
                value={
                  generalInfo.accountNeeded
                    ? t('detail.mispExistingOrgValues.true')
                    : t('detail.mispExistingOrgValues.false')
                }
              />
            )}
            <FullViewItem label={t('detail.labels.mispOrganizationName')} value={generalInfo.mispOrganizationName} />
            <FullViewItem label={t('detail.labels.mispDescription')} value={generalInfo.mispDescription} />
            <FullViewItem label={t('detail.labels.mispOrganizationGuid')} value={generalInfo.mispOrganizationGuid} />
            <FullViewItem label={t('detail.labels.mispCountryId')} value={generalInfo.mispCountry?.name} />
            <FullViewItem
              label={t('detail.labels.mispSectors')}
              value={generalInfo.keywords
                .filter((item) => item.type === KeywordType.MispSector)
                .map((item) => item.name)
                .join(`\n`)}
            />
            <FullViewItem label={t('detail.labels.mispEmail')} value={generalInfo.mispPocEmail} />
            <FullViewItem label={t('detail.labels.mispExtraEmail')} value={generalInfo.mispExtraEmail} />
            <FullViewItem
              label={t('detail.labels.mispDomains')}
              value={generalInfo.keywords
                .filter((item) => item.type === KeywordType.MispEmailDomain)
                .map((item) => item.name)
                .join(`\n`)}
            />
            {generalInfo.mispLogoFileName && generalInfo.mispLogoFileId && (
              <FullViewItem
                label={t('detail.labels.mispLogoFile')}
                value={
                  <DownloadLink
                    text={generalInfo.mispLogoFileName || ''}
                    to={`${process.env.REACT_APP_API_ROOT}${ORGANIZATIONS_URL}/files/${generalInfo.mispLogoFileId}/download`}
                  />
                }
              />
            )}
            {generalInfo.mispPocPgpFileName && generalInfo.mispPocPgpFileId && (
              <FullViewItem
                label={t('detail.labels.mispPocPgpFileId')}
                value={
                  <DownloadLink
                    text={generalInfo.mispPocPgpFileName || ''}
                    to={`${process.env.REACT_APP_API_ROOT}${ORGANIZATIONS_URL}/files/${generalInfo.mispPocPgpFileId}/download`}
                  />
                }
              />
            )}
          </div>
        </FullViewBlock>
        {generalInfo.ipRanges.filter((item) => item.rangeType === IpType.whiteList).length > 0 && (
          <FullViewBlock>
            <h2 className="block-title">{t('detail.blocks.generalIpRanges')}</h2>
            <div className="block-data">
              {generalInfo.ipRanges
                .filter((item) => item.rangeType === IpType.whiteList)
                .map((ipRange) => (
                  <FullViewSubBlock key={ipRange.ipRangeId}>
                    <FullViewItem label={t('detail.labels.ipAddresses')} value={ipRange.ipAddresses} />
                    <FullViewItem label={t('detail.labels.ipVersion')} value={ipRange.ipVersion} />
                    <FullViewItem label={t('detail.labels.description')} value={ipRange.description} />
                  </FullViewSubBlock>
                ))}
            </div>
          </FullViewBlock>
        )}
        {generalInfo.ipRanges.filter((item) => item.rangeType === IpType.publicList).length > 0 && (
          <FullViewBlock>
            <h2 className="block-title">{t('detail.blocks.publicIpRanges')}</h2>
            <div className="block-data">
              {generalInfo.ipRanges
                .filter((item) => item.rangeType === IpType.publicList)
                .map((ipRange) => (
                  <FullViewSubBlock key={ipRange.ipRangeId}>
                    <FullViewItem label={t('detail.labels.ipAddresses')} value={ipRange.ipAddresses} />
                    <FullViewItem label={t('detail.labels.ipVersion')} value={ipRange.ipVersion} />
                    <FullViewItem label={t('detail.labels.description')} value={ipRange.description} />
                  </FullViewSubBlock>
                ))}
            </div>
          </FullViewBlock>
        )}
        <FullViewBlock>
          <h2 className="block-title">{t('detail.blocks.optIn')}</h2>
          <div className="block-data">
            <FullViewSubBlock>
              <FullViewItem label={t('detail.labels.optIn')} value={t(`global:general.${generalInfo.optIn}`)} />
            </FullViewSubBlock>
          </div>
        </FullViewBlock>
      </FlexCol>
    )
  );
};

export default GeneralInfo;
