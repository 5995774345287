import { FieldProps, getIn } from 'formik';
import * as React from 'react';
import { TimeSlider } from '../../../../components';

const HOURS_IN_A_DAY = 24;
const QUARTERS_IN_A_HOUR = 4;

const defaultTimeSliderProps = {
  defaultValue: [9, 17],
  max: HOURS_IN_A_DAY,
  min: 0,
  step: 1 / QUARTERS_IN_A_HOUR,
};

const AvailabilityTimeSlider: React.FunctionComponent<FieldProps> = ({ form, field }) => (
  <TimeSlider
    onChange={(e: React.ChangeEvent<{}>, value: number[]) => form.setFieldValue(field.name, value)}
    min={defaultTimeSliderProps.min}
    max={defaultTimeSliderProps.max}
    step={defaultTimeSliderProps.step}
    value={getIn(form.values, field.name) || [9, 17]}
  />
);
export default AvailabilityTimeSlider;
