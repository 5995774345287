import { createSelector } from 'reselect';
import { RootState } from '../../../../config/store/rootReducer';
import { mapAvailabilitiesToBackendValues } from '../../../app/components/availabilities/formatAvailabilityHelper';
import { DARK_WEB, EMAIL_DOMAIN, MISP_EMAIL_DOMAIN, MISP_SECTOR, TAG } from '../../constants';
import formatFileUploadHelper from '../../helpers/formatFileUploadHelper';
import { OnboardingContact, PutContactDataBody } from '../../types/onboarding';
import { ContactType } from 'modules/app/types/contact';

const getGeneralDataFields = (state: RootState) => {
  const {
    mispPocPgpFileId,
    mispPocPgpFile,
    mispLogoFileId,
    mispLogoFile,
    alertFeedEmail,
    alertPhone,
    tlsEmail,
    mispToMisp,
    mispPocEmail,
    mispOrganizationGuid,
    mispOrganizationName,
    generalIpRanges,
    publicIpRanges,
    accountNeeded,
    mispCountryId,
    mispDescription,
    sector,
    domainRestrictions,
    mispExtraEmail,
  } = state.onboarding.formFields;

  return {
    businessLocations: state.onboarding.formFields.businessLocations,
    ...formatFileUploadHelper(
      mispPocPgpFile,
      mispPocPgpFileId,
      {
        content: 'mispPocPgpContent',
        id: 'mispPocPgpFileId',
        name: 'mispPocPgpFileName',
      },
      mispToMisp === 'null'
    ),
    ...formatFileUploadHelper(
      mispLogoFile,
      mispLogoFileId,
      {
        content: 'mispLogoContent',
        id: 'mispLogoFileId',
        name: 'mispLogoFileName',
      },
      mispToMisp === 'null'
    ),
    alertFeedEmail,
    alertPhone,
    ipRanges:
      mispToMisp !== 'null'
        ? [
            ...publicIpRanges.map((item) => ({ ...item, rangeType: 'publicList' })),
            ...generalIpRanges.map((item) => ({ ...item, rangeType: 'whiteList' })),
          ]
        : publicIpRanges.map((item) => ({ ...item, rangeType: 'publicList' })),
    mispOrganizationGuid:
      (accountNeeded === 'true' || mispToMisp === 'true') && mispToMisp !== 'null' ? mispOrganizationGuid : undefined,
    mispOrganizationName: mispToMisp !== 'null' ? mispOrganizationName : undefined,
    mispPocEmail: mispToMisp !== 'null' ? mispPocEmail : undefined,
    mispToMisp: mispToMisp === 'null' ? null : mispToMisp === 'true',
    accountNeeded: mispToMisp === 'null' ? undefined : accountNeeded === 'true',
    tlsEmail,
    mispCountryId: mispToMisp !== 'null' ? mispCountryId : undefined,
    mispDescription: mispToMisp !== 'null' ? mispDescription : undefined,
    mispExtraEmail: mispToMisp !== 'null' ? mispExtraEmail : undefined,
    keywords:
      mispToMisp !== 'null'
        ? [
            ...sector.map((sect: string) => ({
              name: sect,
              type: MISP_SECTOR,
            })),
            ...domainRestrictions.map((rest: string) => ({
              name: rest,
              type: MISP_EMAIL_DOMAIN,
            })),
          ]
        : undefined,
  };
};

export const makeGetGeneralDataFields = () =>
  createSelector(getGeneralDataFields, (generalData: { [key: string]: any }) => generalData);

const getContactDataFields = (state: RootState) => {
  const { contacts } = state.onboarding.formFields;

  return contacts.map((contact: OnboardingContact, index: number) => ({
    ...contact,
    availabilities: mapAvailabilitiesToBackendValues(contact.availabilities),
    businessLocations: contact.businessLocationIds.map((id: string) => id),
    ...formatFileUploadHelper(contact.pgpKey, contact.pgpFileId, {
      id: 'pgpFileId',
      content: 'pgpContent',
      name: 'pgpFileName',
    }),
  }));
};

export const makeGetContactDataFields = () =>
  createSelector(getContactDataFields, (contactData: { [key: string]: any }) => contactData);

const getCtiDataFields = (state: RootState) => {
  const { domains, emailDomains, darkWebSearch, tags, certificates, ipRanges } = state.onboarding.formFields;

  return {
    certificates,
    domains,
    ipRanges,
    keywords: [
      ...emailDomains.map((emailDomain: string) => ({
        name: emailDomain,
        type: EMAIL_DOMAIN,
      })),
      ...darkWebSearch.map((keyword: string) => ({
        name: keyword,
        type: DARK_WEB,
      })),
      ...tags.map((tag: string) => ({
        name: tag,
        type: TAG,
      })),
    ],
  };
};

export const makeGetCtiDataFields = () => createSelector(getCtiDataFields, (ctiData) => ctiData);

const getUserData = (state: RootState) => {
  const { firstName, name, department, language } = state.onboarding.formFields;

  return { firstName, name, department, language };
};

export const makeGetUserData = () => createSelector(getUserData, (userData) => userData as PutContactDataBody);
