import { Button, Input, ModalContent, SubmitButton } from 'components';
import { useActionIsPending } from 'hooks';
import { makeActionRequestIsPendingSelector } from 'modules/app/redux/selectors/loading';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getType } from 'typesafe-actions';
import { repositoryActions } from '../../redux/creators';
import { EditRepositoryValues } from '../../types/repository';
import EditRepositoryForm from './EditRepositoryForm';
import styled from 'styled-components';
import { Flexer } from '@unbrace/components';

type Props = {
  closeModal: () => void;
};

const EditRepositoryModal: React.FunctionComponent<Props> = ({ closeModal, ...props }: Props) => {
  const isUploading = useSelector(makeActionRequestIsPendingSelector('REPOSITORIES_EDIT'));
  const dispatch = useDispatch();
  const { t } = useTranslation(['global', 'repository']);
  const formRef: React.RefObject<any> = React.useRef(null);
  const [sendMail, setSendMail] = React.useState(false);
  const [formEdited, setFormEdited] = React.useState(false);
  const [isEditing, startEditing] = useActionIsPending(getType(repositoryActions.editRepository));

  const handleFormChange = React.useCallback(() => setFormEdited(true), []);

  const handleCancelClick = React.useCallback(() => {
    formRef.current.resetForm();
    setFormEdited(false);
    closeModal();
  }, [closeModal, formRef, setFormEdited]);

  const handleSubmitClick = React.useCallback(() => {
    formRef.current.handleSubmit();
  }, [formRef]);

  const handleSubmit = React.useCallback(
    (values: EditRepositoryValues) => {
      startEditing();
      dispatch(
        repositoryActions.editRepository({ ...values, reportTypeId: values.report.reportTypeId, sendMail }, () => {
          setFormEdited(false);
          closeModal();
        })
      );
    },
    [dispatch, setFormEdited, closeModal, startEditing, sendMail]
  );

  return (
    <ModalContent>
      <ModalContent.Header>
        <h1>{t('repository:edit.title')}</h1>
        <h5>{t('repository:edit.description')}</h5>
      </ModalContent.Header>
      <ModalContent.Body>
        <EditRepositoryForm
          formRef={formRef}
          initialValues={props}
          onFormChange={handleFormChange}
          onSubmit={handleSubmit}
        />
      </ModalContent.Body>
      <ModalContent.Footer>
        <FooterWrapper>
          <Input
            name="sendMail"
            label={t('repository:add.sendMail')}
            type={'checkbox'}
            checked={sendMail}
            onChange={() => setSendMail(!sendMail)}
          />
          <Flexer>
            <Button disabled={isEditing} onClick={handleCancelClick} tertiary>
              {t('general.close')}
            </Button>
            <SubmitButton
              isLoading={isEditing}
              onClick={handleSubmitClick}
              primary
              type="submit"
              disabled={!formEdited || isUploading}
            >
              {t('general.edit')}
            </SubmitButton>
          </Flexer>
        </FooterWrapper>
      </ModalContent.Footer>
    </ModalContent>
  );
};

export default EditRepositoryModal;

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;
`;
