import { Button, ModalContent, SubmitButton } from 'components';
import { useActionIsPending } from 'hooks';
import { organizationDetailActions } from 'modules/organization/redux/actions/creators';
import { RejectFormValues } from 'modules/organization/types/organizationDetail/organizationDetail';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getType } from 'typesafe-actions';
import { MultiSelectActions } from '../../../../hooks/useMultiSelect';
import RejectOrganizationForm from './RejectOrganizationForm';

type Props = {
  closeModal: () => void;
  userId?: string;
  selectedUsersActions: MultiSelectActions;
};

const RejectUserModal: React.FunctionComponent<Props> = ({ closeModal, userId, selectedUsersActions }: Props) => {
  const { t } = useTranslation('organization');
  const formRef: React.RefObject<any> = React.useRef(null);
  const dispatch = useDispatch();
  const [isRejecting, startRejecting] = useActionIsPending(getType(organizationDetailActions.rejectUser));

  const [formEdited, setFormEdited] = React.useState(false);

  const handleFormChange = React.useCallback(() => setFormEdited(true), []);

  const handleCancelClick = React.useCallback(() => {
    formRef.current.resetForm();
    setFormEdited(false);
    closeModal();
  }, [closeModal, formRef, setFormEdited]);

  const handleSubmitClick = React.useCallback(() => {
    formRef.current.handleSubmit();
  }, [formRef]);

  const handleSubmit = React.useCallback(
    (values: RejectFormValues) => {
      if (userId) {
        startRejecting();
        dispatch(
          organizationDetailActions.rejectUser(userId, values.comment, () => {
            setFormEdited(false);
            closeModal();
            selectedUsersActions.deselectAll();
          })
        );
      }
    },
    [dispatch, setFormEdited, selectedUsersActions, closeModal, userId, startRejecting]
  );

  return (
    <ModalContent>
      <ModalContent.Header>
        <h1>{t('detail.rejectUser.modalTitle')}</h1>
        <h5>{t('detail.rejectUser.modalDescription')}</h5>
      </ModalContent.Header>
      <ModalContent.Body>
        <RejectOrganizationForm onSubmit={handleSubmit} formRef={formRef} onFormChange={handleFormChange} />
      </ModalContent.Body>
      <ModalContent.Footer>
        <Button disabled={isRejecting} onClick={handleCancelClick} tertiary>
          {t('global:general.close')}
        </Button>
        <SubmitButton isLoading={isRejecting} onClick={handleSubmitClick} primary type="submit" disabled={!formEdited}>
          {t('detail.rejectUser.modalSubmit')}
        </SubmitButton>
      </ModalContent.Footer>
    </ModalContent>
  );
};

export default RejectUserModal;
