import { createAction, createAsyncAction } from 'typesafe-actions';
import { HttpErrorResponse } from '../../../../../types';

export const initiateLogout = createAction('LOGOUT_INITIATED');

export const logoutRequest = createAsyncAction('LOGOUT_REQUEST', 'LOGOUT_SUCCESS', 'LOGOUT_REJECTED')<
  undefined,
  undefined,
  HttpErrorResponse
>();
