import { Flexer } from '@unbrace/components';
import { ModalInjectedProps } from 'components/Modal/ModalContainer';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { ModalContent, SubmitButton } from '../../../../components';
import { toFullDate } from '../../../../helpers/dateFormatter';
import useAlertsStore from '../../redux/services/useAlertsStore';
import { CriticalAlert } from '../../types/criticalAlert';
import TLPCodeBadge from '../TLPCodeBadge';
import { MarkdownWrapper } from 'components/markdown';

type Props = {
  criticalAlert: CriticalAlert | undefined;
  criticalAlertTotal: number;
  criticalAlertsDone: number;
} & ModalInjectedProps;

const UnconfirmedCriticalAlertModal = ({
  criticalAlert,
  criticalAlertTotal,
  criticalAlertsDone,
  closeModal,
}: Props) => {
  const { t } = useTranslation();
  const { confirmCriticalAlert, isConfirming } = useAlertsStore();
  const isUnconfirmed = Boolean(criticalAlertTotal);

  if (!criticalAlert) {
    return null;
  }

  const { guid, title, description, tlpCode, criticalAlertDate } = criticalAlert;

  const handleConfirm = () => {
    if (isUnconfirmed) {
      confirmCriticalAlert(guid);
    } else {
      closeModal();
    }
  };

  return (
    <ModalContent>
      <ModalContent.Header>
        <Flexer justify="space-between" align="flex-start">
          <h1>{title}</h1>
          <TLPCodeBadge tlp={tlpCode} />
        </Flexer>
        <h5>{toFullDate(criticalAlertDate)}</h5>
      </ModalContent.Header>
      <ModalContent.Body>
        <MarkdownWrapper>
          <ReactMarkdown remarkPlugins={[remarkGfm]}>{description}</ReactMarkdown>
        </MarkdownWrapper>
      </ModalContent.Body>
      <ModalContent.Footer>
        {criticalAlertTotal > 1 && (
          <span>{t('alerts.criticalAlertsDone', { done: criticalAlertsDone, total: criticalAlertTotal })}</span>
        )}
        <SubmitButton onClick={handleConfirm} primary type="submit" disabled={isConfirming} isLoading={isConfirming}>
          {t(isUnconfirmed ? 'general.confirm' : 'general.close')}
        </SubmitButton>
      </ModalContent.Footer>
    </ModalContent>
  );
};

export default UnconfirmedCriticalAlertModal;
