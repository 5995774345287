import { connectRouter, RouterState } from 'connected-react-router';
import loadingReducer, { LoadingState } from 'modules/app/redux/reducers/loading';
import mailModuleReducer, { MailModuleState } from 'modules/mail/redux/reducers';
import notificationsReducer, { NotificationsState } from 'modules/notifications/redux/reducers';
import organizationModuleReducer, { OrganizationModuleState } from 'modules/organization/redux/reducers';
import repositoryModuleReducer, { RepositoryModuleState } from 'modules/repository/redux/reducers';
import sectorModuleReducer, { SectorModuleState } from 'modules/sector/redux/reducers';
import securityReducer, { SecurityState } from 'modules/security/redux/reducers';
import superUsersModuleReducer, { SuperUsersState } from 'modules/superUsers/redux/reducers';
import { combineReducers, Reducer } from 'redux';
import activitiesModuleReducer, { ActivitiesModuleState } from '../../modules/activities/redux/reducers';
import alertsReducer, { AlertsModuleState } from '../../modules/app/redux/reducers/alerts';
import certificatesReducer, { CertificatesState } from '../../modules/app/redux/reducers/certificates';
import globalInfoReducer, { GlobalInfoState } from '../../modules/app/redux/reducers/globalInfo';
import criticalAlertsModuleReducer, { CriticalAlertsState } from '../../modules/flashAlerts/redux/reducers';
import helpCenterModuleReducer, { helpCenternModuleState } from '../../modules/helpCenter/redux/reducers';
import onboardingReducer, { OnboardingState } from '../../modules/onboarding/redux/reducers/onboarding';
import reportTypeModuleReducer, { ReportTypeModuleState } from '../../modules/reportType/redux/reducers';
import settingsModuleReducer, { SettingsModuleState } from '../../modules/settings/redux/reducers';
import feedModuleReducer, { FeedsState } from '../../modules/feed/redux/reducers';
import inactiveUsersModuleReducer, { InactiveUsersState } from '../../modules/inactiveUsers/redux/reducers';

export type RootState = {
  readonly activities: ActivitiesModuleState;
  readonly alerts: AlertsModuleState;
  readonly certificates: CertificatesState;
  readonly criticalAlerts: CriticalAlertsState;
  readonly feed: FeedsState;
  readonly globalInfo: GlobalInfoState;
  readonly helpCenter: helpCenternModuleState;
  readonly loading: LoadingState;
  readonly mail: MailModuleState;
  readonly notifications: NotificationsState;
  readonly onboarding: OnboardingState;
  readonly organizations: OrganizationModuleState;
  readonly reportType: ReportTypeModuleState;
  readonly repositories: RepositoryModuleState;
  readonly router: RouterState;
  readonly sector: SectorModuleState;
  readonly security: SecurityState;
  readonly settings: SettingsModuleState;
  readonly superUsers: SuperUsersState;
  readonly inactiveUsers: InactiveUsersState;
};

export const rootReducer = (history: any): Reducer<RootState> =>
  combineReducers<RootState, any>({
    activities: activitiesModuleReducer,
    alerts: alertsReducer,
    certificates: certificatesReducer,
    criticalAlerts: criticalAlertsModuleReducer,
    feed: feedModuleReducer,
    globalInfo: globalInfoReducer,
    helpCenter: helpCenterModuleReducer,
    loading: loadingReducer,
    mail: mailModuleReducer,
    notifications: notificationsReducer,
    onboarding: onboardingReducer,
    organizations: organizationModuleReducer,
    reportType: reportTypeModuleReducer,
    repositories: repositoryModuleReducer,
    router: connectRouter(history),
    sector: sectorModuleReducer,
    security: securityReducer,
    settings: settingsModuleReducer,
    superUsers: superUsersModuleReducer,
    inactiveUsers: inactiveUsersModuleReducer,
  });
