import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const Warning: React.FunctionComponent = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg width="52" height="47" viewBox="0 0 52 47" xmlns="http://www.w3.org/2000/svg">
      <path d="M51.3377 38.9296L30.2927 2.47864C29.4103 0.949597 27.7649 0 26 0C24.2351 0 22.5897 0.949597 21.7065 2.47864L0.663097 38.9296C-0.220902 40.4586 -0.220902 42.3586 0.662317 43.8868C1.54475 45.4159 3.18937 46.3663 4.95581 46.3663H47.0442C48.8106 46.3663 50.4552 45.4159 51.3369 43.8876C52.2209 42.3586 52.2209 40.4586 51.3377 38.9296ZM48.6318 42.3266C48.3062 42.892 47.6978 43.2426 47.0442 43.2426H4.95581C4.30218 43.2426 3.69384 42.892 3.36742 42.3258C3.04099 41.7596 3.04099 41.0576 3.3682 40.4914L24.4116 4.04047C24.7388 3.47509 25.3472 3.12367 26 3.12367C26.6528 3.12367 27.2612 3.47509 27.5876 4.04047L48.6326 40.4914C48.959 41.0576 48.959 41.7596 48.6318 42.3266ZM24.4382 29.5929H27.5618V14.365H24.4382V29.5929ZM24.4382 37.4677H27.5618V32.6518H24.4382V37.4677Z" />
    </svg>
  </BaseSvgWrapper>
);

export default Warning;
