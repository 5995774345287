import i18n from 'config/i18n';
import OnboardingAvailabilitiesDaysSelect from 'modules/onboarding/components/contact/OnboardingAvailabilitiesDaysSelect';
import OnboardingTimeSlider from 'modules/onboarding/components/contact/OnboardingTimeSlider';
import * as Yup from 'yup';
import { InputField, StepType } from '../../types';
import { CONTACTS_OVERVIEW, CUSTOM, initialAvailabilities } from '../index';

const headerTranslations = {
  content: 'availabilities.content',
  title: 'availabilities.title',
};

const contactHoursFields: InputField[] = [
  {
    addLabel: 'Add more days',
    fields: [
      {
        component: OnboardingAvailabilitiesDaysSelect,
        initialValue: [],
        label: 'availabilities.formLabels.daysAvailable',
        name: 'days',
        type: CUSTOM,
        width: {
          desktop: 6,
          tablet: 6,
        },
      },
      {
        component: OnboardingTimeSlider,
        initialValue: [9, 17],
        label: 'availabilities.formLabels.hoursAvailable',
        name: 'hours',
        type: CUSTOM,
        width: {
          desktop: 6,
          tablet: 6,
        },
      },
    ],
    initialValue: initialAvailabilities,
    name: 'availabilities',
    removeLabel: 'Remove availability',
    type: 'array',
    width: {
      desktop: 12,
    },
  },
];

export const contactHoursValidationSchema = Yup.object().shape({
  availabilities: Yup.array()
    .of(
      Yup.object().shape({
        days: Yup.array()
          .of(Yup.string())
          .min(1, ({ min }) => i18n.t('validation:array.min', { min })),
        hours: Yup.array()
          .of(Yup.number())
          .min(2)
          .max(2),
      })
    )
    .min(1, ({ min }) => i18n.t('validation:array.min', { min })),
});

export const contactHoursStep: StepType = {
  fields: contactHoursFields,
  headerTranslations,
  key: 'availabilities',
  submitParam: CONTACTS_OVERVIEW,
  validationSchema: contactHoursValidationSchema,
};
