import { User, UserStatus } from 'modules/organization/types/organizationDetail/users';
import { getType } from 'typesafe-actions';
import { organizationDetailActions, OrganizationDetailActions } from '../../actions/creators';

const usersReducer = (state: User[] | null = null, action: OrganizationDetailActions) => {
  switch (action.type) {
    case getType(organizationDetailActions.getUsersRequest.success):
      return action.payload;

    case getType(organizationDetailActions.setUserToAdminRequest.success):
      return [...action.payload.users];

    case getType(organizationDetailActions.addUserRequest.success):
      return state ? [...state, action.payload] : [action.payload];

    case getType(organizationDetailActions.addAdminRequest.success):
      return state ? [...state, action.payload] : [action.payload];

    case getType(organizationDetailActions.approveUserRequest.success):
    case getType(organizationDetailActions.rejectUserRequest.success):
      if (!state) {
        return [action.payload];
      } else {
        const userToReplace = state.findIndex((user) => user.userId === action.payload.userId);
        const newState = [...state];
        newState.splice(userToReplace, 1, action.payload);

        return newState;
      }

    case getType(organizationDetailActions.deleteUserRequest.success):
      if (state) {
        return state.map((user) => (user.userId === action.payload ? { ...user, status: UserStatus.Suspended } : user));
      }

      return state;

    case getType(organizationDetailActions.clearOrganizationDetail):
      return null;

    case getType(organizationDetailActions.upgradeUserRequest.success):
      return state
        ? state.map((user) => (user.guid === action.payload ? { ...user, status: UserStatus.Upgraded } : user))
        : state;

    default:
      return state;
  }
};

export default usersReducer;
