import { NotFound } from 'components/EmptyState';
import { MailRoutes, MAILS_BASE_ROUTES } from 'modules/mail/routes';
import { OnboardingRoutes } from 'modules/onboarding/routes';
import { ORGANIZATION_BASE_ROUTE, OrganizationRoutes } from 'modules/organization/routes';
import { REPOSITORY_BASE_ROUTE, RepositoryRoutes } from 'modules/repository/routes';
import { SECTOR_BASE_ROUTE, SectorRoutes } from 'modules/sector/routes';
import { SECURITY_BASE_ROUTES, SecurityRoutes } from 'modules/security/routes';
import { Module } from 'modules/security/types/modules';
import { SUPER_USERS_BASE_ROUTE } from 'modules/superUsers/constants';
import { SuperUsersRoutes } from 'modules/superUsers/routes';
import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { ACTIVITIES_BASE_ROUTE, ActivitiesRoutes } from '../../activities/routes';
import { FEED_BASE_ROUTE } from '../../feed/constants';
import FeedRoutes from '../../feed/routes/FeedRoutes';
import { CRITICAL_ALERTS_BASE_ROUTE, CriticalAlertsRoutes } from '../../flashAlerts/routes';
import { HELPCENTER_BASE_ROUTE, HelpCenterRoutes } from '../../helpCenter/routes';
import { REPORTTYPE_BASE_ROUTE, ReportTypeRoutes } from '../../reportType/routes';
import SettingsRoutes, { SETTINGS_BASE_ROUTE } from '../../settings/routes/SettingsRoutes';
import { INACTIVE_USERS_BASE_ROUTE } from '../../inactiveUsers/constants';
import { InactiveUsersRoutes } from '../../inactiveUsers/routes';

type Props = {
  allowedModules: Module[];
  isAuthenticated: boolean;
  isApproval?: boolean;
};

const Routes: React.FunctionComponent<Props> = ({ allowedModules, isAuthenticated, isApproval }: Props) => {
  return isAuthenticated && !isApproval ? (
    <React.Fragment>
      <Switch>
        <Redirect from="/" exact to={REPOSITORY_BASE_ROUTE} />
        <Route path={ORGANIZATION_BASE_ROUTE} render={() => <OrganizationRoutes allowedModules={allowedModules} />} />
        <Route path={SECTOR_BASE_ROUTE} render={() => <SectorRoutes allowedModules={allowedModules} />} />
        <Route path={REPORTTYPE_BASE_ROUTE} exact render={() => <ReportTypeRoutes allowedModules={allowedModules} />} />
        <Route path={REPOSITORY_BASE_ROUTE} render={() => <RepositoryRoutes allowedModules={allowedModules} />} />
        <Route path={MAILS_BASE_ROUTES} render={() => <MailRoutes allowedModules={allowedModules} />} />
        <Route path={HELPCENTER_BASE_ROUTE} render={() => <HelpCenterRoutes allowedModules={allowedModules} />} />
        <Route path={ACTIVITIES_BASE_ROUTE} render={() => <ActivitiesRoutes allowedModules={allowedModules} />} />
        <Route path={SETTINGS_BASE_ROUTE} render={() => <SettingsRoutes />} />
        <Route path={FEED_BASE_ROUTE} render={() => <FeedRoutes allowedModules={allowedModules} />} />
        <Route
          path={CRITICAL_ALERTS_BASE_ROUTE}
          render={() => <CriticalAlertsRoutes allowedModules={allowedModules} />}
        />
        <Route path={SUPER_USERS_BASE_ROUTE} render={() => <SuperUsersRoutes allowedModules={allowedModules} />} />
        <Route
          path={INACTIVE_USERS_BASE_ROUTE}
          render={() => <InactiveUsersRoutes allowedModules={allowedModules} />}
        />
        <Route render={() => <NotFound />} />
      </Switch>
    </React.Fragment>
  ) : isAuthenticated && isApproval ? (
    <OnboardingRoutes />
  ) : (
    <Route path={SECURITY_BASE_ROUTES} render={() => <SecurityRoutes />} />
  );
};

export default Routes;
