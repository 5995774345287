import { loadScene } from 'components/async/scene';
import { Module } from 'modules/security/types/modules';
import * as React from 'react';
import { Route } from 'react-router-dom';
import ModuleRoutes from '../../../components/routing/ModuleRoutes';

type Props = {
  allowedModules: Module[];
};

export const HELPCENTER_MODULE_KEY = 'helpcenter';
export const HELPCENTER_BASE_ROUTE = '/helpcenter';

const helpCenterOverviewScene = loadScene('helpCenter', 'HelpCenterScene');

const HelpCenterRoutes: React.FunctionComponent<Props> = ({ allowedModules }: Props) => {
  return (
    <ModuleRoutes allowedModules={allowedModules} moduleKey={HELPCENTER_MODULE_KEY}>
      <Route
        path={[HELPCENTER_BASE_ROUTE, `${HELPCENTER_BASE_ROUTE}/:slug`]}
        exact
        component={helpCenterOverviewScene}
      />
    </ModuleRoutes>
  );
};

export default HelpCenterRoutes;
