import { call, put, takeLatest } from 'redux-saga/effects';
import { getFeed } from '../../adapters/feedApi';
import { feedActions } from '../creators';

function* feedSaga() {
  yield takeLatest(feedActions.getFeed, handleGetFeed);
}

function* handleGetFeed(getFeedAction) {
  const { request, success, failure } = feedActions.getFeedRequest;
  yield put(request());

  try {
    const payload = yield call(getFeed, getFeedAction.payload);
    yield put(success(payload));
  } catch (e) {
    yield put(failure(e.response));
  }
}

export default feedSaga;
