import activitiesModuleSaga from 'modules/activities/redux/sagas';
import feedSaga from 'modules/feed/redux/sagas/feedSaga';
import criticalAlertsModuleSaga from 'modules/flashAlerts/redux/sagas';
import helpCenterSaga from 'modules/helpCenter/redux/sagas';
import inactiveUsersSaga from 'modules/inactiveUsers/redux/sagas/inactiveUsers';
import mailSaga from 'modules/mail/redux/sagas';
import notificationSaga from 'modules/notifications/redux/sagas';
import onBoardingSaga from 'modules/onboarding/redux/sagas';
import organizationSaga from 'modules/organization/redux/sagas/';
import reportTypeModuleSaga from 'modules/reportType/redux/sagas';
import repositoryModuleSaga from 'modules/repository/redux/sagas';
import sectorModuleSaga from 'modules/sector/redux/sagas';
import securitySaga from 'modules/security/redux/sagas';
import settingsSaga from 'modules/settings/redux/sagas';
import superUsersSaga from 'modules/superUsers/redux/sagas/superUsers';
import { all } from 'redux-saga/effects';
import certificatesSaga from './certificates';
import criticalAlertsSaga from './criticalAlerts';
import globalInfoSaga from './globalInfo';
import pgpKeySaga from './pgpKey';
import superAdminSaga from './superAdmins';

export default function* rootSaga() {
  yield all([
    activitiesModuleSaga(),
    certificatesSaga(),
    criticalAlertsModuleSaga(),
    criticalAlertsSaga(),
    feedSaga(),
    globalInfoSaga(),
    helpCenterSaga(),
    mailSaga(),
    notificationSaga(),
    onBoardingSaga(),
    organizationSaga(),
    pgpKeySaga(),
    reportTypeModuleSaga(),
    repositoryModuleSaga(),
    sectorModuleSaga(),
    securitySaga(),
    settingsSaga(),
    superAdminSaga(),
    superUsersSaga(),
    inactiveUsersSaga(),
  ]);
}
