import * as React from 'react';
import styled from '../../config/theme';
import { ErrorText } from './ErrorText';
import { FieldContainer } from './FieldContainer';
import { Label } from './Label';

type Props = {
  error?: string;
  label?: string;
} & React.ComponentPropsWithoutRef<'textarea'>;

const TextArea: React.FunctionComponent<Props> = ({
  onChange,
  value,
  error,
  label,
  name,
  autoFocus,
  ...props
}: Props) => (
  <FieldContainer>
    {label && <Label htmlFor={name}>{label || name}</Label>}
    <StyledTextArea
      value={value}
      onChange={onChange}
      name={name}
      {...props}
      autoFocus={autoFocus}
      hasError={Boolean(error)}
    />
    {error && <ErrorText forTextArea>{error}</ErrorText>}
  </FieldContainer>
);

const StyledTextArea = styled.textarea<{ hasError?: boolean; autofocus?: boolean }>`
  background: ${props => props.theme.form.background.main};
  color: ${props => props.theme.form.color};
  border: ${props => (!props.hasError ? props.theme.form.border.input.main : props.theme.form.border.input.error)};
  border-radius: ${props => props.theme.form.borderRadius};
  box-shadow: ${props => props.theme.form.boxShadow.main};
  margin: ${props => props.theme.form.margin.main};
  padding: ${props => props.theme.form.padding.main};
  transition: box-shadow 0.15s ease-in, border 0.15s ease-in;
  max-width: 100%;
  min-height: 80px;
  resize: vertical;

  &:focus {
    box-shadow: ${props =>
      !props.hasError ? props.theme.form.boxShadow.focus : props.theme.form.boxShadow.focusError};
    border: ${props => (!props.hasError ? props.theme.form.border.input.main : props.theme.form.border.input.error)};
  }

  &[disabled] {
    background: ${props => props.theme.form.background.disabled};
    cursor: not-allowed;
  }
`;

export default TextArea;
