import { ElegibleOrganizations } from 'modules/app/types/superAdmin';
import { getType } from 'typesafe-actions';
import { superAdminActions, SuperAdminActions } from '../creators';

export type SuperAdminState = {
  elegibleOrganizations?: ElegibleOrganizations | null;
};

const initialState = {};

const superAdminReducer = (state: SuperAdminState = initialState, action: SuperAdminActions) => {
  switch (action.type) {
    case getType(superAdminActions.getElegibleOrganizationsRequest.success):
      return {
        ...state,
        elegibleOrganizations: action.payload,
      };
    default:
      return state;
  }
};

export default superAdminReducer;
