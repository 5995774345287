import { Button, EmptyStateWrapper, Modal } from 'components';
import { Repository } from 'components/icons';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ModalSizes } from '../../../../components/Modal/Modal';
import { ModuleEditAccessContext } from '../../../security';
import AddRepositoryModal from '../add/AddRepositoryModal';

type Props = {};

const RepositoryEmptyState: React.FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation('repository');
  const moduleAccess = React.useContext(ModuleEditAccessContext);

  return (
    <EmptyStateWrapper>
      <Repository />
      <p>{t('emptyState.description')}</p>
      {moduleAccess.canEdit && (
        <Modal size={ModalSizes.Medium} content={AddRepositoryModal}>
          {toggleModal => (
            <Button onClick={toggleModal} primary>
              {t('emptyState.action')}
            </Button>
          )}
        </Modal>
      )}
    </EmptyStateWrapper>
  );
};

export default RepositoryEmptyState;
