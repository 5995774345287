import { getType } from 'typesafe-actions';
import { ReportType } from '../../types/reportTypes';
import { ReportTypeActions, reportTypeActions } from '../creators';

const reportTypeReducer = (state: ReportType[] | null = null, action: ReportTypeActions) => {
  switch (action.type) {
    case getType(reportTypeActions.getReportTypesRequest.success):
      return action.payload.items;

    case getType(reportTypeActions.addReportTypeRequest.success):
      return state ? [...state, action.payload] : [action.payload];

    case getType(reportTypeActions.editReportTypeRequest.success):
    case getType(reportTypeActions.deleteReportTypeRequest.success):
    default:
      return state;
  }
};

export default reportTypeReducer;
