import { BasicTextField, FullViewBlock } from 'components/FullView';
import { FormikErrors, FormikTouched } from 'formik';
import { GeneralInfoEditValues } from 'modules/organization/types/organizationDetail/general';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  errors: FormikErrors<GeneralInfoEditValues>;
  touched: FormikTouched<GeneralInfoEditValues>;
};

const Tls: React.FunctionComponent<Props> = ({ errors, touched }: Props) => {
  const { t } = useTranslation('organization');

  return (
    <FullViewBlock amountOfButtons={2}>
      <h2 className="block-title">{t('detail.blocks.tls')}</h2>
      <div className="block-data">
        <BasicTextField name="tlsEmail" label={t('detail.labels.tlsEmail')} errors={errors} touched={touched} />
        <BasicTextField name="alertPhone" label={t('detail.labels.alertPhone')} errors={errors} touched={touched} />
      </div>
    </FullViewBlock>
  );
};

export default Tls;
