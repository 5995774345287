import { Input } from 'components';
import { ErrorText } from 'components/Form/ErrorText';
import { TYPE_RADIO } from 'components/Form/Input';
import { Label } from 'components/Form/Label';
import { FieldProps } from 'formik';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { generalIpVersions } from '../constants/generalInfo/generalIpVersions';
import { publicIpVersions } from '../constants/generalInfo/publicIpVersions';
import { createInitialFormikValuesFromFields } from '../lib';

type Props = {
  error?: string;
} & FieldProps;

const MispToMispField: React.FunctionComponent<Props> = ({ field, error, form }: Props) => {
  const { t } = useTranslation('onboarding');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    form.setFieldValue(field.name, e.target.value);
    if (e.target.value === 'true') {
      form.setFieldValue('accountNeeded', undefined);
      form.setFieldValue(
        'generalIpRanges',
        createInitialFormikValuesFromFields(generalIpVersions.fields, {}).generalIpRanges
      );
      form.setFieldValue(
        'publicIpRanges',
        createInitialFormikValuesFromFields(publicIpVersions.fields, {}).publicIpRanges
      );
    } else if (e.target.value === 'null') {
      form.setFieldValue('accountNeeded', undefined);
      form.setFieldValue(
        'publicIpRanges',
        createInitialFormikValuesFromFields(publicIpVersions.fields, {}).publicIpRanges
      );
      form.setFieldValue('generalIpRanges', []);
    } else {
      form.setFieldValue('accountNeeded', '');
      form.setFieldValue('generalIpRanges', []);
      form.setFieldValue('publicIpRanges', []);
    }
  };

  return (
    <RadioButtonsWrapper>
      <Label>{t('misp.formLabels.mispToMisp')}</Label>
      <Input
        {...field}
        type={TYPE_RADIO}
        checked={field.value.toLowerCase() === 'false'}
        value="false"
        label={t('misp.mispToMispValues.false')}
        name="mispToMisp"
        id="mispToMisp_false"
        onChange={handleChange}
      />
      <Input
        {...field}
        type={TYPE_RADIO}
        checked={field.value.toLowerCase() === 'true'}
        value="true"
        label={t('misp.mispToMispValues.true')}
        name="mispToMisp"
        id="mispToMisp_true"
        onChange={handleChange}
      />
      <Input
        {...field}
        type={TYPE_RADIO}
        checked={field.value.toLowerCase() === 'null'}
        value="null"
        label={t('misp.mispToMispValues.null')}
        name="mispToMisp"
        id="mispToMisp_null"
        onChange={handleChange}
      />
      {error && <ErrorText block>{error}</ErrorText>}
    </RadioButtonsWrapper>
  );
};

const RadioButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  label {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 5px;
    }
  }
`;

export default MispToMispField;
