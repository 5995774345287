import { FlexCol, SearchPlaceholder, Sidebar } from 'components';
import styled from 'config/theme';
import { ORGANIZATION_BASE_ROUTE } from 'modules/organization/routes';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

type Props = {
  organizationId: string;
};

const OrganizationDetailSidebar: React.FunctionComponent<Props> = ({ organizationId }: Props) => {
  const { t } = useTranslation('organization');

  return (
    <Sidebar isNavigation>
      <SearchPlaceholder />
      <FlexCol full>
        <StyledNavLink to={`${ORGANIZATION_BASE_ROUTE}/${organizationId}/general-info`} activeClassName="active">
          {t('detail.views.generalInfo')}
        </StyledNavLink>
        <StyledNavLink to={`${ORGANIZATION_BASE_ROUTE}/${organizationId}/contacts`}>
          {t('detail.views.contacts')}
        </StyledNavLink>
        <StyledNavLink to={`${ORGANIZATION_BASE_ROUTE}/${organizationId}/cti`}>{t('detail.views.cti')}</StyledNavLink>
        <StyledNavLink to={`${ORGANIZATION_BASE_ROUTE}/${organizationId}/users`}>
          {t('detail.views.users')}
        </StyledNavLink>
      </FlexCol>
    </Sidebar>
  );
};

const StyledNavLink = styled(NavLink)`
  padding: 10px;
  cursor: pointer;
  color: ${(props) => props.theme.organization.detail.color.sidebar};
  &.active {
    font-weight: 600;
  }
`;

export default OrganizationDetailSidebar;
