import styled from '../../config/theme';
import { Wrapper } from './components';

const NoListItemSelectedWrapper = styled(Wrapper)`
  > p {
    margin: 0 auto;
  }
`;

export { NoListItemSelectedWrapper };
