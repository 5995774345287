import i18n from 'config/i18n';
import * as Yup from 'yup';
import { TYPE_TEXT } from '../../../../components/Form/Input';
import { InputField } from '../../types';

const alertFeedFiels: InputField[] = [
  {
    label: 'alertFeed.formLabels.email',
    name: 'alertFeedEmail',
    type: TYPE_TEXT,
    width: {
      desktop: 6,
    },
  },
];

const alertFeedValidationSchema = Yup.object().shape({
  alertFeedEmail: Yup.string().email(() => i18n.t('validation:string.email')),
});

const headerTranslations = {
  content: 'alertFeed.content',
  title: 'alertFeed.title',
};

export const alertFeedStep = {
  fields: alertFeedFiels,
  headerTranslations,
  key: 'alertFeed',
  validationSchema: alertFeedValidationSchema,
};
