import { BasicConfirmation, ContextMenu, ContextMenuContainer } from 'components';
import { ContextMenuOrientation, ORIENTATION_BOTTOM_RIGHT } from 'components/ContextMenu/constants';
import { ContextMenuRenderProps } from 'components/ContextMenu/ContextMenu';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  callback: (args: any) => void;
  children: JSX.Element;
  orientation?: ContextMenuOrientation;
};

const SuspendConfirmation: React.FunctionComponent<Props> = ({ children, callback, orientation }: Props) => {
  const { t } = useTranslation('organization');

  return (
    <ContextMenu
      enhanceWithOnContext={false}
      orientation={orientation || ORIENTATION_BOTTOM_RIGHT}
      content={
        <ContextMenuContainer>
          <BasicConfirmation
            callback={callback}
            cancelText={t('detail.suspend.cancel')}
            confirmText={t('detail.suspend.confirm')}
            danger
            description={t('detail.suspend.description')}
          />
        </ContextMenuContainer>
      }
    >
      {({ toggleMenu }: ContextMenuRenderProps) => React.cloneElement(children, { onClick: toggleMenu })}
    </ContextMenu>
  );
};

export default SuspendConfirmation;
