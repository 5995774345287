import * as React from 'react';
import { Translation } from 'react-i18next';
import { Input, Modal } from '../';
import { ModalWrapper } from './layout';
import ModalOverlay from './layout/ModalOverlay';
import { ModalSizes } from './Modal';

export type ModalInjectedProps = {
  closeModal: VoidFunction;
  toggleModal: VoidFunction;
};

type Props = {
  toggleKeepOpen: () => void;
  handleClick: (e: React.MouseEvent) => void;
} & ModalStyleProps &
  React.ComponentProps<typeof Modal> &
  ModalInjectedProps;

export type ModalStyleProps = {
  size?: ModalSizes;
};

class ModalContainer extends React.PureComponent<Props> {
  render() {
    const {
      handleClick,
      content: Content,
      contentProps,
      closeModal,
      toggleModal,
      toggleKeepOpen,
      size,
      backdrop,
    } = this.props;

    return (
      <Translation ns="global">
        {t => (
          <ModalOverlay onMouseDown={backdrop !== 'static' ? toggleModal : undefined}>
            <ModalWrapper onClick={handleClick} size={size} onMouseDown={handleClick}>
              <Content
                toggleModal={toggleModal}
                closeModal={closeModal}
                keepOpenCheckbox={
                  <Input
                    name="createAnother"
                    label={t('global:modal.createAnother')}
                    type={'checkbox'}
                    onChange={toggleKeepOpen}
                  />
                }
                {...contentProps}
              />
            </ModalWrapper>
          </ModalOverlay>
        )}
      </Translation>
    );
  }
}
export default ModalContainer;
