import { useDispatch, useSelector } from 'react-redux';
import { makeGetProfileUpdate } from '../selectors/profileUpdate';
import { useCallback, useEffect } from 'react';
import { profileUpdateActions } from '../actions/creators';
import { makeGetOrganizationId } from '../../../onboarding/redux/selectors/user';

const useProfileUpdateStore = () => {
  const dispatch = useDispatch();

  const getProfileUpdateStep = useCallback(
    (id?: string) => {
      id && dispatch(profileUpdateActions.getProfileUpdateStep(id));
    },
    [dispatch]
  );

  const profileUpdateStep = useSelector(makeGetProfileUpdate());
  const organizationId = useSelector(makeGetOrganizationId());

  useEffect(() => {
    getProfileUpdateStep(organizationId);
  }, [getProfileUpdateStep, organizationId]);

  return {
    profileUpdateStep,
    getProfileUpdateStep,
  };
};

export default useProfileUpdateStore;
