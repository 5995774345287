import { responsive } from 'helpers';
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import { MobileSidebar, Sidebar } from './Sidebar';

type Props = {
  isNavigation?: boolean;
  overrideIsOpen?: boolean;
  setOverrideIsOpen?: () => void;
};

const ResponsiveSidebar: React.FunctionComponent<Props> = ({ isNavigation, ...rest }) => {
  const isPhone = useMediaQuery(responsive.isPhone);

  return isPhone ? (
    <MobileSidebar isNavigation={isNavigation} {...rest} />
  ) : (
    <Sidebar isNavigation={isNavigation} {...rest} />
  );
};

export default ResponsiveSidebar;
