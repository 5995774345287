import { HttpErrorResponse } from 'types';
import { createAction, createAsyncAction } from 'typesafe-actions';
import { NotificationType } from '../../types';

export const getNotifications = createAction('NOTIFICATIONS_GET');
export const getNotificationsRequest = createAsyncAction(
  'NOTIFICATIONS_GET_REQUEST',
  'NOTIFICATIONS_GET_SUCCESS',
  'NOTIFICATIONS_GET_REJECTED'
)<undefined, NotificationType[], HttpErrorResponse>();
