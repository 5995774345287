import { Sort } from 'components/icons';
import * as React from 'react';
import styled from 'styled-components';

type Props = {
  state: SortState | undefined;
};

export enum SortState {
  Asc = 'asc',
  Desc = 'desc',
  None = 'none',
}

const SortToggle: React.FunctionComponent<Props> = ({ state }: Props) => {
  return <ColoredSort sortState={state} />;
};

const ColoredSort = styled(Sort)<{ sortState?: SortState }>`
  margin-left: 5px;
  svg {
    width: 15px !important;
    height: 15px !important;
  }

  path:first-child {
    fill: ${props =>
      props.sortState && props.sortState === SortState.Asc
        ? props.theme.table.color.primary
        : props.theme.table.color.tertiary};
  }
  path:nth-child(2) {
    fill: ${props =>
      props.sortState && props.sortState === SortState.Desc
        ? props.theme.table.color.primary
        : props.theme.table.color.tertiary};
  }
`;

export default SortToggle;
