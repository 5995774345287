import * as React from 'react';
import { SelectComponentsProps } from 'react-select/base';
import styled from 'styled-components';
import { Input } from '../../../../components/Form';

const StyledOption = styled('div')<{ selected: boolean; disabled?: boolean }>`
  display: flex;
  height: 40px;
  align-items: center;
  background: ${props => (props.selected ? props.theme.palette.primary.shade1 : 'white')};
  transition: background 0.2s linear;
  ${props => props.disabled && `cursor: not-allowed; background: ${props.theme.palette.neutral.shade1}`};

  > div {
    align-self: initial;
    margin-left: 15px;
  }

  span {
    text-transform: capitalize;
  }
`;

const SelectOption = ({ innerProps, ...props }: SelectComponentsProps) => (
  <StyledOption key={props.value} selected={props.isSelected} {...innerProps} disabled={props.isDisabled}>
    <Input
      key={props.isSelected}
      defaultChecked={props.isSelected}
      name={innerProps.label}
      type="checkbox"
      disabled={props.isDisabled}
    />
    <span>{props.label}</span>
  </StyledOption>
);

export default SelectOption;
