import { combineReducers } from 'redux';
import feedsReducer, { FeedsModuleState } from './feed';

export type FeedsState = {
  feeds: FeedsModuleState;
};

const feedModuleReducer = combineReducers({
  feeds: feedsReducer,
});

export default feedModuleReducer;
