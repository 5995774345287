import { Download } from 'components/icons';
import * as React from 'react';
import styled from 'styled-components';

type Props = {
  to: string;
  text: string;
};

const DownloadLink: React.FunctionComponent<Props> = ({ to, text }: Props) => {
  return (
    <Wrapper href={to}>
      <div>{text}</div>
      <Download />
    </Wrapper>
  );
};

const Wrapper = styled.a`
  display: flex;
  align-items: center;
  text-decoration: underline;
  color: ${props => props.theme.palette.primary.shade4};

  svg {
    margin-left: 10px;
    height: 16px;
    width: 16px;
  }
`;

export default DownloadLink;
