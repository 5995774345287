import * as Yup from 'yup';
import LanguageSelect from '../components/select/LanguageSelect';
import { InputField } from '../types';
import { CUSTOM, USER_DATA } from './index';

export type UserFieldTypes = {
  firstName: string;
  lastName: string;
  department: string;
};

export const userFields: InputField[] = [
  {
    label: 'user.formLabels.firstName',
    name: 'firstName',
    type: 'text',
    width: {
      desktop: 6,
      tablet: 6,
    },
  },
  {
    label: 'user.formLabels.lastName',
    name: 'name',
    type: 'text',
    width: {
      desktop: 6,
      tablet: 6,
    },
  },
  {
    label: 'user.formLabels.department',
    name: 'department',
    type: 'text',
    width: {
      desktop: 6,
      tablet: 6,
    },
  },
  {
    component: LanguageSelect,
    label: 'user.formLabels.language',
    name: 'language',
    type: CUSTOM,
    width: {
      desktop: 6,
      tablet: 6,
    },
  },
];

export const userValidationSchema = Yup.object().shape({
  department: Yup.string().required(),
  firstName: Yup.string()
    .required()
    .max(100)
    .trim(),
  language: Yup.string()
    .max(2)
    .trim(),
  name: Yup.string()
    .required()
    .max(200)
    .trim(),
});

export const headerTranslations = {
  content: 'user.content',
  title: 'user.title',
};

export const userStep = {
  fields: userFields,
  headerTranslations,
  key: 'user',
  submitParam: USER_DATA,
  validationSchema: userValidationSchema,
};
