import * as React from 'react';
import { Translation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import AddButton from './AddButton';
import ItemSidebarWrapper from './ItemSidebarWrapper';
import LoadingState from './LoadingState';

type Props = {
  children: JSX.Element | JSX.Element[] | undefined;
  getDataCb: () => void;
  hasMore: boolean;
  isPending: boolean;
  listSize: number;
  totalItems?: number;
  search?: JSX.Element;
  searchText?: string;
  addButtonUrl?: string;
};

class ItemSidebar extends React.PureComponent<Props> {
  private wrapper: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();

  componentDidUpdate(prevProps: Props) {
    if (prevProps.searchText !== this.props.searchText) {
      if (this.wrapper && this.wrapper.current) {
        this.wrapper.current.scrollTop = 0;
      }
    }
  }

  render() {
    const { children, listSize, getDataCb, isPending, search, hasMore, totalItems, addButtonUrl } = this.props;

    return (
      <ItemSidebarWrapper id="scrollable-list" ref={this.wrapper}>
        {search}
        <Translation ns="global">
          {t => (
            <React.Fragment>
              {Boolean(totalItems) && <span className="count">{`${listSize} ${t('list.countOf')} ${totalItems}`}</span>}
              {addButtonUrl && <AddButton addUrl={addButtonUrl} />}
              <InfiniteScroll
                dataLength={listSize}
                next={getDataCb}
                hasMore={hasMore}
                loader={<h4 className="loading-text">{t('general.loading')}</h4>}
                scrollableTarget="scrollable-list"
                scrollThreshold="75%"
              >
                {children || (isPending && <LoadingState />)}
              </InfiniteScroll>
            </React.Fragment>
          )}
        </Translation>
      </ItemSidebarWrapper>
    );
  }
}

export default ItemSidebar;
