import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const ReportType: React.FunctionComponent = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 32 32"
      xmlSpace="preserve"
    >
      <g>
        <g>
          <path d="M11,21H3c-0.5527344,0-1-0.4472656-1-1v-8c0-0.5527344,0.4472656-1,1-1h8c0.5527344,0,1,0.4472656,1,1v8    C12,20.5527344,11.5527344,21,11,21z M4,19h6v-6H4V19z" />
        </g>
        <g>
          <path d="M29,28h-8c-0.5527344,0-1-0.4472656-1-1v-8c0-0.5527344,0.4472656-1,1-1h8c0.5527344,0,1,0.4472656,1,1v8    C30,27.5527344,29.5527344,28,29,28z M22,26h6v-6h-6V26z" />
        </g>
        <g>
          <path d="M29,12h-8c-0.5527344,0-1-0.4472656-1-1V3c0-0.5527344,0.4472656-1,1-1h8c0.5527344,0,1,0.4472656,1,1v8    C30,11.5527344,29.5527344,12,29,12z M22,10h6V4h-6V10z" />
        </g>
        <g>
          <path d="M16,32c-0.5527344,0-1-0.4472656-1-1V1c0-0.5527344,0.4472656-1,1-1s1,0.4472656,1,1v30    C17,31.5527344,16.5527344,32,16,32z" />
        </g>
        <g>
          <path d="M16,17h-5c-0.5527344,0-1-0.4472656-1-1s0.4472656-1,1-1h5c0.5527344,0,1,0.4472656,1,1S16.5527344,17,16,17z" />
        </g>
        <g>
          <path d="M21,8h-5c-0.5527344,0-1-0.4472656-1-1s0.4472656-1,1-1h5c0.5527344,0,1,0.4472656,1,1S21.5527344,8,21,8z" />
        </g>
        <g>
          <path d="M21,24h-5c-0.5527344,0-1-0.4472656-1-1s0.4472656-1,1-1h5c0.5527344,0,1,0.4472656,1,1S21.5527344,24,21,24z" />
        </g>
      </g>
    </svg>
  </BaseSvgWrapper>
);

export default ReportType;
