import * as React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { ORG_BASE_ROUTE, USERS_URL } from '../constants';
import { NotificationType, CallToActionType } from '../types';
import { NotificationLayout } from './NotificationLayout';

type Props = {
  notification: NotificationType;
  toggleDrawer: () => void;
};

const Notification: React.FunctionComponent<Props> = ({ notification, toggleDrawer }: Props) => {
  const link = React.useMemo(
    () =>
      notification.callToActionType === CallToActionType.OrganizationHome
        ? `${ORG_BASE_ROUTE}/${notification.organizationId}`
        : `${ORG_BASE_ROUTE}/${notification.organizationId}/${USERS_URL}`,
    [notification]
  );

  return (
    <NavLinkBlock to={link} onClick={toggleDrawer}>
      <NotificationLayout>
        <span>{notification.message}</span>
      </NotificationLayout>
    </NavLinkBlock>
  );
};

const NavLinkBlock = styled(NavLink)`
  display: block;
  padding: 0 24px;

  &:hover {
    background: ${(props) => props.theme.notifications.notification.background.hover};
  }
`;

export default Notification;
