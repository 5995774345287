import { FieldProps, getIn } from 'formik';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConvertedFile } from '../../../components/Dropzone/FileInput';
import FileUpload from '../../../components/Dropzone/FileUpload';
import { isSingleFile } from '../../../components/Dropzone/isSingleFileHelper';
import { certificatesActions } from '../redux/creators';
import { makeGetCertificates, makeGetCertificatesName } from '../redux/selectors/certificates';
import { Certificate } from '../types/certificates';

type Props = {
  values: any;
} & FieldProps;

const CerticatesUpload: React.FunctionComponent<Props> = ({ form, field }) => {
  const dispatch = useDispatch();
  const alreadyUploadedFileNames = useSelector(makeGetCertificatesName());
  const certificates = useSelector(makeGetCertificates());
  const [fileNames, setFileNames] = React.useState<string[]>(
    (field.value as Certificate[]).map(certificate => certificate.fileName)
  );

  const initialValues = field.value.map((certificate: Certificate) => ({
    base64: certificate.content,
    file: null,
    name: certificate.fileName,
  }));

  React.useEffect(() => {
    dispatch(certificatesActions.setCertificates(field.value as Certificate[]));
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    const newCertificates = certificates.filter(certificate => fileNames.includes(certificate.fileName));
    form.setFieldValue(field.name, newCertificates);
    // eslint-disable-next-line
  }, [certificates, fileNames]);

  const handleUploadToServer = React.useCallback(
    (files: ConvertedFile[] | ConvertedFile) => {
      isSingleFile(files)
        ? dispatch(certificatesActions.postCertificates(files))
        : files
            .filter(file => !alreadyUploadedFileNames.includes(file.name) && file.file)
            .forEach(file => dispatch(certificatesActions.postCertificates(file)));
    },
    [dispatch, alreadyUploadedFileNames]
  );

  const handleChange = React.useCallback(
    (files: ConvertedFile[] | ConvertedFile) => {
      handleUploadToServer(files);
      if (!isSingleFile(files)) {
        setFileNames(files.map(file => file.name));
      } else {
        setFileNames([files.name]);
      }
    },
    [handleUploadToServer]
  );

  return (
    <FileUpload
      inputId={field.name}
      acceptedFileTypes={['.pem', '.crt']}
      isMulti={true}
      onChange={handleChange}
      initialValues={initialValues}
      error={getIn(form.errors, field.name)}
    />
  );
};

export default CerticatesUpload;
