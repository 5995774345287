import { createAction, createAsyncAction } from 'typesafe-actions';
import { ConvertedFile } from '../../../../components/Dropzone/FileInput';
import { HttpErrorData } from '../../../../types';
import { Certificate } from '../../types/certificates';

export const setCertificates = createAction('CERTIFICATES_SET', action => (certificates: Certificate[]) =>
  action(certificates)
);

export const postCertificates = createAction('CERTIFICATES_UPLOAD_POST', action => (file: ConvertedFile) =>
  action(file)
);
export const postCertificatesRequest = createAsyncAction(
  'CERTIFICATES_UPLOAD_POST_REQUEST',
  'CERTIFICATES_UPLOAD_POST_SUCCESS',
  'CERTIFICATES_UPLOAD_POST_REJECTED'
)<any, Certificate, HttpErrorData>();
