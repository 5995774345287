import { Contact } from 'modules/organization/types/organizationDetail/contacts';
import { getType } from 'typesafe-actions';
import { organizationDetailActions, OrganizationDetailActions } from '../../actions/creators';

const contactsReducer = (state: Contact[] | null = null, action: OrganizationDetailActions) => {
  switch (action.type) {
    case getType(organizationDetailActions.getContactsRequest.success):
    case getType(organizationDetailActions.updateContactsRequest.success):
      return action.payload;

    case getType(organizationDetailActions.clearOrganizationDetail):
      return null;

    default:
      return state;
  }
};

export default contactsReducer;
