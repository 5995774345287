import styled from 'styled-components';

const FullSearchFilter = styled.div`
  border-bottom: 2px dashed ${props => props.theme.palette.neutral.shade3};
  background-color: white;
  display: flex;
  position: relative;
  flex-direction: column;
  margin-top: -2px;

  p {
    color: ${props => props.theme.palette.neutral.shade4};
    font-size: ${props => props.theme.typography.fontSize.small_4};
    font-style: italic;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  width: 30px;
`;

export const FullSearchInputContainer = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 10px;

  h5.fullTextInputText {
    margin: 0;
    display: inline-block;
  }
`;

export default FullSearchFilter;
