import { SearchAction, searchActions } from 'modules/app/redux/creators';
import { CRITICAL_ALERTS_FILTER_KEY } from 'modules/flashAlerts/constants';
import { filterActions, FilterActions } from 'modules/flashAlerts/redux/creators';
import { getType } from 'typesafe-actions';
import { CriticalAlertsFilters } from '../../types';

const filterReducer = (state: CriticalAlertsFilters = {}, action: SearchAction | FilterActions) => {
  const isEmptyPayload = Boolean(!action.payload);
  const stateCopy = { ...state };
  const isEmptyArray = Array.isArray(action.payload) && action.payload.length === 0;

  switch (action.type) {
    case getType(filterActions.setDateFromFilter):
      if (isEmptyPayload) {
        delete stateCopy.dateFrom;

        return stateCopy;
      } else {
        return { ...state, dateFrom: action.payload };
      }

    case getType(filterActions.setDateUntilFilter):
      if (isEmptyPayload) {
        delete stateCopy.dateUntil;

        return stateCopy;
      } else {
        return { ...state, dateUntil: action.payload };
      }

    case getType(filterActions.setSectorFilter):
      if (isEmptyArray) {
        delete stateCopy.sectors;

        return stateCopy;
      } else {
        return { ...state, sectors: action.payload };
      }

    case getType(filterActions.setReportTypeFilter):
      if (isEmptyArray) {
        delete stateCopy.reportType;

        return stateCopy;
      } else {
        return { ...state, reportType: action.payload };
      }

    case getType(filterActions.setAudienceFilter):
      if (isEmptyArray) {
        delete stateCopy.audience;

        return stateCopy;
      } else {
        return { ...state, audience: action.payload };
      }

    case getType(filterActions.setPriorityFilter):
      if (isEmptyArray) {
        delete stateCopy.priority;

        return stateCopy;
      } else {
        return { ...state, priority: action.payload };
      }

    case getType(filterActions.setTlpCodeFilter):
      if (isEmptyArray) {
        delete stateCopy.tlpCode;

        return stateCopy;
      } else {
        return { ...state, tlpCode: action.payload };
      }

    case getType(searchActions.setSearch):
      return action.payload.filterKey === CRITICAL_ALERTS_FILTER_KEY
        ? { ...state, search: action.payload.searchText }
        : state;

    case getType(searchActions.clearSearch):
      delete stateCopy.search;

      return stateCopy;

    default:
      return state;
  }
};

export default filterReducer;
