import { ActionType } from 'typesafe-actions';
import * as alertAction from './alerts';
import { createAlert, removeAlert } from './alerts';
import * as certicatesAction from './certificates';
import * as globalInfoAction from './globalInfo';
import * as pgpKeyAction from './pgpKey';
import * as searchAction from './search';
import * as criticalAlertsAction from './criticalAlerts';
import * as superAdminAction from './superAdmin';

export const searchActions = { ...searchAction };
export const globalInfoActions = { ...globalInfoAction };
export const alertActions = {
  createAlert,
  removeAlert,
};
export const certificatesActions = { ...certicatesAction };
export const pgpKeyActions = { ...pgpKeyAction };
export const criticalAlertsActions = { ...criticalAlertsAction };
export const superAdminActions = { ...superAdminAction };

export type SearchAction = ActionType<typeof searchAction>;
export type GlobalInfoActions = ActionType<typeof globalInfoAction>;
export type AlertAction = ActionType<typeof alertAction>;
export type CertificatesAction = ActionType<typeof certicatesAction>;
export type PgpKeyAction = ActionType<typeof pgpKeyAction>;
export type CriticalAlertsActions = ActionType<typeof criticalAlertsAction>;
export type SuperAdminActions = ActionType<typeof superAdminAction>;
