import { OrganizationUpdateStep } from 'modules/organization/types/organization';
import { getType } from 'typesafe-actions';
import { ProfileUpdateActions, profileUpdateActions } from '../../actions/creators';

const profileUpdateReducer = (state: OrganizationUpdateStep | null = null, action: ProfileUpdateActions) => {
  switch (action.type) {
    case getType(profileUpdateActions.getProfileUpdateStepRequest.success):
      return action.payload;

    default:
      return state;
  }
};

export default profileUpdateReducer;
