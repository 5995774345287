import { SortState } from 'components/Table/SortToggle';
import { SortValue } from 'types';
import { createAction } from 'typesafe-actions';

export const setSorting = createAction(
  'ORGANIZATION_SORT',
  action => (name: string, state: SortState, secondary?: boolean) => {
    const payload: SortValue = { name, state, secondary };

    return action(payload);
  }
);
