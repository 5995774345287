import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const Company: React.FunctionComponent = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 68" x="0px" y="0px">
      <g>
        <path d="M41,12 L41,64 C41,65.1045695 41.8954305,66 43,66 C44.1045695,66 45,65.1045695 45,64 L45,10 C45,8.8954305 44.1045695,8 43,8 L7,8 C5.8954305,8 5,8.8954305 5,10 L5,64 C5,65.1045695 5.8954305,66 7,66 C8.1045695,66 9,65.1045695 9,64 L9,12 L41,12 Z" />
        <path d="M63 64L63 23C63 21.8954305 62.1045695 21 61 21L45 21C43.8954305 21 43 21.8954305 43 23 43 24.1045695 43.8954305 25 45 25L59 25 59 31 50 31C48.8954305 31 48 31.8954305 48 33 48 34.1045695 48.8954305 35 50 35L59 35 59 42 50 42C48.8954305 42 48 42.8954305 48 44 48 45.1045695 48.8954305 46 50 46L59 46 59 53 50 53C48.8954305 53 48 53.8954305 48 55 48 56.1045695 48.8954305 57 50 57L59 57 59 64 2 64C.8954305 64 0 64.8954305 0 66 0 67.1045695.8954305 68 2 68L66 68C67.1045695 68 68 67.1045695 68 66 68 64.8954305 67.1045695 64 66 64L63 64zM34 4L34 8C34 9.1045695 34.8954305 10 36 10 37.1045695 10 38 9.1045695 38 8L38 2C38 .8954305 37.1045695 0 36 0L14 0C12.8954305 0 12 .8954305 12 2L12 8C12 9.1045695 12.8954305 10 14 10 15.1045695 10 16 9.1045695 16 8L16 4 34 4z" />
        <path d="M31,52 L31,64 C31,65.1045695 31.8954305,66 33,66 C34.1045695,66 35,65.1045695 35,64 L35,50 C35,48.8954305 34.1045695,48 33,48 L17,48 C15.8954305,48 15,48.8954305 15,50 L15,64 C15,65.1045695 15.8954305,66 17,66 C18.1045695,66 19,65.1045695 19,64 L19,52 L31,52 Z" />
        <path d="M23 52L23 64C23 65.1045695 23.8954305 66 25 66 26.1045695 66 27 65.1045695 27 64L27 52C27 50.8954305 26.1045695 50 25 50 23.8954305 50 23 50.8954305 23 52zM19 21L19 24 16 24 16 21 19 21zM14 17C12.8954305 17 12 17.8954305 12 19L12 26C12 27.1045695 12.8954305 28 14 28L21 28C22.1045695 28 23 27.1045695 23 26L23 19C23 17.8954305 22.1045695 17 21 17L14 17zM16 36L19 36 19 39 16 39 16 36zM14 32C12.8954305 32 12 32.8954305 12 34L12 41C12 42.1045695 12.8954305 43 14 43L21 43C22.1045695 43 23 42.1045695 23 41L23 34C23 32.8954305 22.1045695 32 21 32L14 32zM34 21L34 24 31 24 31 21 34 21zM29 17C27.8954305 17 27 17.8954305 27 19L27 26C27 27.1045695 27.8954305 28 29 28L36 28C37.1045695 28 38 27.1045695 38 26L38 19C38 17.8954305 37.1045695 17 36 17L29 17zM34 36L34 39 31 39 31 36 34 36zM29 32C27.8954305 32 27 32.8954305 27 34L27 41C27 42.1045695 27.8954305 43 29 43L36 43C37.1045695 43 38 42.1045695 38 41L38 34C38 32.8954305 37.1045695 32 36 32L29 32z" />
      </g>
    </svg>
  </BaseSvgWrapper>
);

export default Company;
