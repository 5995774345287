import { FormikSelect } from 'components/Form';
import { FieldProps } from 'formik';
import { getNestedError } from 'helpers/formikHelper';
import * as React from 'react';
import { components } from 'react-select';
import { ContactType } from '../types/contact';
import { useTranslation } from 'react-i18next';

type Props = {
  fieldProps: FieldProps;
  label?: string;
};

const ContactTypesSelect: React.FunctionComponent<Props> = ({ label, fieldProps: { field, form } }: Props) => {
  const { t } = useTranslation('global');

  return (
    <FormikSelect
      label={label}
      isMulti
      field={field}
      form={form}
      options={[...Object.values(ContactType)]}
      getOptionValue={option => option}
      getOptionLabel={option => t(`contactTypes.${option}`)}
      isClearable={field.value ? !field.value.includes(ContactType.Admin) : true}
      filterOption={option => option.value !== ContactType.Admin}
      components={{
        MultiValueRemove: props =>
          props.data === ContactType.Admin ? null : <components.MultiValueRemove {...props} />,
      }}
      error={getNestedError(field.name, form.errors, form.touched)}
    />
  );
};

export default ContactTypesSelect;
