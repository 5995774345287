import { Select } from 'components';
import { ErrorText } from 'components/Form/ErrorText';
import { SelectWrapper } from 'components/Form/FormikSelect';
import { Label } from 'components/Form/Label';
import { FieldProps, FormikValues, getIn } from 'formik';
import { KeywordType } from 'modules/app/types';
import { Keyword } from 'modules/organization/types/organizationDetail/cti';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  label?: string;
  keyWordType: KeywordType;
  errorAsBlock?: boolean;
} & FieldProps &
  FormikValues;

const CreatableKeywordSelect: React.FunctionComponent<Props> = ({ field, form, label, keyWordType, errorAsBlock }) => {
  const { t } = useTranslation('organization');
  const [inputValue, setInputValue] = React.useState('');
  const formValue = getIn(form.values, field.name);

  const createOption = React.useCallback(
    (text: string): Keyword => ({
      keywordId: '0',
      name: text,
      type: keyWordType,
    }),
    [keyWordType]
  );

  const handleKeyDown = React.useCallback(
    (event: any) => {
      if (!inputValue || formValue.includes(inputValue)) {
        return;
      }
      switch (event.key) {
        case 'Enter':
        case 'Tab':
          const options = [...formValue, createOption(inputValue)];
          event.preventDefault();
          form.setFieldValue(field.name, options);
          setInputValue('');
      }
    },
    [field, form, formValue, inputValue, createOption]
  );
  const handleChange = React.useCallback(
    (changeValues: any) => {
      form.setFieldValue(field.name, changeValues || []);
    },
    [form, field]
  );
  const onBlur = React.useCallback(() => {
    form.setFieldTouched(field.name);
  }, [field, form]);

  return (
    <SelectWrapper>
      {label && <Label htmlFor={field.name}>{label}</Label>}
      <Select
        isMulti={true}
        creatable={true}
        isClearable
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        onInputChange={(text: string) => setInputValue(text)}
        onBlur={onBlur}
        field={field}
        form={form}
        inputValue={inputValue}
        menuIsOpen={false}
        components={{
          DropdownIndicator: null,
        }}
        placeholder={t('detail.labels.keywordPlaceholder')}
        error={getIn(form.errors, field.name)}
        value={formValue && formValue !== null ? formValue : []}
        getOptionLabel={(keyWord: Keyword) => keyWord.name}
        getOptionValue={(keyWord: Keyword) => keyWord.name}
      />
      {getIn(form.errors, field.name) && <ErrorText block={errorAsBlock}>{getIn(form.errors, field.name)}</ErrorText>}
    </SelectWrapper>
  );
};
export default CreatableKeywordSelect;
