import { Select } from 'components';
import { RepositorySector } from 'modules/repository/types/repository';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import useGlobalInfoStore from '../../redux/services/useGlobalInfoStore';

type Props = {
  sectors?: RepositorySector[];
  filterAction: (sectors: RepositorySector[]) => void;
};

const SectorFilters: React.FunctionComponent<Props> = ({ sectors: selectedSectors, filterAction }: Props) => {
  const { t } = useTranslation('global');

  const dispatch = useDispatch();
  const { loadSectors } = useGlobalInfoStore();

  const changeHandler = React.useCallback(
    selected => {
      dispatch(filterAction(selected || []));
    },
    [dispatch, filterAction]
  );

  return (
    <div>
      <React.Fragment>
        <h5>{t('sidebar.filters.sector')}</h5>
        <Select
          async
          defaultOptions
          loadOptions={loadSectors}
          isMulti
          value={selectedSectors}
          getOptionValue={(option: RepositorySector) => option.sectorId.toString()}
          getOptionLabel={(option: RepositorySector) => option.name}
          onChange={changeHandler}
        />
      </React.Fragment>
    </div>
  );
};

export default SectorFilters;
