import { Organization } from 'modules/organization/types/organization';
import { getType } from 'typesafe-actions';
import { OrganizationActions, organizationActions } from '../../actions/creators';

const organizationReducer = (state: Organization[] | null = null, action: OrganizationActions) => {
  switch (action.type) {
    case getType(organizationActions.getOrganizationsRequest.success):
      return action.payload.items;

    case getType(organizationActions.addOrganizationRequest.success):
      return action.payload !== null ? (state ? [...state, action.payload] : [action.payload]) : state;

    default:
      return state;
  }
};

export default organizationReducer;
