import { UserStatus, UserType } from 'modules/security/types/user';
import { superUsersActions } from 'modules/superUsers/redux/creators';
import { Action } from 'redux';
import { getType } from 'typesafe-actions';
import { v4 as uuidv4 } from 'uuid';
import i18n from '../../../../../config/i18n';
import { GenericAction, HttpErrorData } from '../../../../../types';
import { criticalAlertsActions } from '../../../../flashAlerts/redux/creators';
import { helpCenterActions } from '../../../../helpCenter/redux/creators';
import { MAIL_RESEND_SUCCESS } from '../../../../mail/redux/actions/constants/mail';
import { organizationActions, organizationDetailActions } from '../../../../organization/redux/actions/creators';
import { reportTypeActions } from '../../../../reportType/redux/creators';
import { repositoryActions } from '../../../../repository/redux/creators';
import { sectorActions } from '../../../../sector/redux/actions/creators';
import { userActions } from '../../../../security/redux/actions/creators';
import { alertRulesActions } from '../../../../settings/redux/creators';
import { AlertType, AlertWithIdentifier } from '../../../types/alert';
import { alertActions } from '../../creators';
import { inactiveUsersActions } from '../../../../inactiveUsers/redux/creators';

export type AlertState = AlertWithIdentifier[];

const alertsReducer = (state: AlertState = [], action: Action): AlertState => {
  switch (action.type) {
    case getType(alertActions.createAlert):
      return [...state, { ...(action as ReturnType<typeof alertActions.createAlert>).payload, id: uuidv4() }];
    case getType(alertActions.removeAlert):
      return state.filter((alert) => alert.id !== (action as ReturnType<typeof alertActions.removeAlert>).payload);

    case getType(helpCenterActions.submitContactFormRequest.success):
    case getType(organizationActions.addOrganizationRequest.success):
    case getType(organizationDetailActions.addUserRequest.success):
    case getType(organizationDetailActions.addAdminRequest.success):
    case getType(organizationDetailActions.approveRequest.success):
    case getType(organizationDetailActions.approveUserRequest.success):
    case getType(organizationDetailActions.deleteOrganizationRequest.success):
    case getType(organizationDetailActions.deleteUserRequest.success):
    case getType(organizationDetailActions.rejectRequest.success):
    case getType(organizationDetailActions.rejectUserRequest.success):
    case getType(organizationDetailActions.updateContactsRequest.success):
    case getType(organizationDetailActions.updateCTIDataRequest.success):
    case getType(organizationDetailActions.updateGeneralInfoRequest.success):
    case getType(organizationDetailActions.updateOrganizationRequest.success):
    case getType(organizationDetailActions.userResetAuthenticatorRequest.success):
    case getType(organizationDetailActions.userResetPasswordRequest.success):
    case getType(reportTypeActions.addReportTypeRequest.success):
    case getType(reportTypeActions.deleteReportTypeRequest.success):
    case getType(repositoryActions.deleteRepositoryRequest.success):
    case getType(repositoryActions.editRepositoryRequest.success):
    case getType(sectorActions.addSectorRequest.success):
    case getType(sectorActions.deleteSectorRequest.success):
    case getType(sectorActions.editSectorRequest.success):
    case getType(userActions.resetPasswordRequest.success):
    case getType(alertRulesActions.addAlertRuleRequest.success):
    case getType(alertRulesActions.editAlertRuleRequest.success):
    case getType(alertRulesActions.deleteAlertRuleRequest.success):
    case getType(criticalAlertsActions.editCriticalAlertRequest.success):
    case getType(criticalAlertsActions.deleteCriticalAlertRequest.success):
    case getType(repositoryActions.reportQuestionRequest.success):
    case getType(superUsersActions.addSuperUserRequest.success):
    case getType(superUsersActions.disableSuperUserRequest.success):
    case getType(superUsersActions.enableSuperUserRequest.success):
    case getType(superUsersActions.downgradeSuperUserRequest.success):
    case getType(superUsersActions.reset2FARequest.success):
    case getType(inactiveUsersActions.sendReminderRequest.success):
    case getType(organizationDetailActions.upgradeUserRequest.success):
    case MAIL_RESEND_SUCCESS:
    case getType(organizationDetailActions.resendInvitationRequest.success):
      return [...state, { id: uuidv4(), message: i18n.t('alerts.success'), type: AlertType.Success }];

    case getType(userActions.fetchUser.failure):
      return state;

    case getType(userActions.fetchUser.success):
      if ((action as ReturnType<typeof userActions.fetchUser.success>).payload.userStatus === UserStatus.Validating) {
        if ((action as ReturnType<typeof userActions.fetchUser.success>).payload.userType === UserType.Admin) {
          return [
            ...state,
            {
              id: uuidv4(),
              message: i18n.t('alerts.organizationWaiting'),
              noAutoClose: true,
              type: AlertType.Default,
            },
          ];
        } else {
          return [
            ...state,
            {
              id: uuidv4(),
              message: i18n.t('alerts.userWaiting'),
              noAutoClose: true,
              type: AlertType.Default,
            },
          ];
        }
      }

      return state;

    default:
      const { type, payload } = action as GenericAction;
      const matches = /(.*)_REJECTED/.exec(type);

      if (!matches) {
        return state;
      }

      if (payload && payload.data && payload.data.statusCode && payload.data.statusCode === 400) {
        const { errorMessage, fieldErrors } = payload.data as HttpErrorData;

        if (fieldErrors && Object.keys(fieldErrors).length > 0) {
          const fieldErrormessages = Object.keys(fieldErrors)
            .map((key) => {
              return `${key}: ${i18n.t(`errors:${fieldErrors[key]}`)}\n\n`;
            })
            .join();

          return [...state, { id: uuidv4(), message: fieldErrormessages, type: AlertType.Danger }];
        }

        return [
          ...state,
          {
            id: uuidv4(),
            message: i18n.t(`errors:errorPrefix`) + i18n.t(`errors:${errorMessage}`),
            type: AlertType.Danger,
          },
        ];
      }

      return [...state, { id: uuidv4(), message: i18n.t('alerts.error'), type: AlertType.Danger }];
  }
};

export default alertsReducer;
