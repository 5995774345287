import { combineReducers } from 'redux';
import { PaginationInfo, SortValue } from 'types';
import { ReportType, ReportTypefilters } from '../../types/reportTypes';
import filterReducer from './filters';
import pageInfoReducer from './pageInfo';
import reportTypeReducer from './reportType';
import sortingReducer from './sorting';

export type ReportTypeModuleState = {
  filters: ReportTypefilters;
  pageInfo: PaginationInfo;
  reportTypes: ReportType[] | null;
  sorting: SortValue[];
};

const reportTypeModuleReducer = combineReducers({
  filters: filterReducer,
  pageInfo: pageInfoReducer,
  reportTypes: reportTypeReducer,
  sorting: sortingReducer,
});

export default reportTypeModuleReducer;
