import AxiosConfig, { RequestConfig } from '../../../config/api/AxiosConfig';
import { CRITICAL_ALERTS_URL } from './endpoints';

export const getUnconfirmedAlerts = async () => {
  const requestConfig: RequestConfig = {
    url: `${CRITICAL_ALERTS_URL}/unconfirmed`,
  };

  return AxiosConfig.fetchAxiosGetRequest(requestConfig);
};

export const getCriticalAlertDetail = async (guid: string) => {
  const requestConfig: RequestConfig = {
    url: `${CRITICAL_ALERTS_URL}/${guid}`,
  };

  return AxiosConfig.fetchAxiosGetRequest(requestConfig);
};

export const confirmCriticalAlert = async (guid: string) => {
  const requestConfig: RequestConfig = {
    url: `${CRITICAL_ALERTS_URL}/${guid}/confirm`,
  };

  return AxiosConfig.fetchAxiosPostRequest(requestConfig);
};
