import { RootState } from 'config/store/rootReducer';
import { Repository, RepositoryFilters } from 'modules/repository/types/repository';
import { createSelector } from 'reselect';
import { PaginationInfo, SortValue } from 'types';

const getRepositories = (state: RootState): Repository[] | null => state.repositories.repository.repositories;
export const makeGetRepositories = () =>
  createSelector(getRepositories, (repositories: Repository[] | null) => repositories);

const getFilters = (state: RootState): RepositoryFilters => state.repositories.repository.filters;
export const makeGetRepositoryFilters = () =>
  createSelector(getFilters, (repositories: RepositoryFilters) => repositories);

const getPaginationInfo = (state: RootState): PaginationInfo => state.repositories.repository.pageInfo;
export const makeGetRepositoryPageInfo = () =>
  createSelector(getPaginationInfo, (pageInfo: PaginationInfo) => pageInfo);

const getSortingInfo = (state: RootState): SortValue[] => state.repositories.repository.sorting;
export const makeGetRepositoriesSorting = () => createSelector(getSortingInfo, (sorting: SortValue[]) => sorting);
