import { UnbraceThemeProvider } from '@unbrace/components';
import 'config/i18n';
import configureStore, { history } from 'config/store/configureStore';
import { rootReducer } from 'config/store/rootReducer';
import { GlobalStyle, theme } from 'config/theme';
import 'config/yup';
import 'react-day-picker/lib/style.css';
import { ConnectedRouter } from 'connected-react-router';
import { SecureModules } from 'modules/security';
import { Account, AdminAccount, GenericAccount, UserStatus } from 'modules/security/types/user';
import * as React from 'react';
import { Provider } from 'react-redux';
import UnconfirmedCriticalAlerts from '../components/CriticalAlerts';
import ProfileUpdate from '../components/ProfileUpdate';
import { NavigationSidebar } from '../components/navigationSidebar';
import Routes from '../routes/Routes';
import Alerts from '../services/Alerts';
import { UserType } from 'modules/security/types/user';

const hasUserStatus = (account: GenericAccount | AdminAccount): account is AdminAccount =>
  (account as AdminAccount).userStatus !== undefined;

const isApproval = (account?: Account) =>
  account && hasUserStatus(account) && account.userStatus === UserStatus.Pending;

const App = () => (
  <UnbraceThemeProvider customTheme={theme.default}>
    <React.Suspense fallback={<div />}>
      <Provider store={configureStore(rootReducer(history))}>
        <GlobalStyle />
        <Alerts />
        <ConnectedRouter history={history}>
          <SecureModules>
            {({ modules, isAuthenticated, account }) => (
              <React.Fragment>
                {!isApproval(account) && (
                  <React.Fragment>
                    {account?.userType !== UserType.User && <ProfileUpdate />}
                    <UnconfirmedCriticalAlerts />
                    <NavigationSidebar availableModules={[...modules]} />
                  </React.Fragment>
                )}
                <Routes allowedModules={modules} isAuthenticated={isAuthenticated} isApproval={isApproval(account)} />
              </React.Fragment>
            )}
          </SecureModules>
        </ConnectedRouter>
      </Provider>
    </React.Suspense>
  </UnbraceThemeProvider>
);

export default App;
