import { SearchAction, searchActions } from 'modules/app/redux/creators';
import { SUPER_USERS_FILTER_KEY } from 'modules/superUsers/constants';
import { SuperUsersFilters } from 'modules/superUsers/types';
import { getType } from 'typesafe-actions';
import { FilterActions, filterActions } from '../creators';

const filterReducer = (state: SuperUsersFilters = { onlyEnabled: false }, action: SearchAction | FilterActions) => {
  const stateCopy = { ...state };

  switch (action.type) {
    case getType(searchActions.setSearch):
      return action.payload.filterKey === SUPER_USERS_FILTER_KEY
        ? { ...state, search: action.payload.searchText }
        : state;

    case getType(searchActions.clearSearch):
      delete stateCopy.search;

      return stateCopy;
    case getType(filterActions.setRoleFilter):
      const isFilterEmpty = action.payload.length === 0;
      if (isFilterEmpty) {
        delete stateCopy.roles;

        return stateCopy;
      } else {
        return { ...state, roles: action.payload };
      }
    case getType(filterActions.setOnlyEnabledFilter):
      return { ...state, onlyEnabled: action.payload };

    default:
      return state;
  }
};

export default filterReducer;
