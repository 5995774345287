import { IconButton } from 'components';
import { ArrayHelperActionWrapper, BasicTextField, FullViewSubBlock } from 'components/FullView';
import { Add, Delete } from 'components/icons';
import { ArrayHelpers, FormikErrors, FormikTouched } from 'formik';
import { newDomain } from 'modules/organization/constants';
import { CTIData, Domain } from 'modules/organization/types/organizationDetail/cti';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  domain: Domain;
  index: number;
  errors: FormikErrors<CTIData>;
  touched: FormikTouched<CTIData>;
  arrayHelpers: ArrayHelpers;
};

const EditDomain: React.FunctionComponent<Props> = ({ domain, index, errors, touched, arrayHelpers }: Props) => {
  const { t } = useTranslation('organization');

  const addDomain = React.useCallback(() => arrayHelpers.push(newDomain), [arrayHelpers]);
  const removeDomain = React.useCallback(() => arrayHelpers.remove(index), [arrayHelpers, index]);

  return (
    <FullViewSubBlock>
      <h3 className="sub-title">{domain.domainName || t('detail.labels.domainPlaceholder')}</h3>
      <ArrayHelperActionWrapper>
        <BasicTextField
          name={`domains.${index}.domainName`}
          label={t('detail.labels.name')}
          errors={errors}
          touched={touched}
          autoFocus={!domain.domainId}
        />
        {index === 0 && (
          <IconButton onClick={addDomain} tooltip={t('tooltips.addDomain')}>
            <Add />
          </IconButton>
        )}
        <IconButton onClick={removeDomain} tooltip={t('tooltips.removeDomain')}>
          <Delete />
        </IconButton>
      </ArrayHelperActionWrapper>
      <BasicTextField
        name={`domains.${index}.registrar`}
        label={t('detail.labels.registrar')}
        errors={errors}
        touched={touched}
      />
      <BasicTextField
        name={`domains.${index}.hostingCompany`}
        label={t('detail.labels.hostingCompany')}
        errors={errors}
        touched={touched}
      />
      <BasicTextField
        name={`domains.${index}.emailAbuse`}
        label={t('detail.labels.emailAbuse')}
        errors={errors}
        touched={touched}
      />
    </FullViewSubBlock>
  );
};

export default EditDomain;
