export type NotificationType = {
  correlationId: string;
  message: string;
  notificationId: string;
  status: string;
  timeStamp: string;
  organizationId: string;
  callToActionType: string;
};

export enum CallToActionType {
  UserHome = 'usersHome',
  OrganizationHome = 'organizationHome'
}