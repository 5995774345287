import AxiosConfig, { RequestConfig } from 'config/api/AxiosConfig';
import { NOTIFICATIONS_URL } from './endpoints';

const getNotifications = () => {
  const requestConfig: RequestConfig = {
    url: NOTIFICATIONS_URL,
  };

  return AxiosConfig.fetchAxiosGetRequest(requestConfig);
};

export default { getNotifications };
