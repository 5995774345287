import { useFocus } from 'hooks';
import * as React from 'react';
import SelectComp, { OptionTypeBase, Props as ReactSelectProps } from 'react-select';
import Async from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import promiseDebounce from 'debounce-promise';
import { theme } from '../../config/theme/index';

type OwnProps<T extends OptionTypeBase> = ReactSelectProps<T> & {
  error?: string;
  name?: string;
  options?: T[];
  creatable?: boolean;
  autoFocus?: boolean;
  isCapitalized?: boolean;
};

const Select = <T extends {}>({
  options,
  async,
  creatable,
  loadOptions,
  defaultOptions,
  isCapitalized,
  ...props
}: OwnProps<T>) => {
  const autoFocusRef = useFocus();
  const { control, option } = theme.default.form.select;
  const customStyles = {
    control: (provided: any, state: any) => {
      const { ...defaultStyles } = provided;

      return {
        ...defaultStyles,
        ...control.regularStyles,
        border: props.error
          ? control.stateStyles.borderError
          : state.isFocused
          ? control.stateStyles.borderFocus
          : control.stateStyles.border,
        boxShadow: state.isFocused
          ? props.error
            ? control.stateStyles.boxShadowFocusError
            : control.stateStyles.boxShadowFocus
          : control.stateStyles.boxShadow,
      };
    },
    indicatorSeparator: () => ({
      display: 'none',
    }),
    option: (provided: any, state: any) => {
      const { stateStyles } = option;

      return {
        ...provided,
        ...option.regularStyles,
        textTransform: isCapitalized ? 'capitalize' : 'inherit',
        background: state.isSelected
          ? stateStyles.backgroundActive
          : state.isFocused
          ? stateStyles.backgroundHover
          : stateStyles.background,
        color: state.isSelected
          ? stateStyles.colorActive
          : state.isDisabled
          ? stateStyles.colorDisabled
          : stateStyles.color,
        cursor: state.isDisabled ? 'not-allowed' : 'default',
      };
    },
  };

  return async ? (
    <Async
      cacheOptions={true}
      defaultOptions={defaultOptions || true}
      loadOptions={promiseDebounce(loadOptions, 340)}
      styles={customStyles}
      ref={props.autoFocus ? autoFocusRef : undefined}
      {...props}
    />
  ) : creatable ? (
    <CreatableSelect
      styles={customStyles}
      options={options}
      ref={props.autoFocus ? autoFocusRef : undefined}
      {...props}
    />
  ) : (
    <SelectComp styles={customStyles} options={options} ref={props.autoFocus ? autoFocusRef : undefined} {...props} />
  );
};

export default Select;
