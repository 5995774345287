import * as React from 'react';
import styled from 'styled-components';
import { Badge, TextWrap } from '@unbrace/components';

type Props = {
  organizationColor: 'danger' | 'success' | 'neutral';
  children?: string | number | JSX.Element | JSX.Element[] | React.ReactNode;
  width?: number;
};

const DifferenceBadge: React.FC<Props> = ({ children, ...props }: Props) => {
  return (
    <ContractBadge {...props} size="large" isCapitalized>
      <BadgeContent {...props}>
        <p className={props.organizationColor === 'danger' ? 'first min' : 'first plus'}>
          {props.organizationColor === 'danger' ? '-' : props.organizationColor === 'success' ? '+' : '='}
        </p>
        <TextWrap className="content">{children}</TextWrap>
      </BadgeContent>
    </ContractBadge>
  );
};

export default DifferenceBadge;

const ContractBadge = styled(Badge)<Props>`
  background: ${(props) =>
    `linear-gradient(to right, ${props.theme.palette[`${props.organizationColor}`].shade4} 0px, ${
      props.theme.palette[`${props.organizationColor}`].shade4
    } 23px, ${props.theme.palette[`${props.organizationColor}`].shade1} 24px, ${
      props.theme.palette[`${props.organizationColor}`].shade1
    } 100%)`};
  border-radius: 5px;
  padding: 0;
  margin-bottom: 20px;
  width: auto;

  &,
  > span {
    max-width: ${(props) => props.organizationColor !== 'neutral' && '350px'};
  }
`;

export const BadgeContent = styled.span<Props>`
  display: flex;
  flex-direction: row;
  padding: 2px;
  padding-right: 5px;
  text-transform: none;

  p,
  .content {
    margin-bottom: 0;
    font-size: 14px;
    color: ${(props) =>
      props.organizationColor === 'danger'
        ? `${props.theme.palette.danger.shade4}`
        : props.organizationColor === 'success'
        ? `${props.theme.palette.success.shade5}`
        : `${props.theme.palette.neutral.shade6}`};
  }

  .first {
    color: ${(props) => `${props.theme.palette[`${props.organizationColor}`].shade1}`};
    font-weight: 600;
  }

  .plus {
    margin-left: 6px;
  }

  .min {
    margin-left: 7px;
  }

  .content {
    font-weight: 500;
    margin-left: 15px;
    padding-right: 5px;
  }
`;
