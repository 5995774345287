import { getType } from 'typesafe-actions';
import { SearchAction, searchActions } from '../../../app/redux/creators';
import { MAIL_FILTER_KEY } from '../../constants/mails';
import { MailFilters } from '../../types/mails';

const filterReducer = (state: MailFilters = {}, action: SearchAction) => {
  switch (action.type) {
    case getType(searchActions.setSearch):
      return action.payload.filterKey === MAIL_FILTER_KEY ? { ...state, search: action.payload.searchText } : state;

    case getType(searchActions.clearSearch):
      const newState = { ...state };
      delete newState.search;

      return newState;

    default:
      return state;
  }
};

export default filterReducer;
