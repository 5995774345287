import { Modal } from '../../../../components';
import { ModalSizes } from '../../../../components/Modal/Modal';
import useAlertsStore from '../../redux/services/useAlertsStore';
import UnconfirmedCriticalAlertModal from './UnconfirmedCriticalAlertModal';

const UnconfirmedCriticalAlerts = () => {
  const { unconfirmedCriticalAlerts, initialUnconfirmedCriticalAlertsLength: totalUnconfirmedCriticalAlerts } =
    useAlertsStore();
  const totalUnconfirmedCriticalAlertsDone =
    totalUnconfirmedCriticalAlerts - (unconfirmedCriticalAlerts?.length || 0) + 1;

  const criticalAlert = unconfirmedCriticalAlerts?.[0];

  return (
    <Modal
      isVisible={Boolean(criticalAlert)}
      size={ModalSizes.Medium}
      content={UnconfirmedCriticalAlertModal}
      contentProps={{
        criticalAlert,
        criticalAlertTotal: totalUnconfirmedCriticalAlerts,
        criticalAlertsDone: totalUnconfirmedCriticalAlertsDone,
      }}
      backdrop="static"
    />
  );
};

export default UnconfirmedCriticalAlerts;
