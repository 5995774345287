import { FieldProps, getIn } from 'formik';
import AvailabilityDaysSelect from 'modules/app/components/availabilities/AvailabilityDaysSelect';
import * as React from 'react';

type Props = FieldProps & { arrayFieldPrefix?: string };

const OnboardingAvailabilitiesDaysSelect: React.FunctionComponent<Props> = ({
  form,
  field,
  arrayFieldPrefix,
  ...props
}: Props) => {
  const availabilities = React.useMemo(
    () => (arrayFieldPrefix ? getIn(form.values, arrayFieldPrefix)?.availabilities : []),
    [form, arrayFieldPrefix]
  );
  const getDisabledDaysForAvailability = React.useMemo(
    (): string[] =>
      availabilities.reduce((result: string[], currentValue: any) => {
        result.push(...currentValue.days.filter((day: string) => !field.value.includes(day)));

        return result;
      }, []),
    [availabilities, field]
  );

  return <AvailabilityDaysSelect form={form} field={field} disabledDays={getDisabledDaysForAvailability} {...props} />;
};

export default OnboardingAvailabilitiesDaysSelect;
