import { FlexRow, Badge } from 'components';
import styled, { css } from 'config/theme';
import { media } from 'config/theme/utils';
import { Sector } from 'modules/app/types';
import { OrganizationStatus } from 'modules/organization/types/organization';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import StatusBadge from '../../shared/StatusBadge';

type Props = {
  name: string;
  status: OrganizationStatus;
  sectors: Sector[];
  maxUsers: number;
  userViewActive: boolean;
};

const Heading: React.FunctionComponent<Props> = ({ name, sectors, status, maxUsers, userViewActive }: Props) => {
  const { t } = useTranslation('organization');
  const sectorNames = sectors.map(sector => sector.name);

  return (
    <HeadingWrapper>
      <FlexRow justifyContent="space-between">
        <h1>{name}</h1>
        <div>
          {userViewActive && <Badge secondary>{t('detail.labels.maxUsers') + maxUsers}</Badge>}{' '}
          <StatusBadge status={status} />
        </div>
      </FlexRow>
      <span>{sectorNames.join(' / ')}</span>
    </HeadingWrapper>
  );
};

const mobileStyling = css`
  display: flex;
  flex-direction: column;
  align-items: center;

  > div:first-child {
    width: 100%;
  }
`;

const HeadingWrapper = styled.div`
  margin-bottom: 30px;

  > div:first-child {
    margin-bottom: 20px;

    h1 {
      font-weight: ${props => props.theme.organization.detail.fontWeight.name};
      font-size: ${props => props.theme.organization.detail.fontSize.name};
    }
  }

  span {
    color: ${props => props.theme.organization.detail.color.sectors};
    font-size: ${props => props.theme.organization.detail.fontSize.sectors};
    font-weight: ${props => props.theme.organization.detail.fontWeight.sectors};
    text-transform: uppercase;
    letter-spacing: 0.2px;
  }

  ${() => media.mobile`
    ${mobileStyling}
  `}

  ${() => media.tablet`
    ${mobileStyling}
  `}
`;

export default Heading;
