import * as React from 'react';
import { ContentLoader, SkeletonGenerator } from '../';
import styled from '../../config/theme';

const SelectLoadingState: React.FunctionComponent = () => (
  <LoadingStateWrapper>
    <SkeletonGenerator count={1}>
      <ContentLoader height={100}>
        <rect x="0" y="0" rx="5" ry="5" width="180" height="26" />
        <rect x="0" y="40" rx="5" ry="5" width="300" height="40" />
      </ContentLoader>
    </SkeletonGenerator>
  </LoadingStateWrapper>
);

const LoadingStateWrapper = styled.div`
  margin-top: 30px;
`;

export default SelectLoadingState;
