import { TenantTheme } from '../tenants';

const helpCenterTheme = (theme: TenantTheme) => ({
  detail: {
    border: `1px solid ${theme.palette.neutral.shade3}`,
    color: {
      icon: theme.palette.primary.shade4,
      iconHeader: theme.palette.neutral.shade2,
      text: theme.palette.neutral.shade7,
      textHeader: theme.palette.neutral.shade5,
      title: theme.palette.primary.shade5,
      titleHeader: theme.palette.neutral.shade7,
      titleHover: theme.palette.primary.shade7,
    },
    fontSize: {
      subText: theme.typography.fontSize.small_4,
      subTitleHeader: theme.typography.fontSize.medium_4,
      textHeader: theme.typography.fontSize.regular,
      titleHeader: theme.typography.fontSize.medium_6,
    },
  },
  sidebar: {
    background: {
      active: theme.palette.primary.shade1,
      hover: theme.palette.neutral.shade1,
    },
    border: `1px solid ${theme.palette.neutral.shade2}`,
    color: {
      title: theme.palette.neutral.shade4,
    },
    fontSize: {
      item: theme.typography.fontSize.regular,
      title: theme.typography.fontSize.small_5,
    },
    fontWeight: {
      title: 500,
    },
    letterSpacing: {
      title: '0.1em',
    },
    padding: '15px 12px',
  },
});

export default helpCenterTheme;
