import AxiosConfig, { RequestConfig } from 'config/api/AxiosConfig';
import { ContactFormType } from '../types/helpCenter';
import { HELPCENTER_URL } from './endpoints';
import faqDetailData from './faq.json';
import faqCategories from './faqCategories.json';

const submitContactFormApi = (contactForm: ContactFormType) => {
  const { name, content, from, subject, language } = contactForm;
  const requestConfig: RequestConfig = {
    data: {
      content,
      from,
      language,
      name,
      subject,
    },
    url: `${HELPCENTER_URL}/contact-us`,
  };

  return AxiosConfig.fetchAxiosPostRequest(requestConfig);
};

const getFaqCategoriesApi = () => {
  return faqCategories;
};

const getFaqDetailApi = (slug: string) => {
  return faqDetailData.filter(faqDetail => faqDetail.faqCategorySlug === slug);
};

export default { submitContactFormApi, getFaqCategoriesApi, getFaqDetailApi };
