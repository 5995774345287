import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { OrganizationUpdateStep } from '../../../organization/types/organization';
import ModalContainer from './UpdateSteps/ModalContainer';
import UpdateSteps from './UpdateSteps/UpdateSteps';

type Props = {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  profileUpdateStep: OrganizationUpdateStep;
};

const ProfileUpdateModal = ({ setShowModal, profileUpdateStep }: Props) => {
  const { t } = useTranslation('organization');

  const [reviewStarted, setReviewStarted] = React.useState(false);

  const handleConfirm = () => {
    setReviewStarted(true);
  };

  const handlePostPone = () => {
    setShowModal(false);
  };

  return reviewStarted ? (
    <UpdateSteps profileUpdateStep={profileUpdateStep} setShowModal={setShowModal} />
  ) : (
    <ModalContainer
      modalBody={t('updateModal.body')}
      modaltitle={t('updateModal.title')}
      primaryBtnHandler={handleConfirm}
      primaryBtnText={t('updateModal.approve')}
      secondaryBtnHandler={handlePostPone}
      secondaryBtnText={t('updateModal.postpone')}
    />
  );
};

export default ProfileUpdateModal;
