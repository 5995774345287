import { DayOptionType } from '../types';

export const COUNTRIES_URL = '/organization/v2/countries';
export const REPOSITORIES_URL = '/repository/v2/reports';
export const UPLOAD_CERTIFICATE_URL = '/organization/v2/organizations/upload-certificate';
export const ORGANIZATION_FILES_DOWNLOAD = (id: string) => `/organization/v2/organizations/files/${id}/download`;
export const FLASH_ALERTS_BACKEND_MODULE_KEY = 'FlashAlerts';
export const ILLEGAL_NAME_REGEX = /^((?!(javascript)).)*$/;

export const daysInTheWeekList: DayOptionType[] = [
  {
    label: 'monday',
    shortLabel: 'Mon',
    value: '0',
  },
  {
    label: 'tuesday',
    shortLabel: 'Tue',
    value: '1',
  },
  {
    label: 'wednesday',
    shortLabel: 'Wed',
    value: '2',
  },
  {
    label: 'thursday',
    shortLabel: 'Thu',
    value: '3',
  },
  {
    label: 'friday',
    shortLabel: 'Fri',
    value: '4',
  },
  {
    label: 'saturday',
    shortLabel: 'Sat',
    value: '5',
  },
  {
    label: 'sunday',
    shortLabel: 'Sun',
    value: '6',
  },
];

export enum Priority {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
  Critical = 'critical',
}
export const priorityOptions = [Priority.Low, Priority.Medium, Priority.High, Priority.Critical];

export enum TlpCode {
  Amber = 'amber',
  AmberStrict = 'amberStrict',
  Clear = 'clear',
  Green = 'green',
}

export const tlpCodeOptions = [TlpCode.Clear, TlpCode.Green, TlpCode.Amber, TlpCode.AmberStrict];

export enum Audience {
  Operational = 'operational',
  Strategic = 'strategic',
  Technical = 'technical',
}
export const audienceOptions = [Audience.Strategic, Audience.Operational, Audience.Technical];
