import useOrganizationDetail from 'modules/organization/redux/services/useOrganizationDetail';
import { useAccessRightsToFunctionality, IdentityAccessRights } from 'modules/security';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { superAdminActions } from '../creators';
import { makeGetElegibleOrganizations } from '../selectors/superAdmin';

const useSuperAdminStore = () => {
  const dispatch = useDispatch();
  const hasAccessToUpgradeUser = useAccessRightsToFunctionality(IdentityAccessRights.UpgradeUser);
  const elegibleOrganizations = useSelector(makeGetElegibleOrganizations());
  const { organization } = useOrganizationDetail();

  const getElegibleOrganizations = React.useCallback(() => {
    dispatch(superAdminActions.getElegibleOrganizations());
  }, [dispatch]);

  React.useEffect(() => {
    if (hasAccessToUpgradeUser && !elegibleOrganizations) {
      getElegibleOrganizations();
    }
  }, [hasAccessToUpgradeUser, getElegibleOrganizations, elegibleOrganizations]);

  const isCompanyAllowedToUpgradeUser = React.useMemo(
    () => elegibleOrganizations?.map(organization => organization.guid).includes(organization?.guid),
    [organization, elegibleOrganizations]
  );

  return {
    isCompanyAllowedToUpgradeUser,
  };
};

export default useSuperAdminStore;
