import i18n from 'config/i18n';
import * as Yup from 'yup';
import PGPKeyUpload from '../../../app/components/PGPKeyUpload';
import { InputField } from '../../types';
import { CUSTOM } from '../index';

export type ContactFieldTypes = {
  businessPhone: string;
  phoneNumber: string;
  businessEmail: string;
  skype: string;
  other: string;
};

export const contactFields: InputField[] = [
  {
    label: 'contact.formLabels.phone',
    name: 'phoneBusiness',
    type: 'tel',
    width: {
      desktop: 4,
      tablet: 6,
    },
  },
  {
    label: 'contact.formLabels.phoneNumber',
    name: 'phoneMobile',
    type: 'tel',
    width: {
      desktop: 4,
      tablet: 6,
    },
  },
  {
    label: 'contact.formLabels.treemaId',
    name: 'treemaId',
    type: 'text',
    width: {
      desktop: 4,
      tablet: 6,
    },
  },
  {
    label: 'contact.formLabels.skype',
    name: 'skype',
    type: 'text',
    width: {
      desktop: 6,
    },
  },
  {
    label: 'contact.formLabels.other',
    name: 'info',
    type: 'text',
    width: {
      desktop: 6,
    },
  },
  {
    acceptedFileTypes: ['.asc'],
    component: PGPKeyUpload,
    initialValue: { name: '', file: null, base64: '' },
    label: 'contact.formLabels.pgpKey',
    name: 'pgpKey',
    type: CUSTOM,
    width: {
      desktop: 12,
    },
  },
];

export const contactValidationSchema = Yup.object().shape({
  info: Yup.string().max(500),
  pgpKey: Yup.object().shape({
    base64: Yup.string(),
    file: Yup.mixed(),
    name: Yup.string(),
    pgpFileId: Yup.string(),
  }),
  phoneBusiness: Yup.string()
    .required(() => i18n.t('validation:required'))
    .max(50)
    .matches(/^((\+[1-9]{2,3}[.\s]?){1}(\d{1,3}){1}(\s?\d{2,3}){2,4})?$/, () =>
      i18n.t('validation:string.phoneNumber')
    ),
  phoneMobile: Yup.string()
    .required(() => i18n.t('validation:required'))
    .max(50)
    .matches(/^((\+[1-9]{2,3}[.\s]?){1}(\d{1,3}){1}(\s?\d{2,3}){2,4})?$/, () =>
      i18n.t('validation:string.phoneNumber')
    ),
  skype: Yup.string().max(100),
  treemaId: Yup.string().max(50),
});

export const headerTranslations = {
  content: 'contact.content',
  title: 'contact.title',
};

export const contactStep = {
  fields: contactFields,
  headerTranslations,
  key: 'contact',
  validationSchema: contactValidationSchema,
};
