import Badge from 'components/Badge/Badge';
import styled, { css } from 'styled-components';
import { TlpCode } from '../constants';
import { useTranslation } from 'react-i18next';

type Props = {
  tlp: TlpCode;
};

const tlpColors = {
  [TlpCode.Green]: 'rgb(51,255,0)',
  [TlpCode.AmberStrict]: 'rgb(255,192,0)',
  [TlpCode.Amber]: 'rgb(255,192,0)',
  [TlpCode.Clear]: 'rgb(255,255,255)',
};

const TLPCodeBadge = ({ tlp }: Props) => {
  const { t } = useTranslation('global');

  return <TLPCodeBadgeWrapper tlp={tlp}>{t(`tlpCode.${tlp.toLowerCase()}`)}</TLPCodeBadgeWrapper>;
};

export default TLPCodeBadge;

const TLPCodeBadgeWrapper = styled(Badge)<Props>`
  ${({ tlp }) => css`
    background-color: ${tlpColors[tlp]};
    font-size: 12px;
    border: ${tlp.toLowerCase() === TlpCode.Clear.toLowerCase() ? '1px solid' : 'none'};
    color: ${tlp.toLowerCase() === TlpCode.Amber.toLowerCase() ? 'white' : 'inherit'};
  `}
`;
