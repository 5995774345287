import * as React from 'react';
import styled from '../../config/theme';
import BaseSvgWrapper from './BaseSvgWrapper';

const MailOutline: React.FunctionComponent = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 10V40.66H46.8V10H3ZM38.916 14.38L24.9 26.863L10.884 14.38H38.916ZM7.38 36.28V17.008L23.367 31.243C23.805 31.681 24.462 31.9 24.9 31.9C25.338 31.9 25.995 31.681 26.433 31.243L42.42 17.008V36.28H7.38Z" />
    </svg>
  </BaseSvgWrapper>
);

type Props = {
  type?: 'success' | 'danger' | 'warning';
};

const MailWrapper = styled.span<{ type?: string }>`
  svg {
    fill: ${props => props.theme.palette.neutral.shade9};
    ${props => props.type === 'success' && `fill: ${props.theme.palette.success.shade4}`};
    ${props => props.type === 'danger' && `fill: ${props.theme.palette.danger.shade5}`};
    ${props => props.type === 'warning' && `fill: ${props.theme.palette.warning.shade7}`};
  }
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const MailStatus = (props: Props) => (
  <MailWrapper type={props.type}>
    <MailOutline />
  </MailWrapper>
);

export default MailStatus;
