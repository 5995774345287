import i18n from 'config/i18n';
import * as Yup from 'yup';
import { InputField } from '../../types';
import { HIDDEN } from '../index';

const domainInformationFields: InputField[] = [
  {
    addLabel: 'Add domain name',
    fields: [
      {
        label: 'domainInformation.formLabels.domain',
        name: 'domainName',
        type: 'text',
        width: {
          desktop: 4,
        },
      },
      {
        label: 'domainInformation.formLabels.registrar',
        name: 'registrar',
        type: 'text',
        width: {
          desktop: 4,
        },
      },
      {
        label: 'domainInformation.formLabels.hosting',
        name: 'hostingCompany',
        type: 'text',
        width: {
          desktop: 4,
        },
      },
      {
        label: 'domainInformation.formLabels.email',
        name: 'emailAbuse',
        type: 'text',
        width: {
          desktop: 4,
        },
      },
      {
        name: '',
        type: HIDDEN,
        width: {
          desktop: 4,
        },
      },
    ],
    initialValue: null,
    name: 'domains',
    removeLabel: 'Remove domain name',
    type: 'array',
    width: {
      desktop: 12,
    },
  },
];

const domainInformationValidationSchema = Yup.object().shape({
  domains: Yup.array().of(
    Yup.object().shape({
      domainName: Yup.string()
        .required(() => i18n.t('validation:required'))
        .max(200)
        .trim()
        .matches(/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/, () =>
          i18n.t('validation:onboarding.domainName')
        ),
      emailAbuse: Yup.string()
        .max(200)
        .required(() => i18n.t('validation:required'))
        .email(() => i18n.t('validation:string.email')),
      hostingCompany: Yup.string()
        .required(() => i18n.t('validation:required'))
        .max(200),
      registrar: Yup.string()
        .required(() => i18n.t('validation:required'))
        .max(200),
    })
  ),
});

const headerTranslations = {
  content: 'domainInformation.content',
  title: 'domainInformation.title',
};

export const domainInformationStep = {
  fields: domainInformationFields,
  headerTranslations,
  key: 'domains',
  validationSchema: domainInformationValidationSchema,
};
