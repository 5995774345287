import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getType } from 'typesafe-actions';
import CTIInfoEdit from '../../../../organization/components/detail/content/cti/CTIInfoEdit';
import {
  ORGANIZATION_DETAIL_CTI_ACTION_PREFIX,
  ORGANIZATION_DETAIL_GENERAL_INFO_ACTION_PREFIX,
  organizationDetailActions,
} from '../../../../organization/redux/actions/creators';
import { makeGetCTIData, makeGetGeneralInfo } from '../../../../organization/redux/selectors/organizationDetail';
import { UpdateStep } from '../../../../organization/types/organization';
import { makeActionRequestIsPendingSelector, makeIsPendingSelector } from '../../../redux/selectors/loading';
import ModalContainer from './ModalContainer';

type Props = {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setUpdateStep: React.Dispatch<React.SetStateAction<UpdateStep>>;
  organizationId: string;
};

const CtiStep = ({ setShowModal, organizationId, setUpdateStep }: Props) => {
  const { t } = useTranslation('organization');
  const dispatch = useDispatch();
  const formRef = useRef<any>(null);

  const generalInfo = useSelector(makeGetGeneralInfo());
  const CTIData = useSelector(makeGetCTIData());

  const generalInfoPending = useSelector(makeIsPendingSelector(ORGANIZATION_DETAIL_GENERAL_INFO_ACTION_PREFIX));
  const ctiPending = useSelector(makeIsPendingSelector(ORGANIZATION_DETAIL_CTI_ACTION_PREFIX));
  const isUpdatingCti = useSelector(
    makeActionRequestIsPendingSelector(getType(organizationDetailActions.updateCTIData))
  );

  useEffect(() => {
    if (!CTIData) {
      dispatch(organizationDetailActions.getCTIData(organizationId));
    }
  }, [organizationId, dispatch, CTIData]);

  useEffect(() => {
    if (!generalInfo) {
      dispatch(organizationDetailActions.getGeneralInfo(organizationId));
    }
  }, [organizationId, dispatch, generalInfo]);

  const handleConfirm = useCallback(() => {
    if (formRef && formRef.current && formRef.current.submitForm) {
      formRef.current.submitForm();
    }
  }, [formRef]);

  const handlePostPone = () => {
    setShowModal(false);
  };

  const handleGoPreviousStep = () => {
    setUpdateStep(UpdateStep.generalInfoCompleted);
  };

  const ModalBody = () =>
    generalInfoPending || ctiPending ? (
      <div>{t('updateModal.loading')}</div>
    ) : (
      CTIData && (
        <CTIInfoEdit
          formRef={formRef}
          ctiData={CTIData}
          organizationId={organizationId}
          successCallback={() => setUpdateStep(UpdateStep.allUpToDate)}
          setHasChanges={() => {}}
          setEditMode={() => {}}
          hasChanges={true}
        />
      )
    );

  return (
    <ModalContainer
      modalBody={<ModalBody />}
      modaltitle={t('updateModal.cti.title')}
      primaryBtnHandler={handleConfirm}
      primaryBtnText={t('updateModal.cti.approve')}
      primaryBtnLoading={isUpdatingCti}
      secondaryBtnHandler={handlePostPone}
      secondaryBtnText={t('updateModal.postpone')}
      tertiaryBtnHandler={handleGoPreviousStep}
      tertiaryBtnText={t('updateModal.backToContact')}
    />
  );
};

export default CtiStep;
