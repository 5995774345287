import AxiosConfig, { RequestConfig } from '../../../config/api/AxiosConfig';
import { PaginationInfo } from '../../../types';
import { InactiveUsersFilters, SendReminderValues } from '../types';
import { INACTIVE_USERS_SEND_REMINDER_URL, INACTIVE_USERS_URL } from './endpoints';

export const getInactiveUsers = async (
  pageInfo: Pick<PaginationInfo, 'pageIndex' | 'pageSize'>,
  filters?: InactiveUsersFilters,
  sorting?: string[]
) => {
  const params = {
    pageIndex: pageInfo.pageIndex,
    pageSize: pageInfo.pageSize,
    ...(filters && {
      ...(filters.search && {
        search: filters.search,
      }),
      ...(filters.lastActiveDate && {
        lastActiveDate: filters.lastActiveDate,
      }),
      ...(filters.lastActiveDate && {
        statuses: filters.status,
      }),
    }),
    ...(sorting && { orderBys: sorting }),
  };

  const requestconfig: RequestConfig = {
    params,
    url: INACTIVE_USERS_URL,
  };

  return AxiosConfig.fetchAxiosPagedGetRequest(requestconfig);
};

export const reminderUser = async (values: SendReminderValues) => {
  const requestConfig: RequestConfig = {
    url: INACTIVE_USERS_SEND_REMINDER_URL,
    data: values,
  };

  return AxiosConfig.fetchAxiosPostRequest(requestConfig);
};
