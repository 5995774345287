import { loadScene } from 'components/async/scene';
import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

type Props = {};
const onboardingScene = loadScene('onboarding', 'OnboardingScene');

export const ONBOARDING_BASE_ROUTE = '/activate';

const OnboardingRoutes: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <Switch>
      <Route path={ONBOARDING_BASE_ROUTE} component={onboardingScene} />
      <Redirect to={ONBOARDING_BASE_ROUTE} />
    </Switch>
  );
};

export default OnboardingRoutes;
