import { ActionType } from 'typesafe-actions';
import * as pageInfoAction from './pageInfo';
import * as reportTypeAction from './reportTypes';
import * as sortingAction from './sorting';

export const reportTypeActions = { ...reportTypeAction };
export const pageInfoActions = { ...pageInfoAction };
export const sortingActions = { ...sortingAction };

export type ReportTypeActions = ActionType<typeof reportTypeAction>;
export type PageInfoActions = ActionType<typeof pageInfoAction>;
export type SortingActions = ActionType<typeof sortingAction>;
