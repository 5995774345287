import { BasicTextField, FullViewSubBlock } from 'components/FullView';
import { ArrayHelpers, FormikErrors, FormikTouched } from 'formik';
import { newDomain } from 'modules/organization/constants';
import { CTIDataEditValues } from 'modules/organization/types/organizationDetail/cti';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  errors: FormikErrors<CTIDataEditValues>;
  touched: FormikTouched<CTIDataEditValues>;
  arrayHelpers: ArrayHelpers;
};

const DomainPlaceholder: React.FunctionComponent<Props> = ({ errors, touched, arrayHelpers }: Props) => {
  const { t } = useTranslation('organization');

  const addDomain = React.useCallback(() => arrayHelpers.push(newDomain), [arrayHelpers]);

  return (
    <FullViewSubBlock onClick={addDomain}>
      <h3 className="sub-title">{t('detail.labels.domainPlaceholder')}</h3>
      <BasicTextField name="domains" label={t('detail.labels.name')} errors={errors} touched={touched} />
    </FullViewSubBlock>
  );
};

export default DomainPlaceholder;
