import { call, put, takeLatest } from 'redux-saga/effects';
import helpCenterApi from '../../api/helpCenter';
import { FaqCategory, FaqsDetail } from '../../types/helpCenter';
import { helpCenterActions } from '../creators';

function* helpCenterSaga() {
  yield takeLatest(helpCenterActions.submitContactForm, handleSubmitContactForm);
  yield takeLatest(helpCenterActions.getFaqCategories, handleGetFaqCategories);
  yield takeLatest(helpCenterActions.getFaqCategoryDetail, handleGetFaqCategoryDetail);
}

function* handleSubmitContactForm(action: ReturnType<typeof helpCenterActions.submitContactForm>) {
  const { request, success, failure } = helpCenterActions.submitContactFormRequest;
  yield put(request());
  try {
    yield call(helpCenterApi.submitContactFormApi, action.payload);
    action.successCallback();
    yield put(success());
  } catch (e) {
    yield put(failure(e.response));
  }
}

function* handleGetFaqCategories() {
  const { request, success, failure } = helpCenterActions.getFaqCategoriesRequest;
  yield put(request());
  try {
    const payload: FaqCategory[] = yield call(helpCenterApi.getFaqCategoriesApi);
    yield put(success(payload));
  } catch (e) {
    yield put(failure(e.response));
  }
}
function* handleGetFaqCategoryDetail(action: ReturnType<typeof helpCenterActions.getFaqCategoryDetail>) {
  const { request, success, failure } = helpCenterActions.getFaqCategoryDetailRequest;
  yield put(request());
  try {
    const payload: FaqsDetail[] = yield call(helpCenterApi.getFaqDetailApi, action.payload);
    yield put(success(payload && payload[0]));
  } catch (e) {
    yield put(failure(e.response));
  }
}

export default helpCenterSaga;
