import AxiosConfig, { RequestConfig } from 'config/api/AxiosConfig';
import { PaginationInfo } from 'types';
import { AddOrganizationValues, OrganizationFilters, OrganizationSector } from '../types/organization';
import { ORGANIZATIONS_URL } from './enpoints';

const getOrganizations = (pageInfo: PaginationInfo, filters?: OrganizationFilters, sorting?: string[]) => {
  const params = {
    pageIndex: pageInfo.pageIndex,
    pageSize: pageInfo.pageSize,
    ...(filters && {
      ...(filters.search && { search: filters.search }),
      ...(filters.sector && { sectors: filters.sector.map((sector) => sector.sectorId) }),
      ...(filters.status && { statuses: filters.status }),
    }),
    ...(sorting && { orderBys: sorting }),
  };

  const requestConfig: RequestConfig = {
    params,
    url: ORGANIZATIONS_URL,
  };

  return AxiosConfig.fetchAxiosGetRequest(requestConfig);
};

const addOrganization = (addOrganizationValues: AddOrganizationValues) => {
  const requestConfig: RequestConfig = {
    data: {
      ...addOrganizationValues,
    },
    url: ORGANIZATIONS_URL,
  };

  return AxiosConfig.fetchAxiosPostRequest(requestConfig);
};

const getSectors = (search?: string) => {
  const requestConfig: RequestConfig = {
    params: {
      ...(search && { search }),
    },
    url: `${ORGANIZATIONS_URL}/sectors`,
  };

  return AxiosConfig.fetchAxiosGetRequest<OrganizationSector[]>(requestConfig);
};

export default { getOrganizations, addOrganization, getSectors };
