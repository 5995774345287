import i18nInstance from 'i18next';
import XHR from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

i18nInstance
  .use(initReactI18next)
  .use(XHR)
  .init({
    backend: {
      loadPath:
        process.env.NODE_ENV === 'production'
          ? `/locales${process.env.REACT_APP_VERSION?.replace(/\./g, '-')}/{{lng}}/{{ns}}.json`
          : '/locales/{{lng}}/{{ns}}.json',
    },
    defaultNS: 'global',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    lng: 'en',
    ns: ['global', 'validation', 'errors'],
    react: {
      wait: true,
    },
    returnObjects: true,
  });

export default i18nInstance;
