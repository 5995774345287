import { combineReducers } from 'redux';
import loginReducer, { LoginState } from './login';
import modulesReducer, { ModulesState } from './modules';
import userReducer, { UserState } from './user';

export interface SecurityState {
  login: LoginState;
  modules: ModulesState;
  user: UserState;
}

const securityReducer = combineReducers<SecurityState, any>({
  login: loginReducer,
  modules: modulesReducer,
  user: userReducer,
});

export default securityReducer;
