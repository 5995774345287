import { loadScene } from 'components/async/scene';
import { Module } from 'modules/security/types/modules';
import * as React from 'react';
import { Route } from 'react-router-dom';
import ModuleRoutes from '../../../components/routing/ModuleRoutes';
import { FEED_BASE_ROUTE, FEED_MODULE_KEY } from '../constants';

type Props = {
  allowedModules: Module[];
};

const feedOverviewScene = loadScene('feed', 'FeedScene');

const FeedRoutes: React.FC<Props> = ({ allowedModules }: Props) => (
  <ModuleRoutes allowedModules={allowedModules} moduleKey={FEED_MODULE_KEY}>
    <Route path={[FEED_BASE_ROUTE]} exact component={feedOverviewScene} />
  </ModuleRoutes>
);

export default FeedRoutes;
