import { Modules, Module } from 'modules/security/types/modules';

const createNavigationSubModulesHelper = (modules: Modules) => {
  return modules.reduce((result: Modules, module: Module) => {
    if (module.groupingKey && module.groupingKey !== module.key) {
      return result.map(resultModule =>
        resultModule.key === module.groupingKey
          ? resultModule.subModules
            ? { ...resultModule, subModules: [...resultModule.subModules, module] }
            : { ...resultModule, subModules: [module] }
          : resultModule
      );
    } else {
      result.push(module);
    }
    return result;
  }, []);
};

export default createNavigationSubModulesHelper;
