import * as React from 'react';
import logoSrc from '../../assets/images/logo/logo.png';
import styled from '../../config/theme';

type Props = {
  isOnOnboarding?: boolean;
};

// Right margin is needed because of none center alignment of brand image
const LogoContainer = styled('img')<Props>`
  width: ${(props) => (props.isOnOnboarding ? '350px' : '150px')};
  padding: ${(props) => props.isOnOnboarding && '40px'};
`;

const Logo: React.FC<Props> = ({ isOnOnboarding }: Props): JSX.Element => (
  <LogoContainer src={logoSrc} isOnOnboarding={isOnOnboarding} />
);

export default Logo;
