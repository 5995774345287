import * as React from 'react';
import ContentLoader from 'react-content-loader';
import { Transition } from 'react-transition-group';
import { withTheme } from '../../config/theme';
import { RootTheme } from '../../config/theme/types';

type Props = {
  theme: RootTheme;
  height: number;
  children: JSX.Element[] | JSX.Element;
};

const duration = 250;

const defaultStyle = {
  fillOpacity: 0,
  transition: `fill-opacity ${duration}ms 100ms ease-in`,
};

const transitionStyles = {
  entered: { fillOpacity: 1 },
  entering: { fillOpacity: 0.05 },
  exited: { fillOpacity: 0 },
  exiting: { fillOpacity: 0 },
};

const GlobalContentLoader: React.FunctionComponent<Props> = ({ theme, ...other }) => (
  <Transition in timeout={duration} mountOnEnter appear>
    {(state) => (
      <div style={{ ...defaultStyle, ...transitionStyles[state] }}>
        <ContentLoader
          speed={2}
          primaryColor={theme.skeleton.primaryColor}
          secondaryColor={theme.skeleton.secondaryColor}
          {...other}
        />
      </div>
    )}
  </Transition>
);

export default withTheme(GlobalContentLoader);
