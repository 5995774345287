import { HttpErrorData, SuccessCallback } from 'types';
import { createAction, createAsyncAction, createCustomAction } from 'typesafe-actions';
import { Alert, EditRuleSubmitValues, NewRuleSubmitValues, Rule } from '../../types/alertRules';

export const getAlerts = createAction('ALERT_RULES_GET');
export const getAlertsRequest = createAsyncAction(
  'ALERT_RULES_GET_REQUEST',
  'ALERT_RULES_GET_SUCCESS',
  'ALERT_RULES_GET_REJECTED'
)<undefined, Alert, HttpErrorData>();

export const toggleAlerts = createAction('ALERT_RULES_TOGGLE', (action) => (value: boolean) => action(value));
export const toggleAlertsRequest = createAsyncAction(
  'ALERT_RULES_TOGGLE_REQUEST',
  'ALERT_RULES_TOGGLE_SUCCESS',
  'ALERT_RULES_TOGGLE_REJECTED'
)<undefined, Alert, HttpErrorData>();

export const addAlertRule = createCustomAction(
  'ALERT_RULES_ADD',
  (type) => (newAlertRule: NewRuleSubmitValues, successCallback: SuccessCallback) => ({
    payload: newAlertRule,
    successCallback,
    type,
  })
);
export const addAlertRuleRequest = createAsyncAction(
  'ALERT_RULES_ADD_REQUEST',
  'ALERT_RULES_ADD_SUCCESS',
  'ALERT_RULES_ADD_REJECTED'
)<undefined, Rule, HttpErrorData>();

export const editAlertRule = createCustomAction(
  'ALERT_RULES_EDIT',
  (type) => (alertrule: EditRuleSubmitValues, successCallback: SuccessCallback) => ({
    payload: alertrule,
    successCallback,
    type,
  })
);
export const editAlertRuleRequest = createAsyncAction(
  'ALERT_RULES_EDIT_REQUEST',
  'ALERT_RULES_EDIT_SUCCESS',
  'ALERT_RULES_EDIT_REJECTED'
)<undefined, Rule, HttpErrorData>();

export const deleteAlertRule = createAction('ALERT_RULES_DELETE', (action) => (ruleId: string) => action(ruleId));
export const deleteAlertRuleRequest = createAsyncAction(
  'ALERT_RULES_DELETE_REQUEST',
  'ALERT_RULES_DELETE_SUCCESS',
  'ALERT_RULES_DELETE_REJECTED'
)<undefined, undefined, HttpErrorData>();
