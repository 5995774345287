import { FlexCol, Input, Paper, Table } from 'components';
import { TableColumn } from 'components/Table/Table';
import { responsive } from 'helpers';
import { useMultiSelect } from 'hooks';
import { Repository } from 'modules/repository/types/repository';
import { ModuleEditAccessContext } from 'modules/security';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { sortingActions } from '../../redux/creators';
import { makeGetRepositoriesSorting } from '../../redux/selectors/repository';
import Header from '../header';
import { RepositoryEmptyState, RepositoryLoadingState, RepositoryRow } from './';

type Props = {
  isPending?: boolean;
  repositories: Repository[] | null;
};

const Repositories: React.FunctionComponent<Props> = ({ isPending, repositories }: Props) => {
  const { t } = useTranslation('repository');
  const [selectedItems, multiSelectActions] = useMultiSelect(repositories || [], 'reportId');
  const moduleAccess = React.useContext(ModuleEditAccessContext);
  const sortValues = useSelector(makeGetRepositoriesSorting());
  const isLaptopLG = useMediaQuery(responsive.isLaptopLG);

  const tableColumnProps: TableColumn[] = [
    {
      size: 40,
      title: (
        <Input
          type="checkbox"
          name="select_all"
          key="select_all"
          onChange={multiSelectActions.selectAll}
          checked={repositories ? selectedItems.length === repositories.length : false}
        />
      ),
    },
    {
      size: 30,
      title: '',
    },
    {
      title: t('tableHeaders.nameAndSectors'),
      size: 'auto',
      sortable: true,
      customSortingName: 'description',
    },
    { title: t('tableHeaders.type'), size: 100, sortable: true },
    {
      title: t('tableHeaders.audienceAndTlpCode'),
      size: 100,
      customSortingName: 'audience',
    },
    { title: '', size: 45, align: 'center' },
    {
      align: 'left',
      customSortingName: 'uploadDate',
      size: isLaptopLG ? 90 : 90,
      sortable: true,
      title: t('tableHeaders.uploadDate'),
    },
    {
      align: 'left',
      sortable: true,
      customSortingName: 'reportDate',
      size: isLaptopLG ? 90 : 90,
      title: t('tableHeaders.reportDate'),
    },
  ];

  if (moduleAccess.canEdit) {
    tableColumnProps.push({ title: '', size: 250, align: 'right' });
  } else {
    tableColumnProps.push({ title: '', size: 200, align: 'right' });
  }

  return (
    <FlexCol>
      <Header selectedRepositories={selectedItems} deselectRepositories={multiSelectActions.deselectAll} />
      <Paper>
        {!isPending && repositories && repositories.length === 0 ? (
          <RepositoryEmptyState />
        ) : !repositories || (isPending && repositories.length === 0) ? (
          <RepositoryLoadingState />
        ) : (
          <React.Fragment>
            <Table sortValues={sortValues} sortingActionCreator={sortingActions.setSorting} columns={tableColumnProps}>
              {repositories.map((repository) => (
                <RepositoryRow
                  key={repository.reportId}
                  repository={repository}
                  selected={Boolean(selectedItems.find((item) => item.reportId === repository.reportId))}
                  toggleIsSelected={multiSelectActions.toggleId}
                  isPending={isPending}
                />
              ))}
            </Table>
          </React.Fragment>
        )}
      </Paper>
    </FlexCol>
  );
};

export default Repositories;
