import styled from 'config/theme';
import { AnimationProps } from 'config/theme/types';
import { media } from 'config/theme/utils';
import { responsive } from 'helpers';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { CSSTransition } from 'react-transition-group';

type AsideProps = {
  isLaptopSM?: boolean;
} & AnimationProps;

const AsideWrapper = styled.aside<AsideProps>`
  background: ${(props) => props.theme.sidebar.background.main};
  border-right: ${(props) => props.theme.sidebar.border};
  display: flex;
  flex-direction: column;
  flex: 0 0 ${(props) => (props.isLaptopSM ? props.theme.sidebar.width.small : props.theme.sidebar.width.regular)};
  position: relative;
  top: 0;
  width: ${(props) => (props.isLaptopSM ? props.theme.sidebar.width.small : props.theme.sidebar.width.regular)};
  align-items: center;

  ${(props) => media.desktop`
    flex: 0 0 ${props.theme.sidebar.width.large};
    width: ${props.theme.sidebar.width.large};
  `};

  > nav {
    width: 100%;
    overflow-y: auto;
    flex: 1;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  ${(props) => props.state === 'entering' && props.theme.sidebar.animation.entering};
  ${(props) => props.state === 'entered' && props.theme.sidebar.animation.entered};
`;

const TransitionedWrapper: React.FunctionComponent = ({ children }) => {
  const isLaptopSM = useMediaQuery(responsive.isLaptopSM);

  return (
    <CSSTransition in timeout={150} mountOnEnter appear>
      {(state) => (
        <AsideWrapper isLaptopSM={isLaptopSM} state={state}>
          {children}
        </AsideWrapper>
      )}
    </CSSTransition>
  );
};

const MobileAsideWrapper = styled(AsideWrapper)`
  padding: 0;
  border-right: none;
  border-top: ${(props) => props.theme.sidebar.border};
  flex-direction: row;
  flex: 1;
  flex-grow: 0;
  align-items: flex-end;
  width: 100%;
  top: unset;
  bottom: 0;
  z-index: 1000;

  ul {
    display: flex;
    justify-content: space-evenly;
  }

  ${(props) => props.state === 'entering' && props.theme.sidebar.mobile.animation.entering};
  ${(props) => props.state === 'entered' && props.theme.sidebar.mobile.animation.entered};
`;

const TransitionedMobileWrapper: React.FunctionComponent = ({ children }) => (
  <CSSTransition in timeout={150} mountOnEnter appear>
    {(state) => <MobileAsideWrapper state={state}>{children}</MobileAsideWrapper>}
  </CSSTransition>
);

export { TransitionedWrapper as AsideWrapper, TransitionedMobileWrapper as MobileAsideWrapper };
