import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const Print: React.FunctionComponent = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M19 8H5C3.34 8 2 9.34 2 11V17H6V21H18V17H22V11C22 9.34 20.66 8 19 8ZM16 19H8V14H16V19ZM19 12C18.45 12 18 11.55 18 11C18 10.45 18.45 10 19 10C19.55 10 20 10.45 20 11C20 11.55 19.55 12 19 12ZM18 3H6V7H18V3Z" />
    </svg>
  </BaseSvgWrapper>
);

export default Print;
