import styled from 'config/theme';

export const HeaderActions = styled.div`
  display: flex;
  align-items: center;

  > button {
    margin-left: 10px;
  }
`;

type Props = {
  disabled?: boolean;
};

export const CombinedActionWrapper = styled.div<Props>`
  display: flex;
  flex-direction: row;
  position: relative;
  margin: 0 2px;

  &::after {
    content: '';
    position: absolute;
    top: -3px;
    bottom: -3px;
    right: -2px;
    left: -2px;
    background: ${props => props.theme.palette.primary.shade1};
    border: 2px solid ${props => props.theme.palette.primary.shade3};
    opacity: ${props => (props.disabled ? '0.3' : '1')};
    z-index: -1;
    border-radius: 24px;
  }
`;
