import { TenantTheme } from '../tenants';

const organizationTheme = (theme: TenantTheme) => ({
  detail: {
    color: {
      sectors: theme.palette.primary.shade4,
      sidebar: theme.palette.neutral.shade9,
    },
    fontSize: {
      name: theme.typography.fontSize.medium_6,
      sectors: theme.typography.fontSize.medium_2,
    },
    fontWeight: {
      name: 600,
      sectors: 500,
    },
  },
});

export default organizationTheme;
