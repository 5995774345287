import { RootState } from 'config/store/rootReducer';
import { Sector, SectorFilters } from 'modules/sector/types/sector';
import { createSelector } from 'reselect';
import { PaginationInfo, SortValue } from 'types';

const getSectors = (state: RootState): Sector[] | null => state.sector.sectors;
export const makeGetSectors = () => createSelector(getSectors, (sectors: Sector[] | null) => sectors);

const getFilters = (state: RootState): SectorFilters => state.sector.filters;
export const makeGetSectorFilters = () => createSelector(getFilters, (sectors: SectorFilters) => sectors);

const getPaginationInfo = (state: RootState): PaginationInfo => state.sector.pageInfo;
export const makeGetSectorPageInfo = () => createSelector(getPaginationInfo, (pageInfo: PaginationInfo) => pageInfo);

const getSortingInfo = (state: RootState): SortValue[] => state.sector.sorting;
export const makeGetSectorSorting = () => createSelector(getSortingInfo, (sorting: SortValue[]) => sorting);
