import { ActionType } from 'typesafe-actions';
import * as pageInfoAction from './pageInfo';
import * as superUsersAction from './superUsers';
import * as sortingAction from './sorting';
import * as filterAction from './filters';

export const SUPER_USERS_ACTION_PREFIX = 'SUPER_USERS';

export const pageInfoActions = { ...pageInfoAction };
export const superUsersActions = { ...superUsersAction };
export const sortingActions = { ...sortingAction };
export const filterActions = { ...filterAction };

export type PageInfoActions = ActionType<typeof pageInfoAction>;
export type SuperUsersActions = ActionType<typeof superUsersAction>;
export type SortingActions = ActionType<typeof sortingAction>;
export type FilterActions = ActionType<typeof filterAction>;
