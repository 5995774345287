import { createSelector } from 'reselect';
import { RootState } from '../../../../config/store/rootReducer';
import { Certificate } from '../../types/certificates';

const getCertificates = (state: RootState): Certificate[] => state.certificates;

export const makeGetCertificates = () => createSelector(getCertificates, (certificates: Certificate[]) => certificates);

export const makeGetCertificatesName = () =>
  createSelector(getCertificates, certificates => certificates.map(certificate => certificate.fileName));
