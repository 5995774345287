import * as React from 'react';
import { Modal } from '../../../../components';
import { ModalSizes } from '../../../../components/Modal/Modal';
import useProfileUpdateStore from '../../../organization/redux/services/useProfileUpdateStore';
import ProfileUpdateModal from './ProfileUpdateModal';
import { UpdateStep } from '../../../organization/types/organization';

const ProfileUpdate = () => {
  const [showModal, setShowModal] = React.useState(false);

  const { profileUpdateStep } = useProfileUpdateStore();

  const needsUpdate = profileUpdateStep
    ? profileUpdateStep.updateStep === UpdateStep.needsUpdate ||
      profileUpdateStep.updateStep === UpdateStep.generalInfoCompleted ||
      profileUpdateStep.updateStep === UpdateStep.contactsCompleted
    : false;

  React.useEffect(() => {
    setShowModal(needsUpdate);
  }, [needsUpdate]);

  return (
    <Modal
      isVisible={showModal}
      size={ModalSizes.Large}
      content={ProfileUpdateModal}
      contentProps={{ setShowModal, profileUpdateStep }}
      backdrop="static"
    />
  );
};
export default ProfileUpdate;
