import AxiosConfig, { RequestConfig } from 'config/api/AxiosConfig';
import { PaginationInfo } from 'types';
import { SectorFilters } from '../types/sector';
import { SECTORS_URL } from './endpoints';

const getSectors = async (pageInfo: PaginationInfo, filters?: SectorFilters, sorting?: string[]) => {
  const params = {
    pageIndex: pageInfo.pageIndex,
    pageSize: pageInfo.pageSize,
    ...(filters && { ...(filters.search && { search: filters.search }) }),
    ...(sorting && { orderBys: sorting }),
  };

  const requestConfig: RequestConfig = {
    params,
    url: SECTORS_URL,
  };

  return AxiosConfig.fetchAxiosGetRequest(requestConfig);
};

const addSector = async (name: string) => {
  const requestConfig: RequestConfig = {
    data: {
      name,
    },
    url: SECTORS_URL,
  };

  return AxiosConfig.fetchAxiosPostRequest(requestConfig);
};

const editSector = async (name: string, sectorId: string) => {
  const requestConfig: RequestConfig = {
    data: {
      name,
      sectorId,
    },
    url: SECTORS_URL,
  };

  return AxiosConfig.fetchAxiosPutRequest(requestConfig);
};

const deleteSector = async (sectorId: string) => {
  const requestConfig: RequestConfig = {
    data: {
      sectorId,
    },
    url: `${SECTORS_URL}/${sectorId}`,
  };

  return AxiosConfig.fetchAxiosDeleteRequest(requestConfig);
};

export default { getSectors, addSector, editSector, deleteSector };
