import { FlexRow } from 'components';
import * as React from 'react';
import styled from 'styled-components';

type Props = {
  onClick: () => void;
  primary?: boolean;
};

const ArrayItemButton: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <FlexRow justifyContent="space-between">
      <ArrayItemButtonWrapper {...props} />
    </FlexRow>
  );
};

const ArrayItemButtonWrapper = styled('span')<{ primary?: boolean }>`
  cursor: pointer;
  color: ${props => (props.primary ? props.theme.palette.secondary.shade5 : props.theme.palette.neutral.shade5)};
  width: auto;
  display: block;
  
  &::before {
    content: '${props => (props.primary ? '+ ' : '- ')}';
  }
  
`;

export default ArrayItemButton;
