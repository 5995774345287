import * as React from 'react';
import { AlertBell, Checkmark, Close, Cross, Warning } from '../../../../components/icons';
import { AlertType, AlertWithIdentifier } from '../../types/alert';
import { AlertWrapper } from './AlertWrapper';

type Props = {
  alert: AlertWithIdentifier;
  index: number;
  removeAlert: (id: string) => void;
  className?: string;
  noAutoClose?: boolean;
};

const Alert: React.FunctionComponent<Props> = ({ alert, index, removeAlert, className, noAutoClose }: Props) => {
  const removeSelf = React.useCallback(() => {
    removeAlert(alert.id);
  }, [removeAlert, alert.id]);

  React.useEffect(() => {
    if (!noAutoClose) {
      setTimeout(
        () => {
          removeSelf();
        },
        alert.type !== AlertType.Success ? 5000 : 10000
      );
    }
  }, [alert, removeSelf, noAutoClose]);

  return (
    <AlertWrapper index={index} type={alert.type} className={className}>
      <div className="icon">
        {alert.type === AlertType.Default && <AlertBell />}
        {alert.type === AlertType.Success && <Checkmark />}
        {alert.type === AlertType.Warning && <Warning />}
        {alert.type === AlertType.Danger && <Cross />}
      </div>
      <div className="message">{alert.message}</div>
      <div className="close">
        <Close onClick={removeSelf} />
      </div>
    </AlertWrapper>
  );
};

export default Alert;
