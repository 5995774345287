import { ConvertedFile } from '../../../components/Dropzone/FileInput';
import { Audience, Priority, TlpCode } from '../../app/constants';
import { ReportType, Sector } from '../../app/types';

export type Repository = {
  uploadDate: string;
  description: string;
  fileName: string;
  guid: string;
  reportId: string;
  reportType: string;
  reportTypeId: string;
  sectors: Sector[];
  tlpCode: TlpCode;
  priority: Priority;
  audience: Audience;
  reportDate: string;
  read: boolean;
};

export type EditRepositoryValues = {
  audience?: Audience;
  file: ConvertedFile;
  description: string;
  priority?: Priority;
  report: ReportType;
  reportId: string;
  reportType?: string;
  reportTypeId: string;
  sectors: Sector[];
  tlpCode?: TlpCode;
  reportDate: string;
  sendMail: boolean;
};

export type AddRepositoryValues = {
  audience?: Audience;
  description?: string;
  file?: ConvertedFile;
  priority?: Priority;
  report?: ReportType;
  reportTypeId?: string;
  sectors?: Sector[];
  tlpCode?: TlpCode;
  reportDate: string | Date | undefined;
  sendMail: boolean;
} & AddCriticalAlertRepositoryValues;

export type AddCriticalAlertRepositoryValues =
  | {
      addCriticalAlert: true;
      title: string;
      criticalAlertDescription: string;
    }
  | { addCriticalAlert: false; title?: string; criticalAlertDescription?: string };

export type DeleteRepositoryValues = {
  id: string;
};

export type MarkRepositoryReadUnreadValues = {
  id: string;
  read: boolean;
};

export type DownloadRepositoryValues = {
  guid: string;
  fileName: string;
};

export type ReportQuestionValues = {
  reportId: string;
  reportTitle: string;
  content: string;
  language: string;
};

export type QuestionFormikValues = {
  content: string;
};

export type RepositoryFilters = {
  audience?: Audience[];
  dateFrom?: Date;
  dateUntil?: Date;
  fullText: boolean;
  priority?: Priority[];
  reportType?: ReportType[];
  search?: string;
  sectors?: RepositorySector[];
  tlpCode?: TlpCode[];
};

export type RepositorySector = {
  all?: boolean;
  name: string;
  sectorId: string;
};

export type SectorFilterOption = {
  data: RepositorySector;
  label: string;
  value: string;
};

export const hasEveryAddRepositoryValues = (
  repository: AddRepositoryValues
): repository is Required<AddRepositoryValues> => true;

export type ViewOrDownloadType = 'view' | 'download';
