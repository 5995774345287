import { media } from 'config/theme/utils';
import * as React from 'react';
import { connect } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { bindActionCreators, Dispatch } from 'redux';
import styled from 'styled-components';
import { RootState } from '../../../config/store/rootReducer';
import Alert from '../components/Alerts/Alert';
import { alertActions } from '../redux/creators';
import { makeGetAlerts } from '../redux/selectors/alerts';
import { AlertWithIdentifier } from '../types/alert';

type Props = StateToProps & DispatchToProps;

type StateToProps = {
  alerts: AlertWithIdentifier[];
};

type DispatchToProps = {
  removeAlert: (id: string) => void;
};

const AlertContainer = styled(TransitionGroup)`
  position: fixed;
  top: ${props => props.theme.header.height};
  right: 30px;
  z-index: 99999;

  ${() => media.mobile`
    right: 8px;
    left: 8px;
  `};
`;

const Alerts: React.FunctionComponent<Props> = ({ alerts, removeAlert }: Props) => {
  return (
    <AlertContainer className="alert-list">
      {alerts.map((alert, index) => (
        <CSSTransition timeout={200} classNames="alert" key={alert.id} appear>
          <Alert key={alert.id} alert={alert} index={index} removeAlert={removeAlert} noAutoClose={alert.noAutoClose} />
        </CSSTransition>
      ))}
    </AlertContainer>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      removeAlert: alertActions.removeAlert,
    },
    dispatch
  );

const mapStateToProps = () => {
  const alertsSelector = makeGetAlerts();

  return (state: RootState) => ({
    alerts: alertsSelector(state),
  });
};

export default connect(mapStateToProps, mapDispatchToProps)(Alerts);
