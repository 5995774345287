import { call, put, takeLatest } from 'redux-saga/effects';
import alertRules from '../../api/alertRules';
import { alertRulesActions } from '../creators';

function* alertRulesSaga() {
  yield takeLatest(alertRulesActions.getAlerts, handleGetAlerts);
  yield takeLatest(alertRulesActions.toggleAlerts, handleToggleAlerts);
  yield takeLatest(alertRulesActions.deleteAlertRule, handleDeleteAlertRule);
  yield takeLatest(alertRulesActions.addAlertRule, handleAddAlertRule);
  yield takeLatest(alertRulesActions.editAlertRule, handleEditAlertRule);
}

function* handleGetAlerts() {
  const { request, success, failure } = alertRulesActions.getAlertsRequest;
  yield put(request());
  try {
    const payload = yield call(alertRules.getAlerts);
    yield put(success(payload));
  } catch (e) {
    yield put(failure(e));
  }
}

function* handleToggleAlerts(action: ReturnType<typeof alertRulesActions.toggleAlerts>) {
  const { request, success, failure } = alertRulesActions.toggleAlertsRequest;
  yield put(request());
  try {
    const payload = yield call(alertRules.toggleAllAlerts, action.payload);
    yield put(success(payload));
  } catch (e) {
    yield put(failure(e));
  }
}

function* handleDeleteAlertRule(action: ReturnType<typeof alertRulesActions.deleteAlertRule>) {
  const { request, success, failure } = alertRulesActions.deleteAlertRuleRequest;
  yield put(request());
  try {
    yield call(alertRules.deleteAlertRule, action.payload);
    yield put(success());
    yield call(handleGetAlerts);
  } catch (e) {
    yield put(failure(e));
  }
}

function* handleAddAlertRule(action: ReturnType<typeof alertRulesActions.addAlertRule>) {
  const { request, success, failure } = alertRulesActions.addAlertRuleRequest;
  yield put(request());
  try {
    const payload = yield call(alertRules.addAlertRule, action.payload);
    yield put(success(payload));
    action.successCallback();
  } catch (e) {
    yield put(failure(e));
  }
}

function* handleEditAlertRule(action: ReturnType<typeof alertRulesActions.editAlertRule>) {
  const { request, success, failure } = alertRulesActions.editAlertRuleRequest;
  yield put(request());
  try {
    const payload = yield call(alertRules.editAlertRule, action.payload);
    yield put(success(payload));
    action.successCallback();
  } catch (e) {
    yield put(failure(e));
  }
}

export default alertRulesSaga;
