import { ActionType } from 'typesafe-actions';
import * as filterAction from './filters';
import * as pageInfoAction from './pageInfo';
import * as repositoryAction from './repository';
import * as sortingAction from './sorting';

export const REPOSITORY_ACTION_PREFIX = 'REPOSITORIES';
export const REPOSITORY_REPORT_TYPE_ACTION_PREFIX = 'REPOSITORY_REPORT_TYPE';
export const REPOSITORY_SECTOR_ACTION_PREFIX = 'REPOSITORY_SECTOR';
export const REPOSITORY_QUESTION_ACTION_PREFIX = 'REPOSITORIES_QUESTION';

export const filterActions = { ...filterAction };
export const pageInfoActions = { ...pageInfoAction };
export const repositoryActions = { ...repositoryAction };
export const sortingActions = { ...sortingAction };

export type FilterActions = ActionType<typeof filterAction>;
export type PageInfoActions = ActionType<typeof pageInfoAction>;
export type RepositoryActions = ActionType<typeof repositoryAction>;
export type SortingActions = ActionType<typeof sortingAction>;
