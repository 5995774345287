import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const Repository: React.FunctionComponent = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 500 500"
      enableBackground="new 0 0 500 500"
      xmlSpace="preserve"
    >
      <g>
        <g>
          <g>
            <g>
              <path d="M429.2,85.6c0,31.9,0,63.8,0,95.7c0,50.8,0,101.5,0,152.3c0,11.6,0,23.1,0,34.7c5-5,10-10,15-15      c-12.9,0-25.8,0-38.7,0c-30.8,0-61.7,0-92.5,0c-37.5,0-74.9,0-112.4,0c-32.3,0-64.5,0-96.8,0c-15.7,0-31.6-0.9-47.3,0      c-0.2,0-0.4,0-0.7,0c5,5,10,10,15,15c0-37.1,0-74.2,0-111.3c0-59.2,0-118.4,0-177.5c0-13.6,0-27.3,0-40.9c-5,5-10,10-15,15      c33.3,0,66.6,0,99.9,0c4.8,0,9.5,0,14.3,0c-3.5-1.5-7.1-2.9-10.6-4.4c16.7,13.3,33.4,26.7,50.1,40c6.1,4.9,12.3,11.3,20.4,11.5      c5.2,0.2,10.5,0,15.7,0c50.8,0,101.6,0,152.5,0c15.4,0,30.8,0,46.1,0c7.8,0,15.4-6.9,15-15c-0.4-8.1-6.6-15-15-15      c-24.2,0-48.5,0-72.7,0c-38.6,0-77.3,0-115.9,0c-8.9,0-17.7,0-26.6,0c3.5,1.5,7.1,2.9,10.6,4.4c-19.7-15.7-39.3-31.5-59-47.1      c-5.6-4.4-10.3-4.4-16.7-4.4c-6.3,0-12.6,0-18.9,0c-18,0-36,0-54,0c-11.7,0-23.5,0-35.2,0c-8.1,0-15,6.9-15,15      c0,37.1,0,74.2,0,111.3c0,59.2,0,118.4,0,177.5c0,13.6,0,27.3,0,40.9c0,8.1,6.9,15,15,15c12.9,0,25.8,0,38.7,0      c30.8,0,61.7,0,92.5,0c37.5,0,74.9,0,112.4,0c32.3,0,64.5,0,96.8,0c15.7,0,31.6,0.5,47.3,0c0.2,0,0.4,0,0.7,0      c8.1,0,15-6.9,15-15c0-31.9,0-63.8,0-95.7c0-50.8,0-101.5,0-152.3c0-11.6,0-23.1,0-34.7c0-7.8-6.9-15.4-15-15      C436.1,70.9,429.2,77.1,429.2,85.6z" />
            </g>
          </g>
          <g>
            <g>
              <path d="M56.5,171.7c12.8,0,25.7,0,38.5,0c30.8,0,61.7,0,92.5,0c37.1,0,74.3,0,111.4,0c32.3,0,64.5,0,96.8,0      c15.7,0,31.4,0.5,47.1,0c0.2,0,0.4,0,0.7,0c7.8,0,15.4-6.9,15-15c-0.4-8.1-6.6-15-15-15c-12.8,0-25.7,0-38.5,0      c-30.8,0-61.7,0-92.5,0c-37.1,0-74.3,0-111.4,0c-32.3,0-64.5,0-96.8,0c-15.7,0-31.4-0.5-47.1,0c-0.2,0-0.4,0-0.7,0      c-7.8,0-15.4,6.9-15,15C41.8,164.8,48.1,171.7,56.5,171.7L56.5,171.7z" />
            </g>
          </g>
        </g>
        <g>
          <g>
            <path d="M235,368.6c0,27.1,0,54.2,0,81.3c0,3.9,0,7.8,0,11.7c0,7.8,6.9,15.4,15,15c8.1-0.4,15-6.6,15-15     c0-27.1,0-54.2,0-81.3c0-3.9,0-7.8,0-11.7c0-7.8-6.9-15.4-15-15C241.9,353.9,235,360.2,235,368.6L235,368.6z" />
          </g>
        </g>
        <g>
          <g>
            <path d="M180,476.6c40.9,0,81.9,0,122.8,0c5.7,0,11.5,0,17.2,0c7.8,0,15.4-6.9,15-15c-0.4-8.1-6.6-15-15-15     c-40.9,0-81.9,0-122.8,0c-5.7,0-11.5,0-17.2,0c-7.8,0-15.4,6.9-15,15C165.4,469.7,171.6,476.6,180,476.6L180,476.6z" />
          </g>
        </g>
      </g>
    </svg>
  </BaseSvgWrapper>
);

export default Repository;
