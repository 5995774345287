import { loadScene } from 'components/async/scene';
import { Module } from 'modules/security/types/modules';
import * as React from 'react';
import { Route } from 'react-router-dom';
import ModuleRoutes from '../../../components/routing/ModuleRoutes';
import { INACTIVE_USERS_BASE_ROUTE, INACTIVE_USERS_MODULE_KEY } from '../constants';

type Props = {
  allowedModules: Module[];
};

const InactiveUsersScene = loadScene('inactiveUsers', 'InactiveUsersScene');

const InactiveUsersRoutes: React.FC<Props> = ({ allowedModules }: Props) => (
  <ModuleRoutes allowedModules={allowedModules} moduleKey={INACTIVE_USERS_MODULE_KEY}>
    <Route path={[INACTIVE_USERS_BASE_ROUTE]} exact>
      <InactiveUsersScene />
    </Route>
  </ModuleRoutes>
);

export default InactiveUsersRoutes;
