import { Input } from 'components';
import { Field, FieldProps, FormikErrors, FormikTouched } from 'formik';
import { getNestedError } from 'helpers/formikHelper';
import * as React from 'react';
import EditableItem from './EditableItem';

type Props = {
  label: string;
  name: string;
  errors: FormikErrors<any>;
  touched: FormikTouched<any>;
  autoFocus?: boolean;
  disabled?: boolean;
  ref?: React.Ref<HTMLInputElement>;
  errorAsBlock?: boolean;
};

const BasicTextField: React.FunctionComponent<Props> = React.forwardRef(
  ({ label, name, errors, touched, autoFocus, disabled, errorAsBlock }: Props, ref: React.Ref<HTMLInputElement>) => {
    return (
      <EditableItem
        label={label}
        value={
          <Field
            name={name}
            render={({ field }: FieldProps) => (
              <Input
                {...field}
                noLabel
                type="text"
                error={getNestedError(name, errors, touched)}
                autoFocus={autoFocus}
                disabled={disabled}
                ref={ref}
                errorAsBlock={errorAsBlock}
              />
            )}
          />
        }
      />
    );
  }
);

export default BasicTextField;
