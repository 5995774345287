import { loadScene } from 'components/async/scene';
import { Module } from 'modules/security/types/modules';
import * as React from 'react';
import { Route } from 'react-router-dom';
import ModuleRoutes from '../../../components/routing/ModuleRoutes';
import { REPOSITORY_MODULE_KEY } from '../constants';

type Props = {
  allowedModules: Module[];
};

export const REPOSITORY_BASE_ROUTE = '/reports';

const repositoryOverviewScene = loadScene('repository', 'RepositoryScene');

const RepositoryRoutes: React.FC<Props> = ({ allowedModules }: Props) => (
  <ModuleRoutes allowedModules={allowedModules} moduleKey={REPOSITORY_MODULE_KEY}>
    <Route
      path={[REPOSITORY_BASE_ROUTE, `${REPOSITORY_BASE_ROUTE}/:subroute`]}
      exact
      component={repositoryOverviewScene}
    />
  </ModuleRoutes>
);

export default RepositoryRoutes;
