import { certPalette as palette } from '../palettes/cert';
import { certTypography as typography } from '../typography/cert';

const certTheme = {
  globals: {
    borderRadius: '7px',
    borderRadiusLarge: '20px',
    borderRadiusSmall: '5px',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
    boxShadowLarge: '2px 4px 20px rgba(0, 0, 0, 0.2)',
    boxShadowNarrow: '0 1px 4px rgba(0, 0, 0, 0.1)',
    transition: 'ease-in-out 0.25s',
  },
  palette,
  typography,
};

export type TenantTheme = typeof certTheme;

export { certTheme };
