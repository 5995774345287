import { FlexRow, Header, IconButton } from 'components';
import { Add, BackArrow, Minus } from 'components/icons';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { REPOSITORY_BASE_ROUTE } from '../../routes';

type Props = {
  scale: number;
  setScale: (scale: number) => void;
};

const PdfHeader: React.FC<Props> = ({ scale, setScale }) => {
  const { t } = useTranslation('repository');
  const history = useHistory();

  const handleBackClick = React.useCallback(() => history.push(REPOSITORY_BASE_ROUTE), [history]);

  return (
    <Header animated>
      <FlexRow>
        <IconButton tooltip={t('global:general.back')} onClick={handleBackClick}>
          <BackArrow />
        </IconButton>
        <IconButton tooltip={t('pdfView.zoomOut')} onClick={() => setScale(scale - 0.1)}>
          <Minus />
        </IconButton>
        <IconButton tooltip={t('pdfView.zoomIn')} onClick={() => setScale(scale + 0.1)}>
          <Add />
        </IconButton>
      </FlexRow>
    </Header>
  );
};

export default PdfHeader;
