import { ElegibleOrganizations } from 'modules/app/types/superAdmin';
import { HttpErrorResponse } from 'types';
import { createAction, createAsyncAction } from 'typesafe-actions';

export const getElegibleOrganizations = createAction('ELEGIBLE_ORGANIZATIONS_GET');

export const getElegibleOrganizationsRequest = createAsyncAction(
  'ELEGIBLE_ORGANIZATIONS_GET_REQUEST',
  'ELEGIBLE_ORGANIZATIONS_GET_SUCCESS',
  'ELEGIBLE_ORGANIZATIONS_GET_REJECTED'
)<undefined, ElegibleOrganizations, HttpErrorResponse>();
