import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const Key: React.FunctionComponent = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 24.5C0 31.6581 5.21173 37 12.1607 37C17.4267 37 21.7155 33.7415 23.4528 28.3996H31.3246V33.688C31.3246 34.703 32.139 35.5043 33.1705 35.5043H35.722C36.7535 35.5043 37.5679 34.703 37.5679 33.688V28.3996H39.7394V30.9637C39.7394 31.9786 40.5537 32.7799 41.5852 32.7799H44.1368C45.1683 32.7799 45.9826 31.9786 45.9826 30.9637V28.3996H48.1542C49.1857 28.3996 50 27.5983 50 26.5833V22.4167C50 21.4017 49.1857 20.6004 48.1542 20.6004L23.5071 20.6004C21.7155 15.2585 17.481 12 12.1607 12C5.21173 12 0 17.3419 0 24.5ZM12.28 30C15.1961 30 17.56 27.5376 17.56 24.5C17.56 21.4624 15.1961 19 12.28 19C9.36394 19 7 21.4624 7 24.5C7 27.5376 9.36394 30 12.28 30Z"
      />
    </svg>
  </BaseSvgWrapper>
);

export default Key;
