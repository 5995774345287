import { setLocale } from 'yup';
import i18n from '../i18n';

setLocale({
  array: {
    max: ({ max }) => i18n.t('validation:array.max', { max }),
    min: ({ min }) => i18n.t('validation:array.min', { min }),
  },
  mixed: {
    oneOf: ({ values }) => i18n.t('validation:oneOf', { values }),
    required: () => i18n.t('validation:required'),
  },
  string: {
    email: () => i18n.t('validation:string.email'),
    max: ({ max }) => i18n.t('validation:string.max', { max }),
    min: ({ min }) => i18n.t('validation:string.min', { min }),
  },
});
