import { createAction, createAsyncAction } from 'typesafe-actions';
import { HttpErrorResponse } from '../../../../../types';
import { AccessRights, Account } from '../../../types/user';

export const USER_ACTION_PREFIX = 'USER';

export const clearUser = createAction('USER_CLEARED');
export const getUser = createAction('USER_GET');
export const fetchUser = createAsyncAction('USER_REQUEST', 'USER_SUCCESS', 'USER_REJECTED')<
  undefined,
  Account,
  HttpErrorResponse
>();

export const fetchUserAccessRights = createAsyncAction(
  'USER_ACCESS_RIGHTS_REQUEST',
  'USER_ACCESS_RIGHTS_SUCCESS',
  'USER_ACCESS_RIGHTS_REJECTED'
)<undefined, AccessRights, HttpErrorResponse>();

export const resetPassword = createAction('RESET_PASSWORD');
export const resetPasswordRequest = createAsyncAction(
  'RESET_PASSWORD_REQUEST',
  'RESET_PASSWORD_SUCCESS',
  'RESET_PASSWORD_REJECTED'
)<undefined, undefined, HttpErrorResponse>();
