import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getType } from 'typesafe-actions';
import GeneralInfoEdit from '../../../../organization/components/detail/content/generalInfo/edit/GeneralInfoEdit';
import {
  ORGANIZATION_DETAIL_GENERAL_INFO_ACTION_PREFIX,
  organizationDetailActions,
} from '../../../../organization/redux/actions/creators';
import { makeGetGeneralInfo } from '../../../../organization/redux/selectors/organizationDetail';
import { UpdateStep } from '../../../../organization/types/organization';
import { globalInfoActions } from '../../../redux/creators';
import { makeGetCountriesSelector } from '../../../redux/selectors/globalInfo';
import { makeActionRequestIsPendingSelector, makeIsPendingSelector } from '../../../redux/selectors/loading';
import ModalContainer from './ModalContainer';

type Props = {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setUpdateStep: React.Dispatch<React.SetStateAction<UpdateStep>>;
  organizationId: string;
};

const GeneralInfoStep = ({ setUpdateStep, setShowModal, organizationId }: Props) => {
  const { t } = useTranslation('organization');
  const dispatch = useDispatch();
  const formRef = useRef<any>(null);

  const generalInfo = useSelector(makeGetGeneralInfo());
  const countries = useSelector(makeGetCountriesSelector());

  const generalInfoPending = useSelector(makeIsPendingSelector(ORGANIZATION_DETAIL_GENERAL_INFO_ACTION_PREFIX));
  const isUpdatingGeneralData = useSelector(
    makeActionRequestIsPendingSelector(getType(organizationDetailActions.updateGeneralInfo))
  );

  useEffect(() => {
    if (!generalInfo) {
      dispatch(organizationDetailActions.getGeneralInfo(organizationId));
    }
  }, [organizationId, dispatch, generalInfo]);

  // Check to see if we have countries to populate the country select, if no: run a GET
  useEffect(() => {
    if (!countries || countries.length === 0) {
      dispatch(globalInfoActions.getCountries());
    }
  }, [dispatch, countries]);

  const handleConfirm = useCallback(() => {
    if (formRef && formRef.current && formRef.current.submitForm) {
      formRef.current.submitForm();
    }
  }, [formRef]);

  const handlePostPone = () => {
    setShowModal(false);
  };

  const ModalBody = () =>
    generalInfoPending ? (
      <div>{t('updateModal.loading')}</div>
    ) : (
      generalInfo && (
        <GeneralInfoEdit
          formRef={formRef}
          generalInfo={generalInfo}
          organizationId={organizationId}
          successCallback={() => setUpdateStep(UpdateStep.generalInfoCompleted)}
          setHasChanges={() => {}}
          setEditMode={() => {}}
          hasChanges={true}
        />
      )
    );

  return (
    <ModalContainer
      modalBody={<ModalBody />}
      modaltitle={t('updateModal.generalInfo.title')}
      primaryBtnHandler={handleConfirm}
      primaryBtnText={t('updateModal.generalInfo.approve')}
      primaryBtnLoading={isUpdatingGeneralData}
      secondaryBtnHandler={handlePostPone}
      secondaryBtnText={t('updateModal.postpone')}
    />
  );
};

export default GeneralInfoStep;
