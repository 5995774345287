import styled from 'config/theme';
import { media } from 'config/theme/utils';

export const PaginatorWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;

  .pageNumber {
    white-space: nowrap;
    margin-right: 10px;

    ${() => media.mobile`
        display: none;
    `};
  }
`;
