import { ChevronLeft, ChevronRight, Filter } from 'components/icons';
import styled from 'config/theme';
import { AnimationProps } from 'config/theme/types';
import { media } from 'config/theme/utils';
import * as React from 'react';
import { CSSTransition } from 'react-transition-group';
import { css } from 'styled-components';
import { LOCAL_STORAGE_SIDEBAR_COLLAPSED_KEY } from '../../modules/settings/constants';
import { IsOpenProps } from './';
import MobileToggle from './MobileToggle';
import * as S from './styles';
import { Tooltip } from '@unbrace/components';
import { useTranslation } from 'react-i18next';

type SidebarProps = {
  isCollapsed?: boolean;
  notFilter?: boolean;
};

type DesktopProps = SidebarProps & AnimationProps;

const Sidebar = styled.div<DesktopProps>`
  ${({ theme, isCollapsed }) => css`
    background-color: ${theme.sidebar.background.main};
    width: ${isCollapsed ? theme.sidebar.width.collapsed : theme.sidebar.width.large};
    min-width: ${isCollapsed ? theme.sidebar.width.collapsed : theme.sidebar.width.large};
    border-right: ${theme.sidebar.border};
    max-height: 100%;
    overflow-y: ${isCollapsed ? 'hidden' : 'auto'};
    overflow-x: hidden;

    > * {
      opacity: ${isCollapsed ? '0' : '1'};
      transition: opacity ${!isCollapsed ? '0.03s 0.55s' : '0.05s 0.35s'} ease-in-out;
    }

    ${(props) => media.desktop`
        width: ${isCollapsed ? theme.sidebar.width.collapsed : theme.sidebar.width.huge};
        min-width: ${isCollapsed ? theme.sidebar.width.collapsed : theme.sidebar.width.huge};
  `};
  `};

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.scrollbar.track};
  }
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.scrollbar.thumb};
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.theme.scrollbar.thumbHover};
  }
  ::-webkit-scrollbar-button {
    display: ${(props) => props.theme.scrollbar.buttonDisplay};
  }

  .search {
    position: sticky;
    top: 0;
    z-index: 99;
  }

  > div {
    padding: ${(props) => props.theme.sidebar.padding.filter};

    h5 {
      margin: 30px 0px 10px;
      font-size: 16px;
      font-weight: 600;
      color: ${(props) => props.theme.sidebar.color.filterTitle};

      &:first-of-type {
        margin-top: 0px;
      }
    }

    label {
      font-weight: 200;
      color: ${(props) => props.theme.sidebar.color.filterLabel};
    }
  }
  ${(props) => props.state === 'entering' && props.theme.itemSidebar.animation.entering};
  ${(props) => props.state === 'entered' && props.theme.itemSidebar.animation.entered};
`;

const TransitionedSidebar = (props: any) => {
  const { t } = useTranslation();
  const checkLocalStorage = () => {
    if (localStorage.getItem(LOCAL_STORAGE_SIDEBAR_COLLAPSED_KEY) === null) {
      localStorage.setItem(LOCAL_STORAGE_SIDEBAR_COLLAPSED_KEY, 'false');

      return false;
    }

    return localStorage.getItem(LOCAL_STORAGE_SIDEBAR_COLLAPSED_KEY) === 'true';
  };
  const [sidebarCollapsed, setSidebarCollapsed] = React.useState(checkLocalStorage());

  const handleSidebarCollapse = () => {
    localStorage.setItem(LOCAL_STORAGE_SIDEBAR_COLLAPSED_KEY, (!sidebarCollapsed).toString());
    setSidebarCollapsed(!sidebarCollapsed);
  };

  return (
    <CSSTransition in timeout={200} mountOnEnter appear>
      {(state) => (
        <S.CollapsibleSidebarContainer>
          <S.ToggleContainer>
            <Tooltip
              content={
                props.isNavigation
                  ? sidebarCollapsed
                    ? t('global:actions.showSide')
                    : t('global:actions.hideSide')
                  : sidebarCollapsed
                  ? t('global:actions.showFilter')
                  : t('global:actions.hideFilter')
              }
            >
              <S.CollapsibleToggle state={state} onClick={handleSidebarCollapse}>
                {props.isNavigation ? (
                  sidebarCollapsed ? (
                    <ChevronRight />
                  ) : (
                    <ChevronLeft />
                  )
                ) : sidebarCollapsed ? (
                  <Filter />
                ) : (
                  <ChevronLeft />
                )}
              </S.CollapsibleToggle>
            </Tooltip>
          </S.ToggleContainer>
          <Sidebar state={state} {...props} isCollapsed={sidebarCollapsed}>
            {props.children}
          </Sidebar>
        </S.CollapsibleSidebarContainer>
      )}
    </CSSTransition>
  );
};

const MobileSidebar = styled(Sidebar)<IsOpenProps>`
  position: absolute;
  width: ${(props) => props.theme.sidebar.mobile.width};
  top: 0;
  left: ${(props) => (props.isOpen ? '0' : '-100%')};
  bottom: ${(props) => props.theme.sidebar.mobile.heightSmall};
  transition: ${(props) => props.theme.sidebar.mobile.transition};
  z-index: 100;
`;

type Props = {
  isNavigation?: boolean;
  overrideIsOpen?: boolean;
  setOverrideIsOpen?: (booleanValue?: boolean) => void;
};

const TransitionedMobileSidebar: React.FunctionComponent<Props> = ({
  isNavigation,
  overrideIsOpen,
  setOverrideIsOpen,
  ...rest
}) => (
  <CSSTransition in timeout={200} mountOnEnter appear>
    {(state) => (
      <MobileToggle
        overrideIsOpen={overrideIsOpen}
        setOverrideIsOpen={setOverrideIsOpen}
        state={state}
        forNavigation={isNavigation}
      >
        {(isOpen) => <MobileSidebar isOpen={overrideIsOpen ?? isOpen} state={state} {...rest} />}
      </MobileToggle>
    )}
  </CSSTransition>
);

export { TransitionedMobileSidebar as MobileSidebar, TransitionedSidebar as Sidebar };
