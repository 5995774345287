import styled, { css } from 'config/theme';

type Props = {
  isLaptopSM?: boolean;
};
export const Footer = styled.footer<Props>`
  align-items: center;
  background: ${props => props.theme.sidebar.background.brand};
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  height: 60px;
  padding: 9px 28px;
  width: 100%;

  ${props =>
    props.isLaptopSM &&
    css`
      height: 120px;
      padding: 5px;
      justify-content: space-around;
    `}
`;
