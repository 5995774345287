import { TenantTheme } from '../tenants/';

const sidebarTheme = (theme: TenantTheme) => ({
  animation: {
    entered: 'opacity: 1; transition: all 0.3s ease; transform: none',
    entering: 'opacity: 0.01; transform: translateX(-20px)',
  },
  background: {
    active: theme.palette.primary.shade5,
    activeHighlight: theme.palette.primary.shade7,
    brand: theme.palette.neutral.shade1,
    hover: theme.palette.neutral.shade1,
    main: theme.palette.neutral.shade0,
    subMenuParent: theme.palette.neutral.shade2,
    submenu: theme.palette.neutral.shade1,
  },
  border: `2px solid ${theme.palette.neutral.shade2}`,
  color: {
    activeText: theme.palette.primary.shade1,
    filterLabel: theme.palette.neutral.shade5,
    filterTitle: theme.palette.neutral.shade9,
    icon: theme.palette.primary.shade5,
    text: theme.palette.primary.shade7,
  },
  mobile: {
    animation: {
      entered: 'opacity: 1; transition: all 110ms ease; transform: none',
      entering: 'opacity: 0.01; transform: translateY(20px)',
    },
    height: '79px',
    heightSmall: '66px',
    transition: 'left 0.2s cubic-bezier(0.67, 0.49, 0.15, 0.82)',
    width: '85%',
  },
  padding: {
    filter: '20px',
  },
  width: {
    huge: '320px',
    large: '275px',
    regular: '275px',
    small: '75px',
    collapsed: '20px',
  },
});

export default sidebarTheme;
