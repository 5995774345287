import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const RssFeed: React.FunctionComponent = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 5V10.6364H14.3182C38.695 10.6364 57.3636 29.3049 57.3636 53.6818V58H63V53.6818C63 24.6375 43.3624 5 14.3182 5H10ZM10 20.5455V26.1818H14.3182C30.1095 26.1818 41.8182 37.8905 41.8182 53.6818V58H47.4545V53.6818C47.4545 33.2231 34.7769 20.5455 14.3182 20.5455H10ZM18.0909 41.8182C13.4989 41.8182 10 45.2316 10 49.9091C10 53.5869 13.4132 58 18.0909 58C22.7685 58 26.1818 54.5869 26.1818 49.9091C26.1818 45.2316 22.6829 41.8182 18.0909 41.8182Z" />
    </svg>
  </BaseSvgWrapper>
);

export default RssFeed;
