import { Organization, OrganizationFilters } from 'modules/organization/types/organization';
import { combineReducers } from 'redux';
import { PaginationInfo, SortValue } from 'types';
import filterReducer from './filters';
import organizationReducer from './organization';
import pageInfoReducer from './pageInfo';
import sortingReducer from './sorting';

export type OrganizationsState = {
  filters: OrganizationFilters;
  pageInfo: PaginationInfo;
  organizations: Organization[] | null;
  sorting: SortValue[];
};

const organizationsReducer = combineReducers({
  filters: filterReducer,
  organizations: organizationReducer,
  pageInfo: pageInfoReducer,
  sorting: sortingReducer,
});

export default organizationsReducer;
