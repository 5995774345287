import styled from '../../config/theme';

type Props = {
  capitalize?: boolean;
  danger?: boolean;
  large?: boolean;
  onlyText?: boolean;
  primary?: boolean;
  secondary?: boolean;
  small?: boolean;
  xSmall?: boolean;
  success?: boolean;
  warning?: boolean;
};

export default styled('div')<Props>`
  align-self: center;
  display: inline-block;
  border-radius: ${props => props.theme.badge.borderRadius};
  font-size: ${props => (props.xSmall ? props.theme.badge.fontSizeXSmall : props.theme.badge.fontSize)};
  font-weight: ${props => props.theme.badge.fontWeight};
  letter-spacing: ${props => (props.capitalize ? 'normal' : '2px')};
  max-height: 100%;
  padding: ${props =>
    props.onlyText
      ? '0px'
      : props.large
      ? props.theme.badge.paddingLarge
      : props.small || props.xSmall
      ? props.theme.badge.paddingSmall
      : props.theme.badge.padding};
  text-align: center;
  text-transform: ${props => (props.capitalize ? 'lowercase' : 'uppercase')};

  &:first-letter {
    text-transform: ${props => props.capitalize && 'uppercase'};
  }

  ${props => props.primary && props.theme.badge.color.primary};
  ${props => props.primary && !props.onlyText && props.theme.badge.background.primary};

  ${props => props.secondary && props.theme.badge.color.secondary}
  ${props => props.secondary && !props.onlyText && props.theme.badge.background.secondary};

  ${props => props.success && props.theme.badge.color.success}
  ${props => props.success && !props.onlyText && props.theme.badge.background.success};

  ${props => props.warning && props.theme.badge.color.warning}
  ${props => props.warning && !props.onlyText && props.theme.badge.background.warning};

  ${props => props.danger && props.theme.badge.color.danger}
  ${props => props.danger && !props.onlyText && props.theme.badge.background.danger};
`;
