import { Repository } from 'modules/repository/types/repository';
import { getType } from 'typesafe-actions';
import { RepositoryActions, repositoryActions } from '../../creators';

const repositoryReducer = (state: Repository[] | null = null, action: RepositoryActions) => {
  switch (action.type) {
    case getType(repositoryActions.getRepositoriesRequest.success):
      return action.payload.items;

    case getType(repositoryActions.addRepositoryRequest.success):
      return state
        ? [
            {
              ...action.payload,
              reportDate: new Date().toString(),
              uploadDate: new Date().toString(),
            },
            ...state,
          ]
        : [action.payload];

    case getType(repositoryActions.markRepositoryReadUnreadRequest.success):
      return state
        ? state.map((repository) => (repository.reportId === action.payload.reportId ? action.payload : repository))
        : null;
    case getType(repositoryActions.downloadRepositoryRequest.success):
      return state
        ? state.map((repository) => (repository.guid === action.payload ? { ...repository, read: true } : repository))
        : null;
    case getType(repositoryActions.editRepositoryRequest.success):
    case getType(repositoryActions.reportQuestionRequest.success):
    case getType(repositoryActions.deleteRepositoryRequest.success):
    default:
      return state;
  }
};

export default repositoryReducer;
