import { RootState } from 'config/store/rootReducer';
import { NotificationType } from 'modules/notifications/types';
import { createSelector } from 'reselect';

export const makeGetNotifications = () =>
  createSelector(getNotifications, (notifications: NotificationType[] | null) => notifications);

export const makeGetNotificationsCount = () =>
  createSelector(getNotifications, (notifications: NotificationType[] | null) => notifications?.length);

const getNotifications = (state: RootState): NotificationType[] | null => state.notifications;
