import { Badge } from 'components';
import { UserStatus } from 'modules/organization/types/organizationDetail/users';
import * as React from 'react';

type Props = {
  status: UserStatus;
};

const UserStatusBadge: React.FunctionComponent<Props> = ({ status }: Props) => {
  const getBadgeType = () => {
    switch (status) {
      case UserStatus.Activated:
        return { success: true };
      case UserStatus.Waiting:
        return { warning: true };
      case UserStatus.Suspended:
        return { danger: true };
      case UserStatus.Upgraded:
        return { warning: true };
      case UserStatus.Pending:
      default:
        return { primary: true };
    }
  };

  return (
    <Badge {...getBadgeType()} capitalize>
      {status}
    </Badge>
  );
};

export default UserStatusBadge;
