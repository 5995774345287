import { ConvertedFile } from '../../../components/Dropzone/FileInput';
import AxiosConfig, { RequestConfig } from '../../../config/api/AxiosConfig';
import { UPLOAD_CERTIFICATE_URL } from '../constants';

const uploadCertificates = async (file: ConvertedFile) => {
  const requestConfig: RequestConfig = {
    data: {
      content: file.base64,
      fileName: file.name,
    },
    url: `${UPLOAD_CERTIFICATE_URL}`,
  };

  return AxiosConfig.fetchAxiosPostRequest(requestConfig);
};

export default { uploadCertificates };
