import { Tooltip } from '@unbrace/components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { ChevronDown, ChevronUp, DragHandle, PriorityHigh } from '../../../../components/icons';
import { certPalette } from '../../../../config/theme/palettes/cert';
import { Priority } from '../../../app/constants';
import { capitalizeString } from 'helpers/stringUtils';

type Props = {
  priority: Priority;
};

const PriorityBadge: React.FunctionComponent<Props> = ({ priority }) => {
  const { t } = useTranslation('repository');

  const getIconType = () => {
    switch (priority) {
      case Priority.Low:
        return <ChevronDown />;
      case Priority.Medium:
        return <DragHandle />;
      case Priority.High:
        return <ChevronUp />;
      case Priority.Critical:
        return <PriorityHigh />;
      default:
        return null;
    }
  };

  const getColor = () => {
    switch (priority) {
      case Priority.Low:
        return 'primary';
      case Priority.Medium:
        return 'warning';
      case Priority.High:
        return 'secondary';
      case Priority.Critical:
        return 'danger';
      default:
        return 'primary';
    }
  };

  return (
    <Tooltip content={t('tooltips.priority', { priority: capitalizeString(priority) })}>
      <PriorityIconColor variant={getColor()}>{getIconType()}</PriorityIconColor>
    </Tooltip>
  );
};

export default PriorityBadge;

const PriorityIconColor = styled.span<{ variant: keyof typeof certPalette }>`
  ${({ theme, variant }) => css`
    && {
      svg {
        width: 24px;
        fill: ${theme.palette[variant].shade4};
        margin-right: 4px;
      }
    }
  `}
`;
