import AxiosConfig, { RequestConfig } from '../../../config/api/AxiosConfig';
import { PaginationInfo } from '../../../types';
import { SuperUserPostBody, SuperUsersFilters } from '../types';
import { ENTITIES_URL, SUPER_ADMIN_URL } from './endpoints';

export const getSuperUsers = async (
  pageInfo: Pick<PaginationInfo, 'pageIndex' | 'pageSize'>,
  filters?: SuperUsersFilters,
  sorting?: string[]
) => {
  const params = {
    pageIndex: pageInfo.pageIndex,
    pageSize: pageInfo.pageSize,
    ...(filters && {
      ...(filters.search && { search: filters.search }),
      ...(filters.roles && { roles: filters.roles }),
      ...(filters.onlyEnabled && { onlyEnabled: filters.onlyEnabled }),
    }),
    ...(sorting && { orderBys: sorting }),
  };

  const requestConfig: RequestConfig = {
    params,
    url: ENTITIES_URL,
  };

  return AxiosConfig.fetchAxiosPagedGetRequest(requestConfig);
};

export const addSuperUser = async (values: SuperUserPostBody) => {
  const requestConfig: RequestConfig = {
    url: SUPER_ADMIN_URL,
    data: values,
  };

  return AxiosConfig.fetchAxiosPostRequest(requestConfig);
};

export const disableSuperUser = async (guid: string) => {
  const requestConfig: RequestConfig = {
    url: `${ENTITIES_URL}/${guid}/disable`,
  };

  return AxiosConfig.fetchAxiosPostRequest(requestConfig);
};

export const enableSuperUser = async (guid: string) => {
  const requestConfig: RequestConfig = {
    url: `${ENTITIES_URL}/${guid}/enable`,
  };

  return AxiosConfig.fetchAxiosPostRequest(requestConfig);
};

export const downgradeSuperUser = async (userGuid: string) => {
  const requestConfig: RequestConfig = {
    url: `${ENTITIES_URL}/downgrade`,
    params: { userGuid },
  };

  return AxiosConfig.fetchAxiosPostRequest(requestConfig);
};
