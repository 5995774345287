import axios from 'axios';
import { FlexCol, FlexRow, Paper } from 'components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';
import styled from 'styled-components';
import { REPOSITORIES_URL } from '../../api/endpoints';
import PdfHeader from './PdfHeader';

pdfjs.GlobalWorkerOptions.workerSrc = process.env.PUBLIC_URL + '/pdf.worker.js';

type Props = {
  guid: string;
};

const PdfView: React.FC<Props> = ({ guid }) => {
  const { t } = useTranslation('repository');
  const [numPages, setNumPages] = React.useState<number | null>(null);
  const [document, setDocument] = React.useState<Blob | null>(null);
  const [scale, setScale] = React.useState(1.2);

  const documentUrl = `${process.env.REACT_APP_API_ROOT}${REPOSITORIES_URL}/${guid}/download?type=view`;

  const onDocumentLoadSuccess = (numPages: number) => {
    setNumPages(numPages);
  };

  React.useEffect(() => {
    axios.request({ url: documentUrl, withCredentials: true, responseType: 'blob' }).then((response) => {
      setDocument(new Blob([response.data]));
    });
  }, [documentUrl]);

  return (
    <FlexRow>
      <FlexCol>
        <PdfHeader scale={scale} setScale={setScale} />
        <ContentWrapper>
          <Document
            noData=""
            file={document}
            loading={<Paper>{t('pdfView.loading')}</Paper>}
            error={<Paper>{t('pdfView.error')}</Paper>}
            onLoadSuccess={(pdf) => onDocumentLoadSuccess(pdf.numPages)}
          >
            {numPages &&
              Array.from(new Array(numPages), (el, index) => {
                const pageNumber = index + 1;

                return (
                  <Paper key={`page_${pageNumber}`}>
                    <Page scale={scale} key={`page_${pageNumber}`} pageNumber={pageNumber} />

                    <p>
                      {t('pdfView.page', {
                        pageNumber,
                        numPages,
                      })}
                    </p>
                  </Paper>
                );
              })}
          </Document>
        </ContentWrapper>
      </FlexCol>
    </FlexRow>
  );
};

const ContentWrapper = styled.div`
  width: fit-content;
`;

export default PdfView;
