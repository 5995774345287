import * as Yup from 'yup';
import MobileContactsOverview from '../../components/step/MobileContactsOverview';
import { InputField } from '../../types';
import { CONTACTS, CUSTOM } from '../index';
import { contactHoursValidationSchema } from './contactHours';
import { contactValidationSchema } from './howToReach';
import { mobileProfileValidationSchema } from './mobileProfile';

export const mobileContactsFields: InputField[] = [
  {
    component: MobileContactsOverview,
    initialValue: [],
    name: 'contacts',
    type: CUSTOM,
    width: {
      desktop: 12,
    },
  },
];

const validation = Yup.object().shape({
  contacts: Yup.array()
    .min(1)
    .of(
      Yup.object()
        .concat(contactHoursValidationSchema)
        .concat(contactValidationSchema)
        .concat(mobileProfileValidationSchema)
    ),
});

export const headerTranslations = {
  content: 'contacts.content',
  title: 'contacts.title',
};

export const mobileContactsStep = {
  fields: mobileContactsFields,
  headerTranslations,
  key: 'contacts',
  submitParam: CONTACTS,
  validationSchema: validation,
};
