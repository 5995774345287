import i18n from 'config/i18n';
import * as Yup from 'yup';
import { TYPE_TEXT } from '../../../../components/Form/Input';
import PGPKeyUpload from '../../../app/components/PGPKeyUpload';
import MispToMispField from '../../components/MispToMispField';
import { InputField } from '../../types';
import { CUSTOM, GENERAL_DATA } from '../index';
import MispExistingOrg from 'modules/onboarding/components/MispExistingOrg';
import CountrySelect from 'components/Select/CountrySelect';
import CreatableSelect from 'modules/onboarding/components/select/CreatableSelect';

const mispFields: InputField[] = [
  {
    component: MispToMispField,
    label: 'misp.formLabels.mispToMisp',
    name: 'mispToMisp',
    required: true,
    initialValue: '',
    type: CUSTOM,
    width: {
      desktop: 6,
      tablet: 12,
    },
  },
  {
    component: MispExistingOrg,
    label: '',
    required: true,
    initialValue: '',
    name: 'accountNeeded',
    type: CUSTOM,
    width: {
      desktop: 6,
      tablet: 6,
    },
    noRenderConditions: [
      {
        name: 'mispToMisp',
        value: 'true',
      },
      {
        name: 'mispToMisp',
        value: '',
      },
      {
        name: 'mispToMisp',
        value: 'null',
      },
    ],
  },
  {
    label: 'misp.formLabels.mispOrganizationName',
    name: 'mispOrganizationName',
    required: true,
    type: TYPE_TEXT,
    width: {
      desktop: 6,
      tablet: 6,
    },
    noRenderConditions: [
      {
        name: 'accountNeeded',
        value: '',
      },
      {
        name: 'mispToMisp',
        value: '',
      },
      {
        name: 'mispToMisp',
        value: 'null',
      },
    ],
  },
  {
    label: 'misp.formLabels.mispOrganizationDescription',
    name: 'mispDescription',
    required: true,
    type: TYPE_TEXT,
    width: {
      desktop: 6,
    },
    noRenderConditions: [
      {
        name: 'accountNeeded',
        value: '',
      },
      {
        name: 'mispToMisp',
        value: '',
      },
      {
        name: 'mispToMisp',
        value: 'null',
      },
    ],
  },
  {
    label: 'misp.formLabels.mispOrganizationGuid',
    name: 'mispOrganizationGuid',
    required: true,
    type: TYPE_TEXT,
    width: {
      desktop: 6,
    },
    noRenderConditions: [
      {
        name: 'accountNeeded',
        value: '',
      },
      {
        name: 'accountNeeded',
        value: 'false',
      },
      {
        name: 'mispToMisp',
        value: '',
      },
      {
        name: 'mispToMisp',
        value: 'null',
      },
    ],
  },
  {
    component: CountrySelect,
    label: 'addresses.formLabels.country',
    name: 'mispCountryId',
    required: true,
    type: CUSTOM,
    width: {
      desktop: 6,
    },
    noRenderConditions: [
      {
        name: 'accountNeeded',
        value: '',
      },
      {
        name: 'mispToMisp',
        value: '',
      },
      {
        name: 'mispToMisp',
        value: 'null',
      },
    ],
  },
  {
    component: CreatableSelect,
    initialValue: [],
    label: 'misp.formLabels.sector',
    name: 'sector',
    required: true,
    type: CUSTOM,
    width: {
      desktop: 6,
    },
    noRenderConditions: [
      {
        name: 'accountNeeded',
        value: '',
      },
      {
        name: 'mispToMisp',
        value: '',
      },
      {
        name: 'mispToMisp',
        value: 'null',
      },
    ],
  },
  {
    label: 'misp.formLabels.mispPocEmail',
    name: 'mispPocEmail',
    required: true,
    type: TYPE_TEXT,
    width: {
      desktop: 6,
      tablet: 6,
    },
    noRenderConditions: [
      {
        name: 'accountNeeded',
        value: '',
      },
      {
        name: 'mispToMisp',
        value: '',
      },
      {
        name: 'mispToMisp',
        value: 'null',
      },
    ],
  },
  {
    label: 'misp.formLabels.mispExtraEmail',
    name: 'mispExtraEmail',
    required: true,
    type: TYPE_TEXT,
    width: {
      desktop: 6,
    },
    noRenderConditions: [
      {
        name: 'accountNeeded',
        value: '',
      },
      {
        name: 'mispToMisp',
        value: '',
      },
      {
        name: 'mispToMisp',
        value: 'null',
      },
    ],
  },
  {
    component: CreatableSelect,
    initialValue: [],
    label: 'misp.formLabels.domainRestrictions',
    name: 'domainRestrictions',
    required: true,
    type: CUSTOM,
    width: {
      desktop: 6,
    },
    noRenderConditions: [
      {
        name: 'accountNeeded',
        value: '',
      },
      {
        name: 'mispToMisp',
        value: '',
      },
      {
        name: 'mispToMisp',
        value: 'null',
      },
    ],
  },
  {
    acceptedFileTypes: ['.jpg', '.png', '.jpeg', '.svg'],
    component: PGPKeyUpload,
    label: 'misp.formLabels.mispLogoFile',
    name: 'mispLogoFile',
    requiredIf: {
      name: 'mispToMisp',
      value: 'true',
    },
    type: CUSTOM,
    width: {
      desktop: 12,
      tablet: 12,
    },
    noRenderConditions: [
      {
        name: 'mispToMisp',
        value: 'null',
      },
    ],
  },
  {
    acceptedFileTypes: ['.asc'],
    component: PGPKeyUpload,
    label: 'misp.formLabels.mispPocPgpFile',
    name: 'mispPocPgpFile',
    required: true,
    type: CUSTOM,
    width: {
      desktop: 12,
      tablet: 12,
    },
    noRenderConditions: [
      {
        name: 'mispToMisp',
        value: 'null',
      },
    ],
  },
];

const mispValidationSchema = Yup.object().shape({
  mispLogoFile: Yup.object()
    .shape({
      base64: Yup.string(),
      file: Yup.mixed(),
      name: Yup.string(),
      pgpFileId: Yup.string(),
    })
    .when('mispToMisp', {
      is: 'true',
      then: Yup.object().shape({
        base64: Yup.string(),
        file: Yup.mixed(),
        name: Yup.string().required(() => i18n.t('validation:required')),
        pgpFileId: Yup.string(),
      }),
    }),
  mispOrganizationGuid: Yup.string()
    .matches(/^([0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12})?$/i, () =>
      i18n.t('validation:string.guid')
    )
    .when(['mispToMisp', 'accountNeeded'], {
      is: (mispToMisp, accountNeeded) => mispToMisp === 'true' || accountNeeded === 'true',
      then: Yup.string()
        .matches(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i, () =>
          i18n.t('validation:string.guid')
        )
        .required(),
    }),
  mispOrganizationName: Yup.string().when('mispToMisp', {
    is: (mispToMisp) => mispToMisp !== 'null',
    then: Yup.string().required(),
  }),
  mispDescription: Yup.string().when('mispToMisp', {
    is: (mispToMisp) => mispToMisp !== 'null',
    then: Yup.string().required(),
  }),
  mispCountryId: Yup.string().when('mispToMisp', {
    is: (mispToMisp) => mispToMisp !== 'null',
    then: Yup.string().required(() => i18n.t('validation:required')),
  }),
  sector: Yup.array()
    .of(Yup.string())
    .when('mispToMisp', {
      is: (mispToMisp) => mispToMisp !== 'null',
      then: Yup.array()
        .of(Yup.string())
        .min(1, ({ min }) => i18n.t('validation:array.min', { min })),
    }),
  domainRestrictions: Yup.array()
    .of(
      Yup.string().matches(/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/, () =>
        i18n.t('validation:onboarding.domainName')
      )
    )
    .when('mispToMisp', {
      is: (mispToMisp) => mispToMisp !== 'null',
      then: Yup.array()
        .of(
          Yup.string().matches(/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/, () =>
            i18n.t('validation:onboarding.domainName')
          )
        )
        .min(1, ({ min }) => i18n.t('validation:array.min', { min })),
    }),
  mispPocEmail: Yup.string()
    .email(() => i18n.t('validation:string.email'))
    .when('mispToMisp', {
      is: (mispToMisp) => mispToMisp !== 'null',
      then: Yup.string().required(() => i18n.t('validation:required')),
    }),
  mispExtraEmail: Yup.string()
    .email(() => i18n.t('validation:string.email'))
    .when('mispToMisp', {
      is: (mispToMisp) => mispToMisp !== 'null',
      then: Yup.string().required(() => i18n.t('validation:required')),
    }),
  mispPocPgpFile: Yup.object()
    .shape({
      base64: Yup.string(),
      file: Yup.mixed(),
      name: Yup.string(),
      pgpFileId: Yup.string(),
    })
    .when('mispToMisp', {
      is: (mispToMisp) => mispToMisp !== 'null',
      then: Yup.object().shape({
        base64: Yup.string(),
        file: Yup.mixed(),
        name: Yup.string().required(() => i18n.t('validation:required')),
        pgpFileId: Yup.string(),
      }),
    }),
  mispToMisp: Yup.string()
    .matches(/[true|false]/)
    .required(() => i18n.t('validation:required')),
  accountNeeded: Yup.string().when('mispToMisp', {
    is: 'false',
    then: Yup.string()
      .matches(/[true|false]/)
      .required(() => i18n.t('validation:required')),
  }),
  tlsEmail: Yup.string().email(() => i18n.t('validation:string.email')),
});

const headerTranslations = {
  content: 'misp.content',
  title: 'misp.title',
};

export const mispStep = {
  fields: mispFields,
  headerTranslations,
  key: 'misp',
  submitParam: GENERAL_DATA,
  validationSchema: mispValidationSchema,
};
