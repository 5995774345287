import { combineReducers } from 'redux';
import { PaginationInfo, SortValue } from 'types';
import { InactiveUsersFilters } from '../../types';
import filterReducer from './filters';
import InactiveUsersReducer, { InactiveUsersModuleState } from './inactiveUsers';
import pageInfoReducer from './pageInfo';
import sortingReducer from './sorting';

export type InactiveUsersState = {
  inactiveUsers: InactiveUsersModuleState;
  filters: InactiveUsersFilters;
  pageInfo: PaginationInfo;
  sorting: SortValue[];
};

const inactiveUsersModuleReducer = combineReducers({
  inactiveUsers: InactiveUsersReducer,
  filters: filterReducer,
  pageInfo: pageInfoReducer,
  sorting: sortingReducer,
});

export default inactiveUsersModuleReducer;
