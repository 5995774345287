import { takeEvery } from '@redux-saga/core/effects';
import { searchActions } from 'modules/app/redux/creators';
import {
  getCriticalAlerts,
  addCriticalAlert,
  removeCriticalAlert,
  editCriticalAlert,
} from 'modules/flashAlerts/adapters/criticalAlertsApi';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { formatSortingForApi } from '../../../../helpers/sortingFormatter';
import { CRITICAL_ALERTS_FILTER_KEY } from '../../constants';
import { criticalAlertsActions, filterActions, pageInfoActions, sortingActions } from '../creators';
import {
  makeGetCriticalAlertsSorting,
  makeGetCriticalAlertsFilters,
  makeGetCriticalAlertsPageInfo,
} from '../selectors/criticalAlerts';

function* criticalAlertsSaga() {
  yield takeLatest(
    [
      filterActions.setAudienceFilter,
      filterActions.setDateFromFilter,
      filterActions.setDateUntilFilter,
      filterActions.setPriorityFilter,
      filterActions.setReportTypeFilter,
      filterActions.setSectorFilter,
      filterActions.setTlpCodeFilter,
      pageInfoActions.setPage,
      pageInfoActions.setPageSize,
      criticalAlertsActions.getCriticalAlerts,
      sortingActions.setSorting,
    ],
    handleGetCriticalAlerts
  );

  yield takeLatest(
    [
      filterActions.setAudienceFilter,
      filterActions.setDateFromFilter,
      filterActions.setDateUntilFilter,
      filterActions.setPriorityFilter,
      filterActions.setReportTypeFilter,
      filterActions.setSectorFilter,
      filterActions.setTlpCodeFilter,
      pageInfoActions.setPageSize,
    ],
    handleGetCriticalAlertsWithPage1
  );

  yield takeLatest(searchActions.setSearch, handleSearchChange);
  yield takeLatest(criticalAlertsActions.addCriticalAlert, handleAddCriticalAlert);
  yield takeLatest(criticalAlertsActions.editCriticalAlert, handleEditCriticalAlert);
  yield takeEvery(criticalAlertsActions.deleteCriticalAlert, handleDeleteCriticalAlert);
}

function* handleGetCriticalAlerts() {
  const { request, success, failure } = criticalAlertsActions.getCriticalAlertsRequest;
  yield put(request());
  try {
    const filters = yield select(makeGetCriticalAlertsFilters());
    const pageInfo = yield select(makeGetCriticalAlertsPageInfo());
    const sorting = yield select(makeGetCriticalAlertsSorting());
    const payload = yield call(getCriticalAlerts, pageInfo, filters, formatSortingForApi(sorting));
    yield put(success(payload));
  } catch (e) {
    yield put(failure(e.response));
  }
}
function* handleGetCriticalAlertsWithPage1() {
  yield put(pageInfoActions.setPage(1));
}

function* handleSearchChange(action: ReturnType<typeof searchActions.setSearch>) {
  if (action.payload.filterKey === CRITICAL_ALERTS_FILTER_KEY) {
    yield put(pageInfoActions.setPage(1));
  }
}

function* handleAddCriticalAlert(action: ReturnType<typeof criticalAlertsActions.addCriticalAlert>) {
  const { request, success, failure } = criticalAlertsActions.addCriticalAlertRequest;
  yield put(request());

  try {
    const response = yield call(addCriticalAlert, action.payload);
    yield put(success({ response, data: action.payload }));
    action.successCallback?.();
  } catch (e) {
    yield put(failure(e.response));
  }
}

function* handleDeleteCriticalAlert(action: ReturnType<typeof criticalAlertsActions.deleteCriticalAlert>) {
  const { request, success, failure } = criticalAlertsActions.deleteCriticalAlertRequest;
  yield put(request());

  try {
    yield call(removeCriticalAlert, action.payload);
    yield put(success(action.payload));
  } catch (e) {
    yield put(failure(e.response));
  }
}

function* handleEditCriticalAlert(action: ReturnType<typeof criticalAlertsActions.editCriticalAlert>) {
  const { request, success, failure } = criticalAlertsActions.editCriticalAlertRequest;
  yield put(request());

  try {
    const response = yield call(editCriticalAlert, action.payload);
    yield put(success(response));
    action.successCallback();
  } catch (e) {
    yield put(failure(e.response));
  }
}

export default criticalAlertsSaga;
