import { FullViewBlock } from 'components/FullView';
import { makeIsPendingSelector } from 'modules/app/redux/selectors/loading';
import {
  ORGANIZATION_DETAIL_CONTACTS_ACTION_PREFIX,
  organizationDetailActions,
} from 'modules/organization/redux/actions/creators';
import { makeGetContacts } from 'modules/organization/redux/selectors/organizationDetail';
import { BusinessLocation } from 'modules/organization/types/organizationDetail/general';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ContactType } from '../../../../../app/types/contact';
import BlockLoadingState from '../BlockLoadingState';
import Contact from './Contact';
import ContactInfoEdit from './ContactInfoEdit';

type Props = {
  editMode: boolean;
  formRef: React.RefObject<any>;
  setHasChanges: (hasChanges: boolean) => void;
  organizationId: string;
  setEditMode: (editMode: boolean) => void;
  businessLocations: BusinessLocation[];
  hasChanges: boolean;
};

const ContactInfo: React.FunctionComponent<Props> = ({
  organizationId,
  editMode,
  formRef,
  setHasChanges,
  setEditMode,
  businessLocations,
  hasChanges,
}: Props) => {
  const { t } = useTranslation('organization');
  const contacts = useSelector(makeGetContacts());
  const dispatch = useDispatch();
  const isPending = useSelector(makeIsPendingSelector(ORGANIZATION_DETAIL_CONTACTS_ACTION_PREFIX));

  const adminContact = contacts?.find((contact) => contact.types.includes(ContactType.Admin));
  const otherContacts = contacts?.filter((contact) => !contact.types.includes(ContactType.Admin));

  React.useEffect(() => {
    if (!contacts) {
      dispatch(organizationDetailActions.getContacts(organizationId));
    }
  }, [organizationId, dispatch, contacts]);

  return contacts && !isPending ? (
    editMode ? (
      <ContactInfoEdit
        formRef={formRef}
        contacts={contacts}
        setHasChanges={setHasChanges}
        organizationId={organizationId}
        setEditMode={setEditMode}
        businessLocations={businessLocations || []}
        hasChanges={hasChanges}
      />
    ) : (
      <React.Fragment>
        {adminContact && (
          <FullViewBlock>
            <h2 className="block-title">{t('detail.blocks.adminContact')}</h2>
            <div className="block-data">
              <Contact contact={adminContact} />
            </div>
          </FullViewBlock>
        )}
        {otherContacts && otherContacts.length > 0 && (
          <FullViewBlock>
            <h2 className="block-title">{t('detail.blocks.contacts')}</h2>
            <div className="block-data">
              {otherContacts.map((contact) => (
                <Contact contact={contact} key={contact.contactId} />
              ))}
            </div>
          </FullViewBlock>
        )}
      </React.Fragment>
    )
  ) : (
    <BlockLoadingState />
  );
};

export default ContactInfo;
