import { ContentLoader, SkeletonGenerator } from 'components';
import styled from 'config/theme';
import * as React from 'react';

const UsersLoadingState: React.FunctionComponent = () => (
  <section style={{ maxWidth: 600, textAlign: 'right' }}>
    <SkeletonGenerator count={1}>
      <ContentLoader height={30}>
        <rect x="6" y="7" rx="2" ry="2" width="70" height="10" />
        <rect x="230" y="7" rx="2" ry="2" width="70" height="10" />
        <rect x="330" y="7" rx="2" ry="2" width="45" height="10" />
      </ContentLoader>
    </SkeletonGenerator>
    <SkeletonGenerator count={4}>
      <LoadingWrapper>
        <ContentLoader height={35}>
          <rect x="6" y="15" rx="2" ry="2" width="200" height="7" />
          <rect x="230" y="15" rx="2" ry="2" width="70" height="7" />
          <rect x="330" y="15" rx="2" ry="2" width="45" height="7" />
        </ContentLoader>
      </LoadingWrapper>
    </SkeletonGenerator>
  </section>
);

const LoadingWrapper = styled.div`
  max-width: 1000px;
`;

export default UsersLoadingState;
