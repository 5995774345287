import { RootState } from 'config/store/rootReducer';
import { Organization, OrganizationFilters } from 'modules/organization/types/organization';
import { createSelector } from 'reselect';
import { PaginationInfo, SortValue } from 'types';

const getOrganizations = (state: RootState): Organization[] | null => state.organizations.organization.organizations;

const getFilters = (state: RootState): OrganizationFilters => state.organizations.organization.filters;

const getPaginationInfo = (state: RootState): PaginationInfo => state.organizations.organization.pageInfo;

const getSortingInfo = (state: RootState): SortValue[] => state.organizations.organization.sorting;

export const makeGetOrganizations = () =>
  createSelector(getOrganizations, (organizations: Organization[] | null) => organizations);

export const makeGetOrganizationFilters = () => createSelector(getFilters, (filters: OrganizationFilters) => filters);

export const makeGetOrganizationPageInfo = () =>
  createSelector(getPaginationInfo, (pageInfo: PaginationInfo) => pageInfo);

export const makeGetOrganizationSorting = () => createSelector(getSortingInfo, (sorting: SortValue[]) => sorting);
