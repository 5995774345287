import { TenantTheme } from '../tenants';

const getAlertTheme = (theme: TenantTheme) => ({
  background: theme.palette.neutral.shade0,
  borderRadius: '7px',
  boxShadow: '3px 4px 8px rgba(0, 0, 0, 0.05), 2px 1px 20px rgba(0, 0, 0, 0.05)',
  color: {
    danger: theme.palette.danger.shade5,
    dangerSecondary: theme.palette.danger.shade1,
    default: theme.palette.primary.shade5,
    defaultSecondary: theme.palette.primary.shade1,
    success: theme.palette.success.shade5,
    successSecondary: theme.palette.success.shade1,
    warning: theme.palette.warning.shade5,
    warningSecondary: theme.palette.warning.shade1,
  },
});

export default getAlertTheme;
