import { media } from 'config/theme/utils';
import styled, { css } from '../../config/theme';

type Props = {
  bold?: boolean;
  capitalize?: boolean;
};

const mobileLabelStyling = css`
  width: 100%;
  text-align: left;
  margin-bottom: 8px;
`;

export const Label = styled('label')<Props>`
  color: ${props => (props.bold ? props.theme.fullView.color.primary : props.theme.fullView.color.secondary)};
  font-weight: ${props => (props.bold ? props.theme.fullView.fontWeight.editLabel : 200)};
  text-align: right;

  ${() => media.mobile`
    ${mobileLabelStyling}
  `}

  ${() => media.tablet`
    ${mobileLabelStyling}
  `}

  ${() => media.laptopSM`
    ${mobileLabelStyling}
  `}
`;

const mobileValueStyling = css`
  width: 100%;
  margin-left: 0px;
`;

export const Value = styled('div')<Props>`
  white-space: pre-line;
  color: ${props => props.theme.fullView.color.primary};
  font-weight: ${props => (props.bold ? props.theme.fullView.fontWeight.editLabel : 400)};
  margin-left: 25px;
  width: 320px;
  ${props =>
    props.capitalize &&
    css`
      text-transform: capitalize;
    `};

  input,
  .formik-select {
    margin-bottom: 0;
    margin-top: 4px;

    + .error,
    > .error {
      margin-top: 4px;
      position: relative;
      bottom: 0;
      top: inherit;
    }
  }

  ${() => media.mobile`
    ${mobileValueStyling}
  `}

  ${() => media.tablet`
    ${mobileValueStyling}
  `}

  ${() => media.laptopSM`
    ${mobileValueStyling}
  `}

  ${() => media.desktop`
    margin-left: 32px;
    width: 350px;
  `}
`;
