import { HttpErrorResponse } from '../../../../../types';
import { GetMailDetailResponse } from '../../../types/mailDetail';
import { MailUser } from '../../../types/mails';
import {
  MAIL_GET,
  MAIL_REJECTED,
  MAIL_REQUEST,
  MAIL_RESEND,
  MAIL_RESEND_REJECTED,
  MAIL_RESEND_SUCCESS,
  MAIL_SUCCESS,
} from '../constants/mail';
import {
  FetchMailAction,
  GetMailAction,
  RejectFetchMailAction,
  RejectResendMailAction,
  ResendMailAction,
  SetMailAction,
  SuccessResendMailAction,
} from '../types/mail';

export const getMail = (id: string): GetMailAction => ({
  payload: {
    id,
  },
  type: MAIL_GET,
});

export const fetchMail = (): FetchMailAction => ({
  type: MAIL_REQUEST,
});

export const rejectFetchMail = (error: HttpErrorResponse): RejectFetchMailAction => ({
  payload: error,
  type: MAIL_REJECTED,
});

export const setMail = (payload: GetMailDetailResponse): SetMailAction => ({
  payload,
  type: MAIL_SUCCESS,
});

export const resendMail = (
  id: string,
  to: MailUser[],
  cc: Array<MailUser | undefined>,
  bcc: Array<MailUser | undefined>
): ResendMailAction => ({
  payload: {
    bcc,
    cc,
    id,
    to,
  },
  type: MAIL_RESEND,
});

export const successResendMail = (): SuccessResendMailAction => ({
  type: MAIL_RESEND_SUCCESS,
});

export const rejectResendMail = (error: HttpErrorResponse): RejectResendMailAction => ({
  payload: error,
  type: MAIL_RESEND_REJECTED,
});
