import { CTIData } from 'modules/organization/types/organizationDetail/cti';
import { getType } from 'typesafe-actions';
import { organizationDetailActions, OrganizationDetailActions } from '../../actions/creators';

const ctiReducer = (state: CTIData | null = null, action: OrganizationDetailActions) => {
  switch (action.type) {
    case getType(organizationDetailActions.getCTIRequest.success):
    case getType(organizationDetailActions.updateCTIDataRequest.success):
      return action.payload;

    case getType(organizationDetailActions.clearOrganizationDetail):
      return null;

    default:
      return state;
  }
};

export default ctiReducer;
