import { HttpErrorResponse } from 'types';
import { Country } from 'types/countries';
import { createAction, createAsyncAction } from 'typesafe-actions';

export const getCountries = createAction('COUNTRIES_GET');

export const getCountriesRequest = createAsyncAction(
  'COUNTRIES_GET_REQUEST',
  'COUNTRIES_GET_SUCCESS',
  'COUNTRIES_GET_REJECTED'
)<undefined, Country[], HttpErrorResponse>();
