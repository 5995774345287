import * as React from 'react';
import Slider from './Slider';

const getHour = (value: number): string => (value < 10 ? `0${value}` : value.toString());
const getMinutes = (value: number): string => (value === 0 ? '00' : (value * 60).toString());
const valuetext = (value: number) => `${getHour(Math.floor(value))}:${getMinutes(value - Math.floor(value))}h`;

const TimeSlider = (props: any) => <Slider {...props} getAriaValueText={valuetext} valueLabelDisplay="on" />;

export default TimeSlider;
