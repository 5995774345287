import * as React from 'react';
import { FullViewBlockItem } from './FullViewElement';
import { Label, Value } from './ItemComponents';

type Props = {
  label: string;
  value?: string | React.ReactNode;
  capitalize?: boolean;
  className?: string;
};

// TODO: color for in edit mode and fullview needs some adaptation

const FullViewItem: React.FunctionComponent<Props> = (props: Props) =>
  props.value !== undefined && props.value !== '' ? (
    <FullViewBlockItem key={props.label} className={props.className}>
      <Label>{props.label}:</Label>
      <Value capitalize={props.capitalize}>{props.value}</Value>
    </FullViewBlockItem>
  ) : null;

export default FullViewItem;
