import { useActionIsPending } from 'hooks';
import { criticalAlertsActions } from 'modules/flashAlerts/redux/creators';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { getType } from 'typesafe-actions';
import { MarkRepositoryReadUnreadValues } from '../../types/repository';
import { repositoryActions } from '../creators';

const useRepositoryGeneralStore = () => {
  const dispatch = useDispatch();
  const [isAddingRepository, startAddingRepository] = useActionIsPending(getType(repositoryActions.addRepository));
  const [isAddingFlashAlert, startAddingFlashAlert] = useActionIsPending(
    getType(criticalAlertsActions.addCriticalAlert)
  );

  const handleStartAdding = () => {
    startAddingRepository();
    startAddingFlashAlert();
  };

  const markRepositoryReadUnread = React.useCallback(
    (values: MarkRepositoryReadUnreadValues) => {
      dispatch(repositoryActions.markRepositoryReadUnread(values));
    },
    [dispatch]
  );

  return {
    markRepositoryReadUnread,
    isAddingRepository,
    startAdding: handleStartAdding,
    isAdding: isAddingRepository || isAddingFlashAlert,
  };
};

export default useRepositoryGeneralStore;
