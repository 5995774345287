import styled from '../../config/theme';
import { media } from '../../config/theme/utils';

export const FormFlexRow = styled.div`
  display: flex;
  max-width: 100%;
  & > div {
    flex-basis: 100%;
    &:nth-child(2n) {
      padding-left: 16px;
    }
  }

  ${media.mobile`
      flex-direction: column;
      padding: 0;
    `};

  ${media.tablet`
      flex-direction: column;
      padding: 0;
    `};
`;
