import { RootState } from 'config/store/rootReducer';
import { createSelector } from 'reselect';
import { PaginationInfo, SortValue } from 'types';
import { CriticalAlert } from '../../../app/types/criticalAlert';
import { CriticalAlertsFilters } from '../../types';

const getCriticalAlerts = (state: RootState): CriticalAlert[] | null => state.criticalAlerts.criticalAlerts;
export const makeGetCriticalAlerts = () =>
  createSelector(getCriticalAlerts, (criticalAlerts: CriticalAlert[] | null) => criticalAlerts);

const getFilters = (state: RootState): CriticalAlertsFilters => state.criticalAlerts.filters;
export const makeGetCriticalAlertsFilters = () =>
  createSelector(getFilters, (filters: CriticalAlertsFilters) => filters);

const getPaginationInfo = (state: RootState): PaginationInfo => state.criticalAlerts.pageInfo;
export const makeGetCriticalAlertsPageInfo = () =>
  createSelector(getPaginationInfo, (pageInfo: PaginationInfo) => pageInfo);

const getSortingInfo = (state: RootState): SortValue[] => state.criticalAlerts.sorting;
export const makeGetCriticalAlertsSorting = () => createSelector(getSortingInfo, (sorting: SortValue[]) => sorting);
