import { takeEvery } from '@redux-saga/core/effects';
import { searchActions } from 'modules/app/redux/creators';
import repositoryApi from 'modules/repository/api/repositoryApi';
import { REPOSITORY_FILTER_KEY } from 'modules/repository/constants';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { formatSortingForApi } from '../../../../helpers/sortingFormatter';
import { filterActions, pageInfoActions, repositoryActions, sortingActions } from '../creators';
import {
  makeGetRepositoriesSorting,
  makeGetRepositoryFilters,
  makeGetRepositoryPageInfo,
} from '../selectors/repository';

function* repositorySaga() {
  yield takeLatest(
    [
      filterActions.setAudienceFilter,
      filterActions.setDateFromFilter,
      filterActions.setDateUntilFilter,
      filterActions.setFullTextFilter,
      filterActions.setPriorityFilter,
      filterActions.setReportTypeFilter,
      filterActions.setSectorFilter,
      filterActions.setTlpCodeFilter,
      pageInfoActions.setPage,
      pageInfoActions.setPageSize,
      repositoryActions.getRepositories,
      sortingActions.setSorting,
    ],
    handleGetRepositories
  );
  yield takeLatest(
    [
      filterActions.setAudienceFilter,
      filterActions.setDateFromFilter,
      filterActions.setDateUntilFilter,
      filterActions.setFullTextFilter,
      filterActions.setPriorityFilter,
      filterActions.setReportTypeFilter,
      filterActions.setSectorFilter,
      filterActions.setTlpCodeFilter,
      pageInfoActions.setPageSize,
    ],
    handleGetRepositoriesWithPage1
  );
  yield takeLatest(repositoryActions.addRepository, handleAddRepository);
  yield takeLatest(repositoryActions.editRepository, handleEditRepository);
  yield takeLatest(repositoryActions.deleteRepository, handleDeleteRepository);
  yield takeLatest(repositoryActions.downloadRepository, handleDownloadRepository);
  yield takeLatest(repositoryActions.simpleDownloadRepository, handleSimpleDownloadRepository);
  yield takeLatest(searchActions.setSearch, handleSearchChange);
  yield takeEvery(repositoryActions.markRepositoryReadUnread, handleMarkRepositoryReadUnread);
  yield takeEvery(repositoryActions.reportQuestion, handleReportQuestion);
}

function* handleGetRepositories() {
  const { request, success, failure } = repositoryActions.getRepositoriesRequest;
  yield put(request());
  try {
    const filters = yield select(makeGetRepositoryFilters());
    const pageInfo = yield select(makeGetRepositoryPageInfo());
    const sorting = yield select(makeGetRepositoriesSorting());
    const payload = yield call(repositoryApi.getRepositories, pageInfo, filters, formatSortingForApi(sorting));
    yield put(success(payload));
  } catch (e) {
    yield put(failure(e.response));
  }
}
function* handleGetRepositoriesWithPage1() {
  yield put(pageInfoActions.setPage(1));
}

function* handleAddRepository(action: ReturnType<typeof repositoryActions.addRepository>) {
  const { request, success, failure } = repositoryActions.addRepositoryRequest;
  yield put(request());
  try {
    const payload = yield call(repositoryApi.addRepository, action.payload);
    yield put(success(payload));
    action.successCallback();
  } catch (e) {
    yield put(failure(e.response));
  }
}

function* handleEditRepository(action: ReturnType<typeof repositoryActions.editRepository>) {
  const { request, success, failure } = repositoryActions.editRepositoryRequest;
  yield put(request());
  try {
    yield call(repositoryApi.editRepository, action.payload);
    yield put(success());
    action.successCallback();
    yield call(handleGetRepositories);
  } catch (e) {
    yield put(failure(e.response));
  }
}

function* handleDeleteRepository(action: ReturnType<typeof repositoryActions.deleteRepository>) {
  const { request, success, failure } = repositoryActions.deleteRepositoryRequest;
  yield put(request());
  try {
    yield call(repositoryApi.deleteRepository, action.payload.id);
    yield put(success());
    yield call(handleGetRepositories);
  } catch (e) {
    yield put(failure(e.response));
  }
}

function* handleReportQuestion(action: ReturnType<typeof repositoryActions.reportQuestion>) {
  const { request, success, failure } = repositoryActions.reportQuestionRequest;
  yield put(request());
  try {
    yield call(repositoryApi.reportQuestion, action.payload);
    yield put(success());
    action.successCallback();
  } catch (e) {
    yield put(failure(e.response));
  }
}

function* handleDownloadRepository(action: ReturnType<typeof repositoryActions.downloadRepository>) {
  const { request, success, failure } = repositoryActions.downloadRepositoryRequest;
  yield put(request());
  try {
    yield call(repositoryApi.downloadRepository, action.payload.values, action.payload.type);
    yield put(success(action.payload.values.guid));
  } catch (e) {
    yield put(failure(e.response));
  }
}
function* handleSimpleDownloadRepository(action: ReturnType<typeof repositoryActions.simpleDownloadRepository>) {
  const { request, success, failure } = repositoryActions.simpleDownloadRepositoryRequest;
  yield put(request());
  try {
    yield call(repositoryApi.simpleDownloadRepository, action.payload);
    yield put(success());
  } catch (e) {
    yield put(failure(e.response));
  }
}

function* handleMarkRepositoryReadUnread(action: ReturnType<typeof repositoryActions.markRepositoryReadUnread>) {
  const { request, success, failure } = repositoryActions.markRepositoryReadUnreadRequest;
  yield put(request());
  try {
    const response = yield call(repositoryApi.markAsReadUnread, action.payload);
    yield put(success(response));
  } catch (e) {
    yield put(failure(e.response));
  }
}

function* handleSearchChange(action: ReturnType<typeof searchActions.setSearch>) {
  if (action.payload.filterKey === REPOSITORY_FILTER_KEY) {
    yield put(pageInfoActions.setPage(1));
  }
}

export default repositorySaga;
