import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from '../../../config/store/rootReducer';
import { makeIsPendingSelector } from '../../app/redux/selectors/loading';
import { LOGIN_REDIRECT_ACTION_PREFIX } from '../redux/actions/constants/login';
import { loginActions, LoginActionsType } from '../redux/actions/creators';
import { makeGetIsSameOrigin } from '../redux/selectors/login';

type DispatchProps = {
  initiateRedirect: (returnUrl: string) => void;
};

type MapStateToProps = {
  isPending?: boolean;
  isSameOrigin: boolean;
};

type Props = {
  children?: JSX.Element;
} & DispatchProps &
  MapStateToProps;

class RedirectService extends React.PureComponent<Props> {
  componentDidMount() {
    this.props.initiateRedirect(window.location.href);
  }

  render(): JSX.Element | null {
    const { children, isPending, isSameOrigin } = this.props;

    return children && !isPending && isSameOrigin ? children : null;
  }
}

const makeMapStateToProps = () => {
  const isPendingSelect = makeIsPendingSelector(LOGIN_REDIRECT_ACTION_PREFIX);
  const isSameOriginSelect = makeGetIsSameOrigin();

  return (state: RootState) => ({
    isPending: isPendingSelect(state),
    isSameOrigin: isSameOriginSelect(state),
  });
};

const mapDispatchToProps = (dispatch: Dispatch<LoginActionsType>) =>
  bindActionCreators(
    {
      initiateRedirect: loginActions.initiateRedirect,
    },
    dispatch
  );

const enhance = connect(makeMapStateToProps, mapDispatchToProps);

export default enhance(RedirectService);
