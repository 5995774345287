import AxiosConfig, { RequestConfig } from 'config/api/AxiosConfig';
import { ORGANIZATIONS_URL } from './enpoints';

const getProfileUpdateStep = (id: string) => {
  const requestConfig: RequestConfig = {
    url: `${ORGANIZATIONS_URL}/${id}/profile-update-step`,
  };

  return AxiosConfig.fetchAxiosGetRequest(requestConfig);
};

// /profile-completed

export default { getProfileUpdateStep };
