import AxiosConfig, { RequestConfig } from '../../../config/api/AxiosConfig';
import { MailUser } from '../types/mails';
import { MAIL_URL } from './endpoints';

type MailsParameter = {
  pageIndex?: number;
  search?: string;
};

type MailParameter = {
  id: string;
};

type ResendMailParameter = {
  bcc: MailUser[];
  cc: MailUser[];
  emailId: string;
  to: MailUser[];
  application: string;
};

class MailsApi {
  public async getMails(pageIndex = 1, search?: string) {
    const requestConfig: RequestConfig<MailsParameter> = {
      params: {
        pageIndex,
        search,
      },
      url: MAIL_URL,
    };

    return AxiosConfig.fetchAxiosPagedGetRequest(requestConfig);
  }

  public async getMail(id: string) {
    const requestConfig: RequestConfig<MailParameter> = {
      url: `${MAIL_URL}/${id}`,
    };

    return AxiosConfig.fetchAxiosGetRequest(requestConfig);
  }

  public async resendMail(
    id: string,
    to: MailUser[],
    cc: Array<MailUser | undefined>,
    bcc: Array<MailUser | undefined>
  ) {
    const requestConfig: RequestConfig<ResendMailParameter> = {
      data: {
        bcc,
        cc,
        emailId: id,
        to,
      },
      url: `${MAIL_URL}/resend`,
    };

    return AxiosConfig.fetchAxiosPostRequest(requestConfig);
  }
}

export default new MailsApi();
