import { SearchAction, searchActions } from 'modules/app/redux/creators';
import { REPOSITORY_FILTER_KEY } from 'modules/repository/constants';
import { filterActions, FilterActions } from 'modules/repository/redux/creators';
import { RepositoryFilters } from 'modules/repository/types/repository';
import { getType } from 'typesafe-actions';

const filterReducer = (state: RepositoryFilters = { fullText: false }, action: SearchAction | FilterActions) => {
  switch (action.type) {
    case getType(filterActions.setDateFromFilter):
      const isDateFromEmpty = Boolean(!action.payload);
      if (isDateFromEmpty) {
        const copy = { ...state };
        delete copy.dateFrom;

        return copy;
      } else {
        return { ...state, dateFrom: action.payload };
      }

    case getType(filterActions.setDateUntilFilter):
      const isDateUntilEmpty = Boolean(!action.payload);
      if (isDateUntilEmpty) {
        const copy = { ...state };
        delete copy.dateUntil;

        return copy;
      } else {
        return { ...state, dateUntil: action.payload };
      }

    case getType(filterActions.setSectorFilter):
      const isSectorEmpty = action.payload.length === 0;
      if (isSectorEmpty) {
        const copy = { ...state };
        delete copy.sectors;

        return copy;
      } else {
        return { ...state, sectors: action.payload };
      }

    case getType(filterActions.setReportTypeFilter):
      const isReportTypeEmpty = action.payload.length === 0;

      if (isReportTypeEmpty) {
        const copy = { ...state };
        delete copy.reportType;

        return copy;
      } else {
        return { ...state, reportType: action.payload };
      }

    case getType(filterActions.setAudienceFilter):
      const isAudienceEmpty = action.payload.length === 0;

      if (isAudienceEmpty) {
        const copy = { ...state };
        delete copy.audience;

        return copy;
      } else {
        return { ...state, audience: action.payload };
      }

    case getType(filterActions.setPriorityFilter):
      const isPriorityEmpty = action.payload.length === 0;

      if (isPriorityEmpty) {
        const copy = { ...state };
        delete copy.priority;

        return copy;
      } else {
        return { ...state, priority: action.payload };
      }

    case getType(filterActions.setTlpCodeFilter):
      const isTlpCodeEmpty = action.payload.length === 0;

      if (isTlpCodeEmpty) {
        const copy = { ...state };
        delete copy.tlpCode;

        return copy;
      } else {
        return { ...state, tlpCode: action.payload };
      }

    case getType(filterActions.setFullTextFilter):
      return { ...state, fullText: action.payload };

    case getType(searchActions.setSearch):
      return action.payload.filterKey === REPOSITORY_FILTER_KEY
        ? { ...state, search: action.payload.searchText }
        : state;

    case getType(searchActions.clearSearch):
      const newState = { ...state };
      delete newState.search;

      return newState;

    default:
      return state;
  }
};

export default filterReducer;
