import { FieldProps, getIn } from 'formik';
import * as React from 'react';
import { ConvertedFile } from '../Dropzone/FileInput';
import FileUpload from '../Dropzone/FileUpload';
import { isSingleFile } from '../Dropzone/isSingleFileHelper';

type Props = FieldProps & {
  getFileValues?: (file: ConvertedFile) => any;
  isMulti?: boolean;
  acceptedFileTypes?: string[];
  onFileClick?: () => void;
  label?: string;
  isDownloadable?: boolean;
  maxFileSize?: number;
};

const FormikFileUpload: React.FunctionComponent<Props> = ({
  field,
  form,
  getFileValues,
  isMulti,
  maxFileSize,
  acceptedFileTypes,
  ...props
}) => {
  const handleFilesChange = (files: ConvertedFile[] | ConvertedFile) => {
    isSingleFile(files)
      ? form.setFieldValue(field.name, getFileValues ? getFileValues(files) : files)
      : form.setFieldValue(field.name, getFileValues ? files.map((file) => getFileValues(file)) : files);
  };

  return (
    <FileUpload
      {...props}
      inputId={field.name}
      acceptedFileTypes={acceptedFileTypes}
      initialValues={getIn(form.values, field.name) || undefined}
      isMulti={isMulti}
      onChange={handleFilesChange}
      error={getIn(form.errors, field.name)}
      maxFileSize={maxFileSize}
    />
  );
};

export default FormikFileUpload;
