import { call, put, select, takeLatest } from '@redux-saga/core/effects';
import onBoardingApi from '../../api/onboardingApi';
import { onBoardingActions } from '../actions';
import { makeGetOrganizationId } from '../selectors/user';

function* getOnBoardingSaga() {
  yield takeLatest(onBoardingActions.getGeneralData, handleGeneralDataGet);
  yield takeLatest(onBoardingActions.getContacts, handleContactsGet);
  yield takeLatest(onBoardingActions.getCtiData, handleCtiDataGet);
}

function* handleGeneralDataGet() {
  const organizationId = yield select(makeGetOrganizationId());
  yield put(onBoardingActions.getGeneralDataRequest.request());

  try {
    const payload = yield call(onBoardingApi.getOrganizationGeneralData, organizationId);
    yield put(onBoardingActions.getGeneralDataRequest.success(payload));
  } catch (e) {
    yield put(onBoardingActions.getGeneralDataRequest.failure(e.response));
  }
}

function* handleContactsGet() {
  const organizationId = yield select(makeGetOrganizationId());
  yield put(onBoardingActions.getContactsRequest.request());

  try {
    const payload = yield call(onBoardingApi.getOrganizationContacts, organizationId);
    yield put(onBoardingActions.getContactsRequest.success(payload));
  } catch (e) {
    yield put(onBoardingActions.getContactsRequest.failure(e.response));
  }
}

function* handleCtiDataGet() {
  const organizationId = yield select(makeGetOrganizationId());
  yield put(onBoardingActions.getCtiDataRequest.request());

  try {
    const payload = yield call(onBoardingApi.getOrganizationCtiData, organizationId);
    yield put(onBoardingActions.getCtiDataRequest.success(payload));
  } catch (e) {
    yield put(onBoardingActions.getCtiDataRequest.failure(e.response));
  }
}

export default getOnBoardingSaga;
