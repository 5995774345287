import { RouteLeavingGuard } from 'components';
import { EditableItem, FullViewBlock } from 'components/FullView';
import { Field, FieldArray, FieldProps, Form, Formik } from 'formik';
import { IpRange, IpRangeEditValues, IpVersion, KeywordType } from 'modules/app/types';
import { organizationDetailActions } from 'modules/organization/redux/actions/creators';
import { ctiDataSchema } from 'modules/organization/schemas/organizationDetail';
import { CTIData, CTIDataEditValues, Domain } from 'modules/organization/types/organizationDetail/cti';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import CerticatesUpload from '../../../../../app/components/CertificatesUpload';
import EditIpRange from '../../../shared/EditIpRange';
import IpRangePlaceholder from '../../../shared/IpRangePlaceholder';
import CreatableKeywordSelect from './CreatableKeywordSelect';
import DomainPlaceholder from './DomainPlaceholder';
import EditDomain from './EditDomain';

type Props = {
  ctiData: CTIData;
  formRef: React.RefObject<any>;
  setHasChanges: (hasChanges: boolean) => void;
  organizationId: string;
  setEditMode: (editMode: boolean) => void;
  hasChanges: boolean;
  successCallback?: Function;
};

const CTIInfoEdit: React.FunctionComponent<Props> = ({
  ctiData,
  formRef,
  setHasChanges,
  organizationId,
  setEditMode,
  hasChanges,
  successCallback,
}: Props) => {
  const { t } = useTranslation(['organization', 'validation']);
  const dispatch = useDispatch();

  const successCallbackOnUpdate = () => {
    successCallback?.();
    setEditMode(false);
  };

  React.useEffect(() => {
    return () => {
      setEditMode(false);
    };
  }, [setEditMode]);

  const onSubmit = React.useCallback(
    (values: CTIDataEditValues) => {
      const mappedValues: CTIData = {
        ...values,
        ipRanges: values.ipRanges
          ? values.ipRanges.map((ipRange: IpRangeEditValues) => ({
              ...ipRange,
              ipVersion: ipRange.ipVersion || IpVersion.Ipv4,
            }))
          : [],
        keywords: [...values.darkweb, ...values.emailDomains, ...values.tags],
      };
      dispatch(organizationDetailActions.updateCTIData(organizationId, mappedValues, successCallbackOnUpdate));
    },
    [dispatch, organizationId]
  );

  return (
    <React.Fragment>
      <RouteLeavingGuard when={hasChanges} />

      <Formik
        initialValues={{
          ...ctiData,
          darkweb: ctiData.keywords.filter((k) => k.type === KeywordType.DarkWeb),
          emailDomains: ctiData.keywords.filter((k) => k.type === KeywordType.EmailDomain),
          tags: ctiData.keywords.filter((k) => k.type === KeywordType.Tag),
        }}
        onSubmit={onSubmit}
        ref={formRef}
        validate={() => setHasChanges(true)}
        validationSchema={ctiDataSchema}
        render={({ touched, errors, values }) => (
          <Form>
            <FieldArray
              name="domains"
              render={(arrayHelpers) => (
                <FullViewBlock amountOfButtons={2}>
                  <h2 className="block-title">{t('detail.blocks.domains')}</h2>
                  <div className="block-data">
                    {values.domains && values.domains.length > 0 ? (
                      values.domains.map((domain: Domain, index: number) => (
                        <EditDomain
                          key={index}
                          domain={domain}
                          index={index}
                          errors={errors}
                          touched={touched}
                          arrayHelpers={arrayHelpers}
                        />
                      ))
                    ) : (
                      <DomainPlaceholder arrayHelpers={arrayHelpers} errors={errors} touched={touched} />
                    )}
                  </div>
                </FullViewBlock>
              )}
            />
            <FullViewBlock amountOfButtons={2}>
              <h2 className="block-title">{t('detail.blocks.emailDomains')}</h2>
              <div className="block-data">
                <EditableItem
                  label={t('detail.labels.domains')}
                  value={
                    <Field
                      name={`emailDomains`}
                      render={(fieldProps: FieldProps<CTIDataEditValues>) => (
                        <CreatableKeywordSelect {...fieldProps} keyWordType={KeywordType.EmailDomain} errorAsBlock />
                      )}
                    />
                  }
                />
              </div>
            </FullViewBlock>
            <FieldArray
              name="ipRanges"
              render={(arrayHelpers) => (
                <FullViewBlock amountOfButtons={2}>
                  <h2 className="block-title">{t('detail.blocks.ipRanges')}</h2>
                  <div className="block-data">
                    {values.ipRanges && values.ipRanges.length > 0 ? (
                      values.ipRanges.map((ipRange: IpRange, index: number) => (
                        <EditIpRange
                          key={index}
                          ipRange={ipRange}
                          index={index}
                          errors={errors}
                          touched={touched}
                          arrayHelpers={arrayHelpers}
                          name="ipRanges"
                        />
                      ))
                    ) : (
                      <IpRangePlaceholder
                        arrayHelpers={arrayHelpers}
                        errors={errors}
                        touched={touched}
                        name="ipRanges"
                      />
                    )}
                  </div>
                </FullViewBlock>
              )}
            />
            <FullViewBlock amountOfButtons={2}>
              <h2 className="block-title">{t('detail.blocks.certificates')}</h2>
              <div className="block-data">
                <EditableItem
                  label={t('detail.labels.certificates.title')}
                  value={
                    <Field
                      name={`certificates`}
                      render={({ field, form }: FieldProps<CTIDataEditValues>) => (
                        <CerticatesUpload values={values} field={field} form={form} />
                      )}
                    />
                  }
                />
              </div>
            </FullViewBlock>
            <FullViewBlock amountOfButtons={2}>
              <h2 className="block-title">{t('detail.blocks.keywords')}</h2>
              <div className="block-data">
                <Field
                  name={`darkweb`}
                  render={(fieldProps: FieldProps<CTIDataEditValues>) => (
                    <EditableItem
                      label={t('detail.labels.darkWeb')}
                      value={<CreatableKeywordSelect {...fieldProps} keyWordType={KeywordType.DarkWeb} errorAsBlock />}
                    />
                  )}
                />
              </div>
            </FullViewBlock>
          </Form>
        )}
      />
    </React.Fragment>
  );
};

export default CTIInfoEdit;
