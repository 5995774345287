import { media } from 'config/theme/utils';
import styled from '../../config/theme';

const SceneWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-grow: 1;

  ${props => media.mobile`
    height: calc(100% - ${props.theme.sidebar.mobile.height} - 2px);
    max-height: calc(var(--vh,1vh) * 100 - 2px);
  `};

  ${props => media.tablet`
    height: calc(100% - ${props.theme.sidebar.mobile.height} - 2px);
    max-height: calc(var(--vh,1vh) * 100 - 2px);

  `};
`;

export default SceneWrapper;
