import { FileInstance } from '../../app/types/pgpKey';

const formatFileUploadHelper = (
  fileInstance: FileInstance,
  id: string,
  backEndKeys: { id: string; name: string; content: string },
  disabled?: boolean
) => {
  if (disabled) {
    return {};
  }
  if (!id) {
    // create new
    if (fileInstance.file) {
      return {
        [backEndKeys.content]: fileInstance.base64,
        [backEndKeys.name]: fileInstance.name,
      };
    } else {
      return {};
    }
  } else {
    // update
    if (fileInstance.file) {
      return {
        [backEndKeys.content]: fileInstance.base64,
        [backEndKeys.id]: id,
        [backEndKeys.name]: fileInstance.name,
      };
    }
    // delete
    if (!fileInstance.id) {
      return {};
    } else {
      return {
        [backEndKeys.id]: id,
      };
    }
  }
};

export default formatFileUploadHelper;
