import { getType } from 'typesafe-actions';
import { CriticalAlert } from '../../../types/criticalAlert';
import { criticalAlertsActions, CriticalAlertsActions } from '../../creators';

export type CriticalAlertsState = CriticalAlert[] | null;

const criticalAlertsReducer = (state: CriticalAlertsState = null, action: CriticalAlertsActions) => {
  switch (action.type) {
    case getType(criticalAlertsActions.getUnconfirmedCriticalAlertsRequest.success):
      return action.payload;
    case getType(criticalAlertsActions.confirmCriticalAlertRequest.success):
      return state ? state.filter(criticalAlert => criticalAlert.guid !== action.payload) : state;
    default:
      return state;
  }
};

export default criticalAlertsReducer;
