import * as React from 'react';
import { Button, ModalContent, SubmitButton } from '../../../../../components';
import { Flexer } from '@unbrace/components';

type Props = {
  modalBody: React.ReactNode;
  modaltitle: string;
  primaryBtnHandler: any;
  primaryBtnText: string;
  primaryBtnLoading?: boolean;
  secondaryBtnHandler: any;
  secondaryBtnText: string;
  tertiaryBtnHandler?: any;
  tertiaryBtnText?: string;
};

const ModalContainer = ({
  modalBody,
  modaltitle,
  primaryBtnHandler,
  primaryBtnText,
  primaryBtnLoading,
  secondaryBtnHandler,
  secondaryBtnText,
  tertiaryBtnHandler,
  tertiaryBtnText,
}: Props) => {
  return (
    <ModalContent>
      <ModalContent.Header>
        <h1>{modaltitle}</h1>
      </ModalContent.Header>
      <ModalContent.Body>{modalBody}</ModalContent.Body>
      <ModalContent.Footer style={{ justifyContent: 'space-between' }}>
        <Button onClick={secondaryBtnHandler} disabled={primaryBtnLoading} tertiary>
          {secondaryBtnText}
        </Button>
        <Flexer>
          {tertiaryBtnHandler && (
            <Button onClick={tertiaryBtnHandler} disabled={primaryBtnLoading} tertiary>
              {tertiaryBtnText}
            </Button>
          )}
          <SubmitButton
            isLoading={primaryBtnLoading}
            disabled={primaryBtnLoading}
            onClick={primaryBtnHandler}
            primary
            type="submit"
          >
            {primaryBtnText}
          </SubmitButton>
        </Flexer>
      </ModalContent.Footer>
    </ModalContent>
  );
};
export default ModalContainer;
