import { NotificationType } from 'modules/notifications/types';
import { getType } from 'typesafe-actions';
import { NotificationActions, notificationActions } from '../creators';

const notificationReducer = (state: NotificationType[] | null = null, action: NotificationActions) => {
  switch (action.type) {
    case getType(notificationActions.getNotificationsRequest.success):
      return action.payload;

    default:
      return state;
  }
};

export default notificationReducer;
