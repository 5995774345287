import { Badge } from 'components';
import { OrganizationStatus } from 'modules/organization/types/organization';
import * as React from 'react';

type Props = {
  status: OrganizationStatus;
};

const StatusBadge: React.FunctionComponent<Props> = ({ status }: Props) => {
  const getBadgeType = () => {
    switch (status) {
      case OrganizationStatus.Activated:
        return { success: true };
      case OrganizationStatus.Waiting:
      case OrganizationStatus.ReEvaluation:
      case OrganizationStatus.New:
        return { warning: true };
      case OrganizationStatus.Suspended:
      case OrganizationStatus.ToCorrect:
        return { danger: true };
      case OrganizationStatus.Pending:
      default:
        return { primary: true };
    }
  };

  return (
    <Badge {...getBadgeType()} capitalize>
      {status}
    </Badge>
  );
};

export default StatusBadge;
