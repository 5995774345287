import { createAction, createAsyncAction, createCustomAction } from 'typesafe-actions';
import { HttpErrorResponse, HttpResponse, SuccessCallback } from '../../../../types';
import {
  AddReportTypeValues,
  DeleteReportTypeValues,
  EditReportTypeValues,
  ReportType,
  ReportTypefilters,
} from '../../types/reportTypes';

export const getReportTypes = createAction('REPORTTYPES_GET', action => (params?: ReportTypefilters) => action(params));
export const getReportTypesRequest = createAsyncAction(
  'REPORTTYPES_REQUEST',
  'REPORTTYPES_SUCCESS',
  'REPORTTYPES_REJECTED'
)<undefined, HttpResponse<ReportType>, HttpErrorResponse>();

export const addReportType = createCustomAction(
  'REPORTTYPE_ADD',
  type => (params: AddReportTypeValues, successCallback: SuccessCallback) => ({
    payload: params,
    successCallback,
    type,
  })
);
export const addReportTypeRequest = createAsyncAction(
  'REPORTTYPE_ADD_REQUEST',
  'REPORTTYPE_ADD_SUCCESS',
  'REPORTTYPE_ADD_REJECTED'
)<undefined, ReportType, HttpErrorResponse>();

export const deleteReportType = createAction('REPORTTYPE_DELETE', action => (params: DeleteReportTypeValues) =>
  action(params)
);
export const deleteReportTypeRequest = createAsyncAction(
  'REPORTTYPE_DELETE_REQUEST',
  'REPORTTYPE_DELETE_SUCCESS',
  'REPORTTYPE_DELETE_REJECTED'
)<undefined, undefined, HttpErrorResponse>();

export const editReportType = createCustomAction(
  'REPORTTYPE_EDIT',
  type => (params: EditReportTypeValues, successCallback: SuccessCallback) => ({
    payload: params,
    successCallback,
    type,
  })
);
export const editReportTypeRequest = createAsyncAction(
  'REPORTTYPE_EDIT_REQUEST',
  'REPORTTYPE_EDIT_SUCCESS',
  'REPORTTYPE_EDIT_REJECTED'
)<undefined, ReportType, HttpErrorResponse>();
