import { call, put, takeLatest } from 'redux-saga/effects';
import profileUpdateApi from '../../api/profileUpdateApi';
import { profileUpdateActions } from '../actions/creators';

function* profileUpdateSaga() {
  yield takeLatest(profileUpdateActions.getProfileUpdateStep, handleGetProfileUpdateStep);
}

function* handleGetProfileUpdateStep(action: ReturnType<typeof profileUpdateActions.getProfileUpdateStep>) {
  const { request, success, failure } = profileUpdateActions.getProfileUpdateStepRequest;
  yield put(request());
  try {
    const payload = yield call(profileUpdateApi.getProfileUpdateStep, action.payload);
    yield put(success(payload));
  } catch (e) {
    yield put(failure(e.response));
  }
}

export default profileUpdateSaga;
