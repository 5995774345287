import { FieldProps, getIn } from 'formik';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { ValueType } from 'react-select';
import { makeGetCountriesSelector } from '../../modules/app/redux/selectors/globalInfo';
import { Country } from '../../types/countries';
import { Select } from '../Form';
import { ErrorText } from '../Form/ErrorText';
import { Label } from '../Form/Label';
import { SelectWrapper } from 'components/Form/FormikSelect';

type Props = {
  errorAsBlock?: boolean;
  label?: string;
  error: string;
} & FieldProps;

const CountrySelect: React.FunctionComponent<Props> = ({ field, form, label, error, errorAsBlock }) => {
  const countries = useSelector(makeGetCountriesSelector());

  React.useEffect(() => {
    form.setFieldValue(field.name, countries.find((item) => item.code === 'BE')?.countryId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries]);

  const getCountry = (val: string) => countries.filter((country) => country.countryId === val);
  const getValue = form && getIn(form.values, field.name);

  return (
    <SelectWrapper>
      {label && <Label htmlFor={field.name}>{label}</Label>}
      <Select
        isClearable
        className="country-select"
        menuPosition={'fixed'}
        getOptionValue={(option) => option.countryId}
        getOptionLabel={(option) => option.name}
        options={countries}
        field={field}
        form={form}
        error={error}
        value={getCountry(getValue)}
        onChange={(value: ValueType<Country>) => form.setFieldValue(field.name, value && (value as Country).countryId)}
      />
      {error && <ErrorText block={errorAsBlock}>{error}</ErrorText>}
    </SelectWrapper>
  );
};

export default CountrySelect;
