import { getType } from 'typesafe-actions';
import { loginActions, LoginActionsType } from '../actions/creators';

export type LoginState = {
  isSameOrigin: boolean;
};

const loginReducer = (state: LoginState = { isSameOrigin: false }, action: LoginActionsType) => {
  switch (action.type) {
    case getType(loginActions.loginRedirectRequest.request):
    case getType(loginActions.loginRedirectRequest.failure):
      return {
        ...state,
      };

    case getType(loginActions.loginRedirectRequest.success):
      return {
        isSameOrigin: action.payload.isSameOrigin,
      };

    default:
      return state;
  }
};

export default loginReducer;
