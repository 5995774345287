import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const MakeAdmin: React.FC = () => (
  <BaseSvgWrapper>
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.55 22.3C24.2705 22.3 27.3 19.4593 27.3 15.9707V13.3293C27.3 9.84072 24.2705 7 20.55 7C16.8295 7 13.8 9.84072 13.8 13.3293V16.0205C13.8531 19.4593 16.8827 22.3 20.55 22.3Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.1886 26.9052C27.6653 26.8359 27.129 26.8 26.5841 26.8C26.0129 26.8 25.513 27.0205 25.1559 27.3511L21.514 31.4286C21.0142 31.9245 20.0858 31.9245 19.586 31.4286L15.9441 27.3511C15.6584 27.0205 15.0871 26.8 14.5159 26.8C10.0884 26.8 6.1609 29.1694 5.23257 32.5307L3.09028 39.8041C2.59041 41.4572 4.23284 43 6.37513 43H34.7249C36.8672 43 38.5096 41.4572 38.0097 39.8041L35.8674 32.5307C35.8636 32.5177 35.8597 32.5048 35.8557 32.4918C35.0748 32.9778 34.0386 32.8722 33.3724 32.1926L28.1886 26.9052Z"
      />
      <path d="M44.9852 15.1113C44.9682 14.6867 44.9682 14.2784 44.9682 13.8865C44.9682 13.5762 44.713 13.3312 44.3898 13.3312C41.8893 13.3149 39.9842 12.629 38.4022 11.1592C38.1811 10.9469 37.8069 10.9469 37.5858 11.1592C36.0038 12.629 34.0987 13.3149 31.6152 13.3149C31.292 13.3149 31.0368 13.5598 31.0368 13.8701C31.0368 14.2621 31.0368 14.6703 31.0198 15.1113C30.9348 19.1449 30.8157 24.6484 37.8069 26.9673C37.8749 26.9837 37.926 27 37.994 27C38.062 27 38.1301 26.9837 38.1811 26.9673C45.1723 24.6484 45.0532 19.1449 44.9852 15.1113ZM37.994 23.8405C34 21 34.1935 21 34.1765 17.1439C34.1765 16.899 34.1935 16.6703 34.1935 16.4417C36.5239 16.3274 36.395 15.6578 37.994 14.3187C39.5929 15.6578 39.4641 16.3437 41.7945 16.4254C41.7945 16.654 41.7945 16.8826 41.8115 17.1276C41.7945 21.1008 42 21 37.994 23.8405Z" />
    </svg>
  </BaseSvgWrapper>
);

export default MakeAdmin;
