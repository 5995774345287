import * as React from 'react';
import { CSSTransition } from 'react-transition-group';
import styled, { css } from '../../config/theme';
import { AnimationProps } from '../../config/theme/types';

type Props = {
  block?: boolean;
  forTextArea?: boolean;
};

const ErrorText = styled.span<Props & AnimationProps>`
  margin-top: 4px;
  display: flex;
  justify-content: flex-end;
  flex-basis: 100%;
  ${props =>
    props.block
      ? css`
          text-align: right;
        `
      : css`
          position: absolute;
          right: 0px;
          top: 72px;
          line-height: 15px;
        `}
  ${props =>
    props.forTextArea &&
    css`
      bottom: 8px;
      position: absolute;
      right: 0px;
      top: unset;
    `}

  font-size: 85%;
  color: ${props => props.theme.form.color.input.error};
  text-align: right;

  ${props => props.state === 'entering' && props.theme.form.animation.entering};
  ${props => props.state === 'entered' && props.theme.form.animation.entered};
`;

const TransitionedError: React.FunctionComponent<Props> = (props: Props) => (
  <CSSTransition in timeout={100} mountOnEnter appear>
    {state => <ErrorText className="error" state={state} {...props} />}
  </CSSTransition>
);

export { TransitionedError as ErrorText };
