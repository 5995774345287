import { Input } from 'components';
import { ErrorText } from 'components/Form/ErrorText';
import { TYPE_RADIO } from 'components/Form/Input';
import { Label } from 'components/Form/Label';
import { FieldProps } from 'formik';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { publicIpVersions } from '../constants/generalInfo/publicIpVersions';
import { createInitialFormikValuesFromFields } from '../lib';

type Props = {
  error?: string;
} & FieldProps;

const MispExistingOrg: React.FunctionComponent<Props> = ({ field, error, form }: Props) => {
  const { t } = useTranslation('onboarding');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    form.setFieldValue(field.name, e.target.value);
    form.setFieldValue(
      'publicIpRanges',
      createInitialFormikValuesFromFields(publicIpVersions.fields, {}).publicIpRanges
    );
  };

  return (
    <RadioButtonsWrapper>
      <Label>{t('misp.formLabels.mispExistingOrg')}</Label>
      <Input
        {...field}
        type={TYPE_RADIO}
        checked={field.value?.toLowerCase() === 'false'}
        value="false"
        label={t('misp.mispExistingOrgValues.false')}
        name="accountNeeded"
        id="mispExistingOrg_false"
        onChange={handleChange}
      />
      <Input
        {...field}
        type={TYPE_RADIO}
        checked={field.value?.toLowerCase() === 'true'}
        value="true"
        label={t('misp.mispExistingOrgValues.true')}
        name="accountNeeded"
        id="mispExistingOrg_true"
        onChange={handleChange}
      />
      {error && <ErrorText block>{error}</ErrorText>}
    </RadioButtonsWrapper>
  );
};

const RadioButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  label {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 5px;
    }
  }
`;

export default MispExistingOrg;
