import styled from 'config/theme';
import { animations } from 'config/theme/keyframes';
import { media } from 'config/theme/utils';
import { ModalSizes } from '../Modal';
import { ModalStyleProps } from '../ModalContainer';

export const ModalWrapper = styled('section')<ModalStyleProps>`
  animation: ${animations.flyIn} 0.3s ease-out;
  background-clip: padding-box;
  background-color: ${props => props.theme.modal.background};
  border-radius: ${props => props.theme.modal.borderRadius};
  box-shadow: ${props => props.theme.modal.boxShadow};
  color: ${props => props.theme.modal.color};
  max-height: 90vh;
  max-width: 90vw;
  outline: 0;
  position: relative;
  width: ${props => props.theme.modal.defaultWidth};

  ${props => props.size === ModalSizes.Small && 'width: 300px;'};
  ${props => props.size === ModalSizes.Medium && 'width: 700px;'};
  ${props => props.size === ModalSizes.Large && 'width: 900px;'};
  ${props => props.size === ModalSizes.FullScreen && 'width: 900px;'};

  ${props => media.mobile`
    max-height: ${props.size === ModalSizes.FullScreen ? '100vh' : '90vh'};
  max-width: ${props.size === ModalSizes.FullScreen ? '100vw' : '90vw'};
  height: ${props.size === ModalSizes.FullScreen && '100vh'};
  `}
`;

export const ModalHeader = styled.header`
  align-items: center;
  border-radius: ${props => props.theme.modal.borderRadius} ${props => props.theme.modal.borderRadius} 0 0;
  display: flex;
  flex-direction: column;
  flex-flow: wrap;
  padding: ${props => props.theme.modal.padding};
  padding-bottom: 15px;

  ${() => media.mobile`
      min-height: unset;
  `};

  h1 {
    font-size: ${props => props.theme.modal.header.fontSize};
    font-weight: ${props => props.theme.modal.header.fontWeight};
    width: 100%;
  }

  h5 {
    margin-top: 5px;
    color: ${props => props.theme.palette.neutral.shade5};
    font-size: ${props => props.theme.modal.header.fontSizeSubHeader};
    max-width: 700px;
  }

  > div {
    width: 100%;
  }
`;

export const ModalBody = styled.section`
  max-height: 80vh;
  overflow-y: auto;
  padding: ${props => props.theme.modal.padding};
  padding-top: 0px;

  &.visibleOverflow {
    overflow-y: visible;

    ${() => media.mobile`
      overflow-y: auto;
    `};
  }

  p {
    color: ${props => props.theme.palette.neutral.shade5};
    font-size: 14px;
    font-weight: 200;
    margin: 0 0 20px;
  }
`;

export const ModalFooter = styled.footer`
  background-color: ${props => props.theme.modal.footer.background};
  border-radius: 0 0 ${props => props.theme.modal.borderRadius} ${props => props.theme.modal.borderRadius};
  display: flex;
  justify-content: flex-end;
  padding: ${props => props.theme.modal.padding};

  > * {
    margin-left: 10px;
    align-self: center;
  }
`;
