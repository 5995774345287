import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

type Props = {
  active: boolean;
};

const Visibility = (props: Props): JSX.Element =>
  props.active ? (
    <BaseSvgWrapper>
      <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.05804 16.3539L15.3621 22.6579C15.1193 23.5348 14.9888 24.4604 14.9888 25.4174C15 31.0536 19.4866 35.6183 25 35.6183C26.0046 35.6183 26.9751 35.4678 27.8902 35.1861L32.266 39.5618C30.0662 40.3023 27.6728 40.7188 25 40.7188C14.2176 40.7188 6.19072 32.1806 0.566177 26.1978C0.374672 25.9941 0.185951 25.7933 0 25.596C2.53961 22.5031 5.55097 19.1449 9.05804 16.3539ZM43.6614 46.6123L43.9062 46.8571L46.4286 44.3348L38.471 36.3772C42.307 33.7078 45.6976 30.0586 49.3232 26.1566C49.5478 25.9149 49.7734 25.6721 50 25.4286C41.2612 15.9643 34.1406 10.1384 25 10.1384C21.2612 10.1384 17.8571 11.1094 14.7545 12.6607L6.09375 4L3.59196 6.50179L43.6819 46.5917L43.6614 46.6123ZM23.9789 31.2748C21.6158 30.8475 19.7359 28.9642 19.2727 26.5686L23.9789 31.2748ZM25 15.2388C30.5134 15.2388 35 19.8036 35 25.4286C35 27.5491 34.3527 29.5246 33.2589 31.1652L30.2121 28.1071C30.6138 27.3036 30.8371 26.3884 30.8371 25.4286C30.8371 22.1473 28.2143 19.4799 25 19.4799C24.7098 19.4799 24.4308 19.5022 24.1518 19.5469C24.6763 20.1719 25 20.9754 25 21.8571C25 22.1808 24.9554 22.4933 24.8772 22.7835L19.2188 17.125C20.8482 15.9308 22.846 15.2388 25 15.2388Z"
        />
      </svg>
    </BaseSvgWrapper>
  ) : (
    <BaseSvgWrapper>
      <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M25 11C34.1406 11 41.2612 16.4464 50 25.2857C42.4777 32.8415 36.1607 39.5714 25 39.5714C13.8504 39.5714 5.64732 31.0446 2.49061e-07 25.442C5.78125 18.8795 13.9732 11 25 11ZM25 35.4754C30.5134 35.4754 35 30.8996 35 25.2857C35 19.6607 30.5134 15.096 25 15.096C19.4866 15.096 15 19.6719 15 25.2857C15 30.9107 19.4866 35.4754 25 35.4754Z" />
        <path d="M25 21.7143C25 20.8326 24.6763 20.029 24.1518 19.404C24.4308 19.3594 24.7098 19.337 25 19.337C28.2143 19.337 30.837 22.0045 30.837 25.2857C30.837 28.567 28.2143 31.2344 25 31.2344C21.7857 31.2344 19.1629 28.567 19.1629 25.2857C19.1629 25.029 19.1853 24.7723 19.2076 24.5156C19.8214 24.9955 20.5804 25.2857 21.4174 25.2857C23.404 25.2857 25 23.6897 25 21.7143Z" />
      </svg>
    </BaseSvgWrapper>
  );

export default Visibility;
