import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const List = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.915 7.76013H3.88329C3.38969 7.76013 3 8.06987 3 8.4622V14.0581C3 14.4435 3.38969 14.7601 3.88329 14.7601H10.9236C11.4086 14.7601 11.8069 14.4504 11.8069 14.0581V8.4622C11.7983 8.06987 11.4086 7.76013 10.915 7.76013Z" />
      <path d="M46.1081 7.76013H17.0807C16.5958 7.76013 16.1974 8.06987 16.1974 8.4622V14.0581C16.1974 14.4435 16.5871 14.7601 17.0807 14.7601H46.1167C46.6017 14.7601 47 14.4504 47 14.0581V8.4622C46.9913 8.06987 46.6017 7.76013 46.1081 7.76013Z" />
      <path d="M10.915 21.5H3.88329C3.39835 21.5 3 21.8097 3 22.2021V27.7979C3 28.1834 3.38969 28.5 3.88329 28.5H10.9236C11.4086 28.5 11.8069 28.1903 11.8069 27.7979V22.2021C11.7983 21.8097 11.4086 21.5 10.915 21.5Z" />
      <path d="M46.1081 21.5H17.0807C16.5958 21.5 16.1974 21.8097 16.1974 22.2021V27.7979C16.1974 28.1834 16.5871 28.5 17.0807 28.5H46.1167C46.6017 28.5 47 28.1903 47 27.7979V22.2021C46.9913 21.8097 46.6017 21.5 46.1081 21.5Z" />
      <path d="M10.915 35.2399H3.88329C3.38969 35.2399 3 35.5496 3 35.9419V41.5378C3 41.9233 3.38969 42.2399 3.88329 42.2399H10.9236C11.4086 42.2399 11.8069 41.9301 11.8069 41.5378V35.9419C11.7983 35.5496 11.4086 35.2399 10.915 35.2399Z" />
      <path d="M46.1081 35.2399H17.0807C16.5958 35.2399 16.1974 35.5496 16.1974 35.9419V41.5378C16.1974 41.9233 16.5871 42.2399 17.0807 42.2399H46.1167C46.6017 42.2399 47 41.9301 47 41.5378V35.9419C46.9913 35.5496 46.6017 35.2399 46.1081 35.2399Z" />
    </svg>
  </BaseSvgWrapper>
);

export default List;
