import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button } from '../Button';
import FileUploadIcon from './FileUploadIcon';

const FileUploadContent = () => {
  const { t } = useTranslation();

  return (
    <FileUploadContentWrapper>
      <FileUploadIcon />
      <div>
        <p>{t('fileUpload.description')}</p>
        <Button primary>{t('fileUpload.buttonTitle')}</Button>
      </div>
    </FileUploadContentWrapper>
  );
};

const FileUploadContentWrapper = styled.div`
  display: flex;

  svg {
    width: 100px;
    height: 100px;
    margin-right: 32px;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > p {
      text-align: center;
    }

    button {
      margin-top: 16px;
    }
  }
`;

export default FileUploadContent;
