import { FlexRow, Header, IconButton, Modal, Paginator } from 'components';
import { Add, Delete, Download, Edit, MarkEmailUnread, Question } from 'components/icons';
import { useActionIsPending } from 'hooks';
import { pageInfoActions, repositoryActions } from 'modules/repository/redux/creators';
import { deleteRepository, downloadRepository } from 'modules/repository/redux/creators/repository';
import { makeGetRepositoryPageInfo } from 'modules/repository/redux/selectors/repository';
import { Repository } from 'modules/repository/types/repository';
import { ModuleEditAccessContext } from 'modules/security';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getType } from 'typesafe-actions';
import DeleteConfirmation from '../../../../components/ConfirmationPopup/DeleteConfirmation';
import { ORIENTATION_BOTTOM_RIGHT } from '../../../../components/ContextMenu/constants';
import { ModalSizes } from '../../../../components/Modal/Modal';
import useRepositoryGeneralStore from '../../redux/services/userRepositoryGeneralStore';
import AddRepositoryModal from '../add/AddRepositoryModal';
import EditRepositoryModal from '../edit/EditRepositoryModal';
import QuestionModal from '../content/QuestionModal';

type Props = {
  selectedRepositories?: Repository[];
  deselectRepositories: () => void;
};

const RepositoryHeader: React.FunctionComponent<Props> = ({ selectedRepositories, deselectRepositories }: Props) => {
  const { t } = useTranslation('repository');
  const dispatch = useDispatch();
  const paginationInfo = useSelector(makeGetRepositoryPageInfo());
  const [isDownloading, startDownloading] = useActionIsPending(getType(downloadRepository));
  const [isDeleting, startDeleting] = useActionIsPending(getType(deleteRepository));
  const moduleAccess = React.useContext(ModuleEditAccessContext);
  const { markRepositoryReadUnread } = useRepositoryGeneralStore();
  const isSingleOrMultiValue = Boolean(selectedRepositories && selectedRepositories.length >= 1);
  const isSingleValue = Boolean(selectedRepositories && selectedRepositories.length === 1);

  const handleDelete = React.useCallback(() => {
    if (selectedRepositories) {
      selectedRepositories.forEach((repository) => {
        startDeleting();
        dispatch(repositoryActions.deleteRepository({ id: repository.reportId }));
      });
    }

    deselectRepositories();
  }, [deselectRepositories, dispatch, selectedRepositories, startDeleting]);

  const dispatchDownload = React.useCallback(
    (repository: Repository) => {
      startDownloading();
      dispatch(
        repositoryActions.downloadRepository({ guid: repository.guid, fileName: repository.fileName }, 'download')
      );
    },
    [dispatch, startDownloading]
  );

  const handleDownload = React.useCallback(() => {
    selectedRepositories &&
      selectedRepositories.forEach((repository) => {
        dispatchDownload(repository);
      });
  }, [selectedRepositories, dispatchDownload]);

  const handleMarkReadUnread = React.useCallback(() => {
    selectedRepositories?.forEach((repository) =>
      markRepositoryReadUnread({
        id: repository.reportId,
        read: selectedRepositories.some((repository) => !repository.read),
      })
    );
    deselectRepositories();
  }, [selectedRepositories, markRepositoryReadUnread, deselectRepositories]);

  return (
    <Header animated>
      <FlexRow>
        {moduleAccess.canEdit && (
          <Modal size={ModalSizes.Medium} content={AddRepositoryModal}>
            {(toggleModal) => (
              <IconButton tooltip={t('actions.add')} onClick={toggleModal} tooltipLeftAligned>
                <Add />
              </IconButton>
            )}
          </Modal>
        )}
        <IconButton
          disabled={!isSingleOrMultiValue || isDownloading || (selectedRepositories && selectedRepositories.length > 5)}
          isLoading={isDownloading}
          onClick={handleDownload}
          tooltip={t('actions.download')}
          tooltipLeftAligned
        >
          <Download />
        </IconButton>
        <IconButton
          disabled={!isSingleOrMultiValue}
          isLoading={isDownloading}
          onClick={handleMarkReadUnread}
          tooltipLeftAligned
          tooltip={t(
            !isSingleValue
              ? 'actions.markAsReadUnread'
              : selectedRepositories?.[0].read
              ? 'actions.markUnread'
              : 'actions.markRead'
          )}
        >
          <MarkEmailUnread />
        </IconButton>
        {moduleAccess.canEdit && (
          <React.Fragment>
            <Modal size={ModalSizes.Medium} contentProps={selectedRepositories?.[0]} content={EditRepositoryModal}>
              {(toggleModal) => (
                <IconButton
                  tooltip={t('actions.edit')}
                  onClick={toggleModal}
                  disabled={!isSingleValue}
                  tooltipLeftAligned
                >
                  <Edit />
                </IconButton>
              )}
            </Modal>
            <DeleteConfirmation orientation={ORIENTATION_BOTTOM_RIGHT} callback={handleDelete}>
              <IconButton
                isLoading={isDeleting}
                tooltip={t('actions.delete')}
                disabled={!isSingleOrMultiValue}
                tooltipLeftAligned
              >
                <Delete />
              </IconButton>
            </DeleteConfirmation>
          </React.Fragment>
        )}
        <Modal
          contentProps={{
            reportId: selectedRepositories?.[0]?.guid || '',
            reportTitle: selectedRepositories?.[0]?.description || '',
          }}
          content={QuestionModal}
        >
          {(toggleModal) => (
            <IconButton
              tooltip={t('repository:question.tooltip')}
              onClick={toggleModal}
              disabled={selectedRepositories?.length !== 1}
              tooltipLeftAligned
            >
              <Question />
            </IconButton>
          )}
        </Modal>
      </FlexRow>
      <Paginator
        pageInfo={paginationInfo}
        setPageIndexActionCreator={pageInfoActions.setPage}
        setPageSizeActionCreator={pageInfoActions.setPageSize}
      />
    </Header>
  );
};

export default RepositoryHeader;
