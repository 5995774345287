import { ContentLoader, SkeletonGenerator } from 'components';
import styled from 'config/theme';
import * as React from 'react';

const HeadingLoadingState: React.FunctionComponent = () => (
  <LoadingStateWrapper>
    <SkeletonGenerator count={1}>
      <ContentLoader height={70}>
        <rect x="0" y="8" rx="10" ry="5" width="200" height="13" />
        <rect x="0" y="40" rx="5" ry="5" width="90" height="10" />
      </ContentLoader>
    </SkeletonGenerator>
  </LoadingStateWrapper>
);

const LoadingStateWrapper = styled.section`
  background: ${props => props.theme.paper.mainInfo.background};
  color: ${props => props.theme.paper.mainInfo.color.main};
  max-width: 600px;
`;

export default HeadingLoadingState;
