import i18n from 'config/i18n';
import * as Yup from 'yup';
import { ContactType } from '../../../app/types/contact';
import BusinessLocationsSelect from '../../components/select/BusinessLocationsSelect';
import ContactTypesSelect from '../../components/select/ContactTypesSelect';
import { InputField } from '../../types';
import { CUSTOM } from '../index';

export type ProfileFieldsType = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
  PGP_key: string;
};

export const profileFields: InputField[] = [
  {
    label: 'profile.formLabels.firstName',
    name: 'firstName',
    type: 'text',
    width: {
      desktop: 4,
      tablet: 6,
    },
  },
  {
    label: 'profile.formLabels.lastName',
    name: 'lastName',
    type: 'text',
    width: {
      desktop: 4,
      tablet: 6,
    },
  },
  {
    label: 'profile.formLabels.personal_phoneNumber',
    name: 'phonePrivate',
    type: 'tel',
    width: {
      desktop: 4,
      tablet: 6,
    },
  },
  {
    label: 'profile.formLabels.email',
    name: 'email',
    type: 'email',
    width: {
      desktop: 4,
      tablet: 6,
    },
  },
  {
    component: BusinessLocationsSelect,
    initialValue: [],
    label: 'profile.formLabels.address',
    name: 'businessLocationIds',
    type: CUSTOM,
    width: {
      desktop: 4,
      tablet: 6,
    },
  },
  {
    component: ContactTypesSelect,
    initialValue: [],
    label: 'profile.formLabels.contactTypes',
    name: 'types',
    type: CUSTOM,
    width: {
      desktop: 4,
      tablet: 6,
    },
  },
];

export const profileValidationSchema = Yup.object().shape({
  businessLocationIds: Yup.array()
    .of(Yup.string())
    .min(1, ({ min }) => i18n.t('validation:array.min', { min })),
  email: Yup.string()
    .required(() => i18n.t('validation:required'))
    .email(() => i18n.t('validation:string.email'))
    .max(200)
    .trim(),
  firstName: Yup.string()
    .required(() => i18n.t('validation:required'))
    .max(100)
    .trim(),
  lastName: Yup.string()
    .required(() => i18n.t('validation:required'))
    .max(200)
    .trim(),
  phonePrivate: Yup.string()
    .max(50)
    .matches(/^((\+[1-9]{2,3}[.\s]?){1}(\d{1,3}){1}(\s?\d{2,3}){2,4})?$/, () =>
      i18n.t('validation:string.phoneNumber')
    ),
  types: Yup.array()
    .of(Yup.mixed().oneOf([...Object.values(ContactType)]))
    .min(1, ({ min }) => i18n.t('validation:array.min', { min })),
});

export const headerTranslations = {
  content: 'profile.content',
  title: 'profile.title',
};

export const profileStep = {
  fields: profileFields,
  headerTranslations,
  key: 'profile',
  validationSchema: profileValidationSchema,
};
