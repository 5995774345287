import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import Organization from '../components/detail/content';
import { OrganizationDetailView } from '../types/organizationDetail/organizationDetail';
import { ORGANIZATION_BASE_ROUTE } from './OrganizationRoutes';

export const USERS_URL = 'users';

const OrganizationDetailSubRoutes: React.FunctionComponent = () => {
  return (
    <Switch>
      <Route
        exact
        path={`${ORGANIZATION_BASE_ROUTE}/:id/`}
        render={({
          match: {
            params: { id },
          },
        }) => <Redirect to={`${ORGANIZATION_BASE_ROUTE}/${id}/general-info`} />}
      />
      <Route
        path={`${ORGANIZATION_BASE_ROUTE}/:id/general-info`}
        exact
        render={({
          match: {
            params: { id },
          },
        }) => <Organization id={id || ''} activeView={OrganizationDetailView.GENERAL_INFO} />}
      />
      <Route
        path={`${ORGANIZATION_BASE_ROUTE}/:id/contacts`}
        exact
        render={({
          match: {
            params: { id },
          },
        }) => <Organization id={id || ''} activeView={OrganizationDetailView.CONTACTS} />}
      />
      <Route
        path={`${ORGANIZATION_BASE_ROUTE}/:id/cti`}
        exact
        render={({
          match: {
            params: { id },
          },
        }) => <Organization id={id || ''} activeView={OrganizationDetailView.CTI} />}
      />
      <Route
        path={`${ORGANIZATION_BASE_ROUTE}/:id/${USERS_URL}`}
        exact
        render={({
          match: {
            params: { id },
          },
        }) => <Organization id={id || ''} activeView={OrganizationDetailView.USERS} />}
      />
    </Switch>
  );
};

export default OrganizationDetailSubRoutes;
