import { loadScene } from 'components/async/scene';
import { Module } from 'modules/security/types/modules';
import * as React from 'react';
import { Route } from 'react-router-dom';
import ModuleRoutes from '../../../components/routing/ModuleRoutes';

type Props = {
  allowedModules: Module[];
};

const sectorOverviewScene = loadScene('sector', 'SectorScene');

const SectorRoutes: React.FunctionComponent<Props> = ({ allowedModules }: Props) => {
  return (
    <ModuleRoutes allowedModules={allowedModules} moduleKey={SECTOR_MODULE_KEY}>
      <Route path={SECTOR_BASE_ROUTE} exact component={sectorOverviewScene} />
    </ModuleRoutes>
  );
};

export const SECTOR_MODULE_KEY = 'sectors';
export const SECTOR_BASE_ROUTE = '/sectors';
export default SectorRoutes;
