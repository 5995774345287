import { getHours, getMinutes } from 'date-fns';
import { daysInTheWeekList } from 'modules/app/constants';
import { Availability, DaysInTheWeek } from 'modules/app/types';
import { groupBy } from '../../../../helpers';
import { Group } from '../../../../helpers/groupBy';

export type FrontEndAvailability = {
  days: string[];
  hours: number[];
};

type BackEndTime = { time: number[] };

const formatHourValueToDateTime = (hour: number) => {
  const time = new Date();

  time.setMinutes(60 * (hour - Math.floor(hour)));
  time.setHours(Math.floor(hour) - time.getTimezoneOffset() / 60);

  return time.toISOString().slice(0, -1);
};

const formatDateTimeToHourValue = (backEndDateFrom: string, backEndDateUntil: string) => {
  const dateFrom = new Date(backEndDateFrom);
  const dateUntil = new Date(backEndDateUntil);

  const hourFrom = dateFrom.getHours();
  const minutesFrom = dateFrom.getMinutes();
  const hourUntil = dateUntil.getHours();
  const minutesUntil = dateUntil.getMinutes();

  return [hourFrom + minutesFrom / 60, hourUntil + minutesUntil / 60];
};

const mapAvailabilitiesToBackendValues = (frontEndAvailabilities: FrontEndAvailability[]): Availability[] =>
  frontEndAvailabilities.reduce((response: Availability[], availabilities: FrontEndAvailability) => {
    availabilities.days.forEach((daysAvailable) => {
      const findDayInTheWeek = daysInTheWeekList.find((dayInTheWeek) => dayInTheWeek.value === daysAvailable);

      const day: Availability = {
        day: findDayInTheWeek ? findDayInTheWeek.label : (daysInTheWeekList[0].label as DaysInTheWeek),
        timeFrom: formatHourValueToDateTime(availabilities.hours[0]),
        timeUntil: formatHourValueToDateTime(availabilities.hours[1]),
      };

      response.push(day);
    });

    return response;
  }, []);

const mapAvailabilitiesToFrontendValues = (backEndAvailabilities: Availability[]): FrontEndAvailability[] => {
  const calculateTime: Array<Availability & BackEndTime> = backEndAvailabilities.map((backEndAvailability) => ({
    ...backEndAvailability,
    time: formatDateTimeToHourValue(backEndAvailability.timeFrom, backEndAvailability.timeUntil),
  }));

  const groupedAvailabilitiesBySameTime = groupBy(calculateTime, 'time');

  return Object.keys(groupedAvailabilitiesBySameTime).reduce((response: FrontEndAvailability[], key: string) => {
    response.push({
      days: (groupedAvailabilitiesBySameTime as Group<Availability & BackEndTime>)[key].map(
        (sameTimeAvailability: Availability) => {
          const findDayInWeek = daysInTheWeekList.find(
            (dayInTheWeek) => dayInTheWeek.label === sameTimeAvailability.day
          );

          return findDayInWeek ? findDayInWeek.value : '0';
        }
      ),
      hours: key.split(',').map((hour: string): number => parseFloat(hour)) as number[],
    });

    return response;
  }, []);
};

const availabilityTimesMatch = (availability1: Availability, availability2: Availability) => {
  const av1Starthour = getHours(availability1.timeFrom);
  const av1Endhour = getHours(availability1.timeUntil);
  const av1StartMins = getMinutes(availability1.timeFrom);
  const av1EndMins = getMinutes(availability1.timeFrom);

  const av2Starthour = getHours(availability2.timeFrom);
  const av2Endhour = getHours(availability2.timeUntil);
  const av2StartMins = getMinutes(availability2.timeFrom);
  const av2EndMins = getMinutes(availability2.timeFrom);

  return (
    av1Starthour === av2Starthour &&
    av1StartMins === av2StartMins &&
    av1Endhour === av2Endhour &&
    av1EndMins === av2EndMins
  );
};

export { mapAvailabilitiesToBackendValues, mapAvailabilitiesToFrontendValues, availabilityTimesMatch };
