import { FlexCol } from 'components';
import styled from 'config/theme';
import { getTime } from 'helpers/dateFormatter';

import { Availability } from 'modules/app/types';
import * as React from 'react';

type Props = {
  availabilities: Availability[];
};

const Availabilities: React.FunctionComponent<Props> = ({ availabilities }: Props) => {
  return (
    <FlexCol full>
      {availabilities.map(availability => (
        <Wrapper key={availability.availabilityId}>
          <div>{availability.day}</div>
          <div>{`${getTime(availability.timeFrom.toString(), true)} - ${getTime(
            availability.timeUntil.toString(),
            true
          )}`}</div>
        </Wrapper>
      ))}
    </FlexCol>
  );
};

const Wrapper = styled.div`
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;

  > div:nth-child(2) {
    color: ${props => props.theme.fullView.color.secondary};
  }
`;

export default Availabilities;
