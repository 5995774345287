import { loadScene } from 'components/async/scene';
import { Module } from 'modules/security/types/modules';
import * as React from 'react';
import { Route } from 'react-router-dom';
import ModuleRoutes from '../../../components/routing/ModuleRoutes';
import { CRITICAL_ALERTS_BASE_ROUTE, CRITICAL_ALERTS_MODULE_KEY } from '../constants';

type Props = {
  allowedModules: Module[];
};

const flashAlertsOverviewScene = loadScene('flashAlerts', 'CriticalAlertsScene');

const CriticalAlertsRoutes: React.FC<Props> = ({ allowedModules }: Props) => (
  <ModuleRoutes allowedModules={allowedModules} moduleKey={CRITICAL_ALERTS_MODULE_KEY}>
    <Route path={[CRITICAL_ALERTS_BASE_ROUTE]} exact component={flashAlertsOverviewScene} />
  </ModuleRoutes>
);

export default CriticalAlertsRoutes;
