import { IconButton, Logo } from 'components';
import {
  Activity,
  Bell,
  Company,
  CriticalAlerts,
  Help,
  Mails,
  MakeAdmin,
  ReportType,
  Repository,
  InactiveUsers,
  RssFeed,
  ServiceFee,
} from 'components/icons';
import AdminPanelSettings from 'components/icons/AdminPanelSettings';
import { responsive } from 'helpers';
import createNavigationSubModulesHelper from 'modules/app/helpers/createNavigationSubModulesHelper';
import { Notifications } from 'modules/notifications';
import { ProfileMenu } from 'modules/security';
import { Module, Modules } from 'modules/security/types/modules';
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import { AsideWrapper, MobileAsideWrapper } from './AsideWrapper';
import { Footer } from './Footer';
import { LogoWrapper } from './LogoWrapper';
import Navigation from './Navigation';
import NavigationLink, { StyledNavigationLink } from './NavigationLink';
import SubNavigation from './SubNavigation';

type Props = {
  availableModules: Modules;
};

export const ImageFactory: { [key: string]: JSX.Element } = {
  Activities: <Activity />,
  HelpCenter: <Help />,
  Mails: <Mails />,
  Organizations: <Company />,
  ReportTypes: <ReportType />,
  Reports: <Repository />,
  Sectors: <ServiceFee />,
  FlashAlerts: <CriticalAlerts />,
  Feed: <RssFeed />,
  Users: <MakeAdmin />,
  Administration: <AdminPanelSettings />,
  InactiveUsers: <InactiveUsers />,
};

const Sidebar: React.FunctionComponent<Props> = ({ availableModules }: Props): JSX.Element => {
  const isTablet = useMediaQuery(responsive.isTablet);
  const isLaptopSM = useMediaQuery(responsive.isLaptopSM);

  const modules = isTablet ? availableModules : createNavigationSubModulesHelper(availableModules);

  const navigationComponent = (
    <Navigation>
      <React.Fragment>
        {modules
          .filter((module) => module.showInSideBar)
          .filter((module) => (isTablet ? !module.hideOnMobile : true))
          .map((module: Module) =>
            module.subModules ? (
              <SubNavigation isTablet={isTablet} isLaptopSM={isLaptopSM} key={module.key} module={module}>
                {ImageFactory[module.key]}
              </SubNavigation>
            ) : (
              <NavigationLink key={module.key} to={module.key}>
                {ImageFactory[module.key]}
              </NavigationLink>
            )
          )}
        {isTablet && (
          <StyledNavigationLink>
            <ProfileMenu isMobile />
          </StyledNavigationLink>
        )}
      </React.Fragment>
    </Navigation>
  );

  return isTablet ? (
    <MobileAsideWrapper>{navigationComponent}</MobileAsideWrapper>
  ) : (
    <AsideWrapper>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      {navigationComponent}
      <Footer isLaptopSM={isLaptopSM}>
        <ProfileMenu />
        <Notifications>
          {({ toggleDrawer, Count }) => (
            <IconButton onClick={toggleDrawer} style={{ position: 'relative' }}>
              <Count />
              <Bell />
            </IconButton>
          )}
        </Notifications>
      </Footer>
    </AsideWrapper>
  );
};

export default Sidebar;
