import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ContextMenu, ContextMenuContainer } from '../';
import { ContextMenuOrientation, ORIENTATION_TOP_LEFT } from '../ContextMenu/constants';
import { ContextMenuRenderProps } from '../ContextMenu/ContextMenu';
import BasicConfirmation from './BasicConfirmation';

type Props = {
  callback: (args: any) => void;
  children: JSX.Element;
  orientation?: ContextMenuOrientation;
  confirmText: string;
  description: string;
};

const CustomConfirmation: React.FunctionComponent<Props> = ({
  children,
  callback,
  orientation,
  confirmText,
  description,
}: Props) => {
  const { t } = useTranslation('global');

  return (
    <ContextMenu
      enhanceWithOnContext={false}
      orientation={orientation || ORIENTATION_TOP_LEFT}
      content={
        <ContextMenuContainer>
          <BasicConfirmation
            callback={callback}
            cancelText={t('general.cancel')}
            confirmText={confirmText}
            primary
            description={description}
          />
        </ContextMenuContainer>
      }
    >
      {({ toggleMenu }: ContextMenuRenderProps) => React.cloneElement(children, { onClick: toggleMenu })}
    </ContextMenu>
  );
};

export default CustomConfirmation;
