import { lookup } from 'mime-types';
import { Attachment } from '../modules/mail/types/mailDetail';

export const getDataUrlForAttachment = (attachment: Attachment) => {
  const mime = lookup(attachment.fileName);

  return `data:${mime};base64,${attachment.content}`;
};

export const getBase64ForFile = (file: File, onlyContent = false): Promise<string> =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (reader.result) {
        const base64 = reader.result.toString();
        onlyContent ? resolve(stripPrefix(base64)) : resolve(base64);
      } else {
        reject('no data found');
      }
    };
    reader.onerror = error => reject(error);
  });

export const stripPrefix = (base64: string) => {
  const regex = /[^,]*,(.*)/;
  const result = regex.exec(base64);

  // return the first group => whatever is behind the ',' if there is one found
  return result ? result[1] : base64;
};

export default { getDataUrlForAttachment };
