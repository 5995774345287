import { GeneralInfo } from 'modules/organization/types/organizationDetail/general';
import { getType } from 'typesafe-actions';
import { OrganizationStatus } from '../../../types/organization';
import { OrganizationDetailActions, organizationDetailActions } from '../../actions/creators';

const generalInfoReducer = (state: GeneralInfo | null = null, action: OrganizationDetailActions) => {
  switch (action.type) {
    case getType(organizationDetailActions.getGeneralInfoRequest.success):
    case getType(organizationDetailActions.updateOrganizationRequest.success):
    case getType(organizationDetailActions.approveRequest.success):
    case getType(organizationDetailActions.rejectRequest.success):
    case getType(organizationDetailActions.updateGeneralInfoRequest.success):
      return action.payload;
    case getType(organizationDetailActions.clearOrganizationDetail):
      return null;
    case getType(organizationDetailActions.deleteOrganizationRequest.success):
      return state && { ...state, status: OrganizationStatus.Suspended };
    case getType(organizationDetailActions.getDifferenceRequest.success):
      return state && { ...state, difference: action.payload };
    case getType(organizationDetailActions.addAdminRequest.success):
      return state && { ...state, status: OrganizationStatus.Pending };

    default:
      return state;
  }
};

export default generalInfoReducer;
