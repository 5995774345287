import { getType } from 'typesafe-actions';
import { FaqsDetail } from '../../types/helpCenter';
import { HelpCenterActions, helpCenterActions } from '../creators';

const faqsReducer = (state: FaqsDetail | null = null, action: HelpCenterActions) => {
  switch (action.type) {
    case getType(helpCenterActions.getFaqCategoryDetailRequest.success):
      return action.payload;

    default:
      return state;
  }
};

export default faqsReducer;
