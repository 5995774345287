import { call, put, takeEvery } from '@redux-saga/core/effects';
import pgpKeyApi from '../../api/pgpKeyApi';
import { PgpKeyAction, pgpKeyActions } from '../creators';

function* pgpKeySaga() {
  yield takeEvery(pgpKeyActions.downloadPgpKey, handlePgpKeyDownload);
}

function* handlePgpKeyDownload(action: PgpKeyAction) {
  const { success, failure, request } = pgpKeyActions.downloadPgpKeyRequest;
  yield put(request(action.payload));

  try {
    const payload = yield call(pgpKeyApi.downloadPgpKey, action.payload);
    yield put(success(payload));
  } catch (e) {
    yield put(failure(e));
  }
}

export default pgpKeySaga;
