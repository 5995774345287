import { FullViewBlock } from 'components/FullView';
import { FieldArray, FormikErrors, FormikTouched } from 'formik';
import { IpRangeEditValues } from 'modules/app/types';
import EditIpRange from 'modules/organization/components/shared/EditIpRange';
import IpRangePlaceholder from 'modules/organization/components/shared/IpRangePlaceholder';
import { GeneralInfoEditValues } from 'modules/organization/types/organizationDetail/general';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  whiteIpRanges: IpRangeEditValues[];
  publicIpRanges: IpRangeEditValues[];
  errors: FormikErrors<GeneralInfoEditValues>;
  touched: FormikTouched<GeneralInfoEditValues>;
  showWhiteIpRanges: boolean;
};

const IpRanges: React.FunctionComponent<Props> = ({
  whiteIpRanges,
  publicIpRanges,
  errors,
  touched,
  showWhiteIpRanges,
}: Props) => {
  const { t } = useTranslation('organization');

  return (
    <React.Fragment>
      {showWhiteIpRanges && (
        <FieldArray
          name="whiteIpRanges"
          render={(arrayHelpers) => (
            <FullViewBlock amountOfButtons={2}>
              <h2 className="block-title">{t('detail.blocks.generalIpRanges')}</h2>
              <div className="block-data">
                {whiteIpRanges && whiteIpRanges.length > 0 ? (
                  whiteIpRanges.map((ipRange: IpRangeEditValues, index: number) => (
                    <EditIpRange
                      key={index}
                      ipRange={ipRange}
                      index={index}
                      errors={errors}
                      touched={touched}
                      arrayHelpers={arrayHelpers}
                      name="whiteIpRanges"
                    />
                  ))
                ) : (
                  <IpRangePlaceholder
                    arrayHelpers={arrayHelpers}
                    errors={errors}
                    touched={touched}
                    name="whiteIpRanges"
                  />
                )}
              </div>
            </FullViewBlock>
          )}
        />
      )}
      <FieldArray
        name="publicIpRanges"
        render={(arrayHelpers) => (
          <FullViewBlock amountOfButtons={2}>
            <h2 className="block-title">{t('detail.blocks.publicIpRanges')}</h2>
            <div className="block-data">
              {publicIpRanges && publicIpRanges.length > 0 ? (
                publicIpRanges.map((ipRange: IpRangeEditValues, index: number) => (
                  <EditIpRange
                    key={index}
                    ipRange={ipRange}
                    index={index}
                    errors={errors}
                    touched={touched}
                    arrayHelpers={arrayHelpers}
                    name="publicIpRanges"
                  />
                ))
              ) : (
                <IpRangePlaceholder
                  arrayHelpers={arrayHelpers}
                  errors={errors}
                  touched={touched}
                  name="publicIpRanges"
                />
              )}
            </div>
          </FullViewBlock>
        )}
      />
    </React.Fragment>
  );
};

export default IpRanges;
