import styled from '../../../../config/theme';

export const InfoWrapper = styled.div`
  display: inline-block;
  font-size: ${props => props.theme.typography.fontSize.regular};
  color: ${props => props.theme.palette.neutral.shade6};
  flex: auto;
  width: inherit;

  h4 {
    font-weight: 600;
    font-size: ${props => props.theme.typography.fontSize.medium_6};
    color: ${props => props.theme.palette.neutral.shade9};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  > h5 {
    color: ${props => props.theme.palette.neutral.shade4};
    font-size: ${props => props.theme.typography.fontSize.small_5};
    min-height: 30px;
  }

  > span {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
  }
`;
