import { TenantTheme } from '../tenants/';

const formTheme = (theme: TenantTheme) => ({
  checkbox: {
    border: `2px solid ${theme.palette.neutral.shade2}`,
    borderRadius: '3px',
    checkedColor: theme.palette.primary.shade4,
  },
  inlineLabel: {
    color: {
      close: theme.palette.neutral.shade5,
      closeActive: theme.palette.primary.shade4,
      labelText: theme.palette.neutral.shade4,
    },
    fontWeight: {
      labelText: '400',
    },
  },
  animation: {
    entered: 'opacity: 1; transition: all 100ms 0ms ease-in; transform: none',
    entering: 'opacity: 0.01; transform: translateY(-3px)',
  },
  background: {
    main: theme.palette.neutral.shade0,
    disabled: theme.palette.neutral.shade1,
  },
  border: {
    input: {
      main: `1px solid ${theme.palette.neutral.shade3}`,
      focus: `1px solid ${theme.palette.primary.shade3}`,
      error: `1px solid ${theme.palette.danger.shade3}`,
    },
  },
  borderRadius: {
    input: '5px',
  },
  boxShadow: {
    main: `inset 0px 2px 2px ${theme.palette.neutral.shade3}`,
    focus: `0px 1px 7px -1px ${theme.palette.primary.shade3}, inset 0px 2px 2px ${theme.palette.neutral.shade3}`,
    focusError: `0px 1px 7px -1px ${theme.palette.danger.shade3}, inset 0px 2px 2px ${theme.palette.neutral.shade3}`,
  },
  color: {
    input: {
      main: theme.palette.neutral.shade8,
      placeholder: theme.palette.neutral.shade4,
      error: theme.palette.danger.shade4,
      labelInline: theme.palette.neutral.shade5,
    },
  },
  height: {
    input: '40px',
  },
  margin: {
    main: '8px 0 25px',
  },
  padding: {
    main: '10px 15px',
  },
  transition: theme.globals.transition,

  select: {
    control: {
      regularStyles: {
        background: theme.palette.neutral.shade0,
        borderColor: 'none',
        borderRadius: '5px',
        color: theme.palette.neutral.shade8,
        minHeight: '40px',
        transition: theme.globals.transition,
      },
      stateStyles: {
        border: `1px solid ${theme.palette.neutral.shade3}`,
        borderError: `1px solid ${theme.palette.danger.shade3}`,
        borderFocus: `1px solid ${theme.palette.primary.shade3}`,
        boxShadow: `inset 0px 2px 2px ${theme.palette.neutral.shade3}`,
        boxShadowFocus: `0px 1px 7px -1px ${theme.palette.primary.shade3}, inset 0px 2px 2px ${theme.palette.neutral.shade3}`,
        boxShadowFocusError: `0px 1px 7px -1px ${theme.palette.danger.shade3}, inset 0px 2px 2px ${theme.palette.neutral.shade3}`,
      },
    },
    option: {
      regularStyles: {
        cursor: 'pointer',
        padding: '12px 12px',
      },
      stateStyles: {
        background: theme.palette.neutral.shade0,
        backgroundActive: theme.palette.primary.shade1,
        backgroundHover: theme.palette.neutral.shade1,
        color: theme.palette.neutral.shade9,
        colorActive: theme.palette.primary.shade7,
        colorDisabled: theme.palette.neutral.shade4,
      },
    },
  },
  left: {
    label: {
      absolute: '15px',
    },
  },
  top: {
    label: {
      absolute: '17px',
    },
  },
});

export default formTheme;
