import styled from 'styled-components';

export const NotificationLayout = styled.div`
  padding: 16px 8px;
  font-size: ${(props) => props.theme.notifications.notification.fontSize.main};

  > span {
    color: ${(props) => props.theme.notifications.notification.color.content};
    display: block;

    &.time__badge {
      align-items: flex-end;
      color: ${(props) => props.theme.notifications.notification.color.time};
      display: flex;
      font-size: ${(props) => props.theme.notifications.notification.fontSize.time};
      justify-content: flex-end;
      margin-top: 8px;
    }
  }
`;

export const NotificationWrapper = styled.div`
  overflow-y: auto;
  padding-top: 10px;
`;
