import * as React from 'react';
import ReactDOM from 'react-dom';
import { ClickOutside } from '../';
import { ContextMenuOrientation, ORIENTATION_BOTTOM_RIGHT } from './constants';
import ContextMenuWrapper from './ContextMenuWrapper';
import { calculateCoordinates, calculateMaxHeight, Coordinates, setClickedPosition } from './coordinateCalculator';

type Props = {
  children: (props: ContextMenuRenderProps) => JSX.Element;
  content?: JSX.Element;
  orientation?: ContextMenuOrientation;
  enhanceWithOnContext?: boolean;
};

export type ContextMenuRenderProps = {
  toggleMenu: (e: React.MouseEvent) => void;
};

const ContextMenu: React.FunctionComponent<Props> = ({
  children,
  content,
  orientation = ORIENTATION_BOTTOM_RIGHT,
  enhanceWithOnContext = true,
}: Props) => {
  const [isOpen, setOpen] = React.useState(false);
  const [containerVisible, setContainerVisible] = React.useState(false);
  const [positionX, setPositionX] = React.useState(0);
  const [positionY, setPositionY] = React.useState(0);
  const [clickedX, setClickedX] = React.useState(0);
  const [clickedY, setClickedY] = React.useState(0);
  const [maxHeight, setMaxHeight] = React.useState(0);

  const container: React.MutableRefObject<HTMLDivElement | null> = React.useRef<HTMLDivElement | null>(null);

  const closeMenu = React.useCallback(() => setOpen(false), []);

  const handleClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setContainerVisible(false);
      setClickedPosition(e, orientation, setClickedX, setClickedY);
      setOpen(true);
    },
    [orientation]
  );

  const enhancedChildren = React.cloneElement(children({ toggleMenu: handleClick }), {
    onContextMenu: handleClick,
  });

  React.useLayoutEffect(() => {
    if (container.current && isOpen) {
      const { height, width } = container.current.getBoundingClientRect();
      const coords: Coordinates = calculateCoordinates(clickedX, clickedY, width, height, orientation);
      setPositionX(coords.x);
      setPositionY(coords.y);
      setMaxHeight(calculateMaxHeight(coords.y));
      setContainerVisible(true);
    }
  }, [isOpen, clickedX, clickedY, orientation, positionX]);

  React.useEffect(() => {
    document.body.addEventListener('scroll', closeMenu, true);
    document.body.addEventListener('resize', closeMenu);

    return () => {
      document.body.removeEventListener('scroll', closeMenu);
      document.body.removeEventListener('resize', closeMenu);
    };
  }, [closeMenu]);

  return isOpen ? (
    <React.Fragment>
      {ReactDOM.createPortal(
        <ClickOutside clickOutsideHandler={closeMenu}>
          <ContextMenuWrapper x={positionX} y={positionY} ref={container} visible={containerVisible}>
            {content && React.cloneElement(content, { closeMenu, maxHeight })}
          </ContextMenuWrapper>
        </ClickOutside>,
        document.getElementById('context-menu') as HTMLElement
      )}
      {enhanceWithOnContext ? enhancedChildren : children({ toggleMenu: handleClick })}
    </React.Fragment>
  ) : enhanceWithOnContext ? (
    enhancedChildren
  ) : (
    children({ toggleMenu: handleClick })
  );
};

export default ContextMenu;
