import styled from 'styled-components';

export const Pill = styled.span`
  position: absolute;
  top: -4px;
  right: -2px;
  background: ${props => props.theme.notifications.pill.background};
  color: ${props => props.theme.notifications.pill.color};
  border-radius: 50%;
  padding: 2px 5px;
  font-size: 80%;
  min-width: 18px;
`;
