import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from '../../../config/store/rootReducer';
import { makeIsPendingSelector } from '../../app/redux/selectors/loading';
import { LOGOUT_ACTION_PREFIX } from '../redux/actions/constants/logout';
import { logoutActions, LogoutActionsType } from '../redux/actions/creators';

export type LogoutManagerRenderProps = {
  handleLogout: () => void;
  isPending: boolean;
};

type RenderCallback = (renderProp: LogoutManagerRenderProps) => JSX.Element | null;

type Props = {
  initiateLogout: () => void;
  isPending: boolean;
  children: RenderCallback;
};

class LogoutManager extends React.PureComponent<Props> {
  handleLogout = () => {
    this.props.initiateLogout();
  };

  render(): JSX.Element | null {
    const { children, isPending } = this.props;
    const renderProp = {
      handleLogout: this.handleLogout,
      isPending,
    };

    return children ? children(renderProp) : null;
  }
}

const mapDispatchToProps = (dispatch: Dispatch<LogoutActionsType>) =>
  bindActionCreators(
    {
      initiateLogout: logoutActions.initiateLogout,
    },
    dispatch
  );

const mapStateToProps = (state: RootState) => ({
  isPending: makeIsPendingSelector(LOGOUT_ACTION_PREFIX)(state),
});

const enhance = connect(mapStateToProps, mapDispatchToProps);

export default enhance(LogoutManager);
