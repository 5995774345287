import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const ReminderAndInform: React.FunctionComponent = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.9536 4.3999H1.32031V17.8903H20.5923V12.6559C19.9984 13.0022 19.3508 13.2667 18.6651 13.4338V15.9631H3.24751V7.48342L10.2818 13.7468C10.4745 13.9395 10.7636 14.0359 10.9563 14.0359C11.149 14.0359 11.4381 13.9395 11.6308 13.7468L13.032 12.4992C12.4932 12.1544 12.003 11.7403 11.5739 11.2696L10.9563 11.8196L4.78927 6.3271H9.6805C9.68526 5.65942 9.78014 5.01317 9.9536 4.3999Z" />
      <path d="M21.5344 9.89801L20.9167 7.80093C20.6285 6.83182 19.5167 6.14868 18.2402 6.14868C18.0755 6.14868 17.9314 6.21223 17.8284 6.30755L16.7784 7.48319C16.6343 7.62617 16.3666 7.62617 16.2225 7.48319L15.1724 6.30755C15.0901 6.21223 14.9254 6.14868 14.7607 6.14868C13.4842 6.14868 12.3518 6.83182 12.0841 7.80093L11.4665 9.89801C11.3223 10.3746 11.7959 10.8195 12.4135 10.8195H20.5873C21.205 10.8195 21.6785 10.3746 21.5344 9.89801Z" />
      <path d="M16.5008 4.85122C17.5735 4.85122 18.447 4.03219 18.447 3.02636V2.2648C18.447 1.25897 17.5735 0.439941 16.5008 0.439941C15.4282 0.439941 14.5547 1.25897 14.5547 2.2648V3.04073C14.57 4.03219 15.4435 4.85122 16.5008 4.85122Z" />
    </svg>
  </BaseSvgWrapper>
);

export default ReminderAndInform;
