import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import { loginActions, LoginActionsType } from '../redux/actions/creators';

type DispatchProps = {
  initiateOauthSignIn: (searchQuery: string) => void;
};

type Props = RouteComponentProps & DispatchProps;

class OauthSignInService extends React.PureComponent<Props> {
  componentDidMount() {
    const { search } = this.props.location;
    this.props.initiateOauthSignIn(search);
  }

  render(): JSX.Element | null {
    return null;
  }
}

const mapDispatchToProps = (dispatch: Dispatch<LoginActionsType>) =>
  bindActionCreators(
    {
      initiateOauthSignIn: loginActions.initiateOauthSignIn,
    },
    dispatch
  );

const enhance = connect(undefined, mapDispatchToProps);

export default enhance(withRouter(OauthSignInService));
