import * as React from 'react';
import { Route, Switch } from 'react-router';
import { REPOSITORY_BASE_ROUTE } from '.';
import { RepositoriesOverview } from '../components';
import PdfView from '../components/pdfView/PdfView';

const CompanySubRoutes: React.FC = () => {
  return (
    <Switch>
      <Route path={REPOSITORY_BASE_ROUTE} exact component={RepositoriesOverview} />
      <Route path={`${REPOSITORY_BASE_ROUTE}/:downloadGuid/download`} exact component={RepositoriesOverview} />
      <Route
        path={`${REPOSITORY_BASE_ROUTE}/:guid`}
        exact
        render={({
          match: {
            params: { guid },
          },
        }) => <PdfView guid={guid} />}
      />
    </Switch>
  );
};

export default CompanySubRoutes;
