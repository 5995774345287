import { Select } from 'components';
import styled from 'config/theme';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { ValueType } from 'react-select';
import { PaginationInfo } from 'types';
import { Action } from 'typesafe-actions';
import { IconButton } from '../';
import { PAGE_SIZES } from '../../constants';
import { ChevronLeft, ChevronRight } from '../icons';
import { PaginatorWrapper } from './PaginatorWrapper';

type Props = {
  setPageIndexActionCreator: (page: number) => Action;
  setPageSizeActionCreator: (size: number) => Action;
  pageInfo: PaginationInfo;
};

type PageSizeOption = {
  value: number;
  label: string;
};

const pageSizeOptions: PageSizeOption[] = PAGE_SIZES.map((size: number) => ({
  label: size.toString(),
  value: size,
}));

const Paginator: React.FunctionComponent<Props> = ({
  pageInfo: { pageIndex, totalItems, hasNextPage, pageSize },
  setPageIndexActionCreator,
  setPageSizeActionCreator,
}: Props) => {
  const dispatch = useDispatch();

  const setPageIndex = (page: number) => dispatch(setPageIndexActionCreator(page));
  const setPageSize = (value: ValueType<PageSizeOption>) =>
    dispatch(setPageSizeActionCreator((value as PageSizeOption).value));

  const goToPreviousPage = () => (pageIndex > 1 ? setPageIndex(pageIndex - 1) : undefined);
  const goToNextPage = () => (hasNextPage ? setPageIndex(pageIndex + 1) : undefined);

  const getPageStatus = (): string => {
    if (!pageIndex || !totalItems) {
      return '';
    }
    const firstItemIndexOfPage = pageIndex > 1 ? (pageIndex - 1) * pageSize : 1;
    const lastItemIndexOfPage = pageIndex * pageSize > totalItems ? totalItems : pageIndex * pageSize;

    return `${firstItemIndexOfPage} - ${lastItemIndexOfPage} of ${totalItems}`;
  };

  return (
    <PaginatorWrapper>
      <div className="pageNumber">{getPageStatus()}</div>
      <IconButton onClick={goToPreviousPage} disabled={!pageIndex || pageIndex < 2}>
        <ChevronLeft />
      </IconButton>
      <PageSizeWrapper>
        <Select
          options={pageSizeOptions}
          value={pageSizeOptions.find(option => option.value === pageSize)}
          onChange={setPageSize}
        />
      </PageSizeWrapper>
      <IconButton onClick={goToNextPage} disabled={!hasNextPage}>
        <ChevronRight />
      </IconButton>
    </PaginatorWrapper>
  );
};

const PageSizeWrapper = styled.div`
  width: 75px;
`;

export default Paginator;
