import { combineReducers } from 'redux';
import { PaginationInfo, SortValue } from 'types';
import { CriticalAlertsFilters } from '../../types';
import criticalAlertsReducer, { CriticalAlertsModuleState } from './criticalAlerts';
import filterReducer from './filters';
import pageInfoReducer from './pageInfo';
import sortingReducer from './sorting';

export type CriticalAlertsState = {
  filters: CriticalAlertsFilters;
  pageInfo: PaginationInfo;
  criticalAlerts: CriticalAlertsModuleState;
  sorting: SortValue[];
};

const criticalAlertsModuleReducer = combineReducers({
  filters: filterReducer,
  pageInfo: pageInfoReducer,
  criticalAlerts: criticalAlertsReducer,
  sorting: sortingReducer,
});

export default criticalAlertsModuleReducer;
