import { createSelector } from 'reselect';
import { RootState } from '../../../../config/store/rootReducer';
import { AlertWithIdentifier } from '../../types/alert';
import { CriticalAlert } from '../../types/criticalAlert';

const getAlerts = (state: RootState): AlertWithIdentifier[] => state.alerts.alerts;

export const makeGetAlerts = () => createSelector(getAlerts, (alerts: AlertWithIdentifier[]) => alerts);

const getCriticalAlerts = (state: RootState): CriticalAlert[] | null => state.alerts.criticalAlerts;

export const makeGetCriticalAlerts = () =>
  createSelector(getCriticalAlerts, (criticalAlerts: CriticalAlert[] | null) => criticalAlerts);
