import { loadScene } from 'components/async/scene';
import { useAccessRightsToFunctionality, useAccountInfo } from 'modules/security';
import { Module } from 'modules/security/types/modules';
import * as React from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';
import ModuleRoutes from '../../../components/routing/ModuleRoutes';
import { OrganizationAccessRights } from '../constants';

type Props = {
  allowedModules: Module[];
};

const organizationOverviewScene = loadScene('organization', 'OrganizationScene');
const OrganizationDetailScene = loadScene('organization', 'OrganizationDetailScene');

const OrganizationRoutes: React.FunctionComponent<Props> = ({ allowedModules }: Props) => {
  const accountInfo = useAccountInfo();
  const hasAccessToOverview = useAccessRightsToFunctionality(OrganizationAccessRights.AccessToOverview);

  return (
    <ModuleRoutes allowedModules={allowedModules} moduleKey={ORGANIZATION_MODULE_KEY}>
      <React.Fragment>
        <Route
          path={ORGANIZATION_BASE_ROUTE}
          exact
          component={
            hasAccessToOverview
              ? organizationOverviewScene
              : () => <Redirect to={`${ORGANIZATION_BASE_ROUTE}/${accountInfo && accountInfo.organizationId}`} />
          }
        />
        <Route
          path={`${ORGANIZATION_BASE_ROUTE}/:subroute`}
          render={(routeProps: RouteComponentProps<{ subroute: string }>) =>
            hasAccessToOverview || routeProps.match.params.subroute === accountInfo?.organizationId ? (
              <OrganizationDetailScene />
            ) : (
              <Redirect to={`${ORGANIZATION_BASE_ROUTE}/${accountInfo?.organizationId}`} />
            )
          }
        />
      </React.Fragment>
    </ModuleRoutes>
  );
};

export const ORGANIZATION_MODULE_KEY = 'organizations';
export const ORGANIZATION_BASE_ROUTE = '/organizations';
export default OrganizationRoutes;
