import { HttpErrorData, HttpResponse, SuccessCallback } from 'types';
import { createAction, createAsyncAction, createCustomAction } from 'typesafe-actions';
import { CriticalAlert } from '../../../app/types/criticalAlert';
import { CriticalAlertPostBody, CriticalAlertPutBody } from '../../types';

export const getCriticalAlerts = createAction('CRITICAL_ALERTS_GET');
export const getCriticalAlertsRequest = createAsyncAction(
  'CRITICAL_ALERTS_REQUEST',
  'CRITICAL_ALERTS_SUCCESS',
  'CRITICAL_ALERTS_REJECTED'
)<undefined, HttpResponse<CriticalAlert>, HttpErrorData>();

export const addCriticalAlert = createCustomAction(
  'CRITICAL_ALERT_POST',
  (type) => (values: CriticalAlertPostBody, successCallback?: SuccessCallback) => ({
    payload: values,
    successCallback,
    type,
  })
);
export const addCriticalAlertRequest = createAsyncAction(
  'CRITICAL_ALERT_POST_REQUEST',
  'CRITICAL_ALERT_POST_SUCCESS',
  'CRITICAL_ALERT_POST_REJECTED'
)<undefined, { response: CriticalAlert; data: CriticalAlertPostBody }, HttpErrorData>();

export const deleteCriticalAlert = createAction('CRITICAL_ALERT_DELETE', (action) => (guid: string) => action(guid));
export const deleteCriticalAlertRequest = createAsyncAction(
  'CRITICAL_ALERT_DELETE_REQUEST',
  'CRITICAL_ALERT_DELETE_SUCCESS',
  'CRITICAL_ALERT_DELETE_REJECTED'
)<undefined, string, HttpErrorData>();

export const editCriticalAlert = createCustomAction(
  'CRITICAL_ALERT_EDIT',
  (type) => (values: CriticalAlertPutBody, successCallback: SuccessCallback) => ({
    payload: values,
    successCallback,
    type,
  })
);
export const editCriticalAlertRequest = createAsyncAction(
  'CRITICAL_ALERT_EDIT_REQUEST',
  'CRITICAL_ALERT_EDIT_SUCCESS',
  'CRITICAL_ALERT_EDIT_REJECTED'
)<undefined, CriticalAlert, HttpErrorData>();
