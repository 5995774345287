import { SearchAction, searchActions } from 'modules/app/redux/creators';
import { SECTOR_FILTER_KEY } from 'modules/sector/constants';
import { SectorFilters } from 'modules/sector/types/sector';
import { getType } from 'typesafe-actions';

const filterReducer = (state: SectorFilters = {}, action: SearchAction) => {
  switch (action.type) {
    case getType(searchActions.setSearch):
      return action.payload.filterKey === SECTOR_FILTER_KEY ? { ...state, search: action.payload.searchText } : state;

    case getType(searchActions.clearSearch):
      const newState = { ...state };
      delete newState.search;

      return newState;

    default:
      return state;
  }
};

export default filterReducer;
