import { searchActions } from 'modules/app/redux/creators';
import sectorApi from 'modules/sector/api/sectorApi';
import { SECTOR_FILTER_KEY } from 'modules/sector/constants';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { formatSortingForApi } from '../../../../helpers/sortingFormatter';
import { pageInfoActions, sectorActions, sortingActions } from '../actions/creators';
import { makeGetSectorFilters, makeGetSectorPageInfo, makeGetSectorSorting } from '../selectors/sector';

function* sectorSaga() {
  yield takeLatest(
    [pageInfoActions.setPageSize, pageInfoActions.setPage, sectorActions.getSectors, sortingActions.setSorting],
    handleGetSectors
  );
  yield takeLatest([pageInfoActions.setPageSize], handleGetSectorsWithPage1);
  yield takeLatest(sectorActions.addSector, handleAddSector);
  yield takeLatest(searchActions.setSearch, handleSearchChange);
  yield takeLatest(sectorActions.deleteSector, handleDeleteSector);
  yield takeLatest(sectorActions.editSector, handleEditSector);
}

function* handleGetSectors() {
  const { request, success, failure } = sectorActions.getSectorsRequest;
  yield put(request());
  try {
    const filters = yield select(makeGetSectorFilters());
    const pageInfo = yield select(makeGetSectorPageInfo());
    const sorting = yield select(makeGetSectorSorting());
    const payload = yield call(sectorApi.getSectors, pageInfo, filters, formatSortingForApi(sorting));
    yield put(success(payload));
  } catch (e) {
    yield put(failure(e.response));
  }
}
function* handleGetSectorsWithPage1() {
  yield put(pageInfoActions.setPage(1));
}

function* handleSearchChange(action: ReturnType<typeof searchActions.setSearch>) {
  if (action.payload.filterKey === SECTOR_FILTER_KEY) {
    yield put(pageInfoActions.setPage(1));
  }
}

function* handleAddSector(action: ReturnType<typeof sectorActions.addSector>) {
  const { success, failure, request } = sectorActions.addSectorRequest;
  yield put(request());
  try {
    const payload = yield call(sectorApi.addSector, action.payload.name);
    yield put(success(payload));
    action.successCallback();
    yield call(handleGetSectors);
  } catch (e) {
    yield put(failure(e.response));
  }
}

function* handleEditSector(action: ReturnType<typeof sectorActions.editSector>) {
  const { success, failure, request } = sectorActions.editSectorRequest;
  yield put(request());
  try {
    yield call(sectorApi.editSector, action.payload.name, action.payload.sectorId);
    yield put(success());
    action.successCallback();
    yield call(handleGetSectors);
  } catch (e) {
    yield put(failure(e.response));
  }
}

function* handleDeleteSector(action: ReturnType<typeof sectorActions.deleteSector>) {
  const { request, failure, success } = sectorActions.deleteSectorRequest;
  yield put(request());
  try {
    yield call(sectorApi.deleteSector, action.payload.sectorId);
    yield put(success());
    yield call(handleGetSectors);
  } catch (e) {
    yield put(failure(e.response));
  }
}

export default sectorSaga;
