import { RootState } from 'config/store/rootReducer';
import { Contact } from 'modules/organization/types/organizationDetail/contacts';
import { CTIData } from 'modules/organization/types/organizationDetail/cti';
import { GeneralInfo } from 'modules/organization/types/organizationDetail/general';
import { User } from 'modules/organization/types/organizationDetail/users';
import { createSelector } from 'reselect';

const getGeneralInfo = (state: RootState): GeneralInfo | null => state.organizations.organizationDetail.generalInfo;
const getContacts = (state: RootState): Contact[] | null => state.organizations.organizationDetail.contacts;
const getCTIData = (state: RootState): CTIData | null => state.organizations.organizationDetail.cti;
const getUsers = (state: RootState): User[] | null => state.organizations.organizationDetail.users;

export const makeGetGeneralInfo = () =>
  createSelector(getGeneralInfo, (organizationDetail: GeneralInfo | null) => organizationDetail);

export const makeGetContacts = () => createSelector(getContacts, (contacts: Contact[] | null) => contacts);

export const makeGetCTIData = () => createSelector(getCTIData, (ctiData: CTIData | null) => ctiData);

export const makeGetUsers = () => createSelector(getUsers, (users: User[] | null) => users);
