import { FieldProps } from 'formik';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FormikSelect } from '../../../../components/Form';
import { getNestedError } from '../../../../helpers/formikHelper';
import { ContactType } from '../../../app/types/contact';

type Props = FieldProps & { label: string };

const ContactTypesSelect: React.FunctionComponent<Props> = ({ field, form, label }) => {
  const { t } = useTranslation(['global', 'onboarding']);

  const options = [
    { label: t('contactTypes.admin'), value: ContactType.Admin },
    { label: t('contactTypes.strategic'), value: ContactType.Strategic },
    { label: t('contactTypes.operational'), value: ContactType.Operational },
    { label: t('contactTypes.technical'), value: ContactType.Technical },
  ];

  const hasAdmin = Boolean(form.values.contacts.find((item) => item.types?.includes('admin')));

  return (
    <FormikSelect
      label={t(`onboarding:${label}`)}
      isMulti
      field={field}
      form={form}
      isClearable
      filterOption={(option) => (hasAdmin ? option.value !== ContactType.Admin : true)}
      options={options}
      components={{
        ClearIndicator: null,
      }}
      error={getNestedError(field.name, form.errors, form.touched)}
      getOptionValue={(option) => option.value}
      getOptionLabel={(option) => option.label}
    />
  );
};

export default ContactTypesSelect;
