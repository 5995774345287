import AxiosConfig, { RequestConfig } from 'config/api/AxiosConfig';
import { PaginationInfo } from 'types';
import {
  AddRepositoryValues,
  DownloadRepositoryValues,
  EditRepositoryValues,
  MarkRepositoryReadUnreadValues,
  ReportQuestionValues,
  RepositoryFilters,
  ViewOrDownloadType,
} from '../types/repository';
import { REPOSITORIES_URL } from './endpoints';
import { HELPCENTER_BASE_ROUTE } from 'modules/helpCenter/routes';
import { HELPCENTER_URL } from 'modules/helpCenter/api/endpoints';

const getRepositories = async (pageInfo: PaginationInfo, filters?: RepositoryFilters, sorting?: string[]) => {
  const params = {
    pageIndex: pageInfo.pageIndex,
    pageSize: pageInfo.pageSize,
    ...(filters && {
      ...(filters.audience && { audiences: filters.audience.map((aud) => aud) }),
      ...(filters.dateFrom && { dateFrom: filters.dateFrom }),
      ...(filters.dateUntil && { dateUntil: filters.dateUntil }),
      ...(filters.priority && { priorities: filters.priority.map((prio) => prio) }),
      ...(filters.reportType && { types: filters.reportType.map((type) => type.reportTypeId) }),
      ...(filters.search && { search: filters.search }),
      ...(filters.sectors && { sectors: filters.sectors.map((sector) => sector.sectorId) }),
      ...(filters.tlpCode && { tlpCodes: filters.tlpCode.map((tlp) => tlp) }),
      ...(filters.fullText && { fullText: filters.fullText }),
    }),
    ...(sorting && { orderBys: sorting }),
  };

  const requestConfig: RequestConfig = {
    params,
    url: REPOSITORIES_URL,
  };

  return AxiosConfig.fetchAxiosPagedGetRequest(requestConfig);
};

const editRepository = async (repository: EditRepositoryValues) => {
  const { reportId, reportTypeId, description, file, sectors, audience, tlpCode, priority, reportDate, sendMail } =
    repository;
  const sectorIds = sectors?.map((sector) => sector.sectorId);

  const requestConfig: RequestConfig = {
    data: {
      audience,
      content: file.base64,
      description,
      fileName: file.name,
      priority,
      reportId,
      reportTypeId,
      sectors: sectorIds,
      tlpCode,
      reportDate,
      sendMail,
    },
    url: `${REPOSITORIES_URL}/${reportId}`,
  };

  return AxiosConfig.fetchAxiosPutRequest(requestConfig);
};

const addRepository = async (repository: AddRepositoryValues) => {
  const { reportTypeId, description, file, sectors, audience, tlpCode, priority, reportDate, sendMail } = repository;
  const sectorIds = sectors?.map((sector) => sector.sectorId);
  const requestConfig: RequestConfig = {
    data: {
      audience,
      content: file?.base64,
      description,
      fileName: file?.name,
      priority,
      reportTypeId,
      sectors: sectorIds,
      tlpCode,
      reportDate,
      sendMail,
    },
    url: REPOSITORIES_URL,
  };

  return AxiosConfig.fetchAxiosPostRequest(requestConfig);
};

const deleteRepository = async (id: string) => {
  const requestConfig: RequestConfig = {
    url: `${REPOSITORIES_URL}/${id}`,
  };

  return AxiosConfig.fetchAxiosDeleteRequest(requestConfig);
};

const downloadRepository = (repository: DownloadRepositoryValues, type: ViewOrDownloadType) => {
  const requestConfig: RequestConfig = {
    url: `${process.env.REACT_APP_API_ROOT}${REPOSITORIES_URL}/${repository.guid}/download`,
    params: {
      type,
    },
  };

  return AxiosConfig.fetchAxiosDownloadRequest(requestConfig, repository.fileName);
};

const simpleDownloadRepository = (guid: string) => {
  return (window.location.href = `${process.env.REACT_APP_API_ROOT}${REPOSITORIES_URL}/${guid}/download?type=download`);
};

const markAsReadUnread = ({ id, read }: MarkRepositoryReadUnreadValues) => {
  const requestConfig = {
    url: `${REPOSITORIES_URL}/${id}/mark-as-read`,
    params: {
      read,
    },
  };
  return AxiosConfig.fetchAxiosPostRequest(requestConfig);
};

const reportQuestion = (data: ReportQuestionValues) => {
  const requestConfig = {
    url: `${HELPCENTER_URL}/report-question`,
    data,
  };
  return AxiosConfig.fetchAxiosPostRequest(requestConfig);
};

export default {
  addRepository,
  deleteRepository,
  downloadRepository,
  editRepository,
  markAsReadUnread,
  getRepositories,
  simpleDownloadRepository,
  reportQuestion,
};
