import * as React from 'react';
import { ContentLoader, SkeletonGenerator } from '../';
import styled from '../../config/theme';

const FilterLoadingState: React.FunctionComponent = () => (
  <LoadingStateWrapper>
    <SkeletonGenerator count={1}>
      <ContentLoader height={255}>
        <rect x="0" y="10" rx="5" ry="5" width="160" height="30" />
        <rect x="75" y="90" rx="5" ry="5" width="190" height="15" />
        <rect x="75" y="155" rx="5" ry="5" width="230" height="15" />
        <rect x="75" y="210" rx="5" ry="5" width="200" height="15" />
      </ContentLoader>
    </SkeletonGenerator>
  </LoadingStateWrapper>
);

const LoadingStateWrapper = styled.div`
  margin-top: 30px;
`;

export default FilterLoadingState;
