import { Input } from 'components';
import { Field, FieldProps, Form, Formik } from 'formik';
import { addUserSchema } from 'modules/organization/schemas/organizationDetail';
import { AddUserValues } from 'modules/organization/types/organizationDetail/organizationDetail';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  onSubmit: (values: AddUserValues) => void;
  onFormChange: () => void;
  formRef: React.Ref<any>;
  isAdmin?: boolean;
};

const AddUserForm: React.FunctionComponent<Props> = ({ onSubmit, onFormChange, formRef, isAdmin }: Props) => {
  const { t } = useTranslation('organization');

  return (
    <Formik
      initialValues={{ email: '' }}
      onSubmit={onSubmit}
      ref={formRef}
      validate={onFormChange}
      validationSchema={addUserSchema}
      render={({ touched, errors }) => (
        <Form>
          <Field
            name="email"
            render={({ field }: FieldProps<AddUserValues>) => (
              <Input
                autoFocus
                {...field}
                type="text"
                label={isAdmin ? t('detail.userModal.adminEmail') : t('detail.userModal.email')}
                error={(touched.email && errors.email) || ''}
              />
            )}
          />
        </Form>
      )}
    />
  );
};

export default AddUserForm;
