import styled from 'config/theme';
import { FieldProps, getIn } from 'formik';
import { daysInTheWeekList } from 'modules/app/constants';
import { DayOptionType } from 'modules/app/types';
import * as React from 'react';
import { FormikSelect } from '../../../../components/Form';
import { getNestedError } from '../../../../helpers/formikHelper';
import MultiValueContainer from './MultiValueContainer';
import SelectOption from './SelectOption';
import SortedDaysSelectInput from './SortedDaysSelectInput';

type Props = FieldProps & { disabledDays?: string[]; autoFocus?: boolean };

const AvailabilityDaysSelect: React.FunctionComponent<Props> = ({
  field,
  form,
  disabledDays,
  autoFocus,
  ...props
}: Props) => (
  <StyledFormikSelect
    field={field}
    form={form}
    value={getIn(form.values, field.name)}
    hideSelectedOptions={false}
    getOptionValue={(option: DayOptionType) => option.value}
    defaultMenuIsOpen={false}
    components={{ Input: SortedDaysSelectInput, MultiValueContainer, Option: SelectOption }}
    closeMenuOnSelect={false}
    isMulti={true}
    error={getNestedError(field.name, form.errors, form.touched)}
    options={daysInTheWeekList}
    isOptionDisabled={(option: DayOptionType) => (disabledDays ? disabledDays.includes(option.value) : false)}
    autoFocus={autoFocus}
    {...props}
  />
);

const StyledFormikSelect = styled(FormikSelect)`
  span.error {
    top: 50px;
  }

  label {
    margin-bottom: 8px;
  }
`;

export default AvailabilityDaysSelect;
