import { createAction, createAsyncAction } from 'typesafe-actions';
import { HttpErrorResponse } from '../../../../../types';
import { Modules } from '../../../types/modules';

export const getAvailableModules = createAction('AVAILABLE_MODULES_GET');

export const fetchAvailableModules = createAsyncAction(
  'AVAILABLE_MODULES_REQUEST',
  'AVAILABLE_MODULES_SUCCESS',
  'AVAILABLE_MODULES_REJECTED'
)<undefined, Modules, HttpErrorResponse>();
