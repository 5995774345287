import { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeGetOrganizationId } from '../../../../onboarding/redux/selectors/user';
import { OrganizationUpdateStep, UpdateStep } from '../../../../organization/types/organization';
import ContactsStep from './ContactsStep';
import CtiStep from './CtiStep';
import GeneralInfoStep from './GeneralInfoStep';

type Props = {
  profileUpdateStep: OrganizationUpdateStep;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const UpdateSteps = ({ profileUpdateStep, setShowModal }: Props) => {
  const organizationId = useSelector(makeGetOrganizationId());

  const [updateStep, setUpdateStep] = useState<UpdateStep>(profileUpdateStep.updateStep);

  switch (updateStep) {
    case UpdateStep.needsUpdate:
      return (
        <GeneralInfoStep organizationId={organizationId} setUpdateStep={setUpdateStep} setShowModal={setShowModal} />
      );

    case UpdateStep.generalInfoCompleted:
      return <ContactsStep organizationId={organizationId} setUpdateStep={setUpdateStep} setShowModal={setShowModal} />;

    case UpdateStep.contactsCompleted:
      return <CtiStep organizationId={organizationId} setUpdateStep={setUpdateStep} setShowModal={setShowModal} />;

    case UpdateStep.allUpToDate:
      setShowModal(false);
      return null;
  }
};

export default UpdateSteps;
