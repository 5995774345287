import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const ArrowRight: React.FC = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
      <path d="M40.8155 33.0418L32.4975 41.3598L27.8876 36.7477L39.9333 24.702L33.921 18.6897C30.6142 15.3829 27.9088 12.6574 27.9088 12.6331C27.9088 12.6088 28.9413 11.5564 30.2034 10.2945L32.4981 8.0002L40.8159 16.318C45.3907 20.8927 49.1338 24.6556 49.1338 24.68C49.1338 24.7043 45.3904 28.4669 40.8155 33.0418Z" />
      <path d="M40.6858 28.1502L2.00001 28.1502L2.00001 21.5453L40.6858 21.5453V28.1502Z" />
    </svg>
  </BaseSvgWrapper>
);

export default ArrowRight;
