import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const Reminder: React.FunctionComponent = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.95311 4.3999H1.31982V17.8903H20.5918V12.6559C19.9979 13.0022 19.3503 13.2667 18.6646 13.4338V15.9631H3.24702V7.48342L10.2813 13.7468C10.474 13.9395 10.7631 14.0359 10.9558 14.0359C11.1485 14.0359 11.4376 13.9395 11.6303 13.7468L13.0315 12.4992C12.4927 12.1544 12.0025 11.7403 11.5734 11.2696L10.9558 11.8196L4.78878 6.3271H9.68001C9.68477 5.65942 9.77965 5.01317 9.95311 4.3999Z" />
      <path d="M22 6.62199C22 9.63295 19.5859 12.0738 16.6079 12.0738C13.6299 12.0738 11.2158 9.63295 11.2158 6.62199C11.2158 3.61103 13.6299 1.17017 16.6079 1.17017C19.5859 1.17017 22 3.61103 22 6.62199ZM12.4071 6.62199C12.4071 8.96776 14.2878 10.8694 16.6079 10.8694C18.928 10.8694 20.8088 8.96776 20.8088 6.62199C20.8088 4.27622 18.928 2.37459 16.6079 2.37459C14.2878 2.37459 12.4071 4.27622 12.4071 6.62199Z" />
      <path d="M15.8824 3.36401H17.1765V7.72547H16.5294C16.5294 7.72547 16.3289 7.72003 16.2463 7.70332C16.0127 7.65608 15.9571 7.56278 15.9093 7.32681C15.8919 7.24077 15.8824 7.06444 15.8824 7.06444V3.36401Z" />
      <path d="M19.0141 7.86744L18.367 9.00058L16.093 7.65392C16.093 7.65392 15.9733 7.54488 15.9278 7.33021C15.8796 7.10271 15.8824 7.07125 15.8824 7.07125L16.5294 6.41703L19.0141 7.86744Z" />
    </svg>
  </BaseSvgWrapper>
);

export default Reminder;
