import { createAction } from 'typesafe-actions';
import { Audience, Priority, TlpCode } from '../../../app/constants';
import { ReportType, Sector } from '../../../app/types';

export const setDateFromFilter = createAction('REPOSITORY_SET_FILTER_DATE_FROM', action => (values?: Date) =>
  action(values)
);
export const setDateUntilFilter = createAction('REPOSITORY_SET_FILTER_DATE_UNTIL', action => (values?: Date) =>
  action(values)
);
export const setSectorFilter = createAction('REPOSITORY_SET_FILTER_SECTOR', action => (values: Sector[]) =>
  action(values)
);
export const setReportTypeFilter = createAction('REPOSITORY_SET_FILTER_REPORT_TYPE', action => (values: ReportType[]) =>
  action(values)
);
export const setAudienceFilter = createAction('REPOSITORY_SET_FILTER_AUDIENCE', action => (values: Audience[]) =>
  action(values)
);
export const setPriorityFilter = createAction('REPOSITORY_SET_FILTER_PRIORITY', action => (values: Priority[]) =>
  action(values)
);
export const setTlpCodeFilter = createAction('REPOSITORY_SET_FILTER_TLPCODE', action => (values: TlpCode[]) =>
  action(values)
);
export const setFullTextFilter = createAction('REPOSITORY_SET_FILTER_FULL_TEXT', action => (values: boolean) =>
  action(values)
);
