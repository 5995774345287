import { IconButton } from 'components';
import { ConvertedFile } from 'components/Dropzone/FileInput';
import { FormikSelect, Input } from 'components/Form';
import FormikFileUpload from 'components/Form/FormikFileUpload';
import { TYPE_NUMBER } from 'components/Form/Input';
import { ArrayHelperActionWrapper, BasicTextField, EditableItem, FullViewSubBlock } from 'components/FullView';
import { AddPerson, Delete } from 'components/icons';
import { ArrayHelpers, Field, FieldArray, FieldProps, FormikErrors, FormikTouched } from 'formik';
import { getNestedError } from 'helpers/formikHelper';
import ContactTypesSelect from 'modules/app/components/ContactTypeSelect';
import { ORGANIZATIONS_URL } from 'modules/organization/api/enpoints';
import { newContact } from 'modules/organization/constants';
import {
  AvailabilityEditValues,
  ContactEditValues,
  ContactInfoEditValues,
} from 'modules/organization/types/organizationDetail/contacts';
import { BusinessLocation } from 'modules/organization/types/organizationDetail/general';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import AvailabilityEdit from './AvailabilityEdit';
import { Name } from './Contact';

type Props = {
  contact: ContactEditValues;
  index: number;
  errors: FormikErrors<ContactInfoEditValues>;
  touched: FormikTouched<ContactInfoEditValues>;
  arrayHelpers: ArrayHelpers;
  businessLocations: BusinessLocation[];
};

const ContactEdit: React.FunctionComponent<Props> = ({
  contact,
  index,
  errors,
  touched,
  arrayHelpers,
  businessLocations,
}: Props) => {
  const { t } = useTranslation('organization');

  const addContact = React.useCallback(() => arrayHelpers.push(newContact), [arrayHelpers]);
  const removeContact = React.useCallback(() => arrayHelpers.remove(index), [arrayHelpers, index]);

  const getDisabledDaysForAvailability = React.useCallback(
    (availability: AvailabilityEditValues): string[] => {
      return contact.availabilities.reduce((result: string[], currentValue: AvailabilityEditValues) => {
        result.push(...currentValue.days.filter((day) => !availability.days.includes(day)));

        return result;
      }, []);
    },
    [contact.availabilities]
  );

  const handleFileDownload = (id?: string) => () => {
    if (id) {
      window.location.href = `${process.env.REACT_APP_API_ROOT}${ORGANIZATIONS_URL}/files/${id}/download`;
    }
  };

  return (
    <FullViewSubBlock>
      <Name>{contact.firstName !== '' ? `${contact.firstName || ''} ${contact.lastName || ''}` : 'new contact'}</Name>
      <ArrayHelperActionWrapper>
        <BasicTextField
          name={`contacts.${index}.firstName`}
          label={t(`detail.labels.firstName`)}
          errors={errors}
          touched={touched}
          autoFocus={!contact.contactId}
        />
        {index === 0 ? (
          <IconButton onClick={addContact} tooltip={t('tooltips.addContact')}>
            <AddPerson />
          </IconButton>
        ) : (
          <IconButton onClick={removeContact} tooltip={t('tooltips.removeContact')}>
            <Delete />
          </IconButton>
        )}
      </ArrayHelperActionWrapper>
      <BasicTextField
        name={`contacts.${index}.lastName`}
        label={t(`detail.labels.lastName`)}
        errors={errors}
        touched={touched}
      />
      <EditableItem
        label={t('detail.labels.contactType')}
        value={
          <Field
            name={`contacts.${index}.types`}
            render={(fieldProps: FieldProps) => <ContactTypesSelect fieldProps={fieldProps} />}
          />
        }
      />
      <BasicTextField
        name={`contacts.${index}.email`}
        label={t(`detail.labels.email`)}
        errors={errors}
        touched={touched}
      />
      <BasicTextField
        name={`contacts.${index}.skype`}
        label={t(`detail.labels.skype`)}
        errors={errors}
        touched={touched}
      />
      <BasicTextField
        name={`contacts.${index}.phoneBusiness`}
        label={t(`detail.labels.businessPhone`)}
        errors={errors}
        touched={touched}
      />
      <BasicTextField
        name={`contacts.${index}.phoneMobile`}
        label={t(`detail.labels.officePhone`)}
        errors={errors}
        touched={touched}
      />
      <BasicTextField
        name={`contacts.${index}.phonePrivate`}
        label={t(`detail.labels.phonePrivate`)}
        errors={errors}
        touched={touched}
      />
      <EditableItem
        label={t(`detail.labels.phoneOrder`)}
        value={
          <Field
            name={`contacts.${index}.phoneOrder`}
            render={({ field }: FieldProps) => (
              <Input
                {...field}
                noLabel
                type={TYPE_NUMBER}
                error={getNestedError(`contacts.${index}.phoneOrder`, errors, touched)}
              />
            )}
          />
        }
      />
      <EditableItem
        label={t('detail.labels.mispPocPgpFileId')}
        value={
          <Field
            name={`contacts.${index}.pgpFile`}
            render={({ field, form }: FieldProps) => (
              <FormikFileUpload
                field={field}
                form={form}
                acceptedFileTypes={['.asc']}
                getFileValues={(file: ConvertedFile) => ({
                  ...file,
                  pgpFileId: contact.pgpFile && contact.pgpFile.pgpFileId,
                })}
                isDownloadable
                onFileClick={handleFileDownload(contact.pgpFile ? contact.pgpFile.pgpFileId : undefined)}
              />
            )}
          />
        }
      />
      <BasicTextField
        name={`contacts.${index}.treemaId`}
        label={t(`detail.labels.treemaId`)}
        errors={errors}
        touched={touched}
      />
      <BasicTextField
        name={`contacts.${index}.info`}
        label={t(`detail.labels.info`)}
        errors={errors}
        touched={touched}
      />
      <EditableItem
        label={t('detail.labels.address')}
        value={
          <Field
            name={`contacts.${index}.businessLocations`}
            render={(fieldProps: FieldProps) => (
              <FormikSelect
                {...fieldProps}
                options={businessLocations.map((location) => ({
                  businessLocationId: location.addressId,
                  name: location.name,
                }))}
                getOptionValue={(option) => option.businessLocationId.toString()}
                getOptionLabel={(option) => option.name}
                error={getNestedError(`contacts.${index}.businessLocations`, errors, touched)}
                isMulti
              />
            )}
          />
        }
      />
      <EditableItem
        label={t('detail.labels.availabilities')}
        positionLabelTop
        value={
          <FieldArray
            name={`contacts.${index}.availabilities`}
            render={(availabilityArrayHelpers) =>
              contact.availabilities.map((availability, availabilityIndex) => (
                <AvailabilityEdit
                  disabledDays={getDisabledDaysForAvailability(availability)}
                  availability={availability}
                  arrayHelpers={availabilityArrayHelpers}
                  contactIndex={index}
                  availabilityIndex={availabilityIndex}
                  key={availabilityIndex}
                />
              ))
            }
          />
        }
      />
    </FullViewSubBlock>
  );
};

export default ContactEdit;
