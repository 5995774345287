import { Select } from 'components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { TlpCode, tlpCodeOptions } from '../../../app/constants';

type Props = {
  tlpCodes?: TlpCode[];
  filterAction: (tlpCodes: TlpCode[]) => void;
};

const TlpCodeFilters: React.FunctionComponent<Props> = ({ tlpCodes, filterAction }) => {
  const { t } = useTranslation('global');
  const dispatch = useDispatch();

  const changeHandler = React.useCallback(
    (selected) => {
      dispatch(filterAction(selected || []));
    },
    [dispatch, filterAction]
  );

  return (
    <div>
      <h5>{t('sidebar.filters.tlpCode')}</h5>
      <Select
        getOptionLabel={(option: TlpCode) => t(`tlpCode.${option.toLowerCase()}`)}
        getOptionValue={(option: TlpCode) => option}
        isCapitalized
        isMulti
        onChange={changeHandler}
        options={tlpCodeOptions}
        value={tlpCodes || []}
      />
    </div>
  );
};

export default TlpCodeFilters;
