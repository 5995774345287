import { createAction, createAsyncAction } from 'typesafe-actions';
import { HttpErrorData, HttpResponse } from '../../../../types';
import { AccountInfo } from '../../../security/hooks';
import { CTIDataResponse } from '../../types/ctiData';
import { ContactDataResponse, GeneralDataResponse } from '../../types/onboarding';

export const postGeneralData = createAction('GENERAL_DATA_POST');
export const postGeneralDataRequest = createAsyncAction(
  'GENERAL_DATA_POST_REQUEST',
  'GENERAL_DATA_POST_SUCCESS',
  'GENERAL_DATA_POST_REJECTED'
)<undefined, GeneralDataResponse, HttpErrorData>();

export const getGeneralData = createAction('GENERAL_DATA_GET');

export const getGeneralDataRequest = createAsyncAction(
  'GENERAL_DATA_GET_REQUEST',
  'GENERAL_DATA_GET_SUCCESS',
  'GENERAL_DATA_GET_REJECTED'
)<undefined, GeneralDataResponse, HttpErrorData>();

export const postContactData = createAction('CONTACT_DATA_POST');
export const postContactDataRequest = createAsyncAction(
  'CONTACT_DATA_POST_REQUEST',
  'CONTACT_DATA_POST_SUCCESS',
  'CONTACT_DATA_POST_REJECTED'
)<undefined, HttpResponse<any>, HttpErrorData>();

export const getContacts = createAction('CONTACTS_GET');

export const getContactsRequest = createAsyncAction(
  'CONTACTS_GET_REQUEST',
  'CONTACTS_GET_SUCCESS',
  'CONTACTS_GET_REJECTED'
)<undefined, ContactDataResponse, HttpErrorData>();

export const getCtiData = createAction('CTI_DATA_GET');

export const getCtiDataRequest = createAsyncAction(
  'CTI_DATA_GET_REQUEST',
  'CTI_DATA_GET_SUCCESS',
  'CTI_DATA_GET_REJECTED'
)<undefined, CTIDataResponse, HttpErrorData>();

export const postCtiData = createAction('CTI_DATA_POST');
export const postCtiDataRequest = createAsyncAction(
  'CTI_DATA_POST_REQUEST',
  'CTI_DATA_POST_SUCCESS',
  'CTI_DATA_POST_REJECTED'
)<undefined, HttpResponse<any>, HttpErrorData>();

export const setUserData = createAction('USER_DATA_SET', action => (userInfo: AccountInfo) => action(userInfo));
export const putUserData = createAction('USER_DATA_PUT');
export const putUserDataRequest = createAsyncAction(
  'USER_DATA_PUT_REQUEST',
  'USER_DATA_PUT_SUCCESS',
  'USER_DATA_PUT_REJECTED'
)<undefined, HttpResponse<any>, HttpErrorData>();
