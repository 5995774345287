export const preventClickEventBubbling = (e: React.MouseEvent) => {
  // only prevent left clicks, don't prevent right or middle click propagation
  if (e.type === 'click') {
    e.preventDefault();
    e.stopPropagation();
  }
};

export const handleTableRowClick = (e: React.MouseEvent, callback: () => void, isIconButton?: boolean) => {
  const { tagName } = e.target as HTMLElement;

  if (tagName !== 'LABEL' && tagName !== 'INPUT') {
    if (!isIconButton || (tagName !== 'path' && tagName !== 'BUTTON' && tagName !== 'svg')) {
      return callback();
    }
  }
};

export default { preventClickEventBubbling };
