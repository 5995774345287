import { FieldProps, getIn } from 'formik';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { formActions } from '../../redux/actions';
import { OnboardingContact } from '../../types/onboarding';
import ContactCard from '../contact/ContactCard';
import ContactTypesSelect from '../select/ContactTypesSelect';
import ArrayItemButton from './ArrayItemButton';

type Props = {} & FieldProps;

const MobileContactsOverview: React.FunctionComponent<Props> = ({ field, form }) => {
  const { t } = useTranslation('onboarding');
  const { stepIndex } = useParams<{ stepIndex: string }>();
  const history = useHistory();
  const nextStep = `${history.location.pathname.slice(0, -1)}${parseInt(stepIndex, 10) + 1}`;
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (field.value.length === 1 && field.value[0].availabilities[0].days.length === 0) {
      history.replace(`${nextStep}/0`);
    }
  }, [field.value, history, nextStep]);

  const handleAddContact = (id: string) => () => {
    history.push(`${nextStep}/${id}`);
  };

  const handleEditContact = (id: string) => () => {
    history.push(`${nextStep}/${id}`);
  };

  const handleDeleteContact = (id: string) => (e: React.MouseEvent) => {
    e.stopPropagation();
    const contacts = field.value.filter((contact: OnboardingContact, index: number) => index.toString() !== id);
    form.setFieldValue(field.name, contacts);
    dispatch(
      formActions.setMobileOnBoardingFormFields({
        fields: { contacts },
        step: undefined,
      })
    );
  };

  return (
    <MobileContactsOverviewWrapper>
      {field.value &&
        field.value.map((contact: OnboardingContact, index: number) => {
          const contactTypesFieldName = `${field.name}.${index}.types`;

          return (
            <ContactCard
              contact={contact}
              onClick={handleEditContact(index.toString())}
              key={contact.contactId}
              handleDelete={handleDeleteContact(index.toString())}
              canBeDeleted={index !== 0}
            >
              <ContactTypesSelect
                field={{ ...field, name: contactTypesFieldName, value: getIn(form.values, contactTypesFieldName) }}
                form={form}
                label={''}
              />
            </ContactCard>
          );
        })}
      <ArrayItemButton primary onClick={handleAddContact(field.value.length)}>
        {t('contacts.add')}
      </ArrayItemButton>
    </MobileContactsOverviewWrapper>
  );
};

const MobileContactsOverviewWrapper = styled.div``;
export default MobileContactsOverview;
