import { Input } from 'components';
import { ConvertedFile } from 'components/Dropzone/FileInput';
import FormikFileUpload from 'components/Form/FormikFileUpload';
import { TYPE_RADIO } from 'components/Form/Input';
import { BasicTextField, EditableItem, FullViewBlock } from 'components/FullView';
import { Field, FieldProps, FormikErrors, FormikTouched } from 'formik';
import { ORGANIZATIONS_URL } from 'modules/organization/api/enpoints';
import { GeneralInfoEditValues } from 'modules/organization/types/organizationDetail/general';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ErrorText } from 'components/Form/ErrorText';
import CountrySelect from 'components/Select/CountrySelect';
import { getNestedError } from 'helpers/formikHelper';
import CreatableKeywordSelect from '../../cti/CreatableKeywordSelect';
import { KeywordType } from 'modules/app/types';

type Props = {
  values: GeneralInfoEditValues;
  errors: FormikErrors<GeneralInfoEditValues>;
  touched: FormikTouched<GeneralInfoEditValues>;
};

const Misp: React.FunctionComponent<Props> = ({ values, touched, errors }: Props) => {
  const { t } = useTranslation('organization');

  const handleFileDownload = (id?: string) => () => {
    if (id) {
      window.location.href = `${process.env.REACT_APP_API_ROOT}${ORGANIZATIONS_URL}/files/${id}/download`;
    }
  };

  return (
    <FullViewBlock amountOfButtons={2}>
      <h2 className="block-title">{t('detail.blocks.misp')}</h2>
      <div className="block-data">
        <EditableItem
          label={t('detail.labels.mispToMisp')}
          value={
            <Field
              name="mispToMisp"
              render={({ field }: FieldProps) => (
                <RadioButtonsWrapper>
                  <Input
                    {...field}
                    type={TYPE_RADIO}
                    checked={field.value.toLowerCase() === 'false'}
                    value="false"
                    label={t('detail.mispToMispValues.false')}
                    name="mispToMisp"
                    id="mispToMisp_false"
                    onChange={(e) => field.onChange(e)}
                  />
                  <Input
                    {...field}
                    type={TYPE_RADIO}
                    checked={field.value.toLowerCase() === 'true'}
                    value="true"
                    label={t('detail.mispToMispValues.true')}
                    name="mispToMisp"
                    id="mispToMisp_true"
                  />
                  <Input
                    {...field}
                    type={TYPE_RADIO}
                    checked={field.value.toLowerCase() === 'null'}
                    value="null"
                    label={t('detail.mispToMispValues.null')}
                    name="mispToMisp"
                    id="mispToMisp_null"
                  />
                  {touched.mispToMisp && errors.mispToMisp && <ErrorText block>{errors.mispToMisp}</ErrorText>}
                </RadioButtonsWrapper>
              )}
            />
          }
        />
        {values.mispToMisp !== 'null' && values.mispToMisp === 'false' && (
          <EditableItem
            label={t('detail.labels.accountNeeded')}
            value={
              <Field
                name="accountNeeded"
                render={({ field }: FieldProps) => (
                  <RadioButtonsWrapper>
                    <Input
                      {...field}
                      type={TYPE_RADIO}
                      checked={field.value.toLowerCase() === 'false'}
                      value="false"
                      label={t('detail.mispExistingOrgValues.false')}
                      name="accountNeeded"
                      id="accountNeeded_false"
                      onChange={(e) => field.onChange(e)}
                    />
                    <Input
                      {...field}
                      type={TYPE_RADIO}
                      checked={field.value.toLowerCase() === 'true'}
                      value="true"
                      label={t('detail.mispExistingOrgValues.true')}
                      name="accountNeeded"
                      id="accountNeeded_true"
                    />
                    {touched.accountNeeded && errors.accountNeeded && (
                      <ErrorText block>{errors.accountNeeded}</ErrorText>
                    )}
                  </RadioButtonsWrapper>
                )}
              />
            }
          />
        )}
        {values.mispToMisp !== 'null' && (
          <React.Fragment>
            <BasicTextField
              name="mispOrganizationName"
              label={t('detail.labels.mispOrganizationName')}
              errors={errors}
              touched={touched}
            />
            <BasicTextField
              name="mispDescription"
              label={t('detail.labels.mispDescription')}
              errors={errors}
              touched={touched}
            />
            {(values.mispToMisp === 'true' || (values.mispToMisp === 'false' && values.accountNeeded === 'true')) && (
              <BasicTextField
                name="mispOrganizationGuid"
                label={t('detail.labels.mispOrganizationGuid')}
                errors={errors}
                touched={touched}
              />
            )}
            <EditableItem
              label={t('detail.labels.mispCountryId')}
              value={
                <Field
                  name={'mispCountryId'}
                  render={(fieldProps: FieldProps<GeneralInfoEditValues>) => (
                    <CountrySelect
                      {...fieldProps}
                      error={getNestedError('mispCountryId', errors, touched)}
                      errorAsBlock
                    />
                  )}
                />
              }
            />
            <EditableItem
              label={t('detail.labels.mispSectors')}
              value={
                <Field
                  name={'mispSectors'}
                  render={(fieldProps: FieldProps<GeneralInfoEditValues>) => (
                    <CreatableKeywordSelect {...fieldProps} keyWordType={KeywordType.MispSector} errorAsBlock />
                  )}
                />
              }
            />
            <BasicTextField
              name="mispPocEmail"
              label={t('detail.labels.mispEmail')}
              errors={errors}
              touched={touched}
            />
            <BasicTextField
              name="mispExtraEmail"
              label={t('detail.labels.mispExtraEmail')}
              errors={errors}
              touched={touched}
            />
            <EditableItem
              label={t('detail.labels.mispDomains')}
              value={
                <Field
                  name={'mispDomains'}
                  render={(fieldProps: FieldProps<GeneralInfoEditValues>) => (
                    <CreatableKeywordSelect {...fieldProps} keyWordType={KeywordType.MispEmailDomain} errorAsBlock />
                  )}
                />
              }
            />
            <EditableItem
              label={t('detail.labels.mispLogoFile')}
              value={
                <Field
                  name="mispLogoFile"
                  render={({ field, form }: FieldProps) => (
                    <FormikFileUpload
                      field={field}
                      form={form}
                      acceptedFileTypes={['image/*']}
                      getFileValues={(file: ConvertedFile) => ({
                        ...file,
                        mispLogoFileId: values.mispLogoFile && values.mispLogoFile.mispLogoFileId,
                      })}
                      isDownloadable
                      onFileClick={handleFileDownload(
                        values.mispLogoFile ? values.mispLogoFile.mispLogoFileId : undefined
                      )}
                    />
                  )}
                />
              }
            />
            <EditableItem
              label={t('detail.labels.mispPocPgpFileId')}
              value={
                <Field
                  name="mispPocPgpFile"
                  render={({ field, form }: FieldProps) => (
                    <FormikFileUpload
                      field={field}
                      form={form}
                      acceptedFileTypes={['.asc']}
                      getFileValues={(file: ConvertedFile) => ({
                        ...file,
                        mispPocPgpFileId: values.mispPocPgpFile && values.mispPocPgpFile.mispPocPgpFileId,
                      })}
                      isDownloadable
                      onFileClick={handleFileDownload(
                        values.mispPocPgpFile ? values.mispPocPgpFile.mispPocPgpFileId : undefined
                      )}
                    />
                  )}
                />
              }
            />
          </React.Fragment>
        )}
      </div>
    </FullViewBlock>
  );
};

const RadioButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 10px;
  }
`;

export default Misp;
