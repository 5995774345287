import { useToggle } from 'hooks';
import { useAccessRightsToFunctionality } from 'modules/security';
import { MODULE_READ } from 'modules/security/util';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { NOTIFICATIONS_BACKEND_MODULE_KEY } from '../constants';
import { makeGetNotificationsCount } from '../redux/selectors';
import NotificationDrawer from './NotificationDrawer';
import { Pill } from './Pill';

type RenderProps = {
  toggleDrawer: () => void;
  Count: React.FunctionComponent;
};

type Props = {
  children?: (props: RenderProps) => JSX.Element;
};

const Notifications: React.FunctionComponent<Props> = ({ children }) => {
  const [open, handleToggle] = useToggle(false);
  const hasAccess = useAccessRightsToFunctionality(MODULE_READ, NOTIFICATIONS_BACKEND_MODULE_KEY);
  const newNotifications = useSelector(makeGetNotificationsCount());
  const Count = () => (newNotifications ? <Pill>{newNotifications}</Pill> : null);

  return (
    <React.Fragment>
      {hasAccess && (
        <React.Fragment>
          {children && children({ toggleDrawer: handleToggle, Count })}
          <NotificationDrawer toggleDrawer={handleToggle} open={open} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Notifications;
