import styled from 'config/theme';

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
  max-height: 100vh;
  position: relative;
`;

export default PageWrapper;
