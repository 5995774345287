import { ModalBody, ModalContent, ModalFooter, ModalHeader } from '@unbrace/components';
import { Button, EmptyStateWrapper } from 'components';
import { ArrowRight } from 'components/icons';
import { isAfter } from 'date-fns';
import { formatAsDate } from 'helpers/dateFormatter';
import { makeIsPendingSelector } from 'modules/app/redux/selectors/loading';
import { DATETIME_REGEX } from 'modules/organization/constants';
import {
  ORGANIZATIONS_DIFFERENCE_ACTION_PREFIX,
  organizationDetailActions,
} from 'modules/organization/redux/actions/creators';
import {
  Difference,
  DifferenceGroup,
  DifferenceResponse,
} from 'modules/organization/types/organizationDetail/organizationDetail';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import DifferenceBadge from './DifferenceBadge';
import DifferenceLoadingState from './DifferenceLoadingState';

type Props = {
  organizationId: string;
  organizationDifference?: DifferenceResponse;
  closeModal?: VoidFunction;
};

const DifferenceModal = ({ organizationId, organizationDifference, closeModal }: Props) => {
  const { t } = useTranslation('organization');
  const dispatch = useDispatch();
  const isPending = useSelector(makeIsPendingSelector(ORGANIZATIONS_DIFFERENCE_ACTION_PREFIX));

  React.useEffect(() => {
    dispatch(organizationDetailActions.getDifference(organizationId));
  }, [dispatch, organizationId]);

  const isValidDate = (date: string) => {
    return Boolean(Date.parse(date) && isAfter(Date.parse(date), new Date('2002'))) && date.match(DATETIME_REGEX);
  };

  return (
    <ModalContent>
      <ModalHeader>
        <h1>{t('difference.title')}</h1>
        <h5> {t('difference.subTitle')}</h5>
      </ModalHeader>
      <ModalBody>
        {isPending ? (
          <DifferenceLoadingState />
        ) : (
          <React.Fragment>
            {organizationDifference && organizationDifference.differenceGroups.length > 0 ? (
              organizationDifference?.differenceGroups.map((item: DifferenceGroup, index: number) => (
                <React.Fragment key={`${item.type} - ${item.name}`}>
                  <DifferenceItem>
                    <p className="numberLabel">{`${index + 1}.`}</p>
                    <DifferenceBadge
                      organizationColor={
                        item.action === 'create' ? 'success' : item.action === 'delete' ? 'danger' : 'neutral'
                      }
                    >
                      {`${item.type} - ${item.name}`}
                    </DifferenceBadge>
                  </DifferenceItem>
                  {item.differences.map((difference: Difference, index2: number) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <DifferenceItem key={index2}>
                      <React.Fragment>
                        <p className="numberLabel" />
                        <p className="label">{t(`difference.types.${difference.changeType}`)}</p>
                      </React.Fragment>
                      {difference.oldValue && (
                        <DifferenceBadge organizationColor="danger">
                          {isValidDate(difference.oldValue)
                            ? formatAsDate(new Date(difference.oldValue))
                            : difference.oldValue}
                        </DifferenceBadge>
                      )}
                      {difference.oldValue && difference.newValue && (
                        <p className="icon">
                          <ArrowRight />
                        </p>
                      )}
                      {difference.newValue && (
                        <DifferenceBadge organizationColor="success">
                          {isValidDate(difference.newValue)
                            ? formatAsDate(new Date(difference.newValue))
                            : difference.newValue}
                        </DifferenceBadge>
                      )}
                    </DifferenceItem>
                  ))}
                </React.Fragment>
              ))
            ) : (
              <EmptyStateWrapper>
                <p>{t('difference.empty')}</p>
              </EmptyStateWrapper>
            )}
          </React.Fragment>
        )}
      </ModalBody>
      <ModalFooter>
        <Button onClick={closeModal} color="tertiary">
          {t('global:general.close')}
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};

export default DifferenceModal;

const DifferenceItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .label {
    font-weight: 600;
    font-size: 16px;
    width: 40%;
    color: black;

    &.small {
      width: 15%;
    }

    &.icon {
      margin-bottom: 20px;
    }
  }

  .numberLabel {
    font-weight: 500;
    font-size: 16px;
    width: 5%;
    color: black;
  }

  svg {
    margin-left: 10px;
    margin-right: 10px;
    height: 22px;
    width: 22px;
  }
`;
