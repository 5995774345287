import { MailDetail } from '../../types/mailDetail';
import { MAIL_SUCCESS } from '../actions/constants/mail';
import { MailActions } from '../actions/types/mail';

export type MailState = Partial<MailDetail>;

const mailReducer = (state: MailState = {}, action: MailActions) => {
  switch (action.type) {
    case MAIL_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export default mailReducer;
