import { call, put, select, takeLatest } from 'redux-saga/effects';
import { filterActions, inactiveUsersActions, pageInfoActions, sortingActions } from '../creators';
import {
  makeGetInactiveUsersFilters,
  makeGetInactiveUsersPageInfo,
  makeGetInactiveUsersSorting,
} from '../selectors/inactiveUsers';
import { formatSortingForApi } from 'helpers/sortingFormatter';
import { INACTIVE_USERS_FILTER_KEY } from '../../constants';
import { searchActions } from '../../../app/redux/creators';
import { getInactiveUsers, reminderUser } from '../../adapters/inactiveUsers';

function* inactiveUsersSaga() {
  yield takeLatest(
    [
      pageInfoActions.setPage,
      pageInfoActions.setPageSize,
      inactiveUsersActions.getInactiveUsers,
      sortingActions.setSorting,
      filterActions.setLastActiveDate,
      filterActions.setStatusFilter,
    ],
    handleGetInactiveUsers
  );

  yield takeLatest([pageInfoActions.setPageSize], handleGetInactiveUsersWithPage1);
  yield takeLatest(searchActions.setSearch, handleSearchChange);

  yield takeLatest(inactiveUsersActions.sendReminder, handleSendReminder);
}

function* handleGetInactiveUsers() {
  const { request, success, failure } = inactiveUsersActions.getInactiveUsersRequest;
  yield put(request());
  try {
    const filters = yield select(makeGetInactiveUsersFilters());
    const pageInfo = yield select(makeGetInactiveUsersPageInfo());
    const sorting = yield select(makeGetInactiveUsersSorting());

    const payload = yield call(getInactiveUsers, pageInfo, filters, formatSortingForApi(sorting));
    yield put(success(payload));
  } catch (e) {
    yield put(failure(e.response));
  }
}
function* handleGetInactiveUsersWithPage1() {
  yield put(pageInfoActions.setPage(1));
}

function* handleSearchChange(action: ReturnType<typeof searchActions.setSearch>) {
  if (action.payload.filterKey === INACTIVE_USERS_FILTER_KEY) {
    yield put(pageInfoActions.setPage(1));
  }
}

function* handleSendReminder(action: ReturnType<typeof inactiveUsersActions.sendReminder>) {
  const { payload, successCallback } = action;
  const { request, failure, success } = inactiveUsersActions.sendReminderRequest;

  yield put(request());

  try {
    yield call(reminderUser, payload);
    yield put(success());
    successCallback?.();
  } catch (e) {
    yield put(failure(e.response));
  }
}

export default inactiveUsersSaga;
