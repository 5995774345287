import { Sector } from '../../app/types';

export type Organization = {
  alertFeedEmail: string;
  alertPhone: string;
  guid?: string;
  maxUsers: number;
  name: string;
  optIn: boolean;
  organizationId: string;
  sectors: Sector[];
  status: OrganizationStatus;
  tlsEmail: string;
  userCount: number;
};

export type OrganizationFilters = {
  search?: string;
  sector?: OrganizationSector[];
  status?: OrganizationStatus[];
};

export enum OrganizationStatus {
  Activated = 'activated',
  Pending = 'pending',
  ReEvaluation = 'reEvaluate',
  Suspended = 'suspended',
  ToCorrect = 'toCorrect',
  Waiting = 'waiting',
  New = 'new',
}

export type OrganizationSector = {
  name: string;
  sectorId: string;
};

export type AddOrganizationFormValues = {
  emailAdminUser: string;
  maxUsers: number;
  name: string;
  sectors: OrganizationSector[];
};

export type AddOrganizationValues = {
  emailAdminUser: string;
  maxUsers: number;
  name: string;
  sectors: string[];
};

export type EditOrganizationValues = {
  maxUsers: number;
  name: string;
  sectors: OrganizationSector[];
};

export enum UpdateStep {
  needsUpdate = 'needsUpdate',
  generalInfoCompleted = 'generalInfoCompleted',
  contactsCompleted = 'contactsCompleted',
  allUpToDate = 'allUpToDate',
}

export type OrganizationUpdateStep = {
  lastUpdate: Date;
  organizationId: string;
  updateStep: UpdateStep;
};
