import { Account } from '../../security/types/user';
import { DesktopStep, StepType } from '../types';
import { contactsStep } from './contact/contacts';
import { contactStep } from './contact/howToReach';
import { contactHoursStep } from './contact/contactHours';
import { mobileContactsStep } from './contact/mobileContacts';
import { mobileProfileStep } from './contact/mobileProfile';
import { certificatesStep } from './ctiData/certificates';
import { domainInformationStep } from './ctiData/domainInfo';
import { emailDomainStep } from './ctiData/emailDomains';
import { ipv4Step } from './ctiData/ipVersions';
import { keywordsStep } from './ctiData/keywords';
import { addressesStep } from './generalInfo/addresses';
import { alertFeedStep } from './generalInfo/alertFeed';
import { generalIpVersions } from './generalInfo/generalIpVersions';
import { mispStep } from './generalInfo/misp';
import { tlsStep } from './generalInfo/tls';
import { ARRAY, CONTACTS, CTI_DATA, GENERAL_DATA, USER_DATA } from './index';
import { userStep } from './user';
import { publicIpVersions } from './generalInfo/publicIpVersions';

export const desktopSteps: DesktopStep[] = [
  { steps: [addressesStep] },
  { submitParam: GENERAL_DATA, steps: [tlsStep, alertFeedStep, mispStep, generalIpVersions, publicIpVersions] },
  {
    stepType: ARRAY,
    steps: [contactsStep],
    submitParam: CONTACTS,
  },
  { submitParam: CTI_DATA, steps: [domainInformationStep, emailDomainStep, ipv4Step, certificatesStep, keywordsStep] },
];

export const mobileSteps: StepType[] = [
  addressesStep,
  tlsStep,
  alertFeedStep,
  mispStep,
  generalIpVersions,
  mobileContactsStep,
  mobileProfileStep,
  contactStep,
  contactHoursStep,
  domainInformationStep,
  emailDomainStep,
  ipv4Step,
  certificatesStep,
  keywordsStep,
];

export const adminSteps = {
  desktopSteps,
  mobileSteps,
};

export const userSteps = {
  desktopSteps: [{ submitParam: USER_DATA, steps: [userStep] }],
  mobileSteps: [userStep],
};

export const getSteps = (account: Account | undefined) =>
  account && account.userType === 'admin' ? adminSteps : userSteps;
