import { Input } from 'components';
import Table, { TableColumn } from 'components/Table/Table';
import styled from 'config/theme';
import { MultiSelectActions } from 'hooks/useMultiSelect';
import { makeIsPendingSelector } from 'modules/app/redux/selectors/loading';
import useSuperAdminStore from 'modules/app/redux/services/useSuperAdminStore';
import { OrganizationAccessRights } from 'modules/organization/constants';
import {
  ORGANIZATION_DETAIL_USERS_ACTION_PREFIX,
  organizationDetailActions,
} from 'modules/organization/redux/actions/creators';
import { User } from 'modules/organization/types/organizationDetail/users';
import { IdentityAccessRights, useAccessRightsToFunctionality, useAccountInfo } from 'modules/security';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { UserType } from '../../../../../security/types/user';
import UserRow from './UserRow';
import UsersEmptyState from './UsersEmptyState';
import UsersLoadingState from './UsersLoadingState';

type Props = {
  id: string;
  users: User[] | null;
  selectedUsers: User[];
  selectedUsersActions: MultiSelectActions;
};

const UsersInfo: React.FunctionComponent<Props> = ({ id, users, selectedUsers, selectedUsersActions }: Props) => {
  const { t } = useTranslation('organization');
  const dispatch = useDispatch();
  const accountInfo = useAccountInfo();

  const isPending = useSelector(makeIsPendingSelector(ORGANIZATION_DETAIL_USERS_ACTION_PREFIX));
  const hasAccessToReset = useAccessRightsToFunctionality(IdentityAccessRights.IdentityReset);
  const hasAccessToResendInvitation = useAccessRightsToFunctionality(OrganizationAccessRights.InviteUsers);
  const hasAccessToUpgradeUser = useAccessRightsToFunctionality(IdentityAccessRights.UpgradeUser);
  const { isCompanyAllowedToUpgradeUser } = useSuperAdminStore();

  const isNotSuperAdmin = accountInfo?.userType !== UserType.SuperAdmin;

  React.useEffect(() => {
    if (!users) {
      dispatch(organizationDetailActions.getUsers(id));
    }
  }, [id, dispatch, users]);

  const tableColumnProps: TableColumn[] = isNotSuperAdmin
    ? [
        { title: t('detail.labels.name'), size: 'auto' },
        { title: t('detail.labels.email'), size: 'auto' },
        { title: t('detail.labels.type'), size: 110, align: 'left' },
        { title: t('detail.labels.departement'), size: 150, align: 'left' },
        { title: t('detail.labels.status'), size: 150, align: 'center' },
      ]
    : [
        {
          size: 52,
          title: (
            <Input
              type="checkbox"
              name="select_all"
              key="select_all"
              onChange={selectedUsersActions.selectAll}
              checked={users ? selectedUsers.length === users.length : false}
            />
          ),
        },
        { title: t('detail.labels.name'), size: 'auto' },
        { title: t('detail.labels.email'), size: 'auto' },
        { title: t('detail.labels.type'), size: 110, align: 'left' },
        { title: t('detail.labels.departement'), size: 150, align: 'left' },
        { title: t('detail.labels.status'), size: 150, align: 'center' },
      ];

  if (hasAccessToReset || hasAccessToResendInvitation || hasAccessToUpgradeUser) {
    let resetWidth = 44;
    if (hasAccessToReset) {
      resetWidth = resetWidth + 44;
    }
    if (hasAccessToResendInvitation) {
      resetWidth = resetWidth + 44;
    }
    if (hasAccessToUpgradeUser && isCompanyAllowedToUpgradeUser) {
      resetWidth = resetWidth + 44;
    }
    const spacings = 31;
    tableColumnProps.push({ title: '', size: resetWidth + spacings, align: 'right' });
  }

  return (
    <Wrapper>
      {!isPending && users && users.length === 0 ? (
        <UsersEmptyState />
      ) : !users || (isPending && users.length === 0) ? (
        <UsersLoadingState />
      ) : (
        <Table columns={tableColumnProps}>
          {users.map((user) => (
            <UserRow
              user={user}
              key={user.userId}
              selected={Boolean(selectedUsers.find((u) => u.userId === user.userId))}
              toggleIsSelected={selectedUsersActions.toggleId}
            />
          ))}
        </Table>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  border-top: ${(props) => props.theme.fullView.border};
  padding: 20px 0;
`;

export default UsersInfo;
