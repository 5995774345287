import { FieldProps, getIn } from 'formik';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FormikSelect } from '../../../../components/Form';
import { getNestedError } from '../../../../helpers/formikHelper';
import { IpRangeFormat } from '../../../app/types';

type Props = {
  label: string;
} & FieldProps;

const IpFormatSelect: React.FunctionComponent<Props> = ({ field, form, label }: Props) => {
  const { t } = useTranslation('global');
  const ipFormats = [
    {
      label: t('ipFormats.cidr'),
      value: IpRangeFormat.Cidr,
    },
    {
      label: t('ipFormats.range'),
      value: IpRangeFormat.Range,
    },
    {
      label: t('ipFormats.CommaSeparated'),
      value: IpRangeFormat.CommaSeparated,
    },
  ];

  return (
    <FormikSelect
      field={field}
      form={form}
      label={label}
      value={getIn(form.values, field.name)}
      getOptionValue={option => option.value}
      getOptionLabel={option => option.label}
      error={getNestedError(field.name, form.errors, form.touched)}
      options={ipFormats}
    />
  );
};

export default IpFormatSelect;
