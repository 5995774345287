import { createSelector } from 'reselect';
import { RootState } from '../../../../config/store/rootReducer';
import { PaginationInfo, SortValue } from '../../../../types';
import { ReportType, ReportTypefilters } from '../../types/reportTypes';

const getFilters = (state: RootState): ReportTypefilters => state.reportType.filters;
export const makeGetReportTypes = () =>
  createSelector(getReportTypes, (reportTypes: ReportType[] | null) => reportTypes);

const getPaginationInfo = (state: RootState): PaginationInfo => state.reportType.pageInfo;
export const makeGetReportTypeFilters = () =>
  createSelector(getFilters, (reportTypes: ReportTypefilters) => reportTypes);

const getReportTypes = (state: RootState): ReportType[] | null => state.reportType.reportTypes;
export const makeGetReportTypePageInfo = () =>
  createSelector(getPaginationInfo, (pageInfo: PaginationInfo) => pageInfo);

const getSortingInfo = (state: RootState): SortValue[] => state.reportType.sorting;
export const makeGetReportTypeSorting = () => createSelector(getSortingInfo, (sorting: SortValue[]) => sorting);
