import styled, { css } from 'styled-components';
import { Input } from 'components';

export const ReadUnreadIndicator = styled.div`
  ${({ theme }) => css`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${theme.palette.primary.shade5};
  `};
`;

export const StyledInput = styled(Input)`
  margin: 4px 0 0;
  :disabled {
    cursor: pointer;
    background: #ffffff;
  }
  &&& {
    padding-right: 10px;
  }
`;
