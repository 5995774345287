import { Module } from 'modules/security/types/modules';
import * as React from 'react';
import { Translation } from 'react-i18next';
import NavigationLink, { StyledNavigationLink } from './NavigationLink';
import { ImageFactory } from './NavigationSidebar';

type Props = {
  module: Module;
  isLaptopSM?: boolean;
  isTablet?: boolean;
};

type State = {
  isOpen: boolean;
};

class SubNavigation extends React.Component<Props, State> {
  state = {
    isOpen: true,
  };

  toggleNavigationItems = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    this.setState((prevState: State) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  openNavigationItems = (e: React.FocusEvent<HTMLAnchorElement>) => {
    this.setState({
      isOpen: true,
    });
  };

  render(): JSX.Element {
    const { module, children: icon, isLaptopSM, isTablet } = this.props;

    return (
      <StyledNavigationLink isOpen={this.state.isOpen} isLaptopSM={isLaptopSM} isTablet={isTablet}>
        <a href="/" onClick={this.toggleNavigationItems}>
          {icon}
          <Translation ns="global">{t => <span>{t(`sidebar.${module.key}`)}</span>}</Translation>
        </a>
        <ul>
          {(module.subModules as Module[]).map((subModule: Module) => (
            <NavigationLink
              key={`${module.key}/${subModule.key}`}
              to={`${subModule.key}`}
              onFocus={this.openNavigationItems}
            >
              {ImageFactory[subModule.key]}
            </NavigationLink>
          ))}
        </ul>
      </StyledNavigationLink>
    );
  }
}

export default SubNavigation;
