import * as React from 'react';
import styled from '../../config/theme';

type Props = {
  text: string;
  action?: () => void;
  icon?: JSX.Element;
  closeMenu?: () => void;
  disabled?: boolean;
};

const ContextMenuItem: React.FunctionComponent<Props> = ({ text, action, icon, closeMenu, disabled }: Props) => {
  const handleClick = React.useCallback(() => {
    if (action && closeMenu && !disabled) {
      action();
      closeMenu();
    }
  }, [action, closeMenu, disabled]);

  return (
    <ContextMenuItemWrapper disabled={disabled} onClick={handleClick}>
      {icon}
      <p>{text}</p>
    </ContextMenuItemWrapper>
  );
};

const ContextMenuItemWrapper = styled('div')<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
    background-color: ${props => props.theme.contextMenu.color.blockItemHover};
  }

  > p {
    font-size: ${props => props.theme.contextMenu.fontSize.blockItem};
    color: ${props => (props.disabled ? props.theme.contextMenu.color.disabled : 'inherit')};
  }

  > span > svg {
    height: ${props => props.theme.contextMenu.fontSize.blockItemIcon};
    margin-right: 15px;
  }
`;

export default ContextMenuItem;
