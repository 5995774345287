import repositoriesReducer, { RepositoryState } from 'modules/repository/redux/reducers/repositories';
import { combineReducers } from 'redux';

export type RepositoryModuleState = {
  repository: RepositoryState;
};

const organizationModuleReducer = combineReducers({
  repository: repositoriesReducer,
});

export default organizationModuleReducer;
