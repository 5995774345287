import { FlexCol, FlexRow, Paper } from 'components';
import { useMultiSelect } from 'hooks';
import { makeIsPendingSelector } from 'modules/app/redux/selectors/loading';
import {
  ORGANIZATION_DETAIL_GENERAL_INFO_ACTION_PREFIX,
  organizationDetailActions,
} from 'modules/organization/redux/actions/creators';
import { makeGetGeneralInfo, makeGetUsers } from 'modules/organization/redux/selectors/organizationDetail';
import { OrganizationDetailView } from 'modules/organization/types/organizationDetail/organizationDetail';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OrganizationDetailHeader from '../header';
import OrganizationDetailSidebar from '../sidebar';
import ContactInfo from './contacts/ContactInfo';
import CTIInfo from './cti/CTIInfo';
import GeneralInfo from './generalInfo/GeneralInfo';
import Heading from './Heading';
import HeadingLoadingState from './HeadingLoadingState';
import UsersInfo from './users/UsersInfo';

type Props = {
  activeView: OrganizationDetailView;
  id: string;
};

const Organization: React.FunctionComponent<Props> = ({ activeView, id }: Props) => {
  const dispatch = useDispatch();
  const generalInfoData = useSelector(makeGetGeneralInfo());
  const generalInfoPending = useSelector(makeIsPendingSelector(ORGANIZATION_DETAIL_GENERAL_INFO_ACTION_PREFIX));
  const [editScreenHasChanges, setEditScreenHasChanges] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const formRef = React.useRef(null);
  const users = useSelector(makeGetUsers());
  const [selectedUsers, selectedUsersActions] = useMultiSelect(users || [], 'userId');

  React.useEffect(() => {
    dispatch(organizationDetailActions.getGeneralInfo(id));
  }, [id, dispatch]);

  React.useEffect(() => {
    dispatch(organizationDetailActions.clearOrganizationDetail());
  }, [dispatch, id]);

  return (
    <FlexRow>
      <OrganizationDetailSidebar organizationId={id} />

      <FlexCol>
        <OrganizationDetailHeader
          activeView={activeView}
          edited={editScreenHasChanges}
          setEdited={setEditScreenHasChanges}
          editMode={editMode}
          setEditMode={setEditMode}
          organizationId={generalInfoData?.organizationId}
          organizationDifference={generalInfoData?.difference}
          organizationName={generalInfoData?.name}
          maxUsers={generalInfoData?.maxUsers}
          status={generalInfoData ? generalInfoData.status : undefined}
          formRef={formRef}
          selectedUsers={selectedUsers}
          selectedUsersActions={selectedUsersActions}
        />
        <Paper>
          {!generalInfoPending && generalInfoData ? (
            <Heading
              name={generalInfoData.name}
              sectors={generalInfoData.sectors}
              status={generalInfoData.status}
              maxUsers={generalInfoData.maxUsers}
              userViewActive={activeView === OrganizationDetailView.USERS}
            />
          ) : (
            <HeadingLoadingState />
          )}
          {activeView === OrganizationDetailView.GENERAL_INFO && (
            <GeneralInfo
              generalInfo={generalInfoData}
              isPending={generalInfoPending}
              editMode={editMode}
              setHasChanges={setEditScreenHasChanges}
              formRef={formRef}
              organizationId={generalInfoData ? generalInfoData.organizationId : '0'}
              setEditMode={setEditMode}
              hasChanges={editScreenHasChanges}
            />
          )}
          {activeView === OrganizationDetailView.CONTACTS && (
            <ContactInfo
              editMode={editMode}
              setHasChanges={setEditScreenHasChanges}
              formRef={formRef}
              organizationId={id}
              setEditMode={setEditMode}
              businessLocations={generalInfoData ? generalInfoData.businessLocations : []}
              hasChanges={editScreenHasChanges}
            />
          )}
          {activeView === OrganizationDetailView.CTI && (
            <CTIInfo
              editMode={editMode}
              setHasChanges={setEditScreenHasChanges}
              formRef={formRef}
              organizationId={id}
              setEditMode={setEditMode}
              hasChanges={editScreenHasChanges}
            />
          )}
          {activeView === OrganizationDetailView.USERS && (
            <UsersInfo
              id={id}
              users={users}
              selectedUsers={selectedUsers}
              selectedUsersActions={selectedUsersActions}
            />
          )}
        </Paper>
      </FlexCol>
    </FlexRow>
  );
};

export default Organization;
