import { useSelector } from 'react-redux';
import { makeGetAccessRights, makeGetAccount } from '../redux/selectors/user';
import { GenericAccount, UserType } from '../types/user';
import { hasAccessToFunctionality } from '../util';

export const useAccessRightsToFunctionality = (accessCode: string, valueToCheck?: string): boolean => {
  const accessRights = useSelector(makeGetAccessRights());

  return hasAccessToFunctionality(accessCode, accessRights, valueToCheck);
};

export type AccountInfo = {
  email: string;
  guid: string;
  name: string;
  organizationId: string;
  userType: UserType;
  userFirstName: string;
  userLastName: string;
};

export const useAccountInfo = (): GenericAccount | undefined => {
  const account = useSelector(makeGetAccount());

  return account ? { ...account } : undefined;
};
