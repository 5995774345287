import { call, put, takeEvery } from '@redux-saga/core/effects';
import certificatesApi from '../../api/certificatesApi';
import { CertificatesAction, certificatesActions } from '../creators';

function* certificatesSaga() {
  yield takeEvery(certificatesActions.postCertificates, handleUploadCertificate);
}

function* handleUploadCertificate(action: CertificatesAction) {
  const { success, failure } = certificatesActions.postCertificatesRequest;
  try {
    const payload = yield call(certificatesApi.uploadCertificates, action.payload);
    yield put(success(payload));
  } catch (e) {
    yield put(failure(e));
  }
}

export default certificatesSaga;
