import { UserStatus } from 'modules/organization/types/organizationDetail/users';
import { createAction } from 'typesafe-actions';

export const setLastActiveDate = createAction(
  'INACTIVE_USER_SET_LAST_ACTIVE_DATE',
  (action) => (value: Date | undefined) => action(value)
);

export const setStatusFilter = createAction(
  'INACTIVE_USER_SET_FILTER_STATUS',
  (action) => (status: UserStatus) => action(status)
);
