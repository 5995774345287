import { Repository, RepositoryFilters } from 'modules/repository/types/repository';
import { combineReducers } from 'redux';
import { PaginationInfo, SortValue } from 'types';
import filterReducer from './filters';
import pageInfoReducer from './pageInfo';
import repositoryReducer from './repository';
import sortingReducer from './sorting';

export type RepositoryState = {
  filters: RepositoryFilters;
  pageInfo: PaginationInfo;
  repositories: Repository[] | null;
  sorting: SortValue[];
};

const repositoriesReducer = combineReducers({
  filters: filterReducer,
  pageInfo: pageInfoReducer,
  repositories: repositoryReducer,
  sorting: sortingReducer,
});

export default repositoriesReducer;
