import { createSelector } from 'reselect';
import { RootState } from 'config/store/rootReducer';
import { ActivitiesFilters, Activity } from '../../types';
import { PaginationInfo, SortValue } from 'types';

const getActivities = (state: RootState): Activity[] | null => state.activities.activities;
export const makeGetActivities = () => createSelector(getActivities, (activities: Activity[] | null) => activities);

const getFilters = (state: RootState): ActivitiesFilters => state.activities.filters;
export const makeGetActivitiesFilters = () => createSelector(getFilters, (activities: ActivitiesFilters) => activities);

const getPaginationInfo = (state: RootState): PaginationInfo => state.activities.pageInfo;
export const makeGetActivitiesPaginationInfo = () =>
  createSelector(getPaginationInfo, (pageInfo: PaginationInfo) => pageInfo);

const getSortingInfo = (state: RootState): SortValue[] => state.activities.sorting;
export const makeGetActivitiesSorting = () => createSelector(getSortingInfo, (sorting: SortValue[]) => sorting);
