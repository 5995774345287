import * as React from 'react';
import styled from 'styled-components';
import { SkeletonGenerator, ContentLoader } from 'components';

const DifferenceLoadingState: React.FC = () => (
  <section style={{ maxWidth: 600 }}>
    <SkeletonGenerator count={10}>
      <LoadingWrapper>
        <ContentLoader height={30}>
          <rect x="1" y="1" rx="2" ry="2" width="150" height="15" />
        </ContentLoader>
      </LoadingWrapper>
    </SkeletonGenerator>
  </section>
);

const LoadingWrapper = styled.div`
  max-width: 1000px;
`;

export default DifferenceLoadingState;
