export const DEFAULT_PAGE_SIZE = 20;
export const PAGE_SIZES = [10, 20, 50, 100];

export const DEFAULT_PAGEINFO = {
  hasNextPage: true,
  hasPreviousPage: false,
  pageIndex: 1,
  pageSize: DEFAULT_PAGE_SIZE,
  totalItems: 200,
  totalPages: 20,
};
