import { useDispatch, useSelector } from 'react-redux';
import { getType } from 'typesafe-actions';
import { useActionIsPending } from '../../../../hooks';
import { SuccessCallback } from '../../../../types';
import { EditOrganizationValues } from '../../types/organization';
import { organizationDetailActions } from '../actions/creators';
import { makeGetUsers, makeGetGeneralInfo } from '../selectors/organizationDetail';

const useOrganizationDetail = () => {
  const dispatch = useDispatch();

  const organization = useSelector(makeGetGeneralInfo());
  const organizationUsers = useSelector(makeGetUsers());

  const [orgIsUpdating, startOrgIsUpdating] = useActionIsPending(getType(organizationDetailActions.updateOrganization));
  const updateOrganization = (id: string, values: EditOrganizationValues, callback: SuccessCallback) => {
    startOrgIsUpdating();
    dispatch(organizationDetailActions.updateOrganization(id, values, callback));
  };

  return {
    organization,
    organizationUsers,
    orgIsUpdating,
    startOrgIsUpdating,
    updateOrganization,
  };
};

export default useOrganizationDetail;
