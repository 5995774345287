import * as React from 'react';
import { Route, Switch } from 'react-router';
import OauthRoutes from '../routes/OauthRoutes';
import { LoginRedirectService } from '../services';
import { Modules } from '../types/modules';

type RenderCallBackProps = {
  modules: Modules;
  isAuthenticated: boolean;
};

type RenderCallback = ({ modules, isAuthenticated }: RenderCallBackProps) => JSX.Element;

type Props = {
  children: RenderCallback;
};

class UnauthenticatedFlow extends React.Component<Props> {
  render(): JSX.Element {
    const { children } = this.props;

    return (
      <Switch>
        <Route path={'/oauth'} render={() => <OauthRoutes />} />
        <Route
          render={() => (
            <LoginRedirectService>{children({ isAuthenticated: false, modules: [] })}</LoginRedirectService>
          )}
        />
      </Switch>
    );
  }
}

export default UnauthenticatedFlow;
