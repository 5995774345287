import { FieldProps, getIn } from 'formik';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FormikSelect } from '../../../../components/Form';
import { getNestedError } from '../../../../helpers/formikHelper';
import { makeGetGeneralDataFields } from '../../redux/selectors/onBoarding';
import { BusinessLocation } from '../../types/onboarding';

type Props = { label: string } & FieldProps;

const BusinessLocationsSelect: React.FunctionComponent<Props> = ({ field, form, label }) => {
  const generalDataFields = useSelector(makeGetGeneralDataFields());
  const { businessLocations } = generalDataFields;
  const { t } = useTranslation('onboarding');

  return (
    <FormikSelect
      field={field}
      form={form}
      isMulti={true}
      label={t(label)}
      value={getIn(form.values, field.name)}
      getOptionValue={(option: BusinessLocation) => option.addressId.toString()}
      getOptionLabel={(option) => option.name}
      error={getNestedError(field.name, form.errors, form.touched)}
      options={businessLocations as BusinessLocation[]}
    />
  );
};

export default BusinessLocationsSelect;
