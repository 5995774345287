import styled from 'config/theme';
import { media } from 'config/theme/utils';

// fixed button position will prevent responsive button repositioning (because of labels changing from left to top on smaller devices)
// this is useful for items without a label
const ArrayHelperActionWrapper = styled.div<{ fixedButtonPosition?: boolean }>`
  display: flex;
  position: relative;
  justify-content: flex-end;

  > :first-child {
    width: 100%;
  }

  > button {
    position: absolute;
    left: calc(100% + 8px);
    top: 3px;

    ${props => !props.fixedButtonPosition && media.mobile`top: 32px;`}
    ${props => !props.fixedButtonPosition && media.tablet`top: 32px;`}
    ${props => !props.fixedButtonPosition && media.laptopSM`top: 32px;`}

    &:nth-child(3) {
      left: calc(100% + 58px);
    }
  }
`;

export default ArrayHelperActionWrapper;
