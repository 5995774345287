import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const Difference: React.FC = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.0949 17.9052H3.71545V46.2846H32.0949V17.9052ZM27.7572 22.2428H8.05307V41.947H27.7572V22.2428Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.2846 3.71545H17.9052V32.0949H46.2846V3.71545ZM41.9511 8.04893H22.2386V27.7614H41.9511V8.04893Z"
      />
    </svg>
  </BaseSvgWrapper>
);

export default Difference;
