import AxiosConfig, { RequestConfig } from '../../../config/api/AxiosConfig';
import { IDENTITY_URL } from './endpoints';

class IdentityApi {
  public async resetAccountPassword() {
    const requestConfig: RequestConfig = {
      url: `${IDENTITY_URL}/reset-password`,
    };

    return AxiosConfig.fetchAxiosPostRequest(requestConfig);
  }

  public async resetUserPassword(userGuid: string) {
    const requestConfig: RequestConfig = {
      url: `${IDENTITY_URL}/${userGuid}/reset-password`,
    };

    return AxiosConfig.fetchAxiosPostRequest(requestConfig);
  }

  public async resetUserAuthenticator(userGuid: string) {
    const requestConfig: RequestConfig = {
      url: `${IDENTITY_URL}/${userGuid}/reset-authenticator`,
    };

    return AxiosConfig.fetchAxiosPostRequest(requestConfig);
  }
}

export default new IdentityApi();
