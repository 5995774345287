import { call, put, select, takeLatest } from '@redux-saga/core/effects';
import globalInfoApi from '../../api/globalInfoApi';
import { globalInfoActions } from '../creators';
import { makeGetCountriesSelector } from '../selectors/globalInfo';

function* globalInfoSaga() {
  yield takeLatest(globalInfoActions.getCountries, handleGetCountries);
}

function* handleGetCountries() {
  const countries = yield select(makeGetCountriesSelector());

  if (countries.length > 0) {
    return;
  }

  yield put(globalInfoActions.getCountriesRequest.request());

  try {
    const payload = yield call(globalInfoApi.getCountries);
    yield put(globalInfoActions.getCountriesRequest.success(payload));
  } catch (e) {
    yield put(globalInfoActions.getCountriesRequest.failure(e.response));
  }
}

export default globalInfoSaga;
