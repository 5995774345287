import { createAction } from 'typesafe-actions';
import { Organization, Type } from '../../types';

export const setTypeFilter = createAction('ACTIVITIES_SET_FILTER_TYPE', action => (types: Type[]) => action(types));

export const setOrganizationFilter = createAction(
  'ACTIVITIES_SET_FILTER_ORGANIZATION)',
  action => (organizations: Organization[]) => action(organizations)
);

export const setDateFromFilter = createAction('ACTIVITIES_SET_FILTER_DATE_FROM', action => (date?: Date) =>
  action(date)
);

export const setDateUntilFilter = createAction('ACTIVITIES_SET_FILTER_DATE_UNTIL', action => (date?: Date) =>
  action(date)
);
