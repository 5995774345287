import { Button, ModalContent, SubmitButton } from 'components';
import { useActionIsPending } from 'hooks';
import { organizationDetailActions } from 'modules/organization/redux/actions/creators';
import { AddUserValues } from 'modules/organization/types/organizationDetail/organizationDetail';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getType } from 'typesafe-actions';
import AddUserForm from './AddUserForm';

type Props = {
  closeModal: () => void;
  organizationId: string;
  isAdmin?: boolean;
};

const AddUserModal: React.FunctionComponent<Props> = ({ closeModal, organizationId, isAdmin }: Props) => {
  const { t } = useTranslation('organization');
  const formRef: React.RefObject<any> = React.useRef(null);
  const dispatch = useDispatch();
  const [isAdding, startAdding] = useActionIsPending(getType(organizationDetailActions.addUser));
  const [isAdminAdding, startAdminAdding] = useActionIsPending(getType(organizationDetailActions.addAdmin));

  const [formEdited, setFormEdited] = React.useState(false);

  const handleFormChange = React.useCallback(() => setFormEdited(true), []);

  const handleCancelClick = React.useCallback(() => {
    formRef.current.resetForm();
    setFormEdited(false);
    closeModal();
  }, [closeModal, formRef, setFormEdited]);

  const handleSubmitClick = React.useCallback(() => {
    formRef.current.handleSubmit();
  }, [formRef]);

  const handleSubmit = React.useCallback(
    (values: AddUserValues) => {
      startAdding();
      dispatch(
        organizationDetailActions.addUser(organizationId, values, () => {
          setFormEdited(false);
          closeModal();
        })
      );
    },
    [dispatch, setFormEdited, closeModal, organizationId, startAdding]
  );

  const handleAdminSubmit = React.useCallback(
    (values: AddUserValues) => {
      startAdminAdding();
      dispatch(
        organizationDetailActions.addAdmin(organizationId, values, () => {
          setFormEdited(false);
          closeModal();
        })
      );
    },
    [dispatch, setFormEdited, closeModal, organizationId, startAdminAdding]
  );

  return (
    <ModalContent>
      <ModalContent.Header>
        <h1>{isAdmin ? t('detail.userModal.addAdminTitle') : t('detail.userModal.addTitle')}</h1>
      </ModalContent.Header>
      <ModalContent.Body>
        <AddUserForm
          onSubmit={isAdmin ? handleAdminSubmit : handleSubmit}
          formRef={formRef}
          onFormChange={handleFormChange}
          isAdmin={isAdmin}
        />
      </ModalContent.Body>
      <ModalContent.Footer>
        <Button disabled={isAdding} onClick={handleCancelClick} tertiary>
          {t('global:general.close')}
        </Button>
        <SubmitButton
          isLoading={isAdmin ? isAdminAdding : isAdding}
          onClick={handleSubmitClick}
          primary
          type="submit"
          disabled={!formEdited}
        >
          {isAdmin ? t('detail.userModal.adminSave') : t('detail.userModal.save')}
        </SubmitButton>
      </ModalContent.Footer>
    </ModalContent>
  );
};

export default AddUserModal;
