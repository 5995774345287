import { FlexRow, SortToggle } from 'components';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { SortValue } from 'types';
import { Action } from 'typesafe-actions';
import { SortState } from './SortToggle';
import { TableColumn } from './Table';

type Props = {
  column: TableColumn;
  sortingActionCreator?: (name: string, state: SortState, secondary?: boolean) => Action;
  sortValue?: SortValue;
};

const nextState = (currentState: SortState): SortState => {
  switch (currentState) {
    case SortState.Asc:
      return SortState.Desc;
    case SortState.Desc:
      return SortState.None;
    case SortState.None:
      return SortState.Asc;
    default:
      return SortState.None;
  }
};

const Th: React.FunctionComponent<Props> = ({
  column: { sortable, title, customSortingName, align },
  sortingActionCreator,
  sortValue,
}: Props) => {
  const dispatch = useDispatch();
  const changeHandler = React.useCallback(
    (name: string, state: SortState, secondary?: boolean) => {
      if (sortingActionCreator && sortable) {
        dispatch(sortingActionCreator(name, state, secondary));
      }
    },
    [sortingActionCreator, dispatch, sortable]
  );

  const clickHandler = React.useCallback(
    (e: React.MouseEvent) => {
      const value = sortValue || {
        name: (customSortingName || title) as string,
        state: SortState.None,
      };
      changeHandler(value.name, nextState(value.state), e.shiftKey);
    },
    [sortValue, changeHandler, customSortingName, title]
  );

  return (
    <StyledTH onClick={clickHandler} sortable={sortable}>
      {sortable ? (
        <FlexRow
          alignItems="center"
          justifyContent={align === 'right' ? 'flex-end' : align === 'center' ? align : 'flex-start'}
        >
          <div>{title}</div>
          <SortToggle state={sortValue?.state} />
        </FlexRow>
      ) : (
        title
      )}
    </StyledTH>
  );
};

export default Th;

const StyledTH = styled.th<{ sortable?: boolean }>`
  ${({ theme, sortable }) => css`
    position: relative;

    &:hover {
      cursor: ${sortable ? 'pointer' : 'default'};

      div {
        color: ${theme.table.color.headerHover};
      }
    }
  `}
`;
