import { Close, Download } from 'components/icons';
import styled from 'config/theme';
import * as React from 'react';

type Props = {
  id: string;
  name: string;
  handleDelete?: (id: string) => void;
  onDownload?: () => void;
  isDownloadable?: boolean;
  canDelete?: boolean;
};
const FileItem: React.FunctionComponent<Props> = ({
  id,
  name,
  handleDelete,
  onDownload,
  isDownloadable,
  canDelete = true,
}) => {
  const handleDeleteClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      if (handleDelete) {
        handleDelete(id);
      }
    },
    [handleDelete, id]
  );

  return (
    <FileItemWrapper>
      <p>{name}</p>
      {onDownload && isDownloadable && <Download className="primary" onClick={onDownload} />}
      {canDelete && <Close className="danger" onClick={handleDeleteClick} />}
    </FileItemWrapper>
  );
};

const FileItemWrapper = styled.div`
  display: flex;
  width: fit-content;
  padding: 5px 10px;
  align-items: center;
  background: ${props => props.theme.palette.neutral.shade2};
  box-shadow: rgba(9, 30, 66, 0.1) 0px 1px 1px, rgba(9, 30, 66, 0.14) 0px 0px 1px 0px;
  margin: 0 8px 16px 0;

  p {
    margin: 0 5px 0 0;
    text-align: center;
  }
  span {
    svg {
      width: 16px;
      height: 16px;
      cursor: pointer;
      fill: ${props => props.theme.palette.neutral.shade5};
      transition: all 0.3s linear;

      stroke {
        fill: ${props => props.theme.palette.neutral.shade5};
      }
    }
    &.danger {
      svg:hover {
        fill: ${props => props.theme.palette.danger.shade3};
        stroke {
          fill: ${props => props.theme.palette.danger.shade3};
        }
      }
    }
    &.primary {
      svg:hover {
        fill: ${props => props.theme.palette.primary.shade5};
        stroke {
          fill: ${props => props.theme.palette.primary.shade5};
        }
      }
    }
  }
`;

export default FileItem;
