import AxiosConfig, { RequestConfig } from 'config/api/AxiosConfig';
import { PaginationInfo } from '../../../types';
import { ActivitiesFilters } from '../types';
import { ACTIVITIES_URL } from './endpoints';

const getActivities = async (pageInfo: PaginationInfo, filters?: ActivitiesFilters, sorting?: string[]) => {
  const activityTypes = filters?.activityTypes?.map(type => type.activityTypeId);
  const organizationIds = filters?.organizationIds?.map(organization => organization.organizationId);

  const requestConfig: RequestConfig = {
    params: {
      pageIndex: pageInfo.pageIndex || 1,
      pageSize: pageInfo.pageSize,
      ...(filters && {
        search: filters.search,
        activityTypes,
        organizationIds,
        dateFrom: filters.dateFrom,
        dateUntil: filters.dateUntil,
      }),
      ...(sorting && { orderBys: sorting }),
    },
    url: ACTIVITIES_URL,
  };

  return AxiosConfig.fetchAxiosPagedGetRequest(requestConfig);
};

const getActivityTypes = (search?: string) => {
  const requestConfig: RequestConfig = {
    params: {
      ...(search && { search }),
    },
    url: `${ACTIVITIES_URL}/types`,
  };

  return AxiosConfig.fetchAxiosGetRequest(requestConfig);
};

const getActivityOrganizations = (search?: string) => {
  const requestConfig: RequestConfig = {
    params: {
      take: 20,
      ...(search && { search }),
    },
    url: `${ACTIVITIES_URL}/organizations`,
  };

  return AxiosConfig.fetchAxiosGetRequest(requestConfig);
};

export default {
  getActivities,
  getActivityOrganizations,
  getActivityTypes,
};
