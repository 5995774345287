import { Sector, SectorFilters } from 'modules/sector/types/sector';
import { combineReducers } from 'redux';
import { PaginationInfo, SortValue } from 'types';
import filterReducer from './filters';
import pageInfoReducer from './pageInfo';
import sectorReducer from './sector';
import sortingReducer from './sorting';

export type SectorModuleState = {
  filters: SectorFilters;
  pageInfo: PaginationInfo;
  sectors: Sector[] | null;
  sorting: SortValue[];
};

const sectorModuleReducer = combineReducers({
  filters: filterReducer,
  pageInfo: pageInfoReducer,
  sectors: sectorReducer,
  sorting: sortingReducer,
});

export default sectorModuleReducer;
