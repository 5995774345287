import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '../../config/theme';
import { Button } from '../Button';

type Props = {
  callback: (...args: any) => void;
  cancelText?: string;
  closeMenu?: () => void;
  confirmText: string;
  danger?: boolean;
  description?: string;
  primary?: boolean;
};

const BasicConfirmation: React.FunctionComponent<Props> = ({
  callback,
  cancelText,
  closeMenu,
  confirmText,
  danger,
  description,
  primary,
}: Props) => {
  const { t } = useTranslation('global');
  const onClick = React.useCallback(
    (...args) => {
      callback(...args);
      if (closeMenu) {
        closeMenu();
      }
    },
    [callback, closeMenu]
  );

  return (
    <Wrapper>
      {description && <p className="description">{description}</p>}
      <div>
        <Button tertiary onClick={closeMenu}>
          {cancelText || t('general.cancel')}
        </Button>
        <Button danger={danger} primary={primary} onClick={onClick}>
          {confirmText}
        </Button>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;

  .description {
    color: ${props => props.theme.contextMenu.color.blockTitle};
    font-size: ${props => props.theme.contextMenu.fontSize.blockTitle};
    font-weight: ${props => props.theme.contextMenu.fontWeight.blockTitle};
    letter-spacing: 1.5px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
  }

  > div {
    display: flex;
    margin-top: 10px;

    button {
      margin-left: 5px;
      :nth-child(1) {
        margin-left: 0px;
      }
    }
  }
`;

export default BasicConfirmation;
