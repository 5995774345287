import { AnimationProps } from 'config/theme/types';
import styled, { css } from 'styled-components';

export const CollapsibleSidebarContainer = styled.div`
  display: flex;
  position: relative;

  &:hover {
    > div:last-child {
      opacity: 1;
    }
  }
`;

export const ToggleContainer = styled.div`
  ${({ theme }) => {
    return css`
      position: absolute;
      width: 48px;
      transition: all 0.3s ease;
      z-index: 200;
      opacity: 1;
      left: -23px;
      height: 48px;
      top: 36px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        cursor: pointer;

        button {
          background-color: ${theme.palette.primary.shade6};
        }
      }
    `;
  }}
`;

export const CollapsibleToggle = styled.button<AnimationProps>`
  ${({ theme, state }) => {
    return css`
      ${state === 'entering' && theme.itemSidebar.animation.entering};
      ${state === 'entered' && theme.itemSidebar.animation.entered};
      border: 0;
      background-color: ${theme.palette.primary.shade5};
      border-radius: 50%;
      box-shadow: rgba(9, 30, 66, 0.08) 0 0 0px 1px, rgba(9, 30, 66, 0.08) 0px 2px 4px 1px;
      color: rgb(107, 119, 140);
      cursor: pointer;
      height: 30px;
      padding: 0;
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        fill: white;
        width: 18px;
        height: 18px;
      }
    `;
  }};
`;
