const certPalette = {
  danger: {
    shade1: '#FEE4E3',
    shade2: '#F8C7C5',
    shade3: '#F78B87',
    shade4: '#DF5858',
    shade5: '#B22A2A',
    shade6: '#A01818',
    shade7: '#7F0D0D',
    shade8: '#5F0505',
    shade9: '#440404',
  },
  neutral: {
    shade0: '#ffffff',
    shade1: '#F5F7FD',
    shade2: '#ECF1F8',
    shade3: '#DFE4EB',
    shade4: '#AFB8C4',
    shade5: '#768085',
    shade6: '#686B82',
    shade7: '#484C60',
    shade8: '#2C353F',
    shade9: '#070b10',
  },
  primary: {
    shade1: '#ECF6FB',
    shade2: '#B2E2F7',
    shade3: '#7FD3F7',
    shade4: '#5BC5F2',
    shade5: '#748FC9',
    shade6: '#585FA8',
    shade7: '#3C448D',
    shade8: '#313874',
    shade9: '#222750',
  },
  secondary: {
    shade1: '#FFF6ED',
    shade2: '#FFEDDA',
    shade3: '#FFD3A4',
    shade4: '#DA954A',
    shade5: '#FF8200',
    shade6: '#D86E00',
    shade7: '#A45708',
    shade8: '#743F07',
    shade9: '#4C2B09',
  },
  success: {
    shade1: '#E3FCEF',
    shade2: '#BCF7D7',
    shade3: '#8ADCB0',
    shade4: '#46C384',
    shade5: '#188A50',
    shade6: '#19663F',
    shade7: '#0A4D2B',
    shade8: '#033B1E',
    shade9: '#002B15',
  },
  warning: {
    shade1: '#FFFFD8',
    shade2: '#FFFAA5',
    shade3: '#FFF280',
    shade4: '#F6DE62',
    shade5: '#F6D636',
    shade6: '#F6C636',
    shade7: '#EEAE18',
    shade8: '#E09C16',
    shade9: '#C57600',
  },
};

export { certPalette };
