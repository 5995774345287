import { NestedGroup } from 'helpers/groupBy';
import { NOTIFICATIONS_BACKEND_MODULE_KEY } from 'modules/notifications/constants';
import { NotificationType } from 'modules/notifications/types';
import { makeGetAccessRights } from 'modules/security/redux/selectors/user';
import { AccessRight } from 'modules/security/types/user';
import { hasAccessToFunctionality, MODULE_READ } from 'modules/security/util';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import notificationsApi from '../../api/notificationsApi';
import { notificationActions } from '../creators';

function* notificationsSaga() {
  yield takeLatest(
    [
      notificationActions.getNotifications,
      // Currently these are hardcoded strings cause we don't want to have dependencies to another module +
      // this is temporary, as notifications should be refactored to use a websocket implementation instead of polling.
      'ORGANIZATION_APPROVE_SUCCESS',
      'ORGANIZATION_REJECT_SUCCESS',
      'ORGANIZATION_DELETE_SUCCESS',
      'ORGANIZATION_DETAIL_USER_APPROVE_SUCCESS',
      'ORGANIZATION_DETAIL_USER_REJECT_SUCCESS',
      'ORGANIZATION_DETAIL_USER_DELETE_SUCCESS',
    ],
    handleGetNotifications
  );
}

function* handleGetNotifications() {
  const accessRights: NestedGroup<AccessRight> = yield select(makeGetAccessRights());
  if (hasAccessToFunctionality(MODULE_READ, accessRights, NOTIFICATIONS_BACKEND_MODULE_KEY)) {
    const { request, success, failure } = notificationActions.getNotificationsRequest;
    yield request();
    try {
      const payload: NotificationType[] = yield call(notificationsApi.getNotifications);
      yield put(success(payload));
      yield delay(300000);
      yield put(notificationActions.getNotifications());
    } catch (e) {
      yield put(failure(e.response));
      yield delay(300000);
      yield put(notificationActions.getNotifications());
    }
  }
}

export default notificationsSaga;
