import {
  alertTheme,
  badgeTheme,
  buttonTheme,
  contextMenuTheme,
  formTheme,
  fullViewTheme,
  headerTheme,
  iconButtonTheme,
  itemSidebarTheme,
  paperTheme,
  scrollbarTheme,
  showMoreTheme,
  sidebarTheme,
  skeletonTheme,
  tableTheme,
} from './components';
import getModalTheme from './components/modal';
import getDatePickerTheme from './components/datePicker';
import {
  helpCenterTheme,
  loginTheme,
  mailTheme,
  notificationsTheme,
  onboardingTheme,
  organizationTheme,
} from './partials';
import { certTheme } from './tenants';

const themes = {
  default: {
    alert: alertTheme(certTheme),
    badge: badgeTheme(certTheme),
    button: buttonTheme(certTheme),
    contextMenu: contextMenuTheme(certTheme),
    datePicker: getDatePickerTheme(certTheme),
    form: formTheme(certTheme),
    fullView: fullViewTheme(certTheme),
    header: headerTheme(certTheme),
    helpCenter: helpCenterTheme(certTheme),
    iconButton: iconButtonTheme(certTheme),
    itemSidebar: itemSidebarTheme(certTheme),
    login: loginTheme(certTheme),
    mail: mailTheme(certTheme),
    modal: getModalTheme(certTheme),
    notifications: notificationsTheme(certTheme),
    onboarding: onboardingTheme(certTheme),
    organization: organizationTheme(certTheme),
    palette: certTheme.palette,
    paper: paperTheme(certTheme),
    scrollbar: scrollbarTheme(certTheme),
    showMore: showMoreTheme(certTheme),
    sidebar: sidebarTheme(certTheme),
    skeleton: skeletonTheme(certTheme),
    table: tableTheme(certTheme),
    typography: certTheme.typography,
  },
};

export default themes;
