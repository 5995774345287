import { RouteLeavingGuard } from 'components';
import { Form, Formik } from 'formik';
import { mapSingleFile } from 'helpers/singleFileUploadHelper';
import { organizationDetailActions } from 'modules/organization/redux/actions/creators';
import { generalInfoSchema } from 'modules/organization/schemas/organizationDetail';
import {
  GeneralInfo,
  GeneralInfoEditValues,
  GeneralInfoPostValues,
} from 'modules/organization/types/organizationDetail/general';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { IpRangeEditValues, IpType, KeywordType } from '../../../../../../app/types';
import AlertFeed from './AlertFeed';
import BusinessLocations from './BusinessLocations';
import IpRanges from './IpRanges';
import Misp from './Misp';
import Tls from './Tls';

type Props = {
  formRef: React.RefObject<any>;
  generalInfo: GeneralInfo;
  setHasChanges: (hasChanges: boolean) => void;
  hasChanges: boolean;
  organizationId: string;
  setEditMode: (editMode: boolean) => void;
  successCallback?: Function;
};

const GeneralInfoEdit: React.FunctionComponent<Props> = ({
  formRef,
  generalInfo,
  setHasChanges,
  organizationId,
  setEditMode,
  hasChanges,
  successCallback,
}: Props) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    return () => {
      setEditMode(false);
    };
  }, [setEditMode]);

  const successCallbackOnUpdate = () => {
    successCallback?.();
    setEditMode(false);
  };

  const onSubmit = React.useCallback(
    (values: GeneralInfoEditValues) => {
      const { mispPocPgpFile, mispLogoFile, mispToMisp, ...restValues } = values;
      const postValues: GeneralInfoPostValues = {
        ...restValues,
        accountNeeded: mispToMisp === 'null' ? undefined : restValues.accountNeeded === 'true',
        ipRanges:
          mispToMisp === 'null'
            ? values.ipRanges.filter((item) => item.rangeType === IpType.publicList)
            : values.ipRanges
            ? values.ipRanges.map((ipRange: IpRangeEditValues) => ({
                ...ipRange,
                ipVersion: ipRange.ipVersion,
              }))
            : [],
        keywords: mispToMisp === 'null' ? [] : [...values.mispDomains, ...values.mispSectors],
        mispOrganizationName: mispToMisp === 'null' ? undefined : restValues.mispOrganizationName,
        mispPocEmail: mispToMisp === 'null' ? undefined : restValues.mispPocEmail,
        mispExtraEmail: mispToMisp === 'null' ? undefined : restValues.mispExtraEmail,
        mispCountryId: mispToMisp === 'null' ? undefined : restValues.mispCountryId,
        mispDescription: mispToMisp === 'null' ? undefined : restValues.mispDescription,
        mispOrganizationGuid:
          mispToMisp === 'null'
            ? undefined
            : mispToMisp === 'true' || (mispToMisp === 'false' && restValues.accountNeeded === 'true')
            ? restValues.mispOrganizationGuid
            : '',
        mispToMisp: mispToMisp === 'null' ? null : mispToMisp === 'true',
        ...mapSingleFile(
          'mispLogoFileId',
          'mispLogoFileName',
          'mispLogoContent',
          mispToMisp === 'null' ? undefined : mispLogoFile || undefined
        ),
        ...mapSingleFile(
          'mispPocPgpFileId',
          'mispPocPgpFileName',
          'mispPocPgpContent',
          mispToMisp === 'null' ? undefined : mispPocPgpFile || undefined || undefined
        ),
      };
      dispatch(organizationDetailActions.updateGeneralInfo(organizationId, postValues, successCallbackOnUpdate));
    },
    [dispatch, organizationId]
  );

  return (
    <React.Fragment>
      <RouteLeavingGuard when={hasChanges} />

      <Formik
        initialValues={{
          alertFeedEmail: generalInfo.alertFeedEmail || '',
          alertPhone: generalInfo.alertPhone || '',
          businessLocations: generalInfo.businessLocations.map((address) => ({
            ...address,
            countryId: address.country.countryId,
            number: address.number.toString(),
            zipCode: address.zipCode.toString(),
          })),
          ipRanges: generalInfo.ipRanges,
          whiteIpRanges: generalInfo.ipRanges.filter((item) => item.rangeType === IpType.whiteList),
          publicIpRanges: generalInfo.ipRanges.filter((item) => item.rangeType === IpType.publicList),
          mispLogoFile: generalInfo.mispLogoFileId
            ? {
                base64: '',
                file: null,
                mispLogoFileId: generalInfo.mispLogoFileId,
                name: generalInfo.mispLogoFileName || '',
              }
            : null,
          mispOrganizationGuid: generalInfo.mispOrganizationGuid || '',
          mispOrganizationName: generalInfo.mispOrganizationName || '',
          mispPocEmail: generalInfo.mispPocEmail || '',
          mispPocPgpFile: generalInfo.mispPocPgpFileId
            ? {
                base64: '',
                file: null,
                mispPocPgpFileId: generalInfo.mispPocPgpFileId,
                name: generalInfo.mispPocPgpFileName || '',
              }
            : null,

          mispToMisp: generalInfo.mispToMisp === null ? 'null' : generalInfo.mispToMisp?.toString() || '',
          tlsEmail: generalInfo.tlsEmail || '',
          accountNeeded: generalInfo.accountNeeded?.toString() || '',
          mispCountryId: generalInfo.mispCountry?.countryId,
          mispDescription: generalInfo.mispDescription || '',
          mispExtraEmail: generalInfo.mispExtraEmail || '',
          mispDomains: generalInfo.keywords.filter((item) => item.type === KeywordType.MispEmailDomain) || [],
          mispSectors: generalInfo.keywords.filter((item) => item.type === KeywordType.MispSector) || [],
        }}
        onSubmit={(values) => {
          onSubmit({
            ...values,
            ipRanges: [
              ...values.whiteIpRanges.map((item) => ({ ...item, rangeType: IpType.whiteList })),
              ...values.publicIpRanges.map((item) => ({ ...item, rangeType: IpType.publicList })),
            ],
          });
        }}
        ref={formRef}
        validationSchema={generalInfoSchema}
        validate={() => setHasChanges(true)}
        render={({ touched, errors, values }) => (
          <Form>
            <BusinessLocations businessLocations={values.businessLocations} errors={errors} touched={touched} />
            <Tls errors={errors} touched={touched} />
            <AlertFeed errors={errors} touched={touched} />
            <Misp values={values} errors={errors} touched={touched} />
            <IpRanges
              whiteIpRanges={values.whiteIpRanges}
              publicIpRanges={values.publicIpRanges}
              errors={errors}
              touched={touched}
              showWhiteIpRanges={values.mispToMisp !== 'null'}
            />
          </Form>
        )}
      />
    </React.Fragment>
  );
};

export default GeneralInfoEdit;
