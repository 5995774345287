import { FullViewBlock } from 'components/FullView';
import { FieldArray, FormikErrors, FormikTouched } from 'formik';
import {
  BusinessLocationEditValues,
  GeneralInfoEditValues,
} from 'modules/organization/types/organizationDetail/general';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import EditAddressBlock from './EditAddressBlock';

type Props = {
  businessLocations: BusinessLocationEditValues[];
  errors: FormikErrors<GeneralInfoEditValues>;
  touched: FormikTouched<GeneralInfoEditValues>;
};

const BusinessLocations: React.FunctionComponent<Props> = ({ businessLocations, touched, errors }: Props) => {
  const { t } = useTranslation('organization');

  return (
    <FieldArray
      name="businessLocations"
      render={arrayHelpers => (
        <FullViewBlock amountOfButtons={2}>
          <h2 className="block-title">{t('detail.blocks.locations')}</h2>
          <div className="block-data">
            {businessLocations &&
              businessLocations.length > 0 &&
              businessLocations.map((location: BusinessLocationEditValues, index: number) => (
                <EditAddressBlock
                  key={index}
                  location={location}
                  index={index}
                  errors={errors}
                  touched={touched}
                  arrayHelpers={arrayHelpers}
                />
              ))}
          </div>
        </FullViewBlock>
      )}
    />
  );
};

export default BusinessLocations;
