import * as React from 'react';
import BaseSvgWrapper from './BaseSvgWrapper';

const CriticalAlerts: React.FunctionComponent = (): JSX.Element => (
  <BaseSvgWrapper>
    <svg width="59" height="59" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M31.79 43.0031C33.18 43.0031 34.29 44.1231 34.29 45.5031C34.29 46.8831 33.18 48.0031 31.79 48.0031C30.41 48.0031 29.3 46.8831 29.3 45.5031C29.3 44.1231 30.41 43.0031 31.79 43.0031Z" />
      <path d="M45.82 9.98298C57.98 17.613 61.66 33.653 54.019 45.823L50.639 43.693C57.089 33.403 53.98 19.823 43.69 13.363C33.4 6.91298 19.819 10.023 13.359 20.313C6.90899 30.603 10.019 44.183 20.309 50.643C26.819 54.723 34.989 55.113 41.869 51.663L43.659 55.243C35.539 59.313 25.879 58.853 18.179 54.023C6.01899 46.393 2.33899 30.353 9.97899 18.183C17.61 6.02299 33.65 2.34298 45.82 9.98298Z" />
      <path d="M48.21 46.874L51.16 49.574C50.099 50.734 48.93 51.804 47.67 52.754L45.26 49.564C46.32 48.753 47.31 47.854 48.21 46.874Z" />
      <path d="M30 16H34V39H30V16Z" />
    </svg>
  </BaseSvgWrapper>
);

export default CriticalAlerts;
