import { ActionType } from 'typesafe-actions';
import * as alertRulesAction from './alertRules';
import * as sidebarAction from './sidebar';

export const ALERT_RULES_ACTION_PREFIX = 'ALERT_RULES';
export const ALERT_RULES__GET_ACTION_PREFIX = 'ALERT_RULES_GET';

export const alertRulesActions = { ...alertRulesAction };
export const sidebarActions = { ...sidebarAction };

export type AlertRuleActions = ActionType<typeof alertRulesAction>;
export type SidebarActions = ActionType<typeof sidebarAction>;
