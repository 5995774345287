import { formatSortingForApi } from 'helpers/sortingFormatter';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { searchActions } from '../../../app/redux/creators';
import ActivitiesApi from '../../api/ActivitiesApi';
import { ACTIVITIES_FILTER_KEY } from '../../constants';
import { activitiesActions, filterActions } from '../creators';
import {
  makeGetActivitiesFilters,
  makeGetActivitiesPaginationInfo,
  makeGetActivitiesSorting,
} from '../selectors/activities';

function* activitiesSaga() {
  yield takeLatest(
    [
      activitiesActions.getActivities,
      activitiesActions.setPage,
      activitiesActions.setPageSize,
      activitiesActions.setSorting,
    ],
    handleGetActivities
  );
  yield takeLatest(
    [
      filterActions.setTypeFilter,
      filterActions.setDateFromFilter,
      filterActions.setDateUntilFilter,
      filterActions.setOrganizationFilter,
    ],
    handleGetActivitiesWithPageReset
  );
  yield takeLatest(searchActions.setSearch, handleSearchChange);
}

function* handleGetActivities() {
  const { request, success, failure } = activitiesActions.getActivitiesRequest;
  yield put(request());

  try {
    const filters = yield select(makeGetActivitiesFilters());
    const pageInfo = yield select(makeGetActivitiesPaginationInfo());
    const sorting = yield select(makeGetActivitiesSorting());

    const payload = yield call(ActivitiesApi.getActivities, pageInfo, filters, formatSortingForApi(sorting));
    yield put(success(payload));
  } catch (e) {
    yield put(failure(e.response));
  }
}

function* handleGetActivitiesWithPageReset() {
  yield put(activitiesActions.setPage(1));
}

function* handleSearchChange(action: ReturnType<typeof searchActions.setSearch>) {
  if (action.payload.filterKey === ACTIVITIES_FILTER_KEY) {
    yield put(activitiesActions.setPage(1));
  }
}

export default activitiesSaga;
