import styled from 'styled-components';

export type DrawerProps = {
  open: boolean;
};

export const Drawer = styled.aside<DrawerProps>`
  background: ${props => props.theme.notifications.drawer.background};
  border-right: ${props => props.theme.notifications.drawer.border};
  bottom: 0;
  left: ${props => (props.open ? 0 : `calc(-${props.theme.sidebar.width.huge} * 1.2)`)};
  padding: 16px 0 0;
  position: fixed;
  top: 0;
  transition: ${props => props.theme.notifications.drawer.transition};
  width: calc(${props => props.theme.sidebar.width.huge} * 1.2);
  z-index: 1000;
  display: flex;
  flex-direction: column;

  header {
    border-bottom: 1px solid ${props => props.theme.palette.neutral.shade2};
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
  }

  h3 {
    font-size: ${props => props.theme.notifications.drawer.fontSize.header};
    font-weight: ${props => props.theme.notifications.drawer.fontWeight.header};
    margin-bottom: 32px;
    margin-left: 8px;
    margin-top: 3px;
  }
`;
