import { SuperUser, SuperUserPostBody } from 'modules/superUsers/types';
import { HttpErrorData, HttpResponse, SuccessCallback } from 'types';
import { createAction, createAsyncAction, createCustomAction } from 'typesafe-actions';

export const getSuperUsers = createAction('SUPER_USERS_GET');
export const getSuperUsersRequest = createAsyncAction(
  'SUPER_USERS_REQUEST',
  'SUPER_USERS_SUCCESS',
  'SUPER_USERS_REJECTED'
)<undefined, HttpResponse<SuperUser>, HttpErrorData>();

export const addSuperUser = createCustomAction(
  'SUPER_USER_POST',
  type => (values: SuperUserPostBody, successCallback?: SuccessCallback) => ({
    payload: values,
    successCallback,
    type,
  })
);
export const addSuperUserRequest = createAsyncAction(
  'SUPER_USER_POST_REQUEST',
  'SUPER_USER_POST_SUCCESS',
  'SUPER_USER_POST_REJECTED'
)<undefined, SuperUser, HttpErrorData>();

export const disableSuperUser = createCustomAction(
  'SUPER_USER_DISABLE',
  type => (guid: string, successCallback?: SuccessCallback) => ({
    payload: guid,
    successCallback,
    type,
  })
);
export const disableSuperUserRequest = createAsyncAction(
  'SUPER_USER_DISABLE_REQUEST',
  'SUPER_USER_DISABLE_SUCCESS',
  'SUPER_USER_DISABLE_REJECTED'
)<undefined, string, HttpErrorData>();

export const enableSuperUser = createCustomAction(
  'SUPER_USER_ENABLE',
  type => (guid: string, successCallback?: SuccessCallback) => ({
    payload: guid,
    successCallback,
    type,
  })
);
export const enableSuperUserRequest = createAsyncAction(
  'SUPER_USER_ENABLE_REQUEST',
  'SUPER_USER_ENABLE_SUCCESS',
  'SUPER_USER_ENABLE_REJECTED'
)<undefined, string, HttpErrorData>();

export const reset2FA = createCustomAction(
  'SUPER_USERS_RESET_2FA',
  type => (guid: string, successCallback?: SuccessCallback) => ({
    payload: guid,
    successCallback,
    type,
  })
);
export const reset2FARequest = createAsyncAction(
  'SUPER_USERS_RESET_2FA_REQUEST',
  'SUPER_USERS_RESET_2FA_SUCCESS',
  'SUPER_USERS_RESET_2FA_REJECTED'
)<undefined, undefined, HttpErrorData>();

export const downgradeSuperUser = createCustomAction(
  'SUPER_USERS_DOWNGRADE',
  type => (guid: string, successCallback?: SuccessCallback) => ({
    payload: guid,
    successCallback,
    type,
  })
);
export const downgradeSuperUserRequest = createAsyncAction(
  'SUPER_USERS_DOWNGRADE_REQUEST',
  'SUPER_USERS_DOWNGRADE_SUCCESS',
  'SUPER_USERS_DOWNGRADE_REJECTED'
)<undefined, string, HttpErrorData>();
